import { ButtonRow } from 'app/components/button-row';
import clsx from 'clsx';
import React from 'react';

export const SelectAddMode = ({ currentMode, onSelectMode, onBack }) => {
  return (
    <div className="flex flex-col">
      <div className="flex gap-5">
        {OPTIONS.map(item => (
          <OptionButton
            data={item}
            key={item.value}
            onClick={value => onSelectMode(value)}
            currentValue={currentMode}
          />
        ))}
      </div>
      <ButtonRow onLeft={onBack} />
    </div>
  );
};

const OptionButton = ({
  data,
  currentValue,
  onClick,
}: {
  data: OptionType;
  currentValue: string;
  onClick: (value: string) => void;
}) => {
  const { imgSrc, label, value } = data || {};
  const isSelected = currentValue === value;
  return (
    <button
      className={clsx(
        'flex flex-col items-center rounded-xl border border-green-100 p-5 hover:bg-green-100',
        isSelected && 'bg-main-green',
      )}
      onClick={() => onClick(value)}
    >
      <img className="mb-3 h-44 w-44" src={imgSrc} />
      <div className={clsx('w-32 text-center hover:text-black', isSelected && 'text-white')}>{label}</div>
    </button>
  );
};

type OptionType = {
  imgSrc: string;
  label: string;
  value: string;
};
const OPTIONS: OptionType[] = [
  {
    imgSrc: '/img/eb/single-onboard-group.svg',
    label: 'Individual Onboarding',
    value: 'single',
  },
  {
    imgSrc: '/img/eb/bulk-onboard-group.svg',
    label: 'Bulk Onboarding',
    value: 'bulk',
  },
];
