import { useGoogleLogin } from '@react-oauth/google';
import clsx from 'clsx';
import type { FC } from 'react';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { SyncLoader } from 'react-spinners';

interface LoginPanelProps {
  fetching: boolean;
  apiError: string;
  onSubmit: (body: any) => void;
  onForgotPassword: () => void;
  className?: string;
}
export const LoginPanel: FC<LoginPanelProps> = ({ fetching, apiError, onSubmit, onForgotPassword, className }) => {
  const [form, setForm] = useState<any>({});
  const [error, setError] = useState<any>({});

  const _onChange = (e: any) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
    setError({ ...error, [name]: '' });
  };

  const _validateForm = () => {
    let isValid = true;
    const errorsObj: any = {};
    if (!form.email) {
      isValid = false;
      errorsObj.email = 'Email is required';
    }
    if (!form.password) {
      isValid = false;
      errorsObj.password = 'Password is required';
    }
    setError(errorsObj);
    return isValid;
  };

  const _onSubmit = () => {
    if (!_validateForm()) return;
    onSubmit({ username: form.email, password: form.password });
  };

  const loginWithGG = useGoogleLogin({
    onSuccess: tokenResponse => {
      if (tokenResponse.access_token) {
        onSubmit({
          gg_token: tokenResponse.access_token,
        });
      } else {
        toast.error('Login failed');
      }
    },
  });

  return (
    <div className={clsx('flex h-full flex-col rounded-3xl bg-white p-5 drop-shadow-lg md:p-10 xl:p-20', className)}>
      <img className="w-60 object-contain xl:w-80" src="/img/logo-full.png" alt="gigacover-green-logo" />
      <div className="flex flex-1 flex-col items-center justify-center gap-4 xl:gap-6">
        <div className="font-workSans text-2xl font-bold text-gray-900 xl:text-4xl">Welcome Back!</div>
        <FormInput
          className="w-full"
          name="email"
          type="email"
          label="Email Address"
          value={form?.email}
          error={error?.email}
          onChange={_onChange}
        />
        <FormInput
          className="w-full"
          name="password"
          type="password"
          label="Password"
          value={form?.password}
          error={error?.password}
          onChange={_onChange}
        />
        <button
          className="text-sm font-semibold hover:cursor-pointer hover:text-main-green"
          disabled={fetching}
          onClick={onForgotPassword}
        >
          Forgot password?
        </button>
        {apiError ? <div className="text-sm text-red-600">{apiError}</div> : null}
        <button
          className="flex h-12 w-full items-center justify-center gap-2 rounded-full bg-main-green font-semibold text-white drop-shadow-md"
          disabled={fetching}
          onClick={_onSubmit}
        >
          {fetching ? <SyncLoader color="#ddd" speedMultiplier={0.5} size={10} /> : 'LOGIN'}
        </button>
        <div className="flex w-full items-center">
          <div className="h-px flex-1 bg-gray-300" />
          <div className="mx-4 font-medium text-gray-500">OR</div>
          <div className="h-px flex-1 bg-gray-300" />
        </div>
        <button
          className="flex h-12 w-full items-center justify-center gap-2 rounded-full border border-gray-300 bg-white font-semibold text-gray-500 drop-shadow-md"
          onClick={() => loginWithGG()}
        >
          <img src="/img/google.svg" alt="google-icon" />
          <div>Sign in with Google</div>
        </button>
      </div>
    </div>
  );
};

interface FormInputProps {
  value: string;
  name: string;
  label: string;
  type: string;
  error: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  className?: string;
}
const FormInput: FC<FormInputProps> = ({ value, name, label, type, error, onChange, className }) => {
  return (
    <div className={clsx('flex flex-col gap-1', className)}>
      <label className="font-workSans text-sm font-medium">{label}</label>
      <input
        className="rounded-md border border-gray-300 p-2 outline-none"
        name={name}
        type={type}
        value={value}
        onChange={onChange}
      />
      {!!error && <div className="text-sm text-red-600">{error}</div>}
    </div>
  );
};
