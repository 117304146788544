export const validateEmail = email => {
  const regex = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;

  return regex.test(email);
};

export const validatePhone = phoneNumber => {
  if (!phoneNumber) return false;
  const regex = /^\d{8}$/g;
  const firstChar = phoneNumber.toString().charAt(0);
  const expectedFirstChars = ['3', '6', '8', '9'];

  if (regex.test(phoneNumber) === false) {
    return false;
  }

  if (!expectedFirstChars.includes(firstChar)) {
    return false;
  }

  return true;
};

export const validateNricFin = nric => {
  const regex = /^[STFG]\d{7}[A-Z]$/;

  if (regex.test(nric) === false) {
    return false;
  }

  return true;
};

export const validateFileCSV = file => {
  if (!file) return false;

  const regexFile = /^.+\.(xlsx)$/;

  return regexFile.test(file.name);
};

export const validateVehicleReg = value => {
  if (!value) return false;
  const regexReg = /^[A-Z0-9]*$/;

  return regexReg.test(value);
};

export const validateVehicleType = value => {
  if (!value || typeof value !== 'string') return false;

  return (
    value.toLowerCase() === 'car' ||
    value.toLowerCase() === 'van' ||
    value.toLowerCase() === 'bike' ||
    value.toLowerCase() === 'bus'
  );
};

export const getRequiredFieldError = (value: string) => {
  if (typeof value === 'string' ? !value?.trim() : !value) return 'This field is required';
  return undefined;
};

export const getEmailError = (value: string) => {
  if (!value?.trim()) return 'This field is required';
  if (!validateEmail(value)) return 'Please input valid email';
  return undefined;
};
