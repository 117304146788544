import { ImagePreviewProvider } from 'app/context';
import type { FC } from 'react';
import React from 'react';

import { ClaimBottomButton } from './claim-bottom-button';

interface ClaimPageLayoutProps {
  children: React.ReactNode;
  fetching?: boolean;
  onSubmit?: () => void;
  title?: string;
}
export const ClaimPageLayout: FC<ClaimPageLayoutProps> = ({ title, children, fetching, onSubmit }) => {
  return (
    <ImagePreviewProvider>
      <div className="flex flex-col items-center">
        <div className="relative flex w-full items-center justify-center overflow-hidden">
          <img className="absolute bottom-0 -z-10 w-full" src="/img/banner/banner-bg.png" />
          <div className="flex flex-col items-center py-5">
            <img className="w-20" src="/img/banner/claim-icon.svg" />
            <div className="mt-1 text-2xl font-medium text-white">{title || 'Claim'}</div>
          </div>
        </div>
        <div className="flex w-full max-w-7xl flex-col p-7">
          {children}
          <ClaimBottomButton fetching={fetching} onSubmit={onSubmit} />
        </div>
      </div>
    </ImagePreviewProvider>
  );
};
