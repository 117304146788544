import { Switch } from '@headlessui/react';
import clsx from 'clsx';
import React from 'react';

export const SimpleSwitch = ({ checked, onChange, disabled = false }) => {
  return (
    <Switch
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      className={clsx(
        checked ? 'flex-row bg-main-green' : 'flex-row-reverse bg-gray-200',
        disabled && 'cursor-not-allowed opacity-50',
        'relative inline-flex h-7 w-16 items-center justify-between rounded-full px-1',
      )}
    >
      <span className={clsx('flex-1 text-center', checked ? 'text-white' : 'text-gray-400')}>
        {checked ? 'On' : 'Off'}
      </span>
      <span className="h-5 w-5 rounded-full bg-white " />
    </Switch>
  );
};
