import { ApiInstance } from 'app/services/api';
import { handleError } from 'app/services/apiHelper';
import debounce from 'lodash/debounce';
import { useCallback, useRef, useState } from 'react';
import toast from 'react-hot-toast';

export const useOverviewList = () => {
  const [searchParams, setSearchParams] = useState<any>({ page_number: 0, page_size: 5 });
  const [vehicleReg, setVehicleReg] = useState('');

  const [overviewList, setOverviewList] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [fetching, setFetching] = useState(false);
  const [totalData, setTotalData] = useState(0);
  const fetchingApi = useRef(false);

  const searchParamsRef = useRef<any>({ page_number: 0, page_size: 5 });

  const onChangeVehicleReg = e => {
    const text = e.target.value;
    setVehicleReg(text);
    _debounceSearch(text);
  };

  const _debounceSearch = useCallback(
    debounce(text => {
      updateSearchParams({ search_text: text });
    }, 500),
    [],
  );

  const updateSearchParams = data => {
    const overrideParams: any = {};

    const searchParamsFromRef = searchParamsRef.current || {};

    if (
      (data.vehicleReg && searchParamsFromRef.vehicleReg !== data.vehicleReg) ||
      (data.start_date_from && searchParamsFromRef.start_date_from !== data.start_date_from) ||
      (data.start_date_to && searchParamsFromRef.start_date_to !== data.start_date_to) ||
      (data.end_date_from && searchParamsFromRef.end_date_from !== data.end_date_from) ||
      (data.end_date_to && searchParamsFromRef.end_date_to !== data.end_date_to) ||
      (data.current_status && searchParamsFromRef.current_status !== data.current_status) ||
      (data.upcoming_status && searchParamsFromRef.upcoming_status !== data.upcoming_status) ||
      (data.is_auto_renewal && searchParamsFromRef.is_auto_renewal !== data.is_auto_renewal)
    ) {
      overrideParams.page_number = 0;
    }
    onFetchData({ ...searchParamsFromRef, ...data, ...overrideParams });
  };

  const refreshData = () => {
    updateSearchParams({});
  };

  const onFetchData = async params => {
    if (fetchingApi.current) return;
    setSearchParams(params);
    searchParamsRef.current = params;
    setFetching(true);
    const body = { ...params, page_number: params.page_number + 1 };
    Object.keys(body).forEach(key => {
      if (body[key] !== false && !body[key]) delete body[key];
    });
    fetchingApi.current = true;
    const res = await ApiInstance.getVehiclesList(body);
    fetchingApi.current = false;
    setFetching(false);
    const { error, result } = handleError(res);
    if (error) {
      toast.error(error.message);
    } else {
      setTotalData(result?.total_active_vehicle || 0);
      setOverviewList(result?.data || []);
      setTotalPages(Math.ceil((result?.total_data || 0) / (params.page_size || 1)));
    }
  };

  return {
    fetching,
    vehicleReg,
    onChangeVehicleReg,

    searchParams,
    updateSearchParams,
    refreshData,
    totalData,

    overviewList,
    totalPages,
  };
};
