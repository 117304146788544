import type { AnyAction } from 'redux';
import type { DefaultActionCreators, DefaultActionTypes } from 'reduxsauce';
import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

export interface DataAction extends AnyAction {}

interface IActionTypes extends DefaultActionTypes {
  GET_DATA: 'getData';
  SET_LIST_COUNTRIES: 'setListCountries';
  SET_LIST_GROUPS: 'setListGroups';
  SET_LIST_TEMPLATES: 'setListTemplates';
  SET_LIST_UNITS: 'setListUnits';
  SET_PRICING_LIST: 'setPricingList';
  SET_DATA_LOADED: 'setDataLoaded';
  GET_PRODUCTS_LISTING: 'getProductsListing';
}
interface IActionCreators extends DefaultActionCreators {
  setListCountries: (data: any) => AnyAction;
  setListGroups: (data: any) => AnyAction;
  setListTemplates: (data: any) => AnyAction;
  setListUnits: (data: any) => AnyAction;
  setPricingList: (data: any) => AnyAction;
  setDataLoaded: (loaded: boolean) => AnyAction;
  getProductsListing: (userGroupId: string) => AnyAction;
}
type IActions = DataAction | AnyAction;

export interface DataState {
  listCountries: any;
  listGroups: any;
  listTemplates: any;
  listUnits: any;
  dataLoaded: boolean;
  pricingList: any[];
}
type ImmutableMyType = Immutable.ImmutableObject<DataState>;

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions<IActionTypes, IActionCreators>({
  getData: null,
  setListCountries: ['data'],
  setListGroups: ['data'],
  setListTemplates: ['data'],
  setListUnits: ['data'],
  setPricingList: ['data'],
  setDataLoaded: ['loaded'],
  getProductsListing: ['userGroupId'],
});

export const DataTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  listCountries: {},
  listGroups: {},
  listTemplates: {},
  listUnits: {},
  dataLoaded: false,
  pricingList: [],
});

/* ------------- Reducers ------------- */

// we're attempting to login
export const setListCountries = (state: ImmutableMyType, { data }: AnyAction) => state.merge({ listCountries: data });
export const setListGroups = (state: ImmutableMyType, { data }: AnyAction) => state.merge({ listGroups: data });
export const setListTemplates = (state: ImmutableMyType, { data }: AnyAction) => state.merge({ listTemplates: data });
export const setListUnits = (state: ImmutableMyType, { data }: AnyAction) => state.merge({ listUnits: data });
export const setPricingList = (state: ImmutableMyType, { data }: AnyAction) => state.merge({ pricingList: data });
export const setDataLoaded = (state: ImmutableMyType, { loaded }: AnyAction) => state.merge({ dataLoaded: loaded });
/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer<ImmutableMyType, IActions>(INITIAL_STATE, {
  [Types.SET_LIST_COUNTRIES]: setListCountries,
  [Types.SET_LIST_GROUPS]: setListGroups,
  [Types.SET_LIST_TEMPLATES]: setListTemplates,
  [Types.SET_LIST_UNITS]: setListUnits,
  [Types.SET_PRICING_LIST]: setPricingList,
  [Types.SET_DATA_LOADED]: setDataLoaded,
});

/* ------------- Selectors ------------- */
export const reducerMap = { reducerMap: { data: reducer } }; // trick to load new structure
