import clsx from 'clsx';
import type { FC, ReactNode } from 'react';
import React from 'react';

interface InforRowProps {
  label: string;
  value?: string;
  children?: ReactNode;
  labelClassName?: string;
  valueClassName?: string;
}

export const InfoRow: FC<InforRowProps> = ({ label, value, children, labelClassName, valueClassName }) => {
  return (
    <div className="mb-5 flex items-center">
      <div className={clsx('w-40 text-base font-medium', labelClassName)}>{label}</div>
      <div className={clsx('ml-3 w-40 text-base', valueClassName)}>{value ?? children}</div>
    </div>
  );
};
