import clsx from 'clsx';
import type { FC } from 'react';
import React from 'react';

interface StepBarProps {
  steps: string[];
  step: number;
}
export const StepBar: FC<StepBarProps> = ({ steps, step }) => {
  return (
    <div className="mt-8 mb-12 flex w-full items-center justify-center gap-10">
      {steps.map((text, index) => (
        <StepItem step={index + 1} key={`${index}`} currentStep={step} text={text} />
      ))}
    </div>
  );
};

const StepItem = ({ step, currentStep, text }) => {
  const isActive = step === currentStep;
  const isPass = step < currentStep;

  return (
    <div className="flex items-center">
      <div
        className={clsx(
          'flex h-10 w-10 items-center justify-center rounded-full font-bold text-white',
          isActive ? 'bg-main-green' : isPass ? 'bg-main-green opacity-75' : 'bg-zinc-300',
        )}
      >
        {step}
      </div>
      <div className={clsx('ml-5 w-44 text-lg', isActive ? '' : 'text-zinc-400')}>{text}</div>
    </div>
  );
};
