import { CheckBox } from 'app/components/check-box';
import { SimpleModal } from 'app/components/simple-modal';
import type { FC } from 'react';
import React, { useState } from 'react';

interface ClaimAgreeCheckboxProps {
  checked: boolean;
  onCheck: (checked: boolean) => void;
  shouldShowError?: boolean;
}
export const ClaimAgreeCheckbox: FC<ClaimAgreeCheckboxProps> = ({ checked, onCheck, shouldShowError }) => {
  const [showReadMore, setShowReadMore] = useState(false);

  const _onCheck = () => {
    onCheck(!checked);
  };

  return (
    <CheckBox
      showError={shouldShowError && !checked}
      error="Please check the acknowledgement"
      onClick={_onCheck}
      checked={checked}
    >
      I declare that the information disclosed on this form is to the best of my knowledge and belief, true, complete
      and accurate, and that no material information has been withheld or omitted.{' '}
      {!showReadMore ? (
        <span className="cursor-pointer text-main-green" onClick={() => setShowReadMore(true)}>
          Read more
        </span>
      ) : (
        <>
          <br />
          <br />I further acknowledge and accept that Gigacover is at liberty to deny liability or recover amounts paid,
          whether wholly or partially, if any of the information disclosed on this form is incomplete, untrue or
          incorrect in any respect or if the Policy does not provide cover on which such claim is made.
        </>
      )}
    </CheckBox>
  );
};

interface ClaimAgreeCheckboxCdwProps {
  checkState: any;
  setCheckState: (value: any) => void;
  onChangeRadio: (value: string) => void;
  shouldShowError?: boolean;
}
export const ClaimAgreeCheckboxCdw: FC<ClaimAgreeCheckboxCdwProps> = ({
  checkState,
  setCheckState,
  onChangeRadio,
  shouldShowError,
}) => {
  const [showReadMore, setShowReadMore] = useState(false);
  const [showModalTandC, setShowModalTandC] = useState(false);

  return (
    <div>
      <CheckBox
        showError={shouldShowError && !checkState?.checked1}
        error="Please check the acknowledgement"
        onClick={() => setCheckState({ ...checkState, checked1: !checkState?.checked1 })}
        checked={checkState?.checked1}
      >
        I/We declare that the information disclosed on this form is to the best of my knowledge and belief, true,
        complete and accurate, and that no material information has been withheld or omitted.{' '}
        {!showReadMore ? (
          <span className="cursor-pointer text-main-green" onClick={() => setShowReadMore(true)}>
            Read more
          </span>
        ) : (
          <>
            <br />
            <br />
            I/We understand that all information and supporting documents may be subject to review by Etiqa and Etiqa
            shall reserves all rights to reject any claims, recover any and all amounts, or to impose additional charges
            if for any reason any claim is found to be fraudulent. Etiqa shall also reserve the right to pursue any
            actions at law or in equity that it deems appropriate in dealing with such fraudulent activity.
          </>
        )}
      </CheckBox>
      {/* <div className="mt-5 w-full border-b border-b-gray-300" /> */}
      <CheckBox
        showError={shouldShowError && !(checkState?.radioState === '1')}
        error="Please check the acknowledgement"
        onClick={() => onChangeRadio(checkState?.radioState === '1' ? '' : '1')}
        checked={checkState?.radioState === '1'}
      >
        I/We have paid the Excess payable to the Rental Company and have not been reimbursed by any party for the Excess
        amount which I/we are claiming for as part of this policy.
      </CheckBox>
      {/* <CheckRadio onClick={() => onChangeRadio('0')} checked={checkState?.radioState === '0'}>
        I/We have not paid the Excess payable to the Rental Company, and authorise Etiqa Insurance Pte Ltd or their
        respective representatives or agents to make claim payments on my/our behalf to my/our Rental Company.
      </CheckRadio> */}
      {/* <div className="mt-5 w-full border-b border-b-gray-300" /> */}
      <CheckBox
        showError={shouldShowError && !checkState?.checked2}
        error="Please check the acknowledgement"
        onClick={() => setCheckState({ ...checkState, checked2: !checkState?.checked2 })}
        checked={checkState?.checked2}
      >
        I have read and understand the{' '}
        <span className="cursor-pointer text-main-green" onClick={() => setShowModalTandC(true)}>
          Terms and Conditions.
        </span>
      </CheckBox>
      <SimpleModal showCloseIcon isVisible={showModalTandC} hideModalCB={() => setShowModalTandC(false)}>
        <div className="h-full overflow-y-auto text-sm">
          <div className="mt-3 mb-2 font-medium text-main-green">Authorization</div>
          I/We hereby consent to and authorize the medical practitioner involved in the claimant's care to discuss and
          disclose treatment details and discharge arrangements with and to Etiqa Insurance Pte Ltd. I/We agree that a
          copy of this consent shall have the validity ofthe original.
          <div className="mt-3 mb-2 font-medium text-main-green">Customer's Data Privacy Consent</div>
          I/We further declare that the information written in this claim form or held by Etiqa Insurance Pte Ltd
          whether contained in my/our insurance application or otherwise obtained may be used and disclosed to your
          authorised staff, associated individualsand/or companies or any independent third parties (within or outside
          Singapore) who will provide claims administrative, advice and/or information or claims services in relation to
          my/our claim. I/We understand my/our data that may also be used for audit, business analysis and reinsurance
          purposes.
          <div className="mt-3 mb-2 font-medium text-main-green">Direct Credit</div>
          I/We confirm that there had not been any change to my tax residency status or any circumstances which affects
          my/our tax residency status and undertake to provide Etiqa with a suitably updated self-certification and
          documentation otherwise. I /Weconfirm that the payment information provided by me/us in this form is true and
          correct and undertake to immediately inform the Company of any change in the same and will not hold the
          Company liable inthe event that any payment transaction into my Account is delayed or cannot be effected due
          to incorrect or incomplete information being provided in this form, and/or for any other reason beyond the
          reasonable control ofthe Company. Notwithstanding the above, Etiqa Insurance Pte Ltd reserves the right to
          release payment to me/us by cheque if we are unable to payout the claim by direct credit
        </div>
      </SimpleModal>
    </div>
  );
};

interface ClaimAgreeCheckboxHmoProps {
  isChecked: boolean;
  setIsChecked: (value: boolean) => void;
}
export const ClaimAgreeCheckboxHmo: FC<ClaimAgreeCheckboxHmoProps> = ({ isChecked, setIsChecked }) => {
  return (
    <CheckBox centerText onClick={() => setIsChecked(!isChecked)} checked={isChecked}>
      I certify that all information is true and correct.
    </CheckBox>
  );
};
