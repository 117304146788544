import { selectors } from 'app/redux';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const VEHICE_TYPE = {
  car: { label: 'Car', value: 'car' },
  van: { label: 'Van', value: 'van' },
  bike: { label: 'Motorcycle', value: 'bike' },
  bus: { label: 'Bus', value: 'bus' },
};

export const useMerProductList = (vehicleType, section1, section2, overridePricingList?: any[]) => {
  const { pricingList: reduxPricingList } = useSelector(selectors.data);

  const pricingList = overridePricingList || reduxPricingList;

  const vehicleTypeOptions = useMemo(() => {
    if (!pricingList?.length) return [];
    const values = {};
    pricingList.forEach(item => {
      const { config_objs } = item || {};
      if (!config_objs) return;
      if (config_objs.vehicle_type) values[config_objs.vehicle_type] = true;
    });
    return Object.keys(values)
      .map(key => VEHICE_TYPE[key])
      .filter(item => item);
  }, [pricingList]);

  const { section1Options } = useMemo(() => {
    if (!pricingList?.length) return { section1Options: [] };
    const valuesSection1 = {};
    pricingList.forEach(item => {
      const { config_objs } = item || {};
      if (!config_objs || config_objs?.vehicle_type !== vehicleType) return;
      if (config_objs.section1excess || config_objs.section2excess) valuesSection1[config_objs.section1excess] = true;
    });
    return {
      section1Options: Object.keys(valuesSection1).map(key => ({ value: key, label: `$${key}` })),
    };
  }, [pricingList, vehicleType]);

  const section2Options = useMemo(() => {
    if (section1 === undefined || section1 === null) return [];
    if (!pricingList?.length) return [];
    const values = {};
    pricingList.forEach(item => {
      const { config_objs } = item || {};
      if (!config_objs || config_objs?.vehicle_type !== vehicleType) return;
      if (+config_objs.section1excess === +section1 && !config_objs.combined_excess) {
        values[config_objs.section2excess] = true;
      }
    });
    return Object.keys(values).map(key => ({ value: key, label: `$${key}` }));
  }, [pricingList, vehicleType, section1]);

  const reduceOptions = useMemo(() => {
    const values = {};
    if (!pricingList?.length || (!section1 && !section2)) return [];
    pricingList.forEach(item => {
      const { config_objs } = item || {};
      if (!config_objs || config_objs?.vehicle_type !== vehicleType) return;
      if (config_objs.section1excess === +section1 && config_objs.section2excess === +section2)
        values[config_objs.reduced_combined_excess] = true;
    });
    return Object.keys(values).map(key => ({ value: key, label: `$${key}` }));
  }, [pricingList, vehicleType, section1, section2]);

  return { section1Options, section2Options, reduceOptions, vehicleTypeOptions };
};
