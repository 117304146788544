import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface AddOneFormState {
  form: any;
  setForm: (form: any) => void;
  resetForm: () => void;
}

const DEFAULT_FORM = {
  vehicle_reg: '',
  company_uen: '',
  company_name: '',
  // contact_num: '',
  // contact_email: '',
  model: '',
  make: '',
  chassis: '',
  engine: '',
  vehicle_type: '',
  section1excess: undefined,
  section2excess: undefined,
  combined_excess: undefined,
  reduced_excess: undefined,
  // unit_id: listUnits?.months?.id,
  contract_start: undefined,
};

export const useAddOneFormStore = create<AddOneFormState>()(
  persist(
    set => ({
      form: DEFAULT_FORM,
      setForm: data => set(state => ({ form: { ...state.form, ...data } })),
      resetForm: () => set({ form: DEFAULT_FORM }),
    }),
    {
      version: 1,
      name: 'add-one-form-storage',
    },
  ),
);
