import { Button } from 'app/components/button/button';
import { Icon } from 'app/components/icon/icon';
import React from 'react';
import { useHistory } from 'react-router-dom';

export const AddEmployeeSuccess = () => {
  const history = useHistory();
  return (
    <div className="flex flex-col items-center">
      <div className="mt-20 mb-10 text-3xl font-medium text-main-green">Onboarding Successful!</div>
      <Icon name="checkGreen" size={85} />
      <div className="mb-2 mt-4 font-medium">Thank you for trusting the team!</div>
      <div>You can now go back to the dashboard and view the newly added employee.</div>
      <Button className="mt-56" text="Back to dashboard" onClick={() => history.push('/dashboard/hmo/overview')} />
    </div>
  );
};
