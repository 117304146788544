import { UserRedux } from 'app/redux/reducers';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import type { IconName } from './icon/icon';
import { Icon } from './icon/icon';

export const NavBar = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const _onItemClick = action => {
    switch (action) {
      case 'logout':
        dispatch(UserRedux.Creators.logoutRequest());
        break;
      case 'settings':
        dispatch(UserRedux.Creators.logoutRequest());
        break;

      default:
        break;
    }
  };

  const { navItems, mainIcon, mainText } = useMemo(() => {
    const { pathname } = location;
    const paths = pathname.split('/');
    switch (paths[2]) {
      case 'hmo':
        return { navItems: NAV_ITEMS_HMO, mainIcon: 'hmo' as IconName, mainText: 'HMO' };
      case 'mer':
        return { navItems: NAV_ITEMS_MER, mainIcon: 'mer' as IconName, mainText: 'Motor Excess Reduction' };
      default:
        return { navItems: [], mainIcon: '' as IconName };
    }
  }, [location]);

  return (
    <div className="group fixed top-0 left-0 z-30 flex h-screen w-[78px] flex-col bg-white drop-shadow-lg hover:w-56">
      <Link className="my-5 flex items-center pl-[26px]" to="/">
        <img className="h-8 group-hover:hidden" src="/img/logo.svg" alt="gigacover-green-logo" />
        <img className="hidden h-8 group-hover:block" src="/img/logo-full.png" alt="gigacover-green-logo" />
      </Link>
      <div className="flex flex-col items-start p-2 group-hover:pr-4">
        <div className="ml-1 flex items-center justify-center rounded-lg border border-main-green bg-green-100 p-2 group-hover:w-full">
          {mainIcon ? <Icon name={mainIcon} size={40} /> : null}
          <div className="mx-2 hidden flex-1 text-sm group-hover:block">{mainText}</div>
        </div>
      </div>
      {navItems.map(item => (
        <NavItem data={item} key={item.icon} onClick={_onItemClick} />
      ))}
      <NavItem data={{ icon: 'logout', action: 'logout', label: 'Logout' }} onClick={_onItemClick} />
    </div>
  );
};

const NAV_ITEMS_MER = [
  { icon: 'overview', route: '/dashboard/mer/overview', label: 'Overview', checkRoute: ['/dashboard/mer/overview'] },
  {
    icon: 'claim',
    route: '/dashboard/mer/claim-tracker',
    label: 'Claims',
    checkRoute: ['/dashboard/mer/claim-tracker'],
  },
  {
    icon: 'settings',
    route: '/dashboard/mer/settings',
    label: 'Settings',
    checkRoute: ['/dashboard/mer/settings'],
  },
];

const NAV_ITEMS_HMO = [
  { icon: 'overview', route: '/dashboard/hmo/overview', label: 'Overview', checkRoute: ['/dashboard/hmo/overview'] },
  {
    icon: 'settings',
    route: '/dashboard/hmo/settings',
    label: 'Settings',
    checkRoute: ['/dashboard/hmo/settings'],
  },
];

const NavItem = ({
  data,
  onClick,
}: {
  data: { icon: string; label: string; checkRoute?: string[]; action?: string; route?: string };
  onClick?: (action: string) => void;
}) => {
  const { icon, label, checkRoute, action, route } = data || {};
  const location = useLocation();
  const isSelected = checkRoute?.includes(location.pathname);

  const _onClick = () => {
    if (action) onClick?.(action);
  };

  const _renderContent = () => (
    <>
      {isSelected ? <div className="absolute left-0 h-full w-2 rounded-r-full bg-red-300" /> : null}
      <img
        src={isSelected ? `/img/smallIcon/nav-bar/${icon}-selected.svg` : `/img/smallIcon/nav-bar/${icon}.svg`}
        className="ml-6 mr-3 h-6 w-6"
        alt={icon}
      />
      <div className={clsx('hidden text-sm group-hover:block', isSelected ? 'text-main-pink' : '')}>{label}</div>
    </>
  );

  if (route) {
    return (
      <Link className="relative my-2 flex items-center py-1" to={route}>
        {_renderContent()}
      </Link>
    );
  }
  return (
    <button className="relative my-2 flex items-center py-1" onClick={_onClick}>
      {_renderContent()}
    </button>
  );
};
