import { NavBar } from 'app/components';
import ClaimDetail from 'app/pages/claim-detail';
import EbAddEmployee from 'app/pages/eb/add-employee';
import EbOverview from 'app/pages/eb/overview';
import SubmitClaim from 'app/pages/submit-claim';
import { selectors } from 'app/redux';
import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import AddOneVehicle from '../add-vehicle';
import Checkout from '../checkout';
import CheckoutSuccess from '../checkout/success';
import MerClaimTracker from '../claim-tracker';
import HmoClaimDetail from '../eb/claim-detail';
import HmoClaimTracker from '../eb/claim-tracker';
import HmoEmployeeDetail from '../eb/employee-detail';
import SubmitHmoClaim from '../eb/submit-claim';
import OverView from '../overview';
import Settings from '../settings';
import UploadCSV from '../upload-csv';
import VehiclePage from '../vehicles';

const Dashboard = ({ match }) => {
  const { user } = useSelector(selectors.user);
  const { accessPages } = user || {};

  useEffect(() => {
    // @ts-ignore
    if (window.OmiseCard)
      // @ts-ignore
      window.OmiseCard.configure({
        publicKey: process.env.REACT_APP_OMISE_KEY,
      });
    // @ts-ignore
    if (window.Omise) window.Omise.setPublicKey(process.env.REACT_APP_OMISE_KEY);
  }, []);

  return (
    <div className="pl-20">
      <NavBar />
      <Switch>
        {accessPages?.mer_dashboard ? <Route path={`${match.path}/mer`} component={DashboardMer} /> : null}
        {accessPages?.hmo_dashboard ? <Route path={`${match.path}/hmo`} component={DashboardEb} /> : null}

        <DefaultRedirect />
      </Switch>
    </div>
  );
};
const DashboardMer = ({ match }) => (
  <Switch>
    <Route path={`${match.path}/overview`} component={OverView} />
    <Route path={`${match.path}/settings`} component={Settings} />
    <Route path={`${match.path}/checkout`} component={Checkout} />
    <Route path={`${match.path}/checkout/success`} component={CheckoutSuccess} />
    <Route path={`${match.path}/submit-claim`} component={SubmitClaim} />
    <Route path={`${match.path}/claim-detail/:id`} component={ClaimDetail} />

    <Route path={`${match.path}/add-vehicles`} component={UploadCSV} />
    <Route path={`${match.path}/add-vehicle`} component={AddOneVehicle} />
    <Route path={`${match.path}/vehicles`} component={VehiclePage} />
    <Route path={`${match.path}/claim-tracker`} component={MerClaimTracker} />
    <DefaultRedirect />
  </Switch>
);

const DashboardEb = ({ match }) => (
  <Switch>
    <Route path={`${match.path}/overview`} component={EbOverview} />
    <Route path={`${match.path}/settings`} component={Settings} />
    <Route path={`${match.path}/add-employee`} component={EbAddEmployee} />
    <Route path={`${match.path}/claim-tracker`} component={HmoClaimTracker} />
    <Route path={`${match.path}/submit-claim`} component={SubmitHmoClaim} />
    <Route path={`${match.path}/employee-detail`} component={HmoEmployeeDetail} />
    <Route path={`${match.path}/claim-detail/:id`} component={HmoClaimDetail} />
    <DefaultRedirect />
  </Switch>
);

const DefaultRedirect = () => {
  const { user } = useSelector(selectors.user);
  const { accessPages } = user || {};

  const url = useMemo(() => {
    let firstFoundDashboard = '';
    if (accessPages?.mer_dashboard) firstFoundDashboard = 'mer';
    if (accessPages?.hmo_dashboard) firstFoundDashboard = 'hmo';
    return `/dashboard/${firstFoundDashboard}/overview`;
  }, []);

  return <Redirect from="*" to={url} />;
};

export default Dashboard;
