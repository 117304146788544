import { CheckIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import React from 'react';

export const SimpleCheckbox = ({ text, isChecked, onClick }) => {
  return (
    <button className="flex items-center outline-none" onClick={onClick}>
      <div className="mr-2 h-6 w-6 rounded-md border border-gray-400">
        {isChecked ? <CheckIcon className="h-5 w-5 text-gray-800" /> : null}
      </div>
      <div className="w-32 text-left text-sm">{text}</div>
    </button>
  );
};

export const SimpleGreenCheckbox = ({ text, isChecked, onClick }) => {
  return (
    <button className="flex items-center outline-none" onClick={onClick}>
      <div
        className={clsx(
          'mr-2 flex h-6 w-6 items-center justify-center rounded-md',
          isChecked ? 'bg-main-green' : 'border border-gray-400',
        )}
      >
        {isChecked ? <CheckIcon className="h-5 w-5 text-white" /> : null}
      </div>
      <div className="w-32 text-left text-sm">{text}</div>
    </button>
  );
};
