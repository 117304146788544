export const ACCEPT_FILES = {
  DOCUMENTS: 'file_extension|image/*',
  DOCUMENTS_AND_IMAGE: '.jpg, .jpeg, .png, .pdf, .doc, .docx',
  IMAGES: '.jpg, .jpeg, .png',
};

export const INCIDENT_TYPES = {
  ACCIDENT: 'accident',
  ILLNESS: 'illness',
};
export const INCIDENT_OPTIONS = [
  {
    label: 'Accident',
    value: INCIDENT_TYPES.ACCIDENT,
  },
  {
    label: 'Illness',
    value: INCIDENT_TYPES.ILLNESS,
  },
];
export const YES_NO_OPTIONS = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];
export const MC_TYPE = {
  HOSPITAL_LEAVE: 'hospital leave',
  MEDICAL_LEAVE: 'medical leave',
  COVID: 'covid',
};
export const PAYMENT_METHODS = {
  PAYNOW_MOBILENUMBER: 'paynow_mobilenumber',
  PAYNOW_NRIC: 'paynow_nric',
  BANK_ACCOUNT: 'bank_account',
  PAYNOW_UEN: 'paynow_uen',
};

export const PAYMENT_OPTIONS = [
  {
    label: 'GCash',
    value: 'gcash',
  },
  {
    label: 'PayMaya',
    value: 'paymaya',
  },
  {
    label: 'Coins.ph',
    value: 'coins.ph',
  },
];
export const EWALLET_SG = [
  {
    label: 'PayNow - Mobile Number',
    value: 'paynow_mobilenumber',
  },
  {
    label: 'PayNow - NRIC',
    value: 'paynow_nric',
  },
];

export const PAYMENT_OPTIONS_BANK_EWALLET = [
  {
    label: 'GCash',
    value: 'gcash',
    isEWallet: true,
  },
  {
    label: 'PayMaya',
    value: 'paymaya',
    isEWallet: true,
  },
  {
    label: 'Coins.ph',
    value: 'coins.ph',
    isEWallet: true,
  },
  {
    label: 'Bank of Commerce',
    value: 'Bank of Commerce',
  },
  {
    label: 'BDO Private Bank, Inc.',
    value: 'BDO Private Bank, Inc.',
  },
  {
    label: 'Philippine Bank of Communications',
    value: 'Philippine Bank of Communications',
  },
  {
    label: 'Philippine Veterans Bank',
    value: 'Philippine Veterans Bank',
  },
  {
    label: 'Robinsons Bank Corporation',
    value: 'Robinsons Bank Corporation',
  },
  {
    label: 'Asia United Bank Corporation',
    value: 'Asia United Bank Corporation',
  },
  {
    label: 'Bank of the Philippine Islands',
    value: 'Bank of the Philippine Islands',
  },
  {
    label: 'BDO Unibank, Inc.',
    value: 'BDO Unibank, Inc.',
  },
  {
    label: 'China Banking Corporation',
    value: 'China Banking Corporation',
  },
  {
    label: 'East West Banking Corporation',
    value: 'East West Banking Corporation',
  },
  {
    label: 'Metropolitan Bank and Trust Company',
    value: 'Metropolitan Bank and Trust Company',
  },
  {
    label: 'Philippine National Bank',
    value: 'Philippine National Bank',
  },
  {
    label: 'Philippine Trust Company',
    value: 'Philippine Trust Company',
  },
  {
    label: 'Rizal Commercial Banking Corporation',
    value: 'Rizal Commercial Banking Corporation',
  },
  {
    label: 'Security Bank Corporation',
    value: 'Security Bank Corporation',
  },
  {
    label: 'Union Bank of the Philippines',
    value: 'Union Bank of the Philippines',
  },
  {
    label: 'United Coconut Planters Bank',
    value: 'United Coconut Planters Bank',
  },
];

export const checkIsEWallet = value => {
  const eWallet = PAYMENT_OPTIONS_BANK_EWALLET.find(item => item.value === value);
  return eWallet && eWallet.isEWallet;
};

export const PAYMENT_METHODS_OPTIONS = [
  // {
  //   label: 'PayNow - Mobile Number',
  //   value: PAYMENT_METHODS.PAYNOW_MOBILENUMBER,
  // },
  {
    label: 'PayNow - NRIC',
    value: PAYMENT_METHODS.PAYNOW_NRIC,
  },
  {
    label: 'PayNow - UEN',
    value: PAYMENT_METHODS.PAYNOW_UEN,
  },
  {
    label: 'Bank Account',
    value: PAYMENT_METHODS.BANK_ACCOUNT,
  },
];

export const CLAIM_KEYS_CONFIG = {
  //
  incident_date: {
    name: 'Date of Accident',
    keyname: 'incident_date',
    type: 'date',
    setting: {
      general: {
        isRequired: true,
      },
      sgp: {
        eb: {
          isRequired: true,
          name: 'Incident Date',
        },
      },
    },
  },
  medical_expense_claim: {
    name: 'Medical Expense Claim',
    keyname: 'medical_expense_claim',
    type: 'input',
    setting: {
      general: {
        inputType: 'number',
        placeholder: '$200',
      },
    },
  },
  incident_description: {
    name: 'Description',
    keyname: 'incident_description',
    type: 'input',
    setting: {
      general: {
        isRequired: true,
        placeholder: 'Description ...',
      },
    },
  },
  incident_how: {
    name: 'How did the Accident happen?',
    keyname: 'incident_how',
    type: 'input',
    setting: {
      general: {
        isRequired: true,
        placeholder: 'Description ...',
      },
    },
  },
  incident_where: {
    name: 'Where did the accident happen?',
    keyname: 'incident_where',
    type: 'input',
    setting: {
      general: {
        isRequired: true,
        placeholder: 'Description ...',
      },
    },
  },
  start_date: {
    name: 'Start Date of MC',
    keyname: 'start_date',
    type: 'date',
    setting: {
      general: {
        isRequired: true,
      },
    },
  },
  end_date: {
    name: 'End Date of MC',
    keyname: 'end_date',
    type: 'date',
    setting: {
      general: {
        isRequired: true,
      },
    },
  },
  incident_type: {
    name: 'Incident Type',
    keyname: 'incident_type',
    type: 'select',
    setting: {
      general: {
        isRequired: true,
        options: INCIDENT_OPTIONS,
      },
    },
  },
  vehicle_reg: {
    name: 'Vehicle No.',
    keyname: 'vehicle_reg',
    type: 'input',
    setting: {
      general: {
        isRequired: true,
        maxLength: 8,
      },
    },
  },
  policy_holder: {
    name: 'Policy Holder',
    keyname: 'policy_holder',
    type: 'input',
    setting: {
      general: {
        isRequired: true,
      },
    },
  },
  rental_company: {
    name: 'Rental Company',
    keyname: 'rental_company',
    type: 'input',
  },
  accident_date: {
    name: 'Date of Accident',
    keyname: 'accident_date',
    type: 'date',
    setting: {
      general: {
        isRequired: true,
      },
    },
  },
  combined_excess: {
    name: 'Combined Excess',
    keyname: 'combined_excess',
    type: 'input',
    setting: {
      general: {
        isRequired: true,
        inputType: 'number',
      },
    },
  },
  section1excess: {
    name: 'Section 1 (Own Vehicle Claim)',
    keyname: 'section1excess',
    type: 'input',
    setting: {
      general: {
        isRequired: true,
        // inputType: "number",
      },
    },
  },
  section2excess: {
    name: 'Section 2 (Third Party Claim)',
    keyname: 'section2excess',
    type: 'input',
    setting: {
      general: {
        isRequired: true,
        // inputType: "number",
      },
    },
  },

  description: {
    name: 'Description of Illness/ Injury',
    keyname: 'description',
    type: 'input',
    setting: {
      general: {
        isRequired: true,
      },
    },
  },
  indicate_accident_time: {
    name: 'Date & Time of Accident',
    keyname: 'indicate_accident_time',
    type: 'date',
  },
  what_doing_accident: {
    name: 'What were you doing when the accident happened?',
    keyname: 'what_doing_accident',
    type: 'input',
  },
  how_did_the_accident_happen: {
    name: 'How did the accident happen?',
    keyname: 'how_did_the_accident_happen',
    type: 'input',
  },
  injury_or_illness_work_related: {
    name: 'Was your injury/illness caused by or related to your work?',
    keyname: 'injury_or_illness_work_related',
    type: 'select',
    setting: {
      general: {
        isRequired: true,
        options: YES_NO_OPTIONS,
      },
    },
  },
  claim_filed_employee_compensation_commission: {
    name: 'Has your claim been filed under the Employee Compensation Commission?',
    keyname: 'claim_filed_employee_compensation_commission',
    type: 'select',
    setting: {
      general: {
        isRequired: true,
        options: YES_NO_OPTIONS,
      },
    },
  },

  //
  inpatient_form: {
    name: 'Inpatient form',
    keyname: 'inpatient_form',
    type: 'file',
    setting: {
      general: {
        isRequired: true,
        minFileNumber: 1,
        maxFileNumber: 2,
        docTypes: ACCEPT_FILES.DOCUMENTS_AND_IMAGE,
      },
    },
  },
  outpatient_form: {
    name: 'Outpatient form',
    keyname: 'outpatient_form',
    type: 'file',
    setting: {
      general: {
        isRequired: true,
        minFileNumber: 1,
        maxFileNumber: 2,
        docTypes: ACCEPT_FILES.DOCUMENTS_AND_IMAGE,
      },
    },
  },
  hospital_statement: {
    name: 'Hospital Statement of Account',
    keyname: 'hospital_statement',
    type: 'file',
    setting: {
      general: {
        minFileNumber: 0,
        maxFileNumber: 2,
        docTypes: ACCEPT_FILES.DOCUMENTS_AND_IMAGE,
        description: 'Photocopy is okay',
      },
    },
  },
  hospital_medical_bill: {
    name: 'Hospital/ Medical Bills',
    keyname: 'hospital_medical_bill',
    type: 'file',
    setting: {
      general: {
        minFileNumber: 0,
        maxFileNumber: 2,
        docTypes: ACCEPT_FILES.DOCUMENTS_AND_IMAGE,
        limitImages: 1,
        limitDocs: 1,
      },
      sgp: {
        pa: {
          minFileNumber: 0,
          maxFileNumber: 3,
          docTypes: ACCEPT_FILES.DOCUMENTS_AND_IMAGE,
          limitImages: 2,
          limitDocs: 1,
        },
      },
      phl: {
        pa: {
          isRequired: true,
          minFileNumber: 1,
          maxFileNumber: 3,
          docTypes: ACCEPT_FILES.DOCUMENTS_AND_IMAGE,
          limitImages: 2,
          limitDocs: 1,
        },
        flep: {
          isRequired: true,
          minFileNumber: 1,
          maxFileNumber: 3,
          docTypes: ACCEPT_FILES.DOCUMENTS_AND_IMAGE,
          limitImages: 2,
          limitDocs: 1,
        },
        eb: {
          isRequired: true,
          minFileNumber: 1,
          maxFileNumber: 3,
          docTypes: ACCEPT_FILES.DOCUMENTS_AND_IMAGE,
          description: 'BIR approved official receipts for consultation fees, medicines, and laboratories',
          limitImages: 2,
          limitDocs: 1,
        },
      },
    },
  },
  lab_requests: {
    name: 'Lab Requests',
    keyname: 'lab_requests',
    type: 'file',
    setting: {
      general: {
        isRequired: true,
        minFileNumber: 1,
        maxFileNumber: 2,
        docTypes: ACCEPT_FILES.DOCUMENTS_AND_IMAGE,
        description: '*For lab expensees, lab requests signed by concerned doctor',
      },
    },
  },
  medical_certificate: {
    name: 'Medical Certificates',
    keyname: 'medical_certificate',
    type: 'file',
    setting: {
      general: {
        isRequired: true,
        minFileNumber: 1,
        maxFileNumber: 2,
        docTypes: ACCEPT_FILES.DOCUMENTS_AND_IMAGE,
        description: 'Diagnosis should be indicated.',
      },
      sgp: {
        flep: {
          isRequired: true,
          minFileNumber: 1,
          maxFileNumber: 10,
          limitImages: 5,
          limitDocs: 5,
          docTypes: ACCEPT_FILES.DOCUMENTS_AND_IMAGE,
        },
      },
    },
  },
  // proof_non_delivery: {},
  police_report: {
    name: 'Police Report',
    keyname: 'police_report',
    type: 'file',
    setting: {
      general: {
        minFileNumber: 0,
        maxFileNumber: 2,
        docTypes: ACCEPT_FILES.DOCUMENTS_AND_IMAGE,
      },
      sgp: {
        flep: {
          minFileNumber: 0,
          maxFileNumber: 4,
          limitImages: 3,
          limitDocs: 1,
          docTypes: ACCEPT_FILES.DOCUMENTS_AND_IMAGE,
        },
        pa: {
          minFileNumber: 0,
          maxFileNumber: 4,
          limitImages: 3,
          limitDocs: 1,
          docTypes: ACCEPT_FILES.DOCUMENTS_AND_IMAGE,
        },
        cdw: {
          minFileNumber: 0,
          maxFileNumber: 6,
          limitImages: 5,
          limitDocs: 1,
          docTypes: ACCEPT_FILES.DOCUMENTS_AND_IMAGE,
        },
      },
    },
  },
  // claim_form: {},
  // supporting_document: {},
  id_front: {
    name: 'ID Front',
    keyname: 'id_front',
    type: 'file',
    setting: {
      general: {
        isRequired: true,
        minFileNumber: 1,
        maxFileNumber: 1,
        docTypes: ACCEPT_FILES.IMAGES,
      },
      sgp: {
        flep: {
          name: 'NRIC (Front)',
          isRequired: true,
          minFileNumber: 1,
          maxFileNumber: 2,
          limitImages: 1,
          limitDocs: 1,
          docTypes: ACCEPT_FILES.DOCUMENTS_AND_IMAGE,
        },
        cdw: {
          name: 'NRIC Card',
          description: '(Front Page)',
          isRequired: true,
          minFileNumber: 1,
          maxFileNumber: 2,
          limitImages: 1,
          limitDocs: 1,
          docTypes: ACCEPT_FILES.DOCUMENTS_AND_IMAGE,
        },
        pa: {
          name: 'NRIC (Front)',
          isRequired: true,
          minFileNumber: 1,
          maxFileNumber: 2,
          limitImages: 1,
          limitDocs: 1,
          docTypes: ACCEPT_FILES.DOCUMENTS_AND_IMAGE,
        },
      },
    },
  },
  id_back: {
    name: 'ID Back',
    keyname: 'id_back',
    type: 'file',
    setting: {
      general: {
        isRequired: true,
        minFileNumber: 1,
        maxFileNumber: 1,
        docTypes: ACCEPT_FILES.IMAGES,
      },
      sgp: {
        flep: {
          name: 'NRIC (Back)',
          isRequired: true,
          minFileNumber: 1,
          maxFileNumber: 2,
          limitImages: 1,
          limitDocs: 1,
          docTypes: ACCEPT_FILES.DOCUMENTS_AND_IMAGE,
        },
        cdw: {
          name: 'NRIC Card',
          description: '(Back Page)',
          isRequired: true,
          minFileNumber: 1,
          maxFileNumber: 2,
          limitImages: 1,
          limitDocs: 1,
          docTypes: ACCEPT_FILES.DOCUMENTS_AND_IMAGE,
        },
        pa: {
          name: 'NRIC (Back)',
          isRequired: true,
          minFileNumber: 1,
          maxFileNumber: 2,
          limitImages: 1,
          limitDocs: 1,
          docTypes: ACCEPT_FILES.DOCUMENTS_AND_IMAGE,
        },
      },
    },
  },
  aps_form: {
    name: 'APS',
    keyname: 'aps_form',
    type: 'file',
    setting: {
      general: {
        isRequired: true,
        minFileNumber: 1,
        maxFileNumber: 2,
      },
    },
  },
  apf_form: {
    name: 'APF Form',
    keyname: 'apf_form',
    type: 'file',
    setting: {
      general: {
        isRequired: true,
        minFileNumber: 1,
        maxFileNumber: 2,
      },
    },
  },
  medical_report: {
    name: 'Medical Report',
    keyname: 'medical_report',
    type: 'file',
    setting: {
      general: {
        minFileNumber: 0,
        maxFileNumber: 2,
        description: 'You may also upload Inpatient Discharge Summary/Operation Records.',
      },
      sgp: {
        pa: {
          minFileNumber: 0,
          maxFileNumber: 7,
          limitImages: 5,
          limitDocs: 2,
        },
        flep: {
          minFileNumber: 0,
          maxFileNumber: 7,
          limitImages: 5,
          limitDocs: 2,
        },
      },
    },
  },
  referral_letter: {
    name: 'Referral Letters',
    keyname: 'referral_letter',
    type: 'file',
    setting: {
      general: {
        minFileNumber: 0,
        maxFileNumber: 2,
      },
      sgp: {
        flep: {
          minFileNumber: 0,
          maxFileNumber: 3,
          limitImages: 2,
          limitDocs: 1,
        },
        pa: {
          minFileNumber: 0,
          maxFileNumber: 3,
          limitImages: 2,
          limitDocs: 1,
        },
      },
    },
  },
  claimant_statement: {
    name: "Claimant's Statement",
    keyname: 'claimant_statement',
    type: 'file',
    setting: {
      general: {
        isRequired: true,
        minFileNumber: 1,
        maxFileNumber: 2,
        docTypes: ACCEPT_FILES.IMAGES,
      },
    },
  },
  id_of_deceased: {
    name: 'Identification of the Deceased',
    keyname: 'id_of_deceased',
    type: 'file',
    setting: {
      general: {
        isRequired: true,
        minFileNumber: 1,
        maxFileNumber: 2,
        docTypes: ACCEPT_FILES.IMAGES,
      },
    },
  },
  policy_contract: {
    name: 'Policy Contract',
    keyname: 'policy_contract',
    type: 'file',
    setting: {
      general: {
        isRequired: true,
        minFileNumber: 1,
        maxFileNumber: 2,
        docTypes: ACCEPT_FILES.IMAGES,
      },
    },
  },
  death_certificate: {
    name: 'Death Certificate',
    keyname: 'death_certificate',
    type: 'file',
    setting: {
      general: {
        isRequired: true,
        minFileNumber: 1,
        maxFileNumber: 2,
        docTypes: ACCEPT_FILES.IMAGES,
        description: 'Duly sealed and signed by the Office of the Local Civil Registar.',
      },
    },
  },
  birth_certificate: {
    name: 'Birth Certificate',
    keyname: 'birth_certificate',
    type: 'file',
    setting: {
      general: {
        isRequired: true,
        minFileNumber: 1,
        maxFileNumber: 2,
        docTypes: ACCEPT_FILES.IMAGES,
        description: 'Of the Insured and Benefiiciaries',
      },
    },
  },
  marriage_contract: {
    name: 'Marriage Contract',
    keyname: 'marriage_contract',
    type: 'file',
    setting: {
      general: {
        minFileNumber: 0,
        maxFileNumber: 2,
        docTypes: ACCEPT_FILES.IMAGES,
      },
    },
  },
  letter_of_guardianship: {
    name: 'Letter of Guardianship',
    keyname: 'letter_of_guardianship',
    type: 'file',
    setting: {
      general: {
        isRequired: true,
        minFileNumber: 1,
        maxFileNumber: 2,
        docTypes: ACCEPT_FILES.IMAGES,
        description:
          'Proof of Judicial Appointment of a guardian of the minor beneficiaries, if amount of proceeds is more than ₱ 50,000',
      },
    },
  },
  affidavit_of_guardianship: {
    name: 'Affidavit of Guardianship',
    keyname: 'affidavit_of_guardianship',
    type: 'file',
    setting: {
      general: {
        isRequired: true,
        minFileNumber: 1,
        maxFileNumber: 2,
        docTypes: ACCEPT_FILES.IMAGES,
        description: 'To be executed by the natural parent',
      },
    },
  },
  police_investigation_report: {
    name: 'Police Investigation Report',
    keyname: 'police_investigation_report',
    type: 'file',
    setting: {
      general: {
        minFileNumber: 0,
        maxFileNumber: 2,
        docTypes: ACCEPT_FILES.IMAGES,
        description: 'If cause of death is accident, murder, or homicide',
      },
    },
  },
  autopsy_report: {
    name: 'Autopsy Report',
    keyname: 'autopsy_report',
    type: 'file',
    setting: {
      general: {
        isRequired: true,
        minFileNumber: 1,
        maxFileNumber: 2,
        docTypes: ACCEPT_FILES.IMAGES,
        description: 'Or Post-mortem findings',
      },
    },
  },
  nricfin_front: {
    name: 'NRIC (Front)',
    keyname: 'nricfin_front',
    type: 'file',
    setting: {
      general: {
        isRequired: true,
        minFileNumber: 1,
        maxFileNumber: 2,
        limitImages: 1,
        limitDocs: 1,
      },
    },
  },
  nricfin_back: {
    name: 'NRIC (Back)',
    keyname: 'nricfin_back',
    type: 'file',
    setting: {
      general: {
        isRequired: true,
        minFileNumber: 1,
        maxFileNumber: 2,
        limitImages: 1,
        limitDocs: 1,
      },
    },
  },
  drivers_license_front: {
    name: 'Drivers License',
    description: '(Front Page)',
    keyname: 'drivers_license_front',
    type: 'file',
    setting: {
      general: {
        isRequired: true,
        minFileNumber: 1,
        maxFileNumber: 2,
        limitImages: 1,
        limitDocs: 1,
      },
    },
  },
  drivers_license_back: {
    name: 'Drivers License',
    description: '(Back Page)',
    keyname: 'drivers_license_back',
    type: 'file',
    setting: {
      general: {
        isRequired: true,
        minFileNumber: 1,
        maxFileNumber: 2,
        limitImages: 1,
        limitDocs: 1,
      },
    },
  },

  certificate_of_motor_insurance: {
    name: 'Certificate of Motor Insurance',
    keyname: 'certificate_of_motor_insurance',
    type: 'file',
    setting: {
      general: {
        isRequired: true,
        minFileNumber: 1,
        maxFileNumber: 3,
        limitImages: 2,
        limitDocs: 1,
      },
    },
  },
  rental_contract: {
    name: 'Rental Contract',
    keyname: 'rental_contract',
    type: 'file',
    setting: {
      general: {
        isRequired: true,
        minFileNumber: 1,
        maxFileNumber: 3,
        limitImages: 2,
        limitDocs: 1,
      },
    },
  },
  gia_report: {
    name: 'GIA Report',
    keyname: 'gia_report',
    type: 'file',
    setting: {
      general: {
        isRequired: true,
        minFileNumber: 1,
        maxFileNumber: 6,
        limitImages: 5,
        limitDocs: 1,
      },
    },
  },
  proof_of_excess_payable_or_paid: {
    name: 'Proof of Excess Payable or Paid',
    keyname: 'proof_of_excess_payable_or_paid',
    type: 'file',
    setting: {
      general: {
        isRequired: true,
        minFileNumber: 1,
        maxFileNumber: 2,
        limitImages: 1,
        limitDocs: 1,
      },
    },
  },
  original_repair_invoice: {
    name: 'Original Repair Invoice',
    keyname: 'original_repair_invoice',
    type: 'file',
    setting: {
      general: {
        isRequired: true,
        minFileNumber: 1,
        maxFileNumber: 2,
        limitImages: 1,
        limitDocs: 1,
      },
    },
  },
  photos_of_vehicle_before_repair: {
    name: 'Photos of Vehicle before Repair',
    keyname: 'photos_of_vehicle_before_repair',
    type: 'file',
    setting: {
      general: {
        isRequired: true,
        minFileNumber: 1,
        maxFileNumber: 8,
        limitImages: 4,
        limitDocs: 4,
      },
    },
  },
  photos_of_vehicle_after_repair: {
    name: 'Photos of Vehicle after Repair',
    keyname: 'photos_of_vehicle_after_repair',
    type: 'file',
    setting: {
      general: {
        isRequired: true,
        minFileNumber: 1,
        maxFileNumber: 8,
        limitImages: 4,
        limitDocs: 4,
      },
    },
  },
  discharge_voucher: {
    name: 'Discharge Voucher',
    keyname: 'discharge_voucher',
    type: 'file',
    setting: {
      general: {
        isRequired: true,
        minFileNumber: 1,
        maxFileNumber: 3,
        limitImages: 2,
        limitDocs: 1,
      },
    },
  },
  proof_of_excess_paid: {
    name: 'Proof of Excess Paid to Primary Insurer',
    keyname: 'proof_of_excess_paid',
    type: 'file',
    setting: {
      general: {
        isRequired: true,
        minFileNumber: 1,
        maxFileNumber: 2,
        limitImages: 1,
        limitDocs: 1,
      },
    },
  },
  // cdw: {},
  inpatient_discharge_sum: {
    name: 'Inpatient Discharge Summary',
    keyname: 'inpatient_discharge_sum',
    type: 'file',
    setting: {
      general: {
        isRequired: true,
        minFileNumber: 1,
        maxFileNumber: 6,
        limitImages: 5,
        limitDocs: 1,
      },
    },
  },
  operation_record: {
    name: 'Operation Records',
    keyname: 'operation_record',
    type: 'file',
    setting: {
      general: {
        minFileNumber: 0,
        maxFileNumber: 6,
        limitImages: 5,
        limitDocs: 1,
      },
    },
  },
  pcr_art_test: {
    name: 'PCR / ART Test',
    keyname: 'pcr_art_test',
    type: 'file',
    setting: {
      general: {
        isRequired: true,
        minFileNumber: 1,
        maxFileNumber: 2,
        limitImages: 2,
        limitDocs: 0,
      },
    },
  },

  prescription: {
    name: 'Prescription',
    keyname: 'prescription',
    type: 'file',
    setting: {
      general: {
        isRequired: true,
        minFileNumber: 1,
        maxFileNumber: 5,
        docTypes: ACCEPT_FILES.IMAGES,
      },
    },
  },

  government_id: {
    name: 'Government ID',
    keyname: 'government_id',
    type: 'file',
    setting: {
      general: {
        isRequired: true,
        minFileNumber: 1,
        maxFileNumber: 1,
        docTypes: ACCEPT_FILES.IMAGES,
      },
    },
  },
  // discharge_summary: {},
  // income_statement: {},
  // doctor_memo: {},
};

// sgp: {
//   flep: {},
//   pa: {},
//   cdw: {},
// },
// phl: {
//   flep: {},
//   pa: {},
//   eb: {},
//   termlife: {},
// },

export const getClaimConfigObject = (key, country, product) => {
  let configObject = CLAIM_KEYS_CONFIG[key] || {};
  const specifySetting = configObject.setting?.[country?.toLowerCase()]?.[product] || configObject.setting?.general;
  if (specifySetting) {
    configObject = { ...configObject, ...specifySetting };
  }
  return configObject;
};
