import 'react-datepicker/dist/react-datepicker.css';

import { APP_CONFIG } from 'app/constants';
import moment from 'moment';
import React, { forwardRef } from 'react';
import DatePicker from 'react-datepicker';

export const CellDatePicker = ({ value, onChange, ...props }) => {
  // @ts-ignore
  return <DatePicker selected={value} onChange={onChange} customInput={<ButtonDatePicker />} {...props} />;
};

const ButtonPicker = ({ value, onClick }, _ref) => (
  <button className="flex items-center" onClick={onClick}>
    {(value && moment(new Date(value)).format(APP_CONFIG.format.date)) || '-'}{' '}
    <img src="/img/smallIcon/calendar.svg" className="ml-2 w-5" />{' '}
  </button>
);

const ButtonDatePicker = forwardRef(ButtonPicker);
