import * as R from 'ramda';
import { combineReducers } from 'redux';
import { all } from 'redux-saga/effects';

import createStore from './createStore';
import type { DataState } from './Data/DataRedux';
import DataActions from './Data/DataRedux';
import type { OverviewState } from './Overview/OverviewRedux';
import OverviewRedux from './Overview/OverviewRedux';
import * as reducers from './reducers';
import * as sagas from './sagas';
import UserRedux from './User/UserRedux';

/* ------------- Reducers ------------- */
const allReducers = R.pipe(R.values, R.map(R.prop('reducerMap')), R.mergeAll)(reducers);

const rootReducer = combineReducers(allReducers);

/* ------------- Sagas ------------- */
const allSagas = R.pipe(R.values, R.flatten)(sagas);

const rootSaga = function* () {
  yield all(allSagas);
};

export const selectors = {
  app: state => state.app,
  user: state => state.user,
  fetching: state => state.fetching,
  error: state => state.error,
  overview: state => state.overview as unknown as OverviewState,
  data: state => state.data as unknown as DataState,
};

const UserActions = UserRedux.Creators;
const OverviewActions = OverviewRedux.Creators;
export { DataActions, OverviewActions, UserActions };

/* ------------- Create Store ------------- */
const { persistor, store } = createStore(rootReducer, rootSaga);
export { persistor, store };
