import { CardView } from 'app/components';
import React from 'react';

const Settings = () => {
  return (
    <div className="flex h-screen flex-col gap-8 p-10">
      <div className="text-3xl text-main-green">Settings</div>
      <div className="w-full border-t border-t-gray-200" />
      <CardView />
    </div>
  );
};

export default Settings;
