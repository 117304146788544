import { NotFoundPage } from 'app/pages';
import Dashboard from 'app/pages/dashboard';
import React, { useEffect } from 'react';
import { GlobalLoading } from 'react-global-loading';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import LoginPage from './pages/login';
import { DataActions, selectors } from './redux';

const routes = () => {
  const { dataLoaded } = useSelector(selectors.data);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(DataActions.getData());
  }, []);

  return !dataLoaded ? null : (
    <>
      <Switch>
        <LoginRoute exact path="/" />
        <PrivateRoute path="/dashboard" />
        <Route path="*" component={NotFoundPage} />
      </Switch>
      <GlobalLoading />
    </>
  );
};

const LoginRoute = ({ ...rest }) => {
  const { token } = useSelector(selectors.user);
  return (
    <Route
      {...rest}
      render={props =>
        !token ? (
          <LoginPage {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/dashboard',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};
const PrivateRoute = ({ ...rest }) => {
  const { token } = useSelector(selectors.user);

  return (
    <Route
      {...rest}
      render={props =>
        token ? (
          <Dashboard {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default routes;
