import { ButtonRow } from 'app/components/button-row';
import { DATE_FORMAT } from 'app/constants';
import { useEbSheetFileReader } from 'app/hooks';
import { getErrorMessage } from 'app/services/apiHelper';
import clsx from 'clsx';
import dayjs from 'dayjs';
import React, { memo, useEffect, useMemo } from 'react';
import ReactTable from 'react-table';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import { UploadZone } from './upload-zone';

export const UploadEbFile = ({ onBack, onNext }) => {
  const {
    onReadFile,
    isReadingFile,
    progress,
    originalFile,
    fileError,
    extractData,
    uploadError,
    isPreviewingFile,
    onRemoveFile,
    onSubmitFile,
  } = useEbSheetFileReader();

  useEffect(() => {
    if (originalFile) {
      onReadFile([originalFile]);
    }
  }, []);

  const errors = useMemo(() => fileError || uploadError, [fileError, uploadError]);

  const _onSubmitFile = () => {
    onSubmitFile(onNext);
  };

  return (
    <div className="flex w-full flex-col items-center">
      <UploadZone
        isReadingFile={isReadingFile}
        isPreviewingFile={isPreviewingFile}
        fileError={fileError}
        uploadError={uploadError}
        progress={progress}
        dataTable={extractData}
        originalFile={originalFile}
        onReadFile={onReadFile}
        onPressRemove={onRemoveFile}
      />
      <div className="flex w-full flex-col bg-white p-3">
        {extractData?.length ? (
          <div className="overflow-x-auto">
            <ReactTable
              manual
              columns={[
                {
                  Header: 'First Name',
                  accessor: 'First Name',
                  width: 180,
                  Cell: props => <FirstCell {...props} errors={errors} />,
                },
                {
                  Header: 'Last Name',
                  accessor: 'Last Name',
                  width: 180,
                  Cell: props => <Cell {...props} errors={errors} />,
                },
                {
                  Header: 'Date of Birth',
                  accessor: 'Date of Birth',
                  width: 150,
                  Cell: props => <Cell {...props} type="excelDate" errors={errors} />,
                },
                {
                  Header: 'Email Address',
                  accessor: 'Email',
                  width: 250,
                  Cell: props => <Cell {...props} errors={errors} />,
                },
                {
                  Header: 'Mobile Number',
                  accessor: 'Mobile',
                  width: 180,
                  Cell: props => <Cell {...props} errors={errors} />,
                },
                {
                  Header: 'Effective Date',
                  accessor: 'Effective Date',
                  width: 180,
                  Cell: props => <Cell {...props} type="excelDate" errors={errors} />,
                },
                {
                  Header: 'Employee Rank',
                  accessor: 'Employee Rank',
                  Cell: props => <Cell {...props} errors={errors} />,
                },
                {
                  Header: 'ID Number',
                  accessor: 'ID Number',
                  width: 100,
                  Cell: props => <Cell {...props} errors={errors} />,
                },
                {
                  Header: 'ID Type',
                  accessor: 'ID Type',
                  Cell: props => <Cell {...props} errors={errors} />,
                },
                {
                  Header: 'ID Name',
                  accessor: 'ID Name',
                  Cell: props => <Cell {...props} errors={errors} />,
                },
                {
                  Header: 'Gender',
                  accessor: 'Gender',
                  width: 90,
                  Cell: props => <Cell {...props} errors={errors} />,
                },
                {
                  Header: 'Etiqa ID Number',
                  accessor: 'Etiqa ID Number',
                  width: 200,
                  Cell: props => <Cell {...props} errors={errors} />,
                },
                {
                  Header: 'No. of Dependents',
                  accessor: 'No. of Dependents',
                  width: 110,
                  Cell: props => <Cell {...props} errors={errors} />,
                },
                {
                  Header: 'Is Dependent',
                  accessor: 'Is Dependent',
                  width: 110,
                  Cell: props => <Cell {...props} errors={errors} />,
                },
                {
                  Header: 'Primary Email',
                  accessor: 'Primary Email',
                  width: 170,
                  Cell: props => <Cell {...props} errors={errors} />,
                },
              ]}
              data={extractData}
              defaultPageSize={6}
              nextText="Next >"
              previousText="< Previous"
              resizable={false}
              noDataText="Not Found"
              style={{ border: 'none' }}
              // eslint-disable-next-line tailwindcss/no-custom-classname
              className="app-table"
              showPagination={false}
            />
          </div>
        ) : null}
      </div>
      <ButtonRow onLeft={onBack} disableRight={!!fileError || !originalFile} onRight={_onSubmitFile} />
    </div>
  );
};

const Cell = memo(function Cell(props: any) {
  const { value: baseValue, index, column, errors, type, original } = props;

  const value = baseValue || original?.[column?.id];

  const errorText = useMemo(() => {
    const { fields, error_message, column_name } = errors?.[index + 2] || {};
    if (column_name === column?.id) return getErrorMessage(error_message, fields);
    return null;
  }, [value, index, errors]);

  const valueText = useMemo(() => {
    if (type === 'excelDate') {
      return value ? dayjs(value).format(DATE_FORMAT.date) : '-';
    }
    return value;
  }, [value, type]);

  return (
    <div className={clsx(errorText && 'break-all text-center font-medium text-red-600')}>
      {valueText ?? '-'}
      <br />
      <div className="whitespace-normal break-all text-sm font-normal">{errorText}</div>
    </div>
  );
});

const FirstCell = memo(function FirstCell(props: any) {
  const { value: baseValue, index, column, errors, original } = props;

  const value = baseValue || original?.[column?.id];

  const errorText = useMemo(() => {
    const { fields, error_message, column_name } = errors?.[index + 2] || {};
    if (!COLUMN_NAMES.includes(column_name)) return getErrorMessage(error_message, fields);
    return null;
  }, [value, index, errors]);

  return (
    <div className="flex items-center">
      {errorText ? (
        <div data-tooltip-id={`row${index}`}>
          <img className="mr-2 h-4" src="/img/smallIcon/red-warning.svg" alt="error-invalid-field" />
        </div>
      ) : null}

      <ReactTooltip id={`row${index}`} variant="error">
        <span>{errorText}</span>
      </ReactTooltip>

      <Cell {...props} />
    </div>
  );
});

const COLUMN_NAMES = [
  'First Name',
  'Last Name',
  'Date of Birth',
  'Email',
  'Mobile',
  'Effective Date',
  'Employee Rank',
  'ID Number',
  'ID Type',
  'ID Name',
  'Gender',
  'Etiqa ID Number',
  'No. of Dependents',
  'Is Dependent',
  'Primary Email',
];
