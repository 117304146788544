import { SearchIcon } from '@heroicons/react/outline';
import { SimpleModal } from 'app/components';
import { EbOverviewTable, OverviewInfoRow } from 'app/components/pages/eb';
import { useEbOverviewList } from 'app/hooks';
import { ApiInstance } from 'app/services/api';
import { handleError } from 'app/services/apiHelper';
import React, { useState } from 'react';
import { globalLoading } from 'react-global-loading';

const EbOverview = ({ history }) => {
  const [currentRemoveObject, setCurrentRemoveObject] = useState<any>();

  const {
    totalPages,
    updateSearchParams,
    searchString,
    onChangeSearchString,
    searchParams,
    onSort,
    overviewList,
    refreshData,
    overviewInfo,
    fetching,
  } = useEbOverviewList();

  const _onRemove = async () => {
    globalLoading.show();
    const res = await ApiInstance.removeHmo({ order_details_id: currentRemoveObject.id });
    globalLoading.hide();
    const { error } = handleError(res, true);
    if (!error) {
      setCurrentRemoveObject(undefined);
      refreshData();
    }
  };

  return (
    <div className="flex h-screen flex-col p-3">
      <div className="my-5 text-3xl text-main-green">Dashboard</div>
      <OverviewInfoRow overviewInfo={overviewInfo} />
      <div className="mb-4 mt-2 flex justify-between">
        <div className="flex items-center rounded-[10px] border border-gray-200 p-1">
          <SearchIcon className="mr-1 h-6 w-6 text-gray-400" />
          <input
            className="w-40 text-base outline-none placeholder:text-gray-400"
            placeholder="Search this list"
            value={searchString}
            onChange={onChangeSearchString}
          />
        </div>
        <button
          className="flex items-center rounded-md bg-main-green px-2 text-base text-white"
          onClick={() => history.push('/dashboard/hmo/add-employee')}
        >
          + Add Employee
        </button>
      </div>
      <EbOverviewTable
        searchParams={searchParams}
        data={overviewList}
        fetching={fetching}
        totalPages={totalPages}
        onFetchData={({ page, pageSize }) => {
          updateSearchParams({ page_number: page, page_size: pageSize });
        }}
        onRemove={data => setCurrentRemoveObject(data)}
        onSort={onSort}
      />
      <SimpleModal
        isVisible={!!currentRemoveObject}
        title="Confirmation of Action"
        leftCB={() => setCurrentRemoveObject(undefined)}
        rightCB={_onRemove}
        hideModalCB={() => setCurrentRemoveObject(undefined)}
      >
        <div>
          Are you sure you want to <span className="font-bold text-red-500">remove</span>{' '}
          <span className="font-medium">{currentRemoveObject?.user_obj?.full_name}</span> from the dashboard?
        </div>
      </SimpleModal>
    </div>
  );
};

export default EbOverview;
