export * from './APP+API';

export const IMAGE_SIZE = 5; // MB

export const CLAIM_STATUS = {
  PROCESSING: 'processing',
  PROCESSED: 'processed',
  REJECTED: 'rejected',
  APPROVED: 'approved',
  REPORTED: 'reported',
  PAID: 'paid',
  INFO: 'info',
  PAYOUT_READY: 'payout ready',
  INSURER_PAYOUT_READY: 'insurer payout ready',
  INSURER_PAYOUT_DONE: 'insurer payout done',
  DUPLICATE: 'duplicate',
  INCOMPLETED: 'incompleted',
};

export const PH_ID_MAX_LENGTH = {
  TIN: 9,
  SSS: 10,
  SSS_TYPE_2: 12,
  NATIONAL_ID: 12,
  POSTAL_ID: 12,
  PASSPORT: 9,
  DRIVER_LICENSE: 11,
  PRC: 7,
  PHILHEALTH: 12,
  UMID: 12,
  OTHER: 12,
};

export const PRODUCT_REGIONS = {
  SG: 'SGP',
  ID: 'IDN',
  PH: 'PHL',
};

export const DATE_FORMAT = {
  date: 'DD MMM YYYY',
  dateSlash: 'DD/MM/YYYY',
  serverDate: 'YYYY-MM-DD',
  datetime: 'YYYY-MM-DD HH:mm:ssZ',
};

export const PHONE_NUMBER_PREFIX = {
  SG: '+65',
  ID: '+62',
  PH: '+63',
};

export const CREDENTIAL_NUMBER = {
  TIN: 'TIN',
  SSS: 'SSS',
};

export const PH_ID_TYPE = {
  NATIONAL_ID: 'National-ID',
  POSTAL_ID: 'Postal-ID',
  PASSPORT: 'Passport',
  DRIVER_LICENSE: 'Driver-License',
  PRC: 'PRC',
  PHILHEALTH: 'PhilHealth',
  UMID: 'UMID',
  OTHER: 'Other',
};

export const SHOWING_OVERVIEW_STATUS = {
  IN_FORCE: 'In Force',
  EXPIRING: 'Expiring Soon',
  EXPIRED: 'Expired',
  RENEWING: 'Renewing Soon',
  PAID: 'Paid',
  PREPARED: 'Prepared',
  PENDING: 'Pending',
};

export const HMO_STATUS = {
  EXPIRED: 'expired',
  WAITING: 'waiting',
  IN_FORCE: 'in_force',
};

export const OVERVIEW_LIST_FIELDS = {
  VEHICLE_REG: 'vehicle_reg',
  CODE: 'code',
  START_DATE: 'start_date',
  END_DATE: 'end_date',
  STATUS: 'status',
  SHOWING_STATUS: 'showing_status',
  UPCOMING_DATE: 'upcoming_date',
  UPCOMING_STATUS: 'upcoming_status',
  IS_AUTO_RENEWAL: 'is_auto_renewal',
};

export const VEHICLE_ORDER_DETAILS_FIELDS = {
  CODE: 'code',
  START_DATE: 'start_date',
  END_DATE: 'end_date',
  STATUS: 'status',
};

export const ORDER_DETAIL_STATUS = {
  DRAFT: 'draft',
  PAID: 'paid',
  IN_FORCE: 'in_force',
  CANCELLED: 'cancelled',
  PREPARED: 'prepared',
  EXPIRED: 'expired',
  PENDING: 'pending',
  WAITING: 'waiting',
};
