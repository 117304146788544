import type { CSSProperties, FC } from 'react';
import React from 'react';

export type IconName = keyof typeof icons;
interface IconProps {
  name: IconName;
  className?: string;
  size?: number;
}

export const Icon: FC<IconProps> = ({ name, className, size }) => {
  const styles: CSSProperties = {
    width: size,
    height: size,
  };
  return <img style={styles} className={className} src={icons[name]} />;
};

const icons = {
  view: '/img/icon/view.svg',
  viewFilled: '/img/icon/view-fill.svg',
  submit: '/img/icon/submit.svg',
  submitFilled: '/img/icon/submit-fill.svg',
  remove: '/img/icon/remove.svg',
  removeFilled: '/img/icon/remove-fill.svg',
  logoQuareBorder: '/img/icon/giga-square-border.svg',
  totalPremiumLogo: '/img/icon/total-premium-logo.svg',
  totalEnrolledEmployeesLogo: '/img/icon/total-enrolled-employees-logo.svg',
  cloudUpload: '/img/icon/cloud-upload.svg',
  checkGreen: '/img/icon/check-green.svg',
  yellowWarning: '/img/icon/yellow-warning.svg',
  minus: '/img/icon/minus.svg',
  submitDoc: '/img/icon/submit-doc.svg',
  inpatient: '/img/icon/inpatient.svg',
  outpatient: '/img/icon/outpatient.svg',

  menuClaim: '/img/icon/menu-claim.svg',
  cdw: '/img/icon/cdw.svg',
  ci: '/img/icon/ci.svg',
  flep: '/img/icon/flep.svg',
  hmo: '/img/icon/hmo.svg',
  mer: '/img/icon/mer.svg',
  pa: '/img/icon/pa.svg',
  pml: '/img/icon/pml.svg',

  triangleDown: '/img/icon/triangle-down.svg',
};
