import { FilterIcon, SearchIcon } from '@heroicons/react/outline';
import { useCheckClickoutside } from 'app/hooks';
import React, { useRef, useState } from 'react';

import { SimpleCheckbox } from './simple-checkout';

export const SearchFilterBar = ({ searchString, onChangeSearchString, children }) => {
  const [showFilterPopup, setShowFilterPopup] = useState(false);

  const popupRef = useRef<HTMLDivElement>(null);

  useCheckClickoutside(popupRef, () => {
    setShowFilterPopup(false);
  });

  return (
    <div className="z-[8] flex">
      <div className="flex items-center rounded-[10px] border border-gray-200 p-1">
        <SearchIcon className="mr-1 h-6 w-6 text-gray-400" />
        <input
          className="w-40 text-base outline-none placeholder:text-gray-400"
          placeholder="Search this list"
          value={searchString}
          onChange={onChangeSearchString}
        />
      </div>
      <div className="ml-2">
        <button
          className="flex h-full items-center rounded-[10px] border border-gray-200 px-2 outline-none"
          onClick={() => setShowFilterPopup(!showFilterPopup)}
        >
          <FilterIcon className="mr-1 h-4 w-4 text-gray-500" />
          Filters
        </button>
        {showFilterPopup ? (
          <div ref={popupRef} className="absolute z-10 flex flex-col rounded-sm border border-gray-200 bg-white p-2">
            {children}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export const FilterCheckBox = ({ item, currentValue, onClick }) => {
  return (
    <SimpleCheckbox
      isChecked={item.value === currentValue}
      text={item.label || item.value}
      onClick={() => onClick(item)}
    />
  );
};
