import { APP_CONFIG } from 'app/constants';
import { selectors } from 'app/redux';
import { unitToText } from 'app/utilities/data-helper';
import clsx from 'clsx';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

export const CheckoutSummary = ({ className }: { className?: string }) => {
  const { listUploadVehicles, liveQuotesData, batchObj } = useSelector(selectors.overview);

  const { orders } = liveQuotesData?.[0] || {};
  const { total_tax_in_credit, total_before_tax, total_charged, order_details_collection } = orders || {};

  const { vehicleDetail, vehiclesGroup } = useMemo(() => {
    if (listUploadVehicles?.length < 2) {
      return {
        vehicleDetail: {
          detail: listUploadVehicles[0],
          orderInfo: order_details_collection?.find?.(order => order.vehicle_reg === listUploadVehicles[0].vehicle_reg),
        },
        vehiclesGroup: undefined,
      };
    }

    const groupData: { [key: string]: any } = listUploadVehicles.reduce((acc, vehicle) => {
      const createdKey = `${vehicle.section1excess}-${vehicle.section2excess}-${vehicle.combined_excess}-${vehicle.reduced_excess}`;
      if (!acc[createdKey]) acc[createdKey] = [];
      acc[createdKey].push(vehicle);
      return acc;
    }, {});

    const vehiclesGroup = Object.values(groupData).map(group => ({ sample: group[0], count: group.length }));

    return {
      vehicleDetail: undefined,
      vehiclesGroup,
    };
  }, [listUploadVehicles, batchObj, liveQuotesData, order_details_collection]);

  return (
    <div className={clsx('flex flex-col items-center gap-6 rounded-md border border-gray-200 p-6', className)}>
      <div className="flex w-full flex-col">
        {vehicleDetail ? (
          <VehicalSummary vehicalDetails={vehicleDetail.detail} orderInfo={vehicleDetail.orderInfo} />
        ) : vehiclesGroup?.length ? (
          vehiclesGroup.map(group => <VehicelGroup key={group.sample.vehicle_reg} groupData={group} />)
        ) : null}
      </div>
      <div className="w-full border-t border-t-gray-200" />
      <div className="flex w-full flex-col">
        {vehiclesGroup?.length ? (
          <div className="text-center">Total Vehicles Onboarded: {listUploadVehicles?.length}</div>
        ) : null}
        <div className="flex items-center justify-between">
          <div>Subtotal:</div>
          <div>${Number(batchObj?.total_before_tax || total_before_tax).toFixed(2)}</div>
        </div>
        <div className="flex items-center justify-between">
          <div>GST:</div>
          <div>${Number(batchObj?.total_tax_in_credit || total_tax_in_credit).toFixed(2)}</div>
        </div>
        <div className="mt-3 flex items-center justify-between text-4xl font-semibold text-main-green">
          <div>Total:</div>
          <div>${Number(batchObj?.total_charged?.token || total_charged?.token).toFixed(2)}</div>
        </div>
      </div>
    </div>
  );
};

const VehicalSummary = ({ vehicalDetails, orderInfo }) => {
  const { unit_obj, start_date, end_date, coupon_obj, subtotal_before_discount, subtotal_before_tax } = orderInfo || {};
  const discountText = useMemo(() => `Discount (${unitToText(unit_obj?.name)} Coverage):`, [unit_obj?.name]);
  return (
    <div className="mb-5 flex w-full items-end justify-between">
      <div className="flex flex-1 flex-col gap-2">
        <div className="mb-2 text-2xl font-medium">{vehicalDetails.vehicle_reg}</div>
        {vehicalDetails.combined_excess > 0 ? (
          <div className="text-base">Combined Excess: ${vehicalDetails.combined_excess}</div>
        ) : (
          <>
            <div className="text-base">Section I Excess: ${vehicalDetails.section1excess}</div>
            <div className="text-base">Section II Excess: ${vehicalDetails.section2excess}</div>
          </>
        )}
        <div className="text-base">Reduced Excess To: ${vehicalDetails.reduced_excess}</div>
        <div className="text-base">Unit: {unitToText(unit_obj?.name)}</div>
        {start_date ? (
          <div className="text-base">Start Date: {moment(start_date).format(APP_CONFIG.format.date)}</div>
        ) : null}
        {end_date ? <div className="text-base">End Date: {moment(end_date).format(APP_CONFIG.format.date)}</div> : null}
        {coupon_obj?.discount_percent ? (
          <div className="text-base">
            {discountText} {(coupon_obj?.discount_percent || 0) * 100}%
          </div>
        ) : coupon_obj?.discount_amount ? (
          <div className="text-base">
            {discountText} ${(coupon_obj?.discount_amount || 0) * 100}
          </div>
        ) : null}
      </div>
      <div className="text-right">
        {coupon_obj?.discount_percent || coupon_obj?.discount_amount ? (
          <div className="w-24 text-xl text-red-600 line-through">${subtotal_before_discount}</div>
        ) : null}
        <div className="w-24 text-xl text-main-green">${subtotal_before_tax}</div>
      </div>
    </div>
  );
};

const VehicelGroup = ({ groupData }) => {
  return (
    <div className="flex items-center justify-center gap-4 rounded-xl border border-gray-200 p-4">
      <div className="flex flex-1 flex-col items-center">
        {groupData.sample.combined_excess ? (
          <div className="flex flex-col gap-2">
            <div>Combined Excess: ${groupData.sample.combined_excess}</div>
          </div>
        ) : (
          <div className="flex flex-col gap-2">
            <div>Section I Excess: ${groupData.sample.section1excess}</div>
            <div>Section II Excess: ${groupData.sample.section2excess}</div>
          </div>
        )}
      </div>
      <div className="h-full border-r border-r-gray-300" />
      <div className="flex flex-1 flex-col items-center">
        <div className="flex flex-col gap-2">
          <div>Reduced: ${groupData.sample.reduced_excess}</div>
          <div>Vehicle: {groupData.count}</div>
        </div>
      </div>
    </div>
  );
};
