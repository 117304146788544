import { InlineSelectInput } from 'app/components/inline-input';
import { BaseModal, SimpleModalContent } from 'app/components/simple-modal';
import { useMerProductList, useVehicleUpdate } from 'app/hooks';
import { isObjectExistKeys } from 'app/utils';
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';

import { ModalAlertContent, ModalLoadingContent } from '../overview/modal';

export const ModalUpdateExcess = ({ isVisible, pricingList, vehicleDetail, hideModalCB, refreshDataCB }) => {
  const { vehicle_type } = vehicleDetail || {};

  const [step, setStep] = useState(1);
  const [form, setForm] = useState<any>({
    section1excess: vehicleDetail?.section1excess?.toString(),
    section2excess: vehicleDetail?.section2excess?.toString(),
    combined_excess: vehicleDetail?.combined_excess?.toString(),
    reduced_excess: vehicleDetail?.reduced_excess?.toString(),
  });
  const [errors, setErrors] = useState({});
  const [updateResult, setUpdateResult] = useState<any>();

  useEffect(() => {
    if (isVisible) {
      setStep(1);
      setForm({
        section1excess: vehicleDetail?.section1excess?.toString(),
        section2excess: vehicleDetail?.section2excess?.toString(),
        reduced_excess: vehicleDetail?.reduced_excess?.toString(),
      });
      setUpdateResult(undefined);
    }
  }, [isVisible]);

  const { section1Options, section2Options, reduceOptions } = useMerProductList(
    vehicle_type,
    form?.section1excess,
    form?.section2excess,
    pricingList,
  );

  const { updateVehicleExcess } = useVehicleUpdate(refreshDataCB);

  const _validateForm = () => {
    const errorObject: any = {};
    if (!form?.section1excess && form?.section1excess !== 0) {
      errorObject.section1excess = 'This field is required';
    }
    if (!form?.section2excess && form?.section2excess !== 0) {
      errorObject.section2excess = 'This field is required';
    }
    if (!form?.reduced_excess && form?.reduced_excess !== 0) {
      errorObject.reduced_excess = 'This field is required';
    }
    setErrors(errorObject);
    if (isObjectExistKeys(errorObject)) {
      return false;
    }
    return true;
  };

  const _onUpdate = async () => {
    const { result, error } = await updateVehicleExcess(
      vehicleDetail,
      form?.section1excess,
      form?.section2excess,
      0,
      form?.reduced_excess || 0,
      pricingList,
    );
    if (error) {
      setStep(1);
      toast.error(error.message);
    } else {
      setUpdateResult(result?.data?.[0]);
      setStep(4);
    }
  };

  const _onChangeField = e => {
    const { value, name } = e?.target || {};
    switch (name) {
      case 'section1excess':
        if (value)
          setForm({ ...form, section1excess: value, section2excess: '', combined_excess: '', reduced_excess: '' });
        else setForm({ ...form, section1excess: value });
        setErrors({ ...errors, section1excess: '', combined_excess: '' });
        break;
      case 'section2excess':
        if (value) setForm({ ...form, section2excess: value, combined_excess: '', reduced_excess: '' });
        else setForm({ ...form, section2excess: value });
        setErrors({ ...errors, section2excess: '', combined_excess: '' });
        break;
      default:
        setForm({ ...form, [name]: value });
        setErrors({ ...errors, [name]: '' });
        break;
    }
  };

  const _onBack = () => {
    switch (step) {
      case 1:
        hideModalCB();
        break;
      case 2:
        setStep(1);
        break;
      default:
        break;
    }
  };

  const _onNext = () => {
    switch (step) {
      case 1:
        if (!_validateForm()) return;
        setStep(2);
        break;
      case 2:
        setStep(3);
        _onUpdate();
        break;
      default:
        break;
    }
  };

  return (
    <BaseModal
      panelWrapperClassName="flex items-center w-full h-full justify-center p-4"
      panelClassName="relative flex flex-col bg-white p-4 overflow-hidden shadow-xl w-full transform transition-all sm:my-8 sm:max-w-lg rounded-2xl sm:max-h-[90vh] sm:p-6"
      isVisible={isVisible}
    >
      {step === 1 ? (
        <InputInfo
          onBack={_onBack}
          onNext={_onNext}
          form={form}
          errors={errors}
          onChangeField={_onChangeField}
          section1Options={section1Options}
          section2Options={section2Options}
          reduceOptions={reduceOptions}
        />
      ) : step === 2 ? (
        <ConfirmAction onBack={_onBack} onNext={_onNext} />
      ) : step === 3 ? (
        <ModalLoadingContent text="Saving Changes..." />
      ) : step === 4 ? (
        <ModalAlertContent btText="Confirm" onConfirm={hideModalCB}>
          <div className="my-5 text-center text-lg">Changes is successfully saved.</div>
          <div className="mb-5 text-center text-base">
            The changes will take effect on the next renewal{' '}
            <span className="text-main-green">{dayjs(updateResult.orders.od_start_date).format('DD/MM/YYYY')}</span>
          </div>
        </ModalAlertContent>
      ) : null}
    </BaseModal>
  );
};

const InputInfo = ({
  onBack,
  onNext,
  form,
  errors,
  onChangeField,
  section1Options,
  section2Options,
  reduceOptions,
}: {
  onBack: () => void;
  onNext: () => void;
  form: any;
  errors: any;
  onChangeField: (e: any) => void;
  section1Options: any;
  section2Options: any;
  reduceOptions: any;
}) => {
  const _onNext = () => {
    onNext();
  };

  const disableNext = useMemo(
    () => !form?.section1excess && !form?.section2excess && !form?.combined_excess && !form?.reduced_excess,
    [form],
  );

  return (
    <SimpleModalContent
      title="Edit Vehicle Details"
      leftBtText="Back"
      leftCB={onBack}
      rightBtText="Save"
      disableRightBt={disableNext}
      rightCB={_onNext}
    >
      <div className="flex flex-col gap-2 overflow-y-auto">
        <InlineSelectInput
          label="Section 1 Excess"
          options={section1Options}
          name="section1excess"
          error={errors?.section1excess}
          value={form?.section1excess}
          onChange={onChangeField}
        />
        <InlineSelectInput
          label="Section 2 Excess"
          options={section2Options}
          name="section2excess"
          error={errors?.section2excess}
          value={form?.section2excess}
          onChange={onChangeField}
        />
        <InlineSelectInput
          label="Reduced Excess To"
          options={reduceOptions}
          name="reduced_excess"
          error={errors?.reduced_excess}
          value={form?.reduced_excess}
          onChange={onChangeField}
        />
      </div>
    </SimpleModalContent>
  );
};

const ConfirmAction = ({ onBack, onNext }) => {
  return (
    <SimpleModalContent title="Confirmation of Action" leftCB={onBack} rightCB={onNext}>
      Are you sure you want to save changes?
    </SimpleModalContent>
  );
};
