import { ApiInstance } from 'app/services/api';
import { handleError } from 'app/services/apiHelper';
import { globalLoading } from 'react-global-loading';
import toast from 'react-hot-toast';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import DataActions, { DataTypes } from './DataRedux';

export const PRODUCT_MER = {
  COUNTRY: 'SGP',
  TEMPLATE: 'mep',
  UNIT: 'days',
};

export function* getData() {
  const [resListCountries, resListGroups, resListTemplates, resListUnits] = yield all([
    call(ApiInstance.getListCountries),
    call(ApiInstance.getListGroups),
    call(ApiInstance.getListTemplates),
    call(ApiInstance.getListUnits),
  ]);

  const { result: resultListCountries, error: errorListCountries } = handleError(resListCountries, true);
  const listCountries = convertListToObject(resultListCountries?.data, 'code');
  if (!errorListCountries) {
    yield put(DataActions.setListCountries(listCountries));
  }

  const { result: resultListGroups, error: errorListGroups } = handleError(resListGroups, true);
  const listGroups = convertListToObject(resultListGroups?.data);
  if (!errorListGroups) {
    yield put(DataActions.setListGroups(listGroups));
  }

  const { result: resultListTemplates, error: errorListTemplates } = handleError(resListTemplates, true);
  const listTemplates = convertListToObject(resultListTemplates?.data);
  if (!errorListTemplates) {
    yield put(DataActions.setListTemplates(listTemplates));
  }

  const { result: resultListUnits, error: errorListUnits } = handleError(resListUnits, true);
  const listUnits = convertListToObject(resultListUnits?.data);
  if (!errorListUnits) {
    yield put(DataActions.setListUnits(listUnits));
  }

  yield put(DataActions.setDataLoaded(true));
}

export function* getProductsListing({ userGroupId }) {
  const { listCountries, listTemplates, listUnits } = yield select(state => state.data);

  const body = {
    country_id: listCountries?.[PRODUCT_MER.COUNTRY]?.id,
    template_id: listTemplates?.[PRODUCT_MER.TEMPLATE]?.id,
    unit_id: listUnits?.[PRODUCT_MER.UNIT]?.id,
    group_id: userGroupId,
  };

  globalLoading.show();
  const res = yield call(ApiInstance.getProductListing, body);

  const { result, error } = handleError(res, true);
  if (error) {
    yield put(DataActions.getProductsListing(userGroupId));
  } else {
    yield put(DataActions.setPricingList(extractPricingList(result?.data)));
    if (!result?.data?.length) {
      toast.error('Cannot find any products for this group. Please contact hey@gigacover.com for more information.', {
        duration: 10000,
      });
    }
    globalLoading.hide();
  }
}

const convertListToObject = (data, keyCode = 'name'): any => {
  const result = {};
  if (!data?.length) return {};
  data.forEach(item => {
    if (item[keyCode]) {
      result[item[keyCode]] = item;
    }
  });
  return result;
};

export const extractPricingList = list => {
  return (
    list?.map(item => ({
      price_details: item.price_details,
      payout_by_plan: item.payout_by_plan,
      config_objs: item.config_objs,
    })) || []
  );
};

export default [
  takeLatest(DataTypes.GET_DATA, getData),
  // @ts-ignore
  takeLatest(DataTypes.GET_PRODUCTS_LISTING, getProductsListing),
];
