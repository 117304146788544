import { BreadCrumbs, Icon } from 'app/components';
import { HmoClaimTable } from 'app/components/table';
import { DATE_FORMAT, HMO_STATUS } from 'app/constants';
import { useClaimTrackerList } from 'app/hooks';
import { getPHCurrencyString } from 'app/utilities';
import { capitalizeFirstLetter } from 'app/utils';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';

const HmoEmployeeDetail = ({ history }) => {
  const { data } = history.location.state || {};

  const { totalPages, updateSearchParams, claimList } = useClaimTrackerList({
    template_name: 'eb',
    user_id: data?.user_obj?.id,
  });

  useEffect(() => {
    if (!data) history.goBack();
  }, [data]);

  const { start_date, employee_rank, subtotal, user_obj, status } = data || {};
  const { full_name, dob, email, mobile } = user_obj || {};

  return (
    <div className="flex flex-col p-10">
      <BreadCrumbs crumbs={CRUMBS} />
      <div className="my-5 text-3xl text-main-green">Employee Details</div>

      <div className="my-10 flex flex-col items-center self-center">
        <div className="text-3xl text-main-green">{full_name}</div>
        <div className="mt-2">{email}</div>
        <div>{mobile}</div>
      </div>

      <div className="flex gap-4">
        <InfoTag label="Premium" value={getPHCurrencyString(subtotal)} />
        <InfoTag label="Employee Rank" value={capitalizeFirstLetter(employee_rank)} />
        <InfoTag label="Effective Date" value={start_date ? dayjs(start_date).format(DATE_FORMAT.date) : '-'} />
        <InfoTag label="Date of Birth" value={dob ? dayjs(dob).format(DATE_FORMAT.date) : '-'} />
      </div>

      <div className="mb-5 mt-10 flex justify-between">
        <div className="flex-1 text-3xl text-main-green">Claims</div>
        <button
          disabled={status === HMO_STATUS.WAITING}
          className="flex items-center gap-1 rounded-md bg-main-green px-2 text-base text-white disabled:bg-gray-200"
          onClick={() => history.push('/dashboard/hmo/submit-claim', { data })}
        >
          <Icon name="submitDoc" />
          Submit a Claim
        </button>
      </div>
      <HmoClaimTable
        claimDetailPath="/dashboard/hmo/claim-detail"
        data={claimList}
        totalPages={totalPages}
        onFetchData={({ page, pageSize }) => {
          updateSearchParams({ page_number: page, page_size: pageSize });
        }}
      />
    </div>
  );
};

export default HmoEmployeeDetail;

const CRUMBS = [
  {
    to: '/dashboard',
    label: 'Dashboard',
  },
  {
    label: 'View Employee Details',
  },
];

const InfoTag = ({ label, value }) => (
  <div className="containter-shadow flex-1">
    <div className="mb-1 text-xl">{value}</div>
    <div className="text-sm">{label}</div>
  </div>
);
