import { Loading } from 'app/components';
import { CheckoutSummary, SelectPaymentMethod, SuccessStep } from 'app/components/pages/checkout';
import { PAYMENT_METHOD } from 'app/constants';
import { usePayment } from 'app/hooks';
import { selectors } from 'app/redux';
import { generateReferencePaynow } from 'app/utilities';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { SyncLoader } from 'react-spinners';

const Checkout = ({ history }) => {
  const { listUploadVehicles, liveQuotesData, batchObj } = useSelector(selectors.overview);
  const { user } = useSelector(selectors.user);
  const { payLaterObj } = user || {};

  const [step, setStep] = useState(1);
  const [paymentMethod, setPaymentMethod] = useState(PAYMENT_METHOD.CARD);

  const referenceNumber = useMemo(() => generateReferencePaynow(), []);

  const { fetching, onPaymentWithCard, onPayNow, onPayLater } = usePayment(() => {
    setStep(2);
  });

  useEffect(() => {
    if (!listUploadVehicles.length) {
      history.push('/dashboard/mer/overview/add-vehicles');
    }
  }, [listUploadVehicles]);

  const _onConfirmSummary = () => {
    if (payLaterObj) {
      onPayLater(() => setStep(2));
    } else if (paymentMethod === PAYMENT_METHOD.CARD) {
      onPaymentWithCard();
    } else {
      onPayNow(referenceNumber);
    }
  };

  return (
    <div className="flex h-[100dvh] flex-col">
      {fetching && <Loading />}
      {step === 1 ? (
        <>
          <p className="m-4 text-2xl font-bold text-main-green">Purchase Summary</p>
          <div className="flex flex-1 gap-4 p-4">
            <CheckoutSummary className="flex-1" />
            {payLaterObj ? (
              <div className="flex-1" />
            ) : (
              <SelectPaymentMethod
                className="flex-1"
                totalPrice={batchObj?.total_amount || liveQuotesData?.[0]?.orders?.total_amount}
                referenceNumber={referenceNumber}
                paymentMethod={paymentMethod}
                setPaymentMethod={setPaymentMethod}
              />
            )}
          </div>
          <div className="flex justify-between border-t border-t-gray-200 p-5">
            <button className="underline" onClick={() => history.goBack()}>
              Back
            </button>
            <button
              className="flex h-12 items-center rounded-lg bg-main-green px-32 text-base text-white disabled:cursor-not-allowed disabled:bg-gray-400"
              onClick={_onConfirmSummary}
            >
              {fetching ? <SyncLoader color="#eaeaea" speedMultiplier={0.5} size={5} /> : 'Pay'}
            </button>
          </div>
        </>
      ) : step === 2 ? (
        <SuccessStep className="flex flex-1 gap-4 p-4" />
      ) : null}
    </div>
  );
};

export default Checkout;
