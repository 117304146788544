import { useOmise } from 'app/hooks';
import { selectors, UserActions } from 'app/redux';
import { ApiInstance } from 'app/services/api';
import { handleError } from 'app/services/apiHelper';
import { checkHasCard } from 'app/utilities/data-helper';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';

import { ClaimBorderBottomInput } from './claim-form/claim-border-bottom-input/claim-border-bottom-input';
import { SimpleModal } from './simple-modal';

export const CardView = () => {
  const [showModalAddCard, setShowModalAddCard] = useState(false);

  const dispatch = useDispatch();

  const { form, errors, onChange, fetching, onCreateOmiseToken } = useOmise();

  const [fetchingAddCard, setFetchingAddCard] = useState(false);

  const { user } = useSelector(selectors.user);

  const hasCard = checkHasCard(user);
  const { card_obj } = user?.paymentMethods?.token || {};
  const { last4, exp_month, exp_year } = card_obj || {};

  const _onSubmit = async token => {
    const body = {
      payment_method_id: {
        service: 'opn',
      },
      type: 'token',
      cc_token: token,
      // is_purchase: cardDetail ? false : !!isPurchase,
    };
    setFetchingAddCard(true);
    const res = await ApiInstance.addOpnCard(body);
    setFetchingAddCard(false);
    const { error } = handleError(res, true);
    if (error) return;
    setShowModalAddCard(false);
    toast.success('Card added successfully');
    dispatch(UserActions.getUserInfo());
  };

  const _onCreateOmiseToken = () => {
    onCreateOmiseToken(_onSubmit);
  };

  return (
    <>
      <div className="relative w-96">
        <img className="w-full" src="/img/frame/card-details-visual.svg" />
        <div className="absolute top-5 flex w-full flex-col p-8">
          <div className="text-base text-gray-800">**** **** **** {last4 || '0000'}</div>
          <div className="text-base text-gray-800">
            Expiry date {exp_month}/{exp_year}
          </div>
          <button
            className="self-end rounded-lg bg-main-green px-3 py-1 text-sm text-white"
            onClick={() => setShowModalAddCard(true)}
          >
            {hasCard ? 'Update' : 'Add Card'}
          </button>
        </div>
      </div>
      <SimpleModal
        isVisible={showModalAddCard}
        hideModalCB={() => setShowModalAddCard(false)}
        fetchingRight={fetching || fetchingAddCard}
        showCloseIcon
        title="Modify Card Details"
        rightCB={_onCreateOmiseToken}
      >
        <ClaimBorderBottomInput
          name="cardName"
          title="Card Name"
          value={form.cardName}
          onChange={onChange}
          error={errors.cardName}
        />
        <ClaimBorderBottomInput
          name="cardNumber"
          maxLength={19}
          title="Card Number"
          value={form.cardNumber}
          onChange={onChange}
          error={errors.cardNumber}
        />

        <div className="flex gap-4">
          <ClaimBorderBottomInput
            className="flex-1"
            name="cardExpiry"
            maxLength={5}
            title="Expiration date"
            value={form.cardExpiry}
            onChange={onChange}
            error={errors.cardExpiry}
          />
          <ClaimBorderBottomInput
            className="flex-1"
            name="cardCvc"
            maxLength={3}
            title="CVC"
            value={form.cardCvc}
            onChange={onChange}
            error={errors.cardCvc}
          />
        </div>
      </SimpleModal>
    </>
  );
};
