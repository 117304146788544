import clsx from 'clsx';
import type { FC } from 'react';
import React from 'react';
import { SyncLoader } from 'react-spinners';

interface ButtonProps {
  disabled?: boolean;
  onClick?: () => void;
  text: string;
  mt?: number;
  mb?: number;
  gray?: boolean;
  fetching?: boolean;
  small?: boolean;
  className?: string;
}

export const Button: FC<ButtonProps> = ({ disabled, onClick, text, mt, mb, gray, fetching, small, className }) => {
  const btStyles = { marginTop: mt, marginBottom: mb };
  return (
    <button
      disabled={disabled || fetching}
      className={clsx(
        'rounded-lg text-base font-medium text-white disabled:cursor-not-allowed disabled:bg-gray-300',
        small ? 'py-1 px-2' : 'py-1.5 px-3',
        className,
        gray ? 'bg-gray-600' : 'bg-main-green',
      )}
      onClick={onClick}
      style={btStyles}
    >
      {fetching ? <SyncLoader color="#eaeaea" speedMultiplier={0.5} size={5} /> : text}
    </button>
  );
};
