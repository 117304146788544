import clsx from 'clsx';
import dayjs from 'dayjs';
import React, { forwardRef } from 'react';

interface FilterDatePickerInputProps {
  value?: string;
  onClick?: () => void;
  disabled?: boolean;
}
export const FilterDatePickerInput = forwardRef<HTMLButtonElement, FilterDatePickerInputProps>(
  ({ value, onClick, disabled }, ref) => (
    <button
      disabled={disabled}
      className={clsx(
        'flex items-center rounded-md border border-gray-400 p-2 disabled:cursor-not-allowed disabled:opacity-30',
        value ? 'text-black' : 'text-gray-400',
      )}
      onClick={onClick}
      ref={ref}
    >
      <img className="mr-2 w-4" src="/img/smallIcon/calendar.svg" alt="calendar" />
      {value ? dayjs(value).format('DD/MM/YYYY') : 'Select a date'}
    </button>
  ),
);
