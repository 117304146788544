import clsx from 'clsx';
import type { FC } from 'react';
import React from 'react';
import { SyncLoader } from 'react-spinners';

interface ButtonRowProps {
  disabled?: boolean;
  onLeft: () => void;
  leftText?: string;
  rightText?: string;
  disableRight?: boolean;
  onRight?: () => void;
  fetchingRight?: boolean;
  className?: string;
}
export const ButtonRow: FC<ButtonRowProps> = ({
  disabled,
  onLeft,
  leftText = 'Back',
  rightText = 'Next',
  disableRight,
  onRight,
  fetchingRight,
  className,
}) => {
  return (
    <div className={clsx('my-10 flex items-center gap-4 self-center', className)}>
      <button
        disabled={disabled}
        className="rounded-lg border border-main-green py-1.5 px-3 text-lg text-main-green disabled:cursor-not-allowed"
        onClick={onLeft}
      >
        {leftText}
      </button>
      {onRight ? (
        <button
          disabled={disableRight}
          className="rounded-lg bg-main-green py-1.5 px-3 text-lg text-white disabled:cursor-not-allowed disabled:bg-green-100"
          onClick={onRight}
        >
          {fetchingRight ? <SyncLoader color="#eaeaea" speedMultiplier={0.5} size={5} /> : rightText}
        </button>
      ) : null}
    </div>
  );
};
