import { CheckCircleIcon } from '@heroicons/react/outline';
import {
  ChangeSectionErrorModal,
  ClaimAgreeCheckboxCdw,
  ClaimBorderBottomInput,
  ClaimCdwSuccessModal,
  ClaimErrorModal,
  ClaimFormFieldList,
  ClaimInputDisbursement,
  ClaimPageLayout,
} from 'app/components';
import { useSearchCoverage } from 'app/components/pages/submit-claim/useSearchCoverage';
import { PAYMENT_METHODS } from 'app/constants/claimConfig';
//
import { useClaimFormInput } from 'app/hooks';
import { useSubmitClaim } from 'app/hooks/useSubmitClaim';
import { isObjectExistKeys } from 'app/utilities';
import { getRequireValueErrorMessage } from 'app/utilities/validationErrorMessageForm';
import clsx from 'clsx';
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { SyncLoader } from 'react-spinners';

import { DropDownSelectVehicle, ModalCdwInfo } from '../../components/pages/submit-claim';

const SubmitClaim = ({ history }) => {
  const [formData, setFormData] = useState<any>({
    payment_method: PAYMENT_METHODS.PAYNOW_NRIC,
    included_docs: false,
  });
  const [showModalInfo, setShowModalInfo] = useState<any>(true);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const {
    errors,
    setErrors,
    onChange,
    onSelectVehicle,
    onUploadImage,
    onDeleteImage,
    validateDisburmentInput,

    checkState,
    setCheckState,

    onConfirmChangeSection,
    onCancelChangeSection,
    showModalConfirmSectionChange,
    shouldShowError,
    setShouldShowError,

    getCheckData,
  } = useClaimFormInput(formData, setFormData);

  useEffect(() => {
    if (formData?.vehicleObject?.vehicle_reg)
      getCheckData(
        { vehicle_reg: formData?.vehicleObject?.vehicle_reg, template_name: 'mep', sale_type: 'mer' },
        {
          vehicle_reg: `-${formData?.vehicleObject?.vehicle_reg}`,
        },
      );
  }, [formData?.vehicleObject?.vehicle_reg]);

  const {
    showDropdown,
    searchResult,
    temporaryBlockSearch,
    setShowDropdown,
    fetching: fetchingSearch,
  } = useSearchCoverage(formData?.vehicle_reg);

  const { onSubmitClaim, clearClaimId, clearErrorMessage, errorMessage, fetching } = useSubmitClaim(
    formData,
    setFormData,
    () => {
      setShowSuccessModal(true);
    },
  );

  const onSuccessGoBack = () => {
    setShowSuccessModal(false);
    history.replace('/dashboard/mer/overview/claim-tracker');
  };

  const _onChangeRadio = value => {
    setCheckState({ ...checkState, radioState: value });
    setFormData({ ...formData, claimant_have_paid_excess_payable: value });
  };

  const _validateForm = overrideFormData => {
    const checkFormData = overrideFormData || formData;
    const errorForm = validateDisburmentInput(checkFormData);

    if (!checkFormData.vehicleObject) {
      errorForm.vehicle_reg = 'Please select vehicle';
    }
    errorForm.incident_date = getRequireValueErrorMessage(checkFormData.incident_date);
    errorForm.policy_holder = getRequireValueErrorMessage(checkFormData.policy_holder);
    // if (currentCdwPolicy?.combined_excess) {
    //   errorForm.combined_excess = getRequireValueErrorMessage(checkFormData.combined_excess);
    // } else {
    //   errorForm.section1excess = getRequireValueErrorMessage(checkFormData.section1excess);
    //   errorForm.section2excess = getRequireValueErrorMessage(checkFormData.section2excess);
    // }
    if (formData.included_docs) {
      errorForm.id_front = getRequireValueErrorMessage(checkFormData.id_front?.length);
      errorForm.id_back = getRequireValueErrorMessage(checkFormData.id_back?.length);
      errorForm.drivers_license_front = getRequireValueErrorMessage(checkFormData.drivers_license_front?.length);
      errorForm.drivers_license_back = getRequireValueErrorMessage(checkFormData.drivers_license_back?.length);
      errorForm.certificate_of_motor_insurance = getRequireValueErrorMessage(
        checkFormData.certificate_of_motor_insurance?.length,
      );
      errorForm.rental_contract = getRequireValueErrorMessage(checkFormData.rental_contract?.length);
      errorForm.gia_report = getRequireValueErrorMessage(checkFormData.gia_report?.length);
      errorForm.proof_of_excess_payable_or_paid = getRequireValueErrorMessage(
        checkFormData.proof_of_excess_payable_or_paid?.length,
      );
    }

    setShouldShowError(true);
    if (isObjectExistKeys(errorForm)) {
      setErrors(errorForm);
      return false;
    }

    if (!checkState?.checked1 || !checkState?.checked2 || checkState?.radioState !== '1') {
      toast.error('Please check the acknowledgement');
      return false;
    }

    return true;
  };

  const _onSelectItem = item => {
    onSelectVehicle({ ...item, vehicle_reg: item.vehicle_reg.toUpperCase() });
    setShowDropdown(false);
    temporaryBlockSearch.current = true;
  };

  const _onSubmit = () => {
    const formDataSubmit = _clearUnuseField();
    if (!_validateForm(formDataSubmit)) return;
    onSubmitClaim({
      vehicle_reg: formData?.vehicleObject?.vehicle_reg,
      template_name: 'mep',
      sale_type: 'mer',
    });
  };

  const overrideConfig = useMemo(() => {
    const config: any = {
      incident_date: {
        minDate: dayjs(formData?.vehicleObject?.start_date).toDate(),
        maxDate: dayjs(formData?.vehicleObject?.end_date).isAfter(dayjs())
          ? dayjs().toDate()
          : dayjs(formData?.vehicleObject?.end_date).toDate(),
      },
    };
    config.section1excess = { isRequired: false };
    config.section2excess = { isRequired: false };
    config.original_repair_invoice = { isRequired: false };
    config.photos_of_vehicle_before_repair = { isRequired: false };
    config.photos_of_vehicle_after_repair = { isRequired: false };
    config.section2 = { isRequired: false };
    config.discharge_voucher = { isRequired: false };
    config.proof_of_excess_paid = { isRequired: false };

    return config;
  }, [formData?.vehicleObject]);

  const _clearUnuseField = () => {
    const formDataEdit: any = { ...formData };

    if (!formDataEdit.section1excess) {
      delete formDataEdit.original_repair_invoice;
      delete formDataEdit.photos_of_vehicle_before_repair;
      delete formDataEdit.photos_of_vehicle_after_repair;
    }
    if (!formDataEdit.section1excess) {
      delete formDataEdit.discharge_voucher;
      delete formDataEdit.proof_of_excess_paid;
    }

    setFormData(formDataEdit);
    return formDataEdit;
  };

  const _renderFieldList = (data, disabled?: boolean) => (
    <ClaimFormFieldList
      overrideConfig={overrideConfig}
      forms={data}
      country="SGP"
      product="cdw"
      formData={formData}
      error={errors}
      onChange={onChange}
      onUploadImage={onUploadImage}
      onDeleteImage={onDeleteImage}
      disabled={disabled}
    />
  );

  const _onMakeAnotherClaim = () => {
    clearClaimId();
    setFormData({ payment_method: PAYMENT_METHODS.PAYNOW_NRIC });
    setCheckState({
      check1: false,
      check2: false,
      radioState: '-',
    });
    setShowSuccessModal(false);
    setTimeout(() => setShowModalInfo(true), 400);
  };

  return (
    <ClaimPageLayout
      title={formData.included_docs ? 'Notify Claim ' : 'Claim'}
      fetching={fetching}
      onSubmit={_onSubmit}
    >
      <div className="flex gap-2">
        <div className="flex-1">
          <div className="claim-form z-10">
            <div className="claim-form-title">Choose a Vehicle</div>
            <div className="claim-form-subtitle">
              Type in the vehicle number you want to make a claim with. If vehicle is not present, add a new vehicle in
              the overview page.
            </div>
            <div className="relative z-10 w-full">
              <div className="flex w-full items-center">
                <ClaimBorderBottomInput
                  className="flex-1"
                  title="Vehicle No."
                  name="vehicle_reg"
                  maxLength={15}
                  required
                  value={formData?.vehicle_reg}
                  error={errors?.vehicle_reg}
                  onChange={onChange}
                />
                {fetchingSearch ? (
                  <SyncLoader color="#eaeaea" speedMultiplier={0.5} size={5} />
                ) : formData?.vehicleObject ? (
                  <CheckCircleIcon className="w-5 text-main-green" />
                ) : formData?.vehicle_reg && !searchResult?.length ? (
                  <div className="text-xs text-red-500">Not found</div>
                ) : null}
              </div>
              <DropDownSelectVehicle
                showDropdown={showDropdown}
                searchResult={searchResult}
                setShowDropdown={setShowDropdown}
                onSelectItem={_onSelectItem}
              />
            </div>
          </div>
          <ClaimInputDisbursement
            country="SGP"
            formData={formData}
            setFormData={setFormData}
            error={errors}
            onChange={onChange}
            onUploadImage={onUploadImage}
            onDeleteImage={onDeleteImage}
            disabled={!formData?.vehicleObject}
            className={clsx(!formData?.vehicleObject ? 'pointer-events-none opacity-60' : '')}
          />
        </div>
        <div className={clsx('claim-form flex-1', !formData?.vehicleObject ? 'pointer-events-none opacity-60' : '')}>
          <div className="claim-form-title">Claim Details</div>
          {_renderFieldList(['rental_company', 'policy_holder', 'incident_date'], !formData?.vehicleObject)}
          <div className="claim-form-title">Amount Payable or Excess Payable</div>
          {_renderFieldList(['section1excess', 'section2excess'], !formData?.vehicleObject)}
        </div>
      </div>

      {formData.included_docs ? (
        <div className={clsx('claim-form', !formData?.vehicleObject ? 'pointer-events-none opacity-60' : '')}>
          <div className="claim-form-title">Document Submission</div>
          <div className="flex gap-10">
            <div className="flex-1">
              <div className="text-sm text-main-green">For All Claims</div>
              {_renderFieldList([
                'id_front',
                'id_back',
                'drivers_license_front',
                'drivers_license_back',
                'certificate_of_motor_insurance',
                'rental_contract',
                'gia_report',
                'police_report',
                'proof_of_excess_payable_or_paid',
              ])}
            </div>
            <div className="flex-[2]">
              <div className="flex gap-10">
                <div className={clsx('flex-1', !formData.section1excess && 'pointer-events-none opacity-50')}>
                  <div className="text-sm text-main-green">Section 1</div>
                  {_renderFieldList([
                    'original_repair_invoice',
                    'photos_of_vehicle_before_repair',
                    'photos_of_vehicle_after_repair',
                  ])}
                </div>
                <div className={clsx('flex-1', !formData.section2excess && 'pointer-events-none opacity-50')}>
                  <div className="text-sm text-main-green">Section 2</div>
                  {_renderFieldList(['discharge_voucher', 'proof_of_excess_paid'])}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <div className="max-w-xl self-center">
        <ClaimAgreeCheckboxCdw
          shouldShowError={shouldShowError}
          checkState={checkState}
          setCheckState={setCheckState}
          onChangeRadio={_onChangeRadio}
        />
      </div>
      <ModalCdwInfo
        isVisible={showModalInfo}
        onNo={() => setShowModalInfo(false)}
        onYes={() => {
          setShowModalInfo(false);
          setFormData(prev => ({ ...prev, included_docs: true }));
        }}
      />
      <ClaimErrorModal errorMessage={errorMessage} onClose={clearErrorMessage} />
      <ClaimCdwSuccessModal
        isNotifyClaim={!formData.included_docs}
        formData={formData}
        isVisible={showSuccessModal}
        onMakeAnotherClaim={_onMakeAnotherClaim}
        onBack={onSuccessGoBack}
      />
      <ChangeSectionErrorModal
        isVisible={showModalConfirmSectionChange}
        onCancel={onCancelChangeSection}
        onConfirm={onConfirmChangeSection}
      />
    </ClaimPageLayout>
  );
};

export default SubmitClaim;
