// Require our logger before everything else to setup global.logger
import './scss/main.scss';
import 'react-table/react-table.css';
import 'react-datepicker/dist/react-datepicker.css';
import './index.css';

import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Toaster } from 'react-hot-toast';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/lib/integration/react';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(utc)
dayjs.extend(timezone);
dayjs.extend(isBetween);

import { ErrorBoundary } from './components';
import { persistor, store } from './redux';
import AppRoutes from './routes';

const muiTheme = createTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: {
      main: 'rgb(81,189,138)',
    },
  },
});

const root = createRoot(document.getElementById('root') || document.createElement('div'));
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <ErrorBoundary>
          <MuiThemeProvider theme={muiTheme}>
            <AppRoutes />
          </MuiThemeProvider>
          <Toaster />
        </ErrorBoundary>
      </BrowserRouter>
    </PersistGate>
  </Provider>,
);
