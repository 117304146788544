import { ApiInstance } from 'app/services/api';
import { handleError } from 'app/services/apiHelper';
import { validateFileCSV } from 'app/utilities/validates';
import { useState } from 'react';
import { globalLoading } from 'react-global-loading';
import { toast } from 'react-hot-toast';
import XLSX from 'xlsx';

export const useEbSheetFileReader = () => {
  const [isReadingFile, setIsReadingFile] = useState(false);
  const [isPreviewingFile, setIsPreviewingFile] = useState(false);
  const [progress, setProgress] = useState(0);
  const [fileError, setFileError] = useState<any>();
  const [dataTable, setDataTable] = useState<any[]>([]);
  const [originalFile, setOriginalFile] = useState<any>();
  const [fileReady, setFileReady] = useState(true);
  const [extractData, setExtractData] = useState<any[]>([]);
  const [uploadError, setUploadError] = useState<any>();

  const onReadFile = files => {
    const file = files[0];

    // Validate file csv
    if (!validateFileCSV(file)) {
      setFileError('Invalid File Type!');
      setDataTable([]);
      setOriginalFile(null);
      return;
    }

    setFileError('');
    setUploadError(null);
    setOriginalFile(file);
    _readFile(file);
    _previewFile(file);
  };

  const _readFile = file => {
    const reader = new FileReader();

    // Read file xlsx
    reader.onload = (e: any) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, {
        type: 'array',
        cellDates: false, // date cell will be parsed as number code and we will parse date from code ourself
      });

      const first_worksheet = workbook.Sheets[workbook.SheetNames[0]];

      // work sheet to JSON
      const result: any[] = XLSX.utils.sheet_to_json(first_worksheet, {
        // https://github.com/SheetJS/js-xlsx#json
        header: 1, // Generate an array of arrays ("2D Array")
        blankrows: false, // don't include empty excel rows
      });

      if (result[1]?.length) {
        const { data: dataTransfer } = onTransformFile(result);
        setDataTable(dataTransfer);
      }
    };

    reader.onprogress = data => {
      if (data.lengthComputable) {
        const progressValue = Math.floor((data.loaded / data.total) * 100);
        setProgress(progressValue);
      }
    };

    reader.onloadstart = () => {
      setIsReadingFile(true);
    };

    reader.onloadend = () => {
      setIsReadingFile(false);
    };

    reader.onabort = () => {
      setIsReadingFile(false);
    };

    reader.onerror = () => {
      setIsReadingFile(false);
    };

    reader.readAsArrayBuffer(file);
  };

  const _previewFile = async file => {
    globalLoading.show();
    setIsPreviewingFile(true);
    const body = new FormData();
    body.append('file', file);
    const res = await ApiInstance.addEmployee(body, true);
    setIsPreviewingFile(false);
    globalLoading.hide();
    const { result, error } = handleError(res);
    if (error) {
      setFileError(error?.data?.errors);
      setExtractData(error?.data?.extract_data);
      return;
    }
    setExtractData(result?.data?.extract_data);

    setFileReady(true);
  };

  const onRemoveFile = () => {
    setOriginalFile(null);
    setFileReady(false);
    setDataTable([]);
    setFileError(null);
    setExtractData([]);
    setUploadError(null);
  };

  const onSubmitFile = async successCB => {
    if (fileError) {
      toast.error(fileError);
      return;
    }
    if (!originalFile) {
      toast.error('Please submit a valid excel file');
      return;
    }
    const body = new FormData();
    body.append('file', originalFile);
    globalLoading.show();
    const res = await ApiInstance.addEmployee(body, false);
    globalLoading.hide();
    const { error } = handleError(res);
    if (error) {
      setUploadError(error?.data?.errors);
      setExtractData(error?.data?.extract_data);
    } else {
      successCB();
    }
  };

  return {
    onReadFile,
    onRemoveFile,
    fileReady,
    isReadingFile,
    isPreviewingFile,
    progress,
    fileError,
    dataTable,
    originalFile,
    extractData,
    onSubmitFile,
    uploadError,
  };
};

const onTransformFile = (fileRows, startRow = 0) => {
  // data reading from csv will have this format ( first row is headers )
  // [[headers], [data], [data], [data] ...]
  // transform into readable format
  // [ { [headerKey]: dataField, ... }, ... ]
  const headers = fileRows[startRow];
  // create map of { [headerKey] : columnNum (index) } for faster access when checking field in the same row
  const data: any[] = [];

  for (let i = startRow + 1; i < fileRows.length; i += 1) {
    data[i - 1] = {};

    // start validating each column in row
    for (let idx = 0; idx < headers.length; idx += 1) {
      const h = headers[idx];
      const value = fileRows[i][idx];
      const res = value;
      data[i - 1][h] = res;
    }
  }

  return {
    data,
  };
};
