import { DATE_FORMAT } from 'app/constants';
import { selectors } from 'app/redux';
import { capitalizeFirstLetter } from 'app/utils';
import clsx from 'clsx';
import dayjs from 'dayjs';
import React from 'react';
import { useSelector } from 'react-redux';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import type { CsvConvertedData } from '../data-helper';

export const ErrorPreview = ({ form, className }: { form: CsvConvertedData; className?: string }) => {
  const { user } = useSelector(selectors.user);

  const _renderFieldDisplay = ({
    label,
    name,
    isDate,
    overrideValue,
  }: {
    label: string;
    name: string;
    isDate?: boolean;
    overrideValue?: string;
  }) => {
    const formValue = form?.[name]?.value;
    const displayValue =
      overrideValue ||
      (isDate
        ? dayjs(form?.[name]?.value).format(DATE_FORMAT.date)
        : name === 'unit'
        ? capitalizeFirstLetter(formValue)
        : formValue);
    return <FieldDisplay label={label} value={displayValue} error={form?.[name]?.error || form?.warnings?.[name]} />;
  };

  return (
    <div className={clsx('flex flex-col gap-4 rounded-lg border border-gray-300 p-6', className)}>
      <div className="text-sm text-gray-600">ERROR PREVIEW</div>
      <div className="text-xl">Reduced Motor Excess Declaration</div>
      <div className="flex gap-10 p-4">
        <div className="flex flex-1 flex-col gap-4">
          {_renderFieldDisplay({ label: 'Vehicle Registration Number', name: 'vehicle_reg' })}
          {_renderFieldDisplay({ label: 'Vehicle Model', name: 'model' })}
          {_renderFieldDisplay({ label: 'Vehicle Make', name: 'make' })}
          {_renderFieldDisplay({ label: 'Vehicle Chassis No.', name: 'chassis' })}
          {_renderFieldDisplay({ label: 'Vehicle Engine No.', name: 'engine' })}
          {_renderFieldDisplay({ label: 'Vehicle Type', name: 'vehicle_type' })}
          {_renderFieldDisplay({ label: 'Company UEN', name: 'company_uen', overrideValue: user?.group_obj?.uen })}
          {_renderFieldDisplay({
            label: 'Company Name',
            name: 'company_name',
            overrideValue: user?.group_obj?.display_name,
          })}
        </div>
        <div className="flex flex-1 flex-col gap-6">
          <div
            className={clsx(
              'flex flex-col gap-4 rounded-xl border p-4',
              form?.warnings?.pricing ? 'border-red-600' : '',
            )}
          >
            <div className="text-xl">Choose your excess levels</div>
            {form?.combined_excess?.value ? (
              <>{_renderFieldDisplay({ label: 'Combined Excess', name: 'combined_excess' })}</>
            ) : (
              <>
                {_renderFieldDisplay({ label: 'Section 1 Excess', name: 'section1excess' })}
                {_renderFieldDisplay({ label: 'Section 2 Excess', name: 'section2excess' })}
              </>
            )}
            {_renderFieldDisplay({ label: 'Reduced Excess To', name: 'reduced_excess' })}
            {form?.warnings?.pricing ? <div className="error-text">{form?.warnings?.pricing}</div> : null}
          </div>
          <div className="flex flex-col gap-4 rounded-xl border p-4">
            <div className="text-xl">Vehicle Plan</div>
            {_renderFieldDisplay({ label: 'Auto Renewal', name: 'auto_renewal' })}
            {_renderFieldDisplay({ label: 'Unit', name: 'unit' })}
            {_renderFieldDisplay({ label: 'Start Date', name: 'start_date', isDate: true })}
            {form?.unit?.value?.toLowerCase() === 'daily'
              ? _renderFieldDisplay({ label: 'End Date', name: 'end_date', isDate: true })
              : null}
          </div>
        </div>
      </div>
      <div className="error-text">{form?.generalError || form?.warnings?.generalError}</div>
    </div>
  );
};

const FieldDisplay = ({ label, error, value }: { label: string; error?: string; value: any }) => {
  return (
    <div className="flex flex-col">
      <div className="flex items-center">
        <div className={clsx('mr-3 w-56 text-sm font-medium', error ? 'text-red-600' : '')}>
          {label}{' '}
          {error ? <img className="inline-block" src="/img/smallIcon/red-warning.svg" data-tooltip-id={label} /> : null}
        </div>
        <div
          className={clsx(
            'flex-1 rounded-md border bg-transparent p-2 ',
            error ? 'border-red-600 text-red-600' : 'border-zinc-200',
          )}
        >
          {value}&nbsp;
        </div>
      </div>
      {error ? (
        <ReactTooltip place="left" id={label} variant="error">
          <div className="max-w-sm">{error}</div>
        </ReactTooltip>
      ) : null}
    </div>
  );
};
