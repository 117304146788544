import { APP_CONFIG, FLEET_TYPE } from 'app/constants';
import { type CsvConvertedData, onTransformFile } from 'app/modules/upload-csv';
import { selectors } from 'app/redux';
import { checkHasPayLater } from 'app/utilities/data-helper';
import { getDuplicateIndexs } from 'app/utilities/transformData';
import { validateFileCSV } from 'app/utilities/validates';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import XLSX from 'xlsx';

const { VALIDATE_FIELDS } = APP_CONFIG.uploadCSV;

export const useSheetFileReader = () => {
  const { user } = useSelector(selectors.user);
  const hasPayLater = checkHasPayLater(user);

  const { fleet_type } = user || {};
  const triberCarOrTreeDotsFleet = fleet_type === FLEET_TYPE.TRIBECAR || fleet_type === FLEET_TYPE.TREEDOTS;

  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  const onReadFile = (
    files: any[],
    callback: ({ data, error }: { data: CsvConvertedData[]; error: string }) => void,
  ) => {
    const file = files[0];

    // Validate file csv
    if (!validateFileCSV(file)) {
      callback({ data: [], error: 'Invalid file format!' });
      return;
    }

    setIsLoading(true);
    const reader = new FileReader();

    // Read file xlsx
    reader.onload = (e: any) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, {
        type: 'array',
        cellDates: false, // date cell will be parsed as number code and we will parse date from code ourself
      });

      const first_worksheet = workbook.Sheets[workbook.SheetNames[0]];

      // work sheet to JSON
      const result: any[] = XLSX.utils.sheet_to_json(first_worksheet, {
        // https://github.com/SheetJS/js-xlsx#json
        header: 1, // Generate an array of arrays ("2D Array")
        blankrows: false, // don't include empty excel rows
      });

      // Validate fields on xlsx file
      let showError;

      if (result[0] && result[0].length > 0) {
        for (let i = 0; i < VALIDATE_FIELDS.length; i += 1) {
          if (result[0].indexOf(VALIDATE_FIELDS[i]) === -1) {
            callback({ data: [], error: `Your file is missing field ${VALIDATE_FIELDS[i]}!` });
            setIsLoading(false);
            setProgress(0);
            return;
          }
        }

        if (result[0].indexOf('Start Date') === -1 && result[0].indexOf('Contract Start Date') === -1) {
          callback({ data: [], error: 'Your file is missing field Start Date!' });
          setIsLoading(false);
          setProgress(0);
          return;
        }

        const { data, hasError } = onTransformFile(result, { triberCarOrTreeDotsFleet, hasPayLater });

        const getDuplicateRecords = getDuplicateIndexs(
          data,
          item => item.vehicle_reg.value,
          idx => {
            if (data[idx]) {
              data[idx].vehicle_reg.error = 'Duplicated vehicle registration number';
              data[idx].hasError = true;
            }
          },
        );

        if (hasError || getDuplicateRecords.length > 0) {
          showError = true;
        }
        setIsLoading(false);
        setProgress(0);
        callback({ data, error: showError ? 'Invalid/missing input!' : '' });
      } else {
        setIsLoading(false);
        setProgress(0);
        callback({ data: [], error: 'No data' });
      }
    };

    reader.onloadstart = () => {
      setIsLoading(true);
    };

    reader.onprogress = data => {
      if (data.lengthComputable) {
        const progressValue = Math.floor((data.loaded / data.total) * 100);
        setProgress(progressValue);
      }
    };

    reader.readAsArrayBuffer(file);
  };
  return { onReadFile, isLoading, progress };
};
