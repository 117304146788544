import clsx from 'clsx';
import type { FC } from 'react';
import React from 'react';

interface CheckBoxProps {
  checked: boolean;
  children: React.ReactNode;
  onClick: () => void;
  centerText?: boolean;
  showError?: boolean;
  error?: string;
}
export const CheckBox: FC<CheckBoxProps> = ({ checked, children, onClick, centerText, showError, error }) => {
  return (
    <div className={clsx('mt-7 flex', centerText && 'items-center')} onClick={onClick}>
      <div
        className={clsx(
          'mr-2 flex h-7 w-7 items-center justify-center rounded border-2 bg-neutral-100',
          showError ? 'border-red-600' : 'border-neutral-100',
        )}
      >
        {checked ? <div className="h-5 w-5 rounded-sm bg-main-green" /> : null}
      </div>
      <div className="flex-1 text-left text-xs">
        {children}
        {showError && <div className="text-xs text-red-600">{error}</div>}
      </div>
    </div>
  );
};

export const CheckBoxSmall = ({ checked, children, onClick, itemsCenter }) => {
  return (
    <div className={clsx('mt-7 flex', itemsCenter && 'items-center')}>
      <button
        className={clsx(
          'mr-2 flex h-5 w-5 cursor-pointer items-center justify-center rounded',
          checked ? 'bg-main-green' : 'bg-neutral-200',
        )}
        onClick={onClick}
      />
      <div className="flex-1 text-xs font-medium text-zinc-400">{children}</div>
    </div>
  );
};

interface CheckRadioProps {
  checked: boolean;
  children: React.ReactNode;
  onClick?: () => void;
  centerText?: boolean;
  type?: 'regular' | 'donus';
}
export const CheckRadio: FC<CheckRadioProps> = ({ checked, children, onClick, centerText, type = 'regular' }) => {
  return (
    <button className={clsx('mt-7 flex', centerText && 'items-center')} disabled={!onClick} onClick={onClick}>
      {type === 'donus' ? <RadioDonus checked={checked} /> : <RadioRegular checked={checked} />}
      <div className="flex-1 text-left text-xs">{children}</div>
    </button>
  );
};

const RadioRegular = ({ checked }) => (
  <div className={clsx('mr-2 flex h-6 w-6 items-center justify-center rounded-full bg-neutral-100')}>
    {checked ? <div className="h-4 w-4 rounded-full bg-main-green" /> : null}
  </div>
);

const RadioDonus = ({ checked }) => (
  <div
    className={clsx(
      'mr-2 flex h-4 w-4 items-center justify-center rounded-full',
      checked ? 'bg-main-green' : 'bg-white',
    )}
  >
    <div className="h-2 w-2 rounded-full bg-white" />
  </div>
);
