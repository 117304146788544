import React from 'react';

export const PaynowInfo = ({ companyUen, referenceNumber, amount }) => {
  return (
    <div className="flex w-full flex-col items-center">
      <div className="flex justify-center">
        <img
          className="h-64"
          src="https://storage.googleapis.com/gigacover-production-public/files/gigacover-paynow-qr.png"
          alt="qr-code"
        />
        <div className="flex flex-col justify-between">
          <div>
            <div className="text-base font-medium">Unique Entity Number (UEN)</div>
            <div className="text-base text-main-green">{companyUen}</div>
          </div>
          <div>
            <div className="text-base font-medium">Reference Number</div>
            <div className="text-base text-main-green">{referenceNumber}</div>
          </div>
          <div>
            <div className="text-base font-medium">Amount payable</div>
            <div className="text-base text-main-green">${amount}</div>
          </div>
        </div>
      </div>
      <div className="mt-5">
        <div className="text-base">Instructions:</div>
        <ol className="text-base">
          <li>1. Open the iBanking app on your mobile phone and select "PayNow"</li>
          <li>2. Select either "Scan & Pay" or "UEN" to make payment</li>
          <li>3. Scan the QR code above or enter the UEN above and key in the exact amout payable</li>
          <li>4. Enter the Reference Number in the description field.</li>
          <li>5. Once the payment is successful, click on "Done" on the webpage.</li>
        </ol>
      </div>
    </div>
  );
};
