import type { ApiResponse } from 'apisauce';
import apisauce from 'apisauce';
import Axios from 'axios';

import { store, UserActions } from '../redux';
import { handleError } from './apiHelper';

const axiosApiPhoenix = Axios.create({
  baseURL: process.env.REACT_APP_PHOENIX_API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    // 'Cache-Control': 'no-cache',
  },
  timeout: 50000,
});

const axiosApiSwift = Axios.create({
  baseURL: process.env.REACT_APP_SWIFT_API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    // 'Cache-Control': 'no-cache',
  },
  timeout: 50000,
});

const axiosRefeshApi = Axios.create({
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    // 'Cache-Control': 'no-cache',
  },
  timeout: 50000,
});

const refreshApi = apisauce.create({
  // @ts-ignore
  axiosInstance: axiosRefeshApi,
});

let fetchRefreshToken: Promise<ApiResponse<unknown, unknown>> | null = null;

axiosApiPhoenix.interceptors.response.use(
  response => response,
  async error => {
    try {
      if (error?.response?.status !== 401) return Promise.reject(error);

      // @ts-ignore
      const { refreshToken } = store.getState().user;
      if (!refreshToken) {
        throw new Error('Logout');
      }
      if (!fetchRefreshToken) {
        fetchRefreshToken = refreshApi.post(`${process.env.REACT_APP_PHOENIX_API_URL}/v2/refresh`, {
          token: refreshToken,
        });
      }
      const resRefesh = await fetchRefreshToken;
      const { error: refreshError, result } = handleError(resRefesh);
      fetchRefreshToken = null;
      if (refreshError) {
        throw new Error('Logout');
      }

      const { access_token } = result || {};
      store.dispatch(UserActions.setPhoenixToken(access_token));
      ApiInstance.setAuthToken(access_token);

      return axiosApiPhoenix({
        ...error.response.config,
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });
    } catch (e) {
      store.dispatch(UserActions.logoutRequest());
      return Promise.reject(error);
    }
  },
);

let fetchSwiftToken: Promise<ApiResponse<unknown, unknown>> | null = null;
axiosApiSwift.interceptors.response.use(
  response => response,
  async error => {
    try {
      if (error?.response?.status !== 401) return Promise.reject(error);

      // @ts-ignore
      const { refreshToken } = store.getState().user;
      if (!refreshToken) {
        throw new Error('Logout');
      }
      if (!fetchRefreshToken) {
        fetchRefreshToken = refreshApi.post(`${process.env.REACT_APP_PHOENIX_API_URL}/v2/refresh`, {
          token: refreshToken,
        });
      }
      const resRefesh = await fetchRefreshToken;
      const { error: refreshError, result: refreshResult } = handleError(resRefesh);
      fetchRefreshToken = null;
      if (refreshError) {
        throw new Error('Logout');
      }

      const { access_token } = refreshResult || {};
      store.dispatch(UserActions.setToken(access_token));
      ApiInstance.setAuthToken(access_token);

      if (!fetchSwiftToken) {
        fetchSwiftToken = refreshApi.post(`${process.env.REACT_APP_SWIFT_API_URL}/v2/login`, {
          token: access_token,
        });
      }
      const swiftTokenRes = await fetchSwiftToken;
      const { error: swiftTokenError, result: swiftTokenResult } = handleError(swiftTokenRes);

      fetchSwiftToken = null;
      if (swiftTokenError) {
        return null;
      }

      const { access_token: swiftToken } = swiftTokenResult?.data || {};
      store.dispatch(UserActions.setSwiftToken(swiftToken));
      ApiInstance.setSwiftAuthToken(swiftToken);

      return axiosApiSwift({
        ...error.response.config,
        headers: {
          Authorization: `Bearer ${swiftToken}`,
        },
      });
    } catch (e) {
      store.dispatch(UserActions.logout());
      return Promise.reject(error);
    }
  },
);
const create = () => {
  const apiPhoenix = apisauce.create({
    // @ts-ignore
    axiosInstance: axiosApiPhoenix,
  });

  const apiSwift = apisauce.create({
    // @ts-ignore
    axiosInstance: axiosApiSwift,
  });

  // SET AUTH TOKEN
  const setAuthToken = userAuth => {
    if (userAuth) {
      apiPhoenix.setHeader('Authorization', `Bearer ${userAuth}`);
    } else {
      apiPhoenix.setHeader('Authorization', '');
    }
  };

  const setSwiftAuthToken = userAuth => {
    if (userAuth) {
      apiSwift.setHeader('Authorization', `Bearer ${userAuth}`);
    } else {
      apiSwift.setHeader('Authorization', '');
    }
  };

  // API function
  const login = body => apiPhoenix.post('/v2/login', body);
  const getUserInfo = () => apiPhoenix.get('/v2/users');
  const refreshToken = token => apiPhoenix.post('/v2/refresh', { token });
  const swiftLogin = token => apiSwift.post('/v2/login', { token });

  // PHOENIX
  const getListCountries = () => apiPhoenix.get('v2/countries', { page_size: 1000 });
  const getListGroups = () => apiPhoenix.get('v2/groups', { page_size: 1000 });
  const getListTemplates = () => apiPhoenix.get('v2/templates', { page_size: 1000 });
  const getListUnits = () => apiPhoenix.get('v2/units', { page_size: 1000 });

  const getProductListing = body => apiPhoenix.post('v2/products/listing', body);
  const checkUser = body => apiPhoenix.post('v2/check-user', body);
  const createUserAccount = body => apiPhoenix.post('v2/users', body);
  const updateUserAccount = (id, body) => apiPhoenix.put(`v2/users/${id}`, body);
  const getSale = body => apiPhoenix.post('v2/sale', body);
  const getSaleImportStatus = importId => apiPhoenix.post(`v2/sale/${importId}`);
  const addUserPaymentMethod = body => apiPhoenix.post('v2/user_payment_methods', body);
  const userPayment = body => apiPhoenix.post('v2/payment', body);
  const reconcilePayment = body => apiPhoenix.post('v2/sale', body);
  const putOrderDetail = (id, body) => apiPhoenix.put(`v2/order_details/${id}`, body);
  const putBatchOrder = body => apiPhoenix.put('/v2/sale/update-payment-ref', body);

  // OVERVIEW
  const getVehiclesList = body => apiPhoenix.get('/v2/orders/overview?template_name=mep__mer', body);
  const getOrderDetail = body => apiPhoenix.get('/v2/order_details', { sorted_key: '-start_date', ...body });
  const turnOnAutoRenew = body => apiPhoenix.post('v2/auto-renewal/turn-on', body);
  const turnOffAutoRenew = body => apiPhoenix.post('v2/auto-renewal/turn-off', body);

  const searchVehicle = body => apiSwift.get('/v2/coverages', body);

  // BE
  const getEbList = body => apiPhoenix.get('/v2/orders/overview', { template_name: 'eb', ...body });
  const getGroupRankList = group_id =>
    apiPhoenix.get('/v2/group_rank_rels', {
      group_id,
      is_dependent: false,
    });
  const addEmployee = (body, isPreview?: boolean) =>
    apiPhoenix.post(`v2/sales/sponsor-file?is_preview=${isPreview ? 1 : 0}`, body);
  const removeHmo = body => apiPhoenix.post('/v2/sales/expire_now', body);

  // CLAIM
  const getVehicleClaimList = body => apiSwift.get('/v2/claims', body);
  const getVehicleClaimDetail = id => apiSwift.get(`/v2/claims/${id}`);
  const createClaim = body => apiSwift.post('v2/claims', body);
  const uploadFile = body => apiSwift.post('v2/claims/upload', body);
  const confirmClaim = (claimId, body) => apiSwift.put(`v2/claims/${claimId}`, body || {});

  const searchEbUser = body => apiSwift.get('/v2/coverages', body);

  // VEHICLE
  const updatePolicy = body => apiPhoenix.post('/v2/sale/confirmation', body);

  // CREATE PASSWORD
  const generateOtpCreatePassword = body => apiPhoenix.post('v2/auth/generate-otp', body);
  const verifyOtpCreatePassword = body => apiPhoenix.post('v2/auth/verify-otp', body);
  const createPassword = body => apiPhoenix.put('v2/auth/otp-change-password', body);

  // OPN
  const addOpnCard = body => apiPhoenix.post('/v2/payment/add-card', body);
  const checkOmiseCardStatus = (charge_id: string, body: any) =>
    apiPhoenix.post(`v2/payment/card-status/${charge_id}`, body);

  return {
    login,
    getUserInfo,
    refreshToken,
    swiftLogin,
    //
    getVehiclesList,
    getOrderDetail,
    turnOnAutoRenew,
    turnOffAutoRenew,

    searchVehicle,

    // EB
    getEbList,
    getGroupRankList,
    addEmployee,
    removeHmo,

    // PHOENIX
    getListCountries,
    getListGroups,
    getListTemplates,
    getListUnits,

    getProductListing,
    checkUser,
    createUserAccount,
    updateUserAccount,
    getSale,
    getSaleImportStatus,
    addUserPaymentMethod,
    userPayment,
    reconcilePayment,
    putOrderDetail,
    putBatchOrder,
    // CLAIMS
    getVehicleClaimList,
    getVehicleClaimDetail,
    createClaim,
    uploadFile,
    confirmClaim,
    searchEbUser,

    // VEHICLE
    updatePolicy,

    // CREATE PASSWORD
    generateOtpCreatePassword,
    verifyOtpCreatePassword,
    createPassword,

    //
    setAuthToken,
    setSwiftAuthToken,

    // OPN
    addOpnCard,
    checkOmiseCardStatus,
  };
};

export const ApiInstance = create();
