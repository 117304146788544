import DayjsUtils from '@date-io/dayjs';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
//
import { DATE_FORMAT } from 'app/constants';
import clsx from 'clsx';
import dayjs from 'dayjs';
import type { FC, ReactNode } from 'react';
import React from 'react';
import { SyncLoader } from 'react-spinners';
import { Tooltip as ReactTooltip } from 'react-tooltip';

interface InlineInputProps {
  label?: string;
  required?: boolean;
  error?: string;
  children?: ReactNode;
  onClick?: () => void;
  //
  disabled?: boolean;
  value?: any;
  onChange?: (e: any) => void;
  toolTipInfo?: string;
  className?: string;
}
const InlineInputLayout: FC<InlineInputProps> = ({ label, className, required, error, children, onClick }) => {
  return (
    <div className={clsx('flex flex-col', className)} onClick={onClick}>
      <div className="flex items-center">
        <label className="mr-3 w-40 text-sm font-medium">
          {label}
          {required ? <span className="text-red-600">*</span> : null}
        </label>
        <div className="flex-1">{children}</div>
      </div>
      <div className="text-sm text-red-600">{error}&nbsp;</div>
    </div>
  );
};

export const InlineLabel: FC<InlineInputProps> = ({ value, label, required, error, toolTipInfo }) => {
  return (
    <InlineInputLayout label={label} required={required} error={error}>
      <div className="flex items-center">
        <div className={clsx('w-full p-1 text-sm font-medium', toolTipInfo ? 'text-red-600' : 'text-main-green')}>
          {value}&nbsp;
        </div>{' '}
        {toolTipInfo ? (
          <>
            <img src="/img/smallIcon/red-warning.svg" data-tooltip-id={label} />
            <ReactTooltip place="left" id={label} variant="error">
              <div className="max-w-sm">{toolTipInfo}</div>
            </ReactTooltip>
          </>
        ) : null}
      </div>
    </InlineInputLayout>
  );
};

interface InlineTextInputProps extends InlineInputProps {
  prefix?: string;
  name?: string;
  maxLength?: number;
  fetching?: boolean;
}
export const InlineTextInput: FC<InlineTextInputProps> = ({
  label,
  className,
  required,
  prefix,
  error,
  name,
  value,
  maxLength,
  fetching,
  ...rest
}) => {
  return (
    <InlineInputLayout label={label} className={className} required={required} error={error}>
      <div className="flex items-center text-sm text-zinc-900">
        {prefix ? <div className="mr-1 rounded-md border border-zinc-200 p-2">{prefix}</div> : null}
        <div className="relative flex flex-1">
          <input
            className="w-full flex-1 rounded-md border border-zinc-200 bg-transparent p-2 outline-none"
            id={name}
            name={name}
            min="0"
            value={value || ''}
            maxLength={maxLength}
            {...rest}
          />
          {fetching ? (
            <SyncLoader className="absolute right-2 self-center" color="#eaeaea" speedMultiplier={0.5} size={5} />
          ) : null}
        </div>
      </div>
    </InlineInputLayout>
  );
};

export const InlineAreaInput = ({ label, required, error, name, rows = 2, ...rest }) => {
  return (
    <InlineInputLayout label={label} required={required} error={error}>
      <textarea
        className="w-full border-b-2 border-b-zinc-200 bg-transparent p-1 text-sm text-zinc-900 outline-none"
        id={name}
        rows={rows}
        name={name}
        {...rest}
      />
    </InlineInputLayout>
  );
};

interface InlineDateInputProps extends InlineInputProps {
  onChange?: (e: any) => void;
  name?: string;
  maxDate?: Date;
  minDate?: Date;
  disabled?: boolean;
  placeholder?: string;
}
export const InlineDateInput: FC<InlineDateInputProps> = ({
  label,
  required,
  error,
  value,
  onChange,
  name,
  maxDate,
  minDate,
  disabled,
  placeholder,
  className,
  ...rest
}) => {
  const _onChange = (date: Date) => {
    if (onChange) {
      onChange({
        target: {
          name,
          value: dayjs(date).format(DATE_FORMAT.serverDate),
        },
      });
    }
  };
  return (
    <InlineInputLayout label={label} className={className} required={required} error={error}>
      <div className="relative flex items-center">
        <img className="absolute left-2 top-1.5" src="/img/smallIcon/calendar.svg" width={20} alt="calendar" />
        <MuiPickersUtilsProvider utils={DayjsUtils}>
          <DatePicker
            className="w-full"
            value={value || null}
            minDate={minDate}
            maxDate={maxDate}
            disabled={disabled}
            emptyLabel={placeholder}
            format={DATE_FORMAT.date}
            InputProps={{
              disableUnderline: true,
              disabled,
              classes: {
                root: 'border border-zinc-200 pl-10 rounded-md text-sm',
                input: 'text-sm',
                disabled: 'bg-stone-50',
              },
            }}
            // @ts-ignore
            onChange={_onChange}
            {...rest}
          />
        </MuiPickersUtilsProvider>
      </div>
    </InlineInputLayout>
  );
};

export const InlineSelectInput = ({
  label,
  error,
  required,
  disabled,
  name,
  options,
  value,
  onChange,
  className,
}: {
  label?: string;
  required?: boolean;
  error?: string;
  className?: string;
  disabled?: boolean;
  name?: string;
  options: { value: string | number; label: string }[];
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}) => {
  return (
    <InlineInputLayout label={label} className={className} required={required} error={error}>
      <select
        disabled={disabled}
        className="w-full rounded-md border border-zinc-200 bg-transparent py-2 px-1 text-sm outline-none disabled:cursor-not-allowed"
        name={name}
        value={value || ''}
        onChange={onChange}
      >
        <option hidden disabled value={''}></option>
        {options?.map((option, index) => (
          <option key={`${option.value}${index}`} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </InlineInputLayout>
  );
};
