//
import { PRODUCT_REGIONS } from 'app/constants';
import { PAYMENT_METHODS, PAYMENT_METHODS_OPTIONS, PAYMENT_OPTIONS_BANK_EWALLET } from 'app/constants/claimConfig';
import React, { useMemo } from 'react';

//
import { ClaimBorderBottomLabel } from './claim-border-bottom-input/claim-border-bottom-input';
import { ClaimFormLayout } from './claim-form-layout/claim-form-layout';

export const ClaimDisbursementDetail = ({ country = PRODUCT_REGIONS.SG, ...rest }) => {
  // @ts-ignore
  if (country === PRODUCT_REGIONS.SG) return <ClaimSgDisbursementDetail {...rest} />;
  // @ts-ignore
  if (country === PRODUCT_REGIONS.PH) return <ClaimPhDisbursementDetail {...rest} />;
  return null;
};

const ClaimSgDisbursementDetail = ({ data }) => {
  const {
    payment_method,
    paynow_mobile,
    paynow_nickname,
    paynow_nricfin,
    uen_number,
    company_name,
    bank_account_nricfin,
    bank_name,
    bank_account_holder_name,
    bank_account_number,
  } = data || {};
  const method = useMemo(() => PAYMENT_METHODS_OPTIONS.find(item => item.value === payment_method), [payment_method]);

  return (
    <ClaimFormLayout title="Disbursement Details">
      <ClaimBorderBottomLabel title="Payment Method" value={method?.label} />
      {payment_method === PAYMENT_METHODS.PAYNOW_MOBILENUMBER && (
        <>
          <ClaimBorderBottomLabel title="Mobile Number" value={paynow_mobile} />
          <ClaimBorderBottomLabel title="PayNow Nickname" value={paynow_nickname} />
        </>
      )}
      {payment_method === PAYMENT_METHODS.PAYNOW_NRIC && (
        <>
          <ClaimBorderBottomLabel title="NRIC" value={paynow_nricfin} />
          <ClaimBorderBottomLabel title="PayNow Nickname" value={paynow_nickname} />
        </>
      )}
      {payment_method === PAYMENT_METHODS.PAYNOW_UEN && (
        <>
          <ClaimBorderBottomLabel title="UEN Number" value={uen_number} />
          <ClaimBorderBottomLabel title="Company Name" value={company_name} />
        </>
      )}
      {payment_method === PAYMENT_METHODS.BANK_ACCOUNT && (
        <div>
          <ClaimBorderBottomLabel title="NRIC/FIN/UEN" value={bank_account_nricfin} />
          <ClaimBorderBottomLabel title="Name of the Bank" value={bank_name} />
          <ClaimBorderBottomLabel title="Name of the Bank Account Holder" value={bank_account_holder_name} />
          <ClaimBorderBottomLabel title="Bank Account Number" value={bank_account_number} />
        </div>
      )}
    </ClaimFormLayout>
  );
};

const ClaimPhDisbursementDetail = ({ data }) => {
  const { preferred_payment, mobile, bank_account_number } = data || {};

  const method = useMemo(
    () => PAYMENT_OPTIONS_BANK_EWALLET.find(item => item.value === preferred_payment),
    [preferred_payment],
  );

  return (
    <ClaimFormLayout title="Disbursement Details">
      <ClaimBorderBottomLabel title="Bank/ E-Wallet Name" value={method?.label} />
      {method?.isEWallet ? (
        <ClaimBorderBottomLabel title="Mobile Number" value={mobile} />
      ) : (
        <ClaimBorderBottomLabel title="Account Number" value={bank_account_number} />
      )}
    </ClaimFormLayout>
  );
};
