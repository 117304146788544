import { DATE_FORMAT, SHOWING_OVERVIEW_STATUS } from 'app/constants';
import { selectors } from 'app/redux';
import { ApiInstance } from 'app/services/api';
import { handleError } from 'app/services/apiHelper';
import dayjs from 'dayjs';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';

export const useVehicleUpdate = refreshDataCB => {
  const { pricingList, listUnits } = useSelector(selectors.data);
  const [fetching, setFetching] = useState(false);

  const updateRenewal = async (vehicleData, autoRenewal) => {
    const { vehicle_reg, product_id, sale_type, user_id } = vehicleData || {};

    let action = ApiInstance.turnOffAutoRenew;
    if (autoRenewal) action = ApiInstance.turnOnAutoRenew;
    setFetching(true);
    const res = await action({
      user_id: user_id || null,
      product_id: product_id || null,
      vehicle_reg: vehicle_reg || null,
      sale_type: sale_type || null,
    });
    setFetching(false);
    const { result, error } = handleError(res, true);
    if (!error) {
      toast.success('Update successfully');
      if (refreshDataCB) refreshDataCB(result);
    }
  };

  const updatePolicyStart = async (vehicleDetail, policyStart) => {
    const { vehicle_reg, details, price_details_id, price_id, unit_obj, user_id } = vehicleDetail || {};

    const body = {
      user_id: user_id || null,
      product_ids: [
        {
          price_details_id,
          quantity: 1,
          start_date: dayjs(policyStart).format(DATE_FORMAT.serverDate),
          unit_id: unit_obj?.id || listUnits?.months?.id,
          vehicle_reg,
          details,
          price_id,
        },
      ],
      sale_type: 'mer',
      renewal_change_plan: true,
    };
    setFetching(true);
    const res = await ApiInstance.getSale(body);
    setFetching(false);
    const { result, error } = handleError(res, true);
    if (!error) {
      if (refreshDataCB) refreshDataCB(result);
      return { result, error: null };
    }
    return { result: null, error };
  };

  const updateVehicleExcess = async (
    vehicleDetail,
    section1excess,
    section2excess,
    combined_excess,
    reduced_combined_excess,
    overridePricingList,
  ) => {
    const { vehicle_reg, vehicle_type, details, unit_obj, user_id } = vehicleDetail || {};
    const pricingListToFind = overridePricingList || pricingList;
    const pricingObject = pricingListToFind.find(
      findItem =>
        findItem.config_objs.section1excess === +(section1excess || 0) &&
        findItem.config_objs.section2excess === +(section2excess || 0) &&
        findItem.config_objs.reduced_combined_excess === +(reduced_combined_excess || 0) &&
        findItem.config_objs.combined_excess === +(combined_excess || 0) &&
        findItem.config_objs.vehicle_type === vehicle_type,
    );

    const body = {
      user_id: user_id || null,
      product_ids: [
        {
          price_details_id: pricingObject?.price_details?.id,
          quantity: 1,
          // start_date: contract_start,
          unit_id: unit_obj?.id || listUnits?.months?.id,
          vehicle_reg,
          details,
          price_id: pricingObject?.price_details?.price_id,
        },
      ],
      sale_type: 'mer',
      renewal_change_plan: true,
    };
    setFetching(true);
    const res = await ApiInstance.getSale(body);
    setFetching(false);
    const { result, error } = handleError(res, true);
    if (!error) {
      if (refreshDataCB) refreshDataCB(result);
      return { result, error };
    }
    return { result, error };
  };

  const updatePolicyStatus = async (data, refNum) => {
    const { order_id, id, next_order_id, next_od_id, upcoming_status } = data || {};
    setFetching(true);
    let body;
    if (upcoming_status === SHOWING_OVERVIEW_STATUS.PREPARED) {
      body = {
        order_id: next_order_id,
        order_details_id: next_od_id,
        payment_ref: refNum,
        service: 'pay_now',
        // pm_source_id: user.paymentMethods?.other_paynow?.id,
      };
    } else {
      body = {
        order_id,
        order_details_id: id,
        payment_ref: refNum,
        service: 'pay_now',
        // pm_source_id: user.paymentMethods?.other_paynow?.id,
      };
    }

    const res = await ApiInstance.updatePolicy(body);
    setFetching(false);
    const { result, error } = handleError(res);
    if (error) {
      toast.error(error.message);
    } else {
      toast.success('Update successfully');
      if (refreshDataCB) refreshDataCB(result);
    }
  };

  return { fetching, updateRenewal, updatePolicyStart, updateVehicleExcess, updatePolicyStatus };
};
