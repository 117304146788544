import { ApiInstance } from 'app/services/api';
import { handleError } from 'app/services/apiHelper';
import debounce from 'lodash/debounce';
import { useCallback, useRef, useState } from 'react';
import toast from 'react-hot-toast';

export const useEbOverviewList = () => {
  const [searchParams, setSearchParams] = useState<any>({ page_number: 0, page_size: 5 });
  const [searchString, setSearchString] = useState('');

  const [overviewList, setOverviewList] = useState([]);
  const [overviewInfo, setOverviewInfo] = useState<any>({});
  const [totalPages, setTotalPages] = useState(0);
  const [fetching, setFetching] = useState(false);
  const fetchingApi = useRef(false);

  const searchParamsRef = useRef<any>({ page_number: 0, page_size: 5 });

  const onChangeSearchString = e => {
    const text = e.target.value;
    setSearchString(text);
    _debounceSearch(text);
  };

  const _debounceSearch = useCallback(
    debounce(text => {
      updateSearchParams({ search_text: text });
    }, 500),
    [],
  );

  const updateSearchParams = data => {
    const overrideParams: any = {};

    const searchParamsFromRef = searchParamsRef.current || {};

    if (data.search_text && searchParamsFromRef.search_text !== data.search_text) {
      overrideParams.page_number = 0;
    }
    onFetchData({ ...searchParamsFromRef, ...data, ...overrideParams });
  };

  const onSort = key => {
    const searchParamsFromRef = searchParamsRef.current || {};
    const { sort_field } = searchParamsFromRef;
    if (sort_field === key) {
      if (!searchParamsFromRef.sort_type) searchParamsFromRef.sort_type = 'asc';
      else if (searchParamsFromRef.sort_type === 'asc') searchParamsFromRef.sort_type = 'desc';
      else searchParamsFromRef.sort_type = undefined;
    } else {
      searchParamsFromRef.sort_field = key;
      searchParamsFromRef.sort_type = 'asc';
    }
    onFetchData(searchParamsFromRef);
  };

  const refreshData = () => {
    updateSearchParams({});
  };

  const onFetchData = async params => {
    if (fetchingApi.current) return;
    setSearchParams(params);
    searchParamsRef.current = params;
    setFetching(true);
    const body = { ...params, page_number: params.page_number + 1 };
    Object.keys(body).forEach(key => {
      if (body[key] !== false && !body[key]) delete body[key];
    });
    fetchingApi.current = true;
    const res = await ApiInstance.getEbList(body);
    fetchingApi.current = false;
    setFetching(false);
    const { error, result } = handleError(res);
    if (error) {
      toast.error(error.message);
    } else {
      const { data, total_data, total_amount, total_user } = result || {};
      setOverviewList(data || []);
      setOverviewInfo({ total_amount, total_user });
      setTotalPages(Math.ceil((total_data || 0) / (params.page_size || 1)));
    }
  };

  return {
    fetching,
    searchString,
    onChangeSearchString,

    searchParams,
    updateSearchParams,
    refreshData,
    onSort,

    overviewList,
    overviewInfo,
    totalPages,
  };
};
