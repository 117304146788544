export const isSpecialFleetType = user => {
  return user?.fleet_type === 'tribecar' || user?.fleet_type === 'treedots';
};

export const checkHasPayLater = user => {
  return !!user?.payLaterObj;
};

export const checkHasCard = user => {
  return !!user?.paymentMethods?.token?.card_obj?.last4;
};

export const checkHasPayNow = user => {
  return !!user?.paymentMethods?.other_pay_now || user?.paymentMethods?.other_paynow;
};

export const checkShowAddCardTooltip = user => {
  if (user?.role === 'admin') return false;

  const hasPayLater = checkHasPayLater(user);
  const hasCard = checkHasCard(user);
  const hasPayNow = checkHasPayNow(user);
  return !hasCard && !hasPayLater && !hasPayNow;
};

export const checkAllowAddVehicles = user => {
  if (user?.role === 'admin') return true;

  const hasPayLater = checkHasPayLater(user);
  const hasCard = checkHasCard(user);
  const hasPayNow = checkHasPayNow(user);
  return hasPayLater || hasCard || hasPayNow;
};

export const checkAllowEditVehicles = user => {
  if (user?.role === 'admin') return true;

  const hasPayLater = checkHasPayLater(user);
  const hasCard = checkHasCard(user);
  return !hasPayLater && hasCard;
};

export const unitToText = unit => {
  switch (unit) {
    case 'weeks':
      return 'Weekly';
    case 'months':
      return 'Monthly';
    case 'quarters':
      return 'Quarterly';
    case 'halfyears':
      return 'Halfyearly';
    case 'years':
      return 'Yearly';
    case 'days':
      return 'Daily';
    default:
      return '';
  }
};
