import { ApiInstance } from 'app/services/api';
import { put, takeLatest } from 'redux-saga/effects';

import UserRedux from '../User/UserRedux';

// eslint-disable-next-line require-yield
function* afterRehydration({ payload: state }) {
  // restore api header token
  if (state && state.user) {
    const { token, swiftToken } = UserRedux.getReducerState(state);

    if (token) {
      ApiInstance.setAuthToken(token);
      ApiInstance.setSwiftAuthToken(swiftToken);
      yield put(UserRedux.Creators.getUserInfo());
    }
  }
}

// @ts-ignore
export default [takeLatest('persist/REHYDRATE', afterRehydration)];
