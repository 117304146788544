import { selectors } from 'app/redux';
import { ApiInstance } from 'app/services/api';
import { handleError } from 'app/services/apiHelper';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

export const useGroupRank = () => {
  const { user } = useSelector(selectors.user);

  const [fetching, setFetching] = useState(false);
  const [data, setData] = useState<any>();

  useEffect(() => {
    if (user?.group_id) _getData();
  }, [user?.group_id]);

  const _getData = async () => {
    setFetching(true);
    const res = await ApiInstance.getGroupRankList(user?.group_id);
    setFetching(false);

    const { error, result } = handleError(res);
    if (!error) {
      setData(result?.data);
    }
  };

  const groupRankOptions = useMemo(
    () => data?.map(item => ({ value: item.employee_rank, label: item.employee_rank })) || [],
    [data],
  );

  return { groupRankOptions, fetching };
};
