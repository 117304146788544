import { ChevronLeftIcon, DuplicateIcon } from '@heroicons/react/outline';
import { ExclamationCircleIcon } from '@heroicons/react/solid';
import { BaseModal } from 'app/components';
import { selectors } from 'app/redux';
import { OverviewRedux } from 'app/redux/reducers';
import clsx from 'clsx';
import React from 'react';
import Dropzone from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

export const ModalUploadFile = ({
  isVisible,
  whiteBackground,
  canClose,
  hideModalCB,
  onReadFile,
  disabled,
  errorMessage,
  onClearErrorMessage,
}: {
  isVisible: boolean;
  whiteBackground?: boolean;
  canClose?: boolean;
  hideModalCB: () => void;
  onReadFile: (files: any) => void;
  disabled: boolean;
  errorMessage?: string;
  onClearErrorMessage: () => void;
}) => {
  const history = useHistory();
  const { user } = useSelector(selectors.user);

  const dispatch = useDispatch();

  const _handleFile = files => {
    onReadFile(files);
    dispatch(OverviewRedux.Creators.setOriginalFile(files[0]));
  };

  return (
    <BaseModal
      panelWrapperClassName="flex h-full w-full items-center justify-center p-4"
      backdropClassName={clsx(whiteBackground ? 'bg-white' : '')}
      panelClassName="relative flex flex-col gap-4 overflow-hidden w-full transform transition-all sm:my-8 sm:max-w-xl rounded-2xl sm:max-h-[90vh] sm:p-6"
      isVisible={isVisible}
      hideModalCB={canClose ? hideModalCB : undefined}
    >
      {errorMessage ? (
        <ErrorPanel message={errorMessage} onClose={onClearErrorMessage} />
      ) : (
        <>
          <div className="flex flex-col items-center gap-3">
            <div className="text-2xl">Upload Excel File</div>
            <div className="text-sm font-medium">
              Please use this{' '}
              <a className="text-main-pink underline" href={user.accessPages?.mer_dashboard?.template_url}>
                MER_Declaration_Sheet
              </a>{' '}
              to register your vehicles.{' '}
            </div>
            <div className="text-xs text-gray-400">Maximum of 100 vehicles allowed for Excel file upload.</div>
          </div>
          <div className="flex flex-col rounded-lg bg-slate-100 p-8 drop-shadow-lg">
            <Dropzone disabled={disabled} multiple={false} onDrop={_handleFile}>
              {({ getRootProps, getInputProps }) => (
                <div
                  {...getRootProps()}
                  className="relative mt-8 h-64 w-full self-center rounded-lg border border-dashed border-gray-500 bg-white"
                >
                  <input {...getInputProps()} />
                  <div className="z-10 flex h-full w-full flex-col items-center justify-center">
                    <div className="rounded-lg bg-slate-100 p-3">
                      <DuplicateIcon className="w-5" />
                    </div>
                    <p className="mt-3 text-center text-xs">Click to upload or Drag Excel File Here</p>
                  </div>
                </div>
              )}
            </Dropzone>
          </div>
          <button
            className="mt-10 flex items-center gap-1 self-center underline"
            onClick={() => history.replace('/dashboard/mer/overview')}
          >
            <ChevronLeftIcon className="w-5" />
            Back
          </button>
        </>
      )}
    </BaseModal>
  );
};

const ErrorPanel = ({ message, onClose }: { message: string; onClose: () => void }) => {
  return (
    <div className="flex flex-col items-center gap-6 rounded-lg bg-white p-10 drop-shadow-lg">
      <ExclamationCircleIcon className="w-16 text-red-600" />
      <div className="text-2xl font-bold">Upload Limit Reached!</div>
      <div className="text-center text-sm">{message}</div>
      <button className="h-12 w-full rounded-lg bg-main-green text-base text-white" onClick={onClose}>
        Upload again
      </button>
    </div>
  );
};
