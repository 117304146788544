import { ApiInstance } from 'app/services/api';
import { handleError } from 'app/services/apiHelper';
import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useRef, useState } from 'react';

export const DropDownSelectEbUser = ({ userId, fullName, stateData, onSelectUser }) => {
  const [searchResult, setSearchResult] = useState([]);
  const [isVisible, setIsVisible] = useState(false);

  const temporaryBlockSearch = useRef(false);
  const wrapperRef = useRef();

  useEffect(() => {
    _debounceSearch(userId);
  }, [userId]);

  useEffect(() => {
    _debounceSearch(fullName);
  }, [fullName]);

  useEffect(() => {
    function handleClickOutside(event) {
      // @ts-ignore
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsVisible(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const _searchEbUser = async text => {
    if (temporaryBlockSearch.current) {
      temporaryBlockSearch.current = false;
      return;
    }
    if (!text) {
      setIsVisible(false);
      setSearchResult([]);
      return;
    }
    const res = await ApiInstance.searchEbUser({
      template_name: 'eb',
      page_number: 1,
      page_size: 20,
      search_text: text,
    });
    const { result, error } = handleError(res, true);
    if (!error) {
      setSearchResult(result?.data);
      if (result?.data.length > 0) {
        setIsVisible(true);
      }
    }
  };
  const _debounceSearch = useCallback(
    debounce(text => {
      _searchEbUser(text);
    }, 500),
    [],
  );

  const _onSelectItem = item => {
    onSelectUser(item);
    setIsVisible(false);
    temporaryBlockSearch.current = true;
  };

  if (!isVisible || stateData) return null;
  return (
    // @ts-ignore
    <div ref={wrapperRef} className="absolute top-32 w-full rounded-lg bg-white">
      <div className="flex max-h-[50vh] flex-col overflow-y-auto p-1">
        {searchResult?.map((item: any) => (
          <button
            key={item.id}
            className="border-b border-b-gray-100 p-2 outline-none"
            onClick={() => _onSelectItem(item)}
          >
            <div className="text-left text-base">
              {item.user?.id} - {item.user?.first_name} {item.user?.last_name}
            </div>
          </button>
        ))}
      </div>
    </div>
  );
};
