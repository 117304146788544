import { XIcon } from '@heroicons/react/outline';
//
import { IMAGE_SIZE } from 'app/constants';
import { ImageItemWithPreview } from 'app/context';
//
import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

export function ClaimSubmitImage({
  listImages = [],
  subtitle,
  onChange,
  onDeleteImg,
  required,
  label,
  name,
  acceptTypes,
  limit = 100,
  errorText,
  limitDocs = 0,
  limitImages = 0,
  disabled,
}: {
  listImages?: any[];
  subtitle?: string;
  onChange: (name: string, value: any[]) => void;
  onDeleteImg: (name: string, idx: number) => void;
  required?: boolean;
  label?: string;
  name: string;
  acceptTypes?: string;
  limit?: number;
  errorText?: string;
  limitDocs?: number;
  limitImages?: number;
  disabled?: boolean;
}) {
  const [error, setError] = useState('');
  const numberOfFileTypeAdded = useMemo(() => getNumberOfTypeFiles(listImages), [listImages]);

  useEffect(() => {
    setError('');
  }, [listImages.length]);

  const _setError = useCallback(
    debounce(text => {
      setError(text);
      if (text) {
        setTimeout(() => {
          setError('');
        }, 3000);
      }
    }, 500),
    [],
  );

  const onUploadImage = async e => {
    const fileListArray = Array.from(e.target.files);

    const filePromises = fileListArray.map(file => {
      return new Promise(resolve => {
        const reader = new FileReader();
        // @ts-ignore
        reader.readAsArrayBuffer(file);
        reader.onload = async () => {
          // @ts-ignore
          const files = new Blob([reader.result], { type: 'application/pdf' });
          const x = await files.text();

          const isEncrypted =
            x.includes('Encrypt') || x.substring(x.lastIndexOf('<<'), x.lastIndexOf('>>')).includes('/Encrypt');

          resolve({ isEncrypted });
          if (isEncrypted) {
            _setError('You can only upload unencrypted files');
          }
        };
      });
    });

    const fileInfos = await Promise.all(filePromises);
    const fileList = fileListArray
      // @ts-ignore
      .filter((item, index) => !fileInfos[index]?.isEncrypted)
      .map(item => ({ file: item }));

    if (fileList.length + listImages.length > limit) {
      _setError(`You can only upload a maximum of ${limit} files`);
    } else {
      const numberOfFiles = getNumberOfTypeFiles(fileList);
      if (limitImages > 0 && numberOfFiles.image > limitImages - numberOfFileTypeAdded.image) {
        _setError(`You can only upload a maximum of ${limitImages} images`);
        return;
      }
      if (limitDocs > 0 && numberOfFiles.docs > limitDocs - numberOfFileTypeAdded.docs) {
        _setError(`You can only upload a maximum of ${limitDocs} documents`);
        return;
      }
      const fileUpload: any = [];
      for (let i = 0; i < fileList.length; i += 1) {
        const errorMessage = validateFile(fileList[i].file);
        if (errorMessage) {
          _setError(errorMessage);
          break;
        } else {
          fileUpload.push(fileList[i]);
        }
      }
      if (fileUpload.length === fileList.length) {
        // no errors.
        onChange(name, [...listImages, ...fileUpload]);
      }
    }
  };

  function onClick(e) {
    e.target.value = null;
  }

  const _onDeleteImg = idx => {
    onDeleteImg(name, idx);
  };

  return (
    <div className="mb-2">
      <div className="flex items-center">
        <div className="flex-1">
          <div className="text-sm text-zinc-400">
            {label}
            {required ? <span className="text-red-600">*</span> : null}
          </div>
          <div className="text-xs text-zinc-400">{subtitle}&nbsp;</div>
        </div>
        <div>
          <input
            id={name}
            name={name}
            type={'file'}
            onChange={onUploadImage}
            onClick={onClick}
            className="hidden"
            accept={acceptTypes}
            multiple
            disabled={disabled}
          />
          <label className="flex items-center" htmlFor={name}>
            <img
              src={
                listImages?.length ? '/img/smallIcon/cloudDownloadGreen.svg' : '/img/smallIcon/cloudDownloadGray.svg'
              }
              alt="uploadImage"
            />
            <div className="ml-1 text-xs text-neutral-400">UPLOAD</div>
          </label>
        </div>
      </div>
      {listImages?.map((file, idx) => (
        <FileTag key={idx} idx={idx} data={file} onDeleteImg={_onDeleteImg} listImages={listImages} />
      ))}
      <div className="error-text text-right">{error || ''}</div>
      <div className="error-text text-right">{errorText || ''}</div>
    </div>
  );
}

const FileTag = ({ data, idx, onDeleteImg, listImages }) => {
  const listImageFiles = useMemo(() => listImages.map(item => item.file), [listImages]);
  return (
    <div className="flex items-center justify-end">
      <ImageItemWithPreview fileList={listImageFiles} fileName={data?.file?.name} error={data?.error} />
      <button onClick={() => onDeleteImg(idx)} className="outline-none">
        <XIcon className="w-6 text-red-500" />
      </button>
    </div>
  );
};

const LIST_SUPPORT_DOCS_MIME_TYPE = [
  'application/msword', // .doc
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
  'application/pdf', // .pdf
  'text/plain', // .txt
];
const LIST_SUPPORT_IMAGE_MIME_TYPE = ['image/png', 'image/jpeg', 'image/jpg'];

/**
 *
 * @param file {File}
 * @param size {number} size of image // in MB
 * @returns {string}
 */
function validateFile(file, size = IMAGE_SIZE * 1024 * 1024) {
  if (file.size > size) {
    return `You can only upload a maximium of ${IMAGE_SIZE}MB for each file`;
  }
  if (!LIST_SUPPORT_DOCS_MIME_TYPE.includes(file.type) && !LIST_SUPPORT_IMAGE_MIME_TYPE.includes(file.type)) {
    return `Sorry, we dont support this type of file.`;
  }
  return '';
}

/**
 * get number of docs and images in filelist
 * @param fileList {array|FileList}
 * @returns {{image: number, docs: number}}
 */
function getNumberOfTypeFiles(fileList) {
  let numberOfDocs = 0;
  let numberOfImages = 0;
  for (let i = 0; i < fileList.length; i += 1) {
    if (LIST_SUPPORT_DOCS_MIME_TYPE.includes(fileList[i].file.type)) {
      numberOfDocs += 1;
    } else if (LIST_SUPPORT_IMAGE_MIME_TYPE.includes(fileList[i].file.type)) {
      numberOfImages += 1;
    }
  }

  return {
    docs: numberOfDocs,
    image: numberOfImages,
  };
}
