import { PHONE_NUMBER_PREFIX, PRODUCT_REGIONS } from 'app/constants';
import dayjs from 'dayjs';

export const GST = 1.07;

export const REGREX = {
  NUMBER_ONLY: /^[0-9]+$/,
  KTP_NUMBER: /^\d{16}$/, // exactly 16 digits
  PHONE_NUMEBR_SG: /^[3689]\d{7}$/,
  PHONE_NUMBER_ID: /^[8]\d{8,12}$/,
  PHONE_NUMBER_PH: /^9\d{9}$/,
  POSTAL_CODE_SG: /^\d{6}$/,
  POSTAL_CODE_ID: /^\d{5}$/,
  POSTAL_CODE_PH: /^\d{4}$/,
  DO_NOT_CONTAINS_SYMBOLS: /^[a-zA-Z ]+$/,
  PASSWORD: /^(?=.*[a-z])(?=.*[A-Z]).{8,}$/, // atleast 8 characters, contains lower case, upper case.
  ONLY_SPACE: /(\s)$/,
  //
  SSS_FORMAT: /(\d{1,2})?(\d{1,7})?(\d{1,1})?/, // 10-digit number
  SSS_FORMAT_TYPE_2: /(\d{1,4})?(\d{1,7})?(\d{1,1})?/, // 10-digit number
  TIN_FORMAT: /(\d{1,3})?(\d{1,3})?(\d{1,3})?/, // 9-digit number
  NATIONAL_ID: /([a-zA-Z0-9]{1,4})?([a-zA-Z0-9]{1,7})?([a-zA-Z0-9]{1,3})?/, // 9-digit number
  DRIVER_LICENSE: /([a-zA-Z0-9]{1,3})?([a-zA-Z0-9]{1,2})?([a-zA-Z0-9]{1,6})?/, // 9-digit number
  ID_NUMBER_PH: /^[a-zA-Z0-9]{1,}$/,
  VEHICLE_REG: /^[A-Za-z]{3}[\d]{4}[A-Za-z]{1}$/,
};

export const validateEmail = email => {
  const regex = /^([A-Za-z0-9_\-+:.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;
  return regex.test(email);
};

function isPrefixMatchCountry(prefix, country) {
  return (
    (country === PRODUCT_REGIONS.SG && prefix === PHONE_NUMBER_PREFIX.SG) ||
    (country === PRODUCT_REGIONS.PH && prefix === PHONE_NUMBER_PREFIX.PH) ||
    (country === PRODUCT_REGIONS.ID && prefix === PHONE_NUMBER_PREFIX.ID)
  );
}

export const validatePhone = (phoneNumber, country = PRODUCT_REGIONS.SG, prefix = '') => {
  let regrexFormat;
  if (prefix && !isPrefixMatchCountry(prefix, country)) {
    return false;
  }
  if (country === PRODUCT_REGIONS.SG) {
    regrexFormat = REGREX.PHONE_NUMEBR_SG;
  } else if (country === PRODUCT_REGIONS.PH) {
    regrexFormat = REGREX.PHONE_NUMBER_PH;
  } else {
    regrexFormat = REGREX.PHONE_NUMBER_ID;
  }
  return regrexFormat.test(phoneNumber.replace(prefix, ''));
};

export const validateVehicleReg = vehicleReg => REGREX.VEHICLE_REG.test(vehicleReg);

export const validateKTP = ktp => REGREX.KTP_NUMBER.test(ktp);

export const validateStringNotContainSymbol = text => REGREX.DO_NOT_CONTAINS_SYMBOLS.test(text);

export const validatePasswordFormat = password => REGREX.PASSWORD.test(password);

/**
 * Function that check any properties of object errors exist.
 * @param {object} errors
 * errors's keys MUST be string.
 * @return {boolean} true if any fields in object errors existed.
 */
export const isObjectExistKeys = (errors = {}) => Object.keys(errors).some(key => errors[key]);

export const validateNricFin = str => {
  if (str.length !== 9) return false;

  const checkStr = str.toUpperCase();

  const icArray: any[] = [];
  for (let i = 0; i < 9; i += 1) {
    icArray[i] = checkStr.charAt(i);
  }

  icArray[1] = parseInt(icArray[1], 10) * 2;
  icArray[2] = parseInt(icArray[2], 10) * 7;
  icArray[3] = parseInt(icArray[3], 10) * 6;
  icArray[4] = parseInt(icArray[4], 10) * 5;
  icArray[5] = parseInt(icArray[5], 10) * 4;
  icArray[6] = parseInt(icArray[6], 10) * 3;
  icArray[7] = parseInt(icArray[7], 10) * 2;

  let weight = 0;
  for (let i = 1; i < 8; i += 1) {
    weight += icArray[i];
  }

  const offset = icArray[0] === 'T' || icArray[0] === 'G' ? 4 : 0;
  const temp = (offset + weight) % 11;

  const st = ['J', 'Z', 'I', 'H', 'G', 'F', 'E', 'D', 'C', 'B', 'A'];
  const fg = ['X', 'W', 'U', 'T', 'R', 'Q', 'P', 'N', 'M', 'L', 'K'];

  let theAlpha;
  if (icArray[0] === 'S' || icArray[0] === 'T') {
    theAlpha = st[temp];
  } else if (icArray[0] === 'F' || icArray[0] === 'G') {
    theAlpha = fg[temp];
  }

  return icArray[8] === theAlpha;
};

export const capitalizeFirstLetter = string => {
  if (string) {
    return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
  }
  return '';
};
export const validateDate = rawDate => dayjs(rawDate).isValid();

export function numberWithCommas(value, thousandComma, floatSymbol) {
  // eslint-disable-next-line no-restricted-globals
  if (value !== null && value !== undefined && !isNaN(value)) {
    const [currency, cents] = value.toString().split('.');
    if (cents === undefined) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, thousandComma || ',');
    }
    return `${currency.toString().replace(/\B(?=(\d{3})+(?!\d))/g, thousandComma || ',')}${floatSymbol || '.'}${
      cents || ''
    }`;
  }
  return '0';
}
