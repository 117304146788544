import { PlusIcon } from '@heroicons/react/outline';
import { CompanyInfo, SimpleModal } from 'app/components';
import {
  ModalChangeAutoRenewal,
  ModalChangePolicyStart,
  ModalChangePolicyStatus,
} from 'app/components/pages/overview/modal';
import { OVERVIEW_LIST_FIELDS, SHOWING_OVERVIEW_STATUS } from 'app/constants';
import { useOverviewList, useVehicleUpdate } from 'app/hooks';
import { useAddOneFormStore } from 'app/modules/add-one-vehicle';
import { selectors } from 'app/redux';
import { checkAllowAddVehicles, checkShowAddCardTooltip } from 'app/utilities/data-helper';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import { OverviewSearchBar, OverviewTable } from '../../components/pages/overview';

const OverviewPage = ({ history }) => {
  const { user } = useSelector(selectors.user);
  const { role } = user || {};

  const allowAddVehicle = checkAllowAddVehicles(user);
  const showAddCardTooltip = checkShowAddCardTooltip(user);

  const {
    totalPages,
    updateSearchParams,
    searchParams,
    vehicleReg,
    onChangeVehicleReg,
    overviewList,
    fetching,
    refreshData,
    totalData,
  } = useOverviewList();

  const {
    updateRenewal,
    updatePolicyStart,
    updatePolicyStatus,
    fetching: fetchingUpdate,
  } = useVehicleUpdate(refreshData);

  const [updateRenewalModalData, setUpdateRenewalModalData] = useState<any>();
  const [updatePolicyStartlModalData, setUpdatePolicyStartlModalData] = useState<any>();
  const [updatePolicyStatusData, setUpdatePolicyStatusData] = useState<any>();
  const [showModalSelectMode, setShowModalSelectMode] = useState(false);

  const _onUpdateRenewal = () => {
    if (!updateRenewalModalData) return;
    if (updateRenewalModalData?.value && updateRenewalModalData?.showing_status === SHOWING_OVERVIEW_STATUS.EXPIRED) {
      useAddOneFormStore.getState().resetForm();
      history.push('/dashboard/mer/add-vehicle', {
        currentPreFillObject: updateRenewalModalData,
      });
      return;
    }
    updateRenewal(updateRenewalModalData, updateRenewalModalData?.value);
    setUpdateRenewalModalData(undefined);
  };

  const _onUpdatePolicyStart = () => {
    if (!updatePolicyStartlModalData) return;
    updatePolicyStart(updatePolicyStartlModalData.vehicleDetail, updatePolicyStartlModalData.value);
    setUpdatePolicyStartlModalData(undefined);
  };

  const _onUpdatePolicyStatus = refNum => {
    if (!updatePolicyStatusData) return;
    updatePolicyStatus(updatePolicyStatusData, refNum);
    setUpdatePolicyStatusData(undefined);
  };

  const _onChange = action => {
    const { value, rowInfo, column } = action || {};
    switch (column?.id) {
      case OVERVIEW_LIST_FIELDS.IS_AUTO_RENEWAL:
        setUpdateRenewalModalData({ ...rowInfo?.original, value });
        break;
      case OVERVIEW_LIST_FIELDS.UPCOMING_DATE:
        setUpdatePolicyStartlModalData({ vehicleDetail: rowInfo?.original, value: dayjs(value).format('YYYY-MM-DD') });
        break;
      default:
        break;
    }
  };

  const onGetTdProps = (state, rowInfo, column) => {
    return {
      onChange: value => _onChange({ value, rowInfo, column }),
      onClick: () => {
        const { id } = column;
        if (
          !(
            id === OVERVIEW_LIST_FIELDS.IS_AUTO_RENEWAL ||
            id === OVERVIEW_LIST_FIELDS.UPCOMING_STATUS ||
            id === OVERVIEW_LIST_FIELDS.UPCOMING_DATE
          )
        ) {
          const { vehicle_reg } = rowInfo.original;
          history.push({
            pathname: '/dashboard/mer/vehicles',
            state: {
              vehicle_reg,
              rowInfo: rowInfo.original,
            },
          });
        }
      },
    };
  };

  const _onSelectMode = mode => {
    setShowModalSelectMode(false);
    if (mode === 'one') {
      useAddOneFormStore.getState().resetForm();
      history.push('/dashboard/mer/add-vehicle');
    } else {
      history.push('/dashboard/mer/add-vehicles');
    }
  };

  return (
    <div className="flex h-screen flex-col p-3">
      <CompanyInfo numOfVehicles={totalData} />
      <div className="my-5 text-3xl text-main-green">Overview</div>
      <div className="mb-4 mt-2 flex justify-between">
        <OverviewSearchBar
          vehicleReg={vehicleReg}
          onChangeVehicleReg={onChangeVehicleReg}
          searchParams={searchParams}
          updateSearchParams={updateSearchParams}
        />
        <button
          className="flex items-center rounded-lg bg-main-darkBlue p-3 text-base text-white disabled:cursor-not-allowed"
          disabled={!allowAddVehicle}
          onClick={() => setShowModalSelectMode(true)}
          data-tooltip-id="button"
        >
          <PlusIcon className="w-5" />
          Add Vehicle/s
        </button>
      </div>
      <OverviewTable
        onGetTdProps={onGetTdProps}
        currentPage={searchParams.page_number}
        listVehicles={overviewList}
        fetching={fetching || fetchingUpdate}
        totalPages={totalPages}
        onFetchData={({ page, pageSize }) => updateSearchParams({ page_number: page, page_size: pageSize })}
        role={role}
        user={user}
        onClickEdit={data => setUpdatePolicyStatusData(data)}
      />

      {showAddCardTooltip ? (
        <ReactTooltip id="button">
          <span>Please add payment card to perform this action</span>
        </ReactTooltip>
      ) : null}
      <ModalChangeAutoRenewal
        data={updateRenewalModalData}
        hideModalCB={() => setUpdateRenewalModalData(undefined)}
        confirmCB={_onUpdateRenewal}
      />
      <ModalChangePolicyStart
        data={updatePolicyStartlModalData}
        hideModalCB={() => setUpdatePolicyStartlModalData(undefined)}
        confirmCB={_onUpdatePolicyStart}
      />
      <ModalChangePolicyStatus
        data={updatePolicyStatusData}
        hideModalCB={() => setUpdatePolicyStatusData(undefined)}
        confirmCB={_onUpdatePolicyStatus}
      />
      <ModalSelectMode
        isVisible={showModalSelectMode}
        hideModalCB={() => setShowModalSelectMode(false)}
        onSelectMode={_onSelectMode}
      />
    </div>
  );
};
export default OverviewPage;

const ModalSelectMode = ({
  isVisible,
  hideModalCB,
  onSelectMode,
}: {
  isVisible: boolean;
  hideModalCB: () => void;
  onSelectMode: (mode: string) => void;
}) => {
  return (
    <SimpleModal isVisible={isVisible} hideModalCB={hideModalCB}>
      <div className="flex flex-col gap-12 px-4">
        <div className="flex gap-6">
          <SelectButton
            imgSrc="/img/frame/one-vehicle-onboarding.svg"
            text="One Vehicle Onboarding"
            onClick={() => onSelectMode('one')}
          />
          <SelectButton
            imgSrc="/img/frame/bulk-onboarding.svg"
            text="Bulk Onboarding"
            onClick={() => onSelectMode('bulk')}
          />
        </div>
        <div className="flex justify-center gap-4">
          <button className="w-44 p-2 underline" onClick={hideModalCB}>
            Back
          </button>
        </div>
      </div>
    </SimpleModal>
  );
};

const SelectButton = ({ imgSrc, text, onClick }: { imgSrc: string; text: string; onClick: () => void }) => {
  return (
    <button
      className="flex flex-1 flex-col items-center gap-3 rounded-xl bg-white p-5 drop-shadow-md"
      onClick={onClick}
    >
      <img src={imgSrc} className="aspect-square w-full" />
      {text}
    </button>
  );
};
