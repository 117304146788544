import { Icon, StatusText } from 'app/components';
import { APP_CONFIG, DATE_FORMAT, HMO_STATUS, PRODUCT_REGIONS } from 'app/constants';
import { getCurrencyString } from 'app/utilities';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import ReactTable from 'react-table';

export const EbOverviewTable = ({
  data,
  fetching,
  totalPages,
  searchParams,
  onRemove,
  onFetchData,
  onSort,
}: {
  data: any;
  fetching?: boolean;
  totalPages: number;
  searchParams: any;
  onRemove: (data: any) => void;
  onFetchData: ({ page, pageSize }: { page: number; pageSize: number }) => void;
  onSort: (key: string) => void;
}) => {
  const history = useHistory();

  const currentPage = searchParams?.page_number || 0;

  const onGetTdProps = (state, rowInfo, column) => {
    return {
      onSubmit: code => {
        switch (code) {
          case 'view':
            history.push('/dashboard/hmo/employee-detail', { data: rowInfo.original });
            break;
          case 'submit':
            history.push('/dashboard/hmo/submit-claim', { data: rowInfo.original });
            break;
          case 'remove':
            onRemove(rowInfo.original);
            break;
          default:
            break;
        }
      },
    };
  };

  return (
    <div>
      <ReactTable
        manual
        getTdProps={onGetTdProps}
        columns={[
          {
            Header: (
              <HeaderWithSort
                sortField="user_id"
                onClick={onSort}
                title="User ID"
                sortType={searchParams?.sort_field === 'user_id' ? searchParams?.sort_type : ''}
              />
            ),
            sortable: false,
            filterable: false,
            accessor: 'user_obj',
            width: 100,
            Cell: cellInfo => <span>{cellInfo.value?.id}</span>,
          },
          {
            Header: 'Name',
            sortable: false,
            filterable: false,
            accessor: 'user_obj',
            Cell: cellInfo => <span>{cellInfo.value?.full_name}</span>,
          },
          {
            Header: 'Employee Rank',
            sortable: false,
            filterable: false,
            accessor: 'employee_rank',
            width: 230,
            Cell: cellInfo => <span>{cellInfo.value}</span>,
          },
          {
            Header: (
              <HeaderWithSort
                sortField="subtotal"
                onClick={onSort}
                title="Premium"
                sortType={searchParams?.sort_field === 'subtotal' ? searchParams?.sort_type : ''}
              />
            ),
            sortable: false,
            filterable: false,
            accessor: 'subtotal',
            width: 110,
            Cell: cellInfo => <span>{getCurrencyString(cellInfo.value, PRODUCT_REGIONS.PH, 2)}</span>,
          },
          {
            Header: (
              <HeaderWithSort
                sortField="start_date"
                onClick={onSort}
                title="Effective Date"
                sortType={searchParams?.sort_field === 'start_date' ? searchParams?.sort_type : ''}
              />
            ),
            sortable: false,
            filterable: false,
            accessor: 'start_date',
            width: 150,
            Cell: cellInfo => <span>{cellInfo.value ? dayjs(cellInfo.value).format(DATE_FORMAT.date) : '-'}</span>,
          },
          {
            Header: 'Activation Status',
            sortable: false,
            filterable: false,
            accessor: 'status',
            width: 150,
            Cell: cellInfo => <HmoStatusCell status={cellInfo.value} original={cellInfo.original} />,
          },
          {
            sortable: false,
            filterable: false,
            Cell: cellInfo => <ActionButtonRow cellInfo={cellInfo} />,
          },
        ]}
        data={data}
        defaultPageSize={APP_CONFIG.overview.DEFAULT_PAGE_SIZE}
        loading={fetching}
        nextText={`Next >`}
        previousText={`< Previous`}
        page={currentPage}
        pages={totalPages}
        resizable={false}
        onFetchData={onFetchData}
        noDataText="Not Found"
        style={{ border: 'none' }}
        // eslint-disable-next-line tailwindcss/no-custom-classname
        className="app-table"
      />
    </div>
  );
};

const HeaderWithSort = ({
  title,
  sortField,
  sortType,
  onClick,
}: {
  title: string;
  sortField: string;
  sortType: string;
  onClick: (key: string) => void;
}) => {
  return (
    <button className="flex items-center" onClick={() => onClick(sortField)}>
      <div className="text-white">{title}</div>
      <div className="ml-1 flex flex-col gap-1">
        {sortType === 'asc' ? (
          <img src="/img/icon/up-white.svg" className="w-3" />
        ) : (
          <img src="/img/icon/up-white-50.svg" className="w-3" />
        )}
        {sortType === 'desc' ? (
          <img src="/img/icon/down-white.svg" className="w-3" />
        ) : (
          <img src="/img/icon/down-white-50.svg" className="w-3" />
        )}
      </div>
    </button>
  );
};

export const HmoStatusCell = ({ status, original }) => {
  const { statusText, color } = useMemo(() => {
    switch (status) {
      case HMO_STATUS.IN_FORCE:
        if (original?.member_id) return { statusText: 'Active', color: '#27AE60' };
        return { statusText: 'Pending', color: '#FFB700' };
      case HMO_STATUS.WAITING:
        return { statusText: 'Processing', color: '#27AE60' };
      case HMO_STATUS.EXPIRED:
        return { statusText: 'Inactive', color: '#EB5757' };
      default:
        return { statusText: '', color: '#EB5757' };
    }
  }, [status]);
  return <StatusText status={statusText} color={color} />;
};

const ActionButtonRow = ({ cellInfo }) => {
  const { original, tdProps } = cellInfo || {};
  const { onSubmit } = tdProps.rest || {};
  const { status } = original || {};
  return (
    <div className="flex gap-1">
      <button onClick={() => onSubmit('view')}>
        <Icon name="view" />
      </button>
      <button
        disabled={status === HMO_STATUS.WAITING}
        className="disabled:cursor-not-allowed disabled:opacity-30"
        onClick={() => onSubmit('submit')}
      >
        <Icon name="submit" />
      </button>
      <button
        disabled={status === HMO_STATUS.EXPIRED}
        className="disabled:cursor-not-allowed disabled:opacity-30"
        onClick={() => onSubmit('remove')}
      >
        <Icon name="remove" />
      </button>
    </div>
  );
};
