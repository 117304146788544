// take from reducers & put to Error reducer
import { put, takeEvery } from 'redux-saga/effects';

import { UserRedux } from '../reducers';
import ErrorRedux from './ErrorRedux';

const requestFailure = function* ({ error }) {
  if (!error) {
    return;
  }
  if (typeof error === 'string') {
    yield put(ErrorRedux.Creators.errorsQueueAppend(new Error(error)));
  } else if (typeof error.message === 'string') {
    yield put(ErrorRedux.Creators.errorsQueueAppend(error));
  }
};

export default [takeEvery(UserRedux.Types.REQUEST_FAILURE, requestFailure)];
