import { PencilAltIcon } from '@heroicons/react/outline';
import { BreadCrumbs, InfoRow, SimpleSwitch, StatusText, TabContainer } from 'app/components';
import { OverviewStatusCell } from 'app/components/pages/overview';
import { ModalChangeVehicleRenewal, ModalUpdateExcess } from 'app/components/pages/vehicles';
import { APP_CONFIG, ORDER_DETAIL_STATUS, ROLE, VEHICLE_ORDER_DETAILS_FIELDS } from 'app/constants';
import { useVehicleDetail, useVehicleUpdate } from 'app/hooks';
import { useAddOneFormStore } from 'app/modules/add-one-vehicle';
import { selectors } from 'app/redux';
import { extractPricingList, PRODUCT_MER } from 'app/redux/Data/DataSagas';
import { ApiInstance } from 'app/services/api';
import { handleError } from 'app/services/apiHelper';
import { checkAllowAddVehicles, checkShowAddCardTooltip } from 'app/utilities/data-helper';
import { capitalizeFirstLetter } from 'app/utils';
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { BeatLoader } from 'react-spinners';
import ReactTable from 'react-table';
import { Tooltip as ReactTooltip } from 'react-tooltip';

const VehiclePage = ({ history }) => {
  const { listCountries, listTemplates, listUnits } = useSelector(selectors.data);
  const { user } = useSelector(selectors.user);

  const [showModalUpdateExcess, setShowModalUpdateExcess] = useState(false);
  const [showModalUpdateRenewal, setShowModalUpdateRenewal] = useState(false);
  const [fetchingProductListing, setFetchingProductListing] = useState(false);
  const [pricingList, setPricingList] = useState<any>(undefined);

  const { vehicle_reg } = history.location.state || {};

  const { fetching, updateSearchParams, getVehicleDetail, policies, totalPages, vehicleDetail } = useVehicleDetail(
    vehicle_reg || '',
  );

  const showAddCardTooltip = checkShowAddCardTooltip(user);
  const allowAddVehicles = checkAllowAddVehicles(user);

  const { updateRenewal, fetching: fetchingUpdate } = useVehicleUpdate(getVehicleDetail);

  const isDailyUnit = vehicleDetail?.unit_obj?.name === 'days';
  const isExpired = vehicleDetail?.status === 'expired';

  useEffect(() => {
    if (vehicleDetail?.group_id && user?.role === ROLE.ADMIN) getProductListing();
  }, [vehicleDetail?.group_id]);

  const getProductListing = async () => {
    const body = {
      country_id: listCountries?.[PRODUCT_MER.COUNTRY]?.id,
      template_id: listTemplates?.[PRODUCT_MER.TEMPLATE]?.id,
      unit_id: listUnits?.[PRODUCT_MER.UNIT]?.id,
      group_id: vehicleDetail?.group_id,
      underwriting: {
        vehicle_type: vehicleDetail?.vehicle_type,
      },
    };

    setFetchingProductListing(true);
    const res = await ApiInstance.getProductListing(body);
    setFetchingProductListing(false);

    const { result, error } = handleError(res, true);
    if (!error) {
      setPricingList(extractPricingList(result?.data));
    }
  };

  const _onUpdateRenewal = () => {
    updateRenewal(vehicleDetail, !vehicleDetail?.is_auto_renewal);
    setShowModalUpdateRenewal(false);
  };

  const _onChangeAutoRenewal = () => {
    setShowModalUpdateRenewal(true);
  };

  const _onRenew = () => {
    useAddOneFormStore.getState().resetForm();
    history.push('/dashboard/mer/add-vehicle', {
      currentPreFillObject: vehicleDetail,
      disableEdit: true,
      fromVehiclePage: true,
    });
  };

  return (
    <div className="flex flex-col p-10">
      <BreadCrumbs crumbs={CRUMBS} />
      <div className="my-5 text-3xl text-main-green">{vehicleDetail?.vehicle_reg?.toUpperCase()}</div>

      <div className="mb-10 flex gap-2">
        <DataTag text={vehicleDetail?.model} />
        <DataTag text={vehicleDetail?.make} color="#DE1B77" />
        <DataTag text={vehicleDetail?.vehicle_type} color="#DE1B77" iconName={vehicleDetail?.configs?.vehicle_type} />
      </div>
      <div className="flex">
        <div className="flex-1">
          <InfoRow label="Contact Email" value={vehicleDetail?.contact_email} />
          <InfoRow label="Contact Number" value={vehicleDetail?.contact_num} />
        </div>
        <div className="flex-1">
          <InfoRow label="Company UEN" value={vehicleDetail?.company_uen} />
          <InfoRow label="Company Name" value={vehicleDetail?.company_name} />
        </div>
      </div>

      <TabContainer
        tabOptions={[
          {
            label: 'Vehicle Plan',
            value: 'vehiclePlan',
            content: (
              <div className="flex p-5">
                <div className="flex-1">
                  <InfoRow label="Section 1 Excess" value={vehicleDetail?.configs?.section1excess || 0} />
                  <InfoRow label="Section 2 Excess" value={vehicleDetail?.configs?.section2excess || 0} />
                  <InfoRow label="Reduced Excess To" value={vehicleDetail?.configs?.reduced_combined_excess || 0} />
                  <InfoRow label="Combined Excess" value={vehicleDetail?.configs?.combined_excess || 0} />
                  <InfoRow
                    label="Contract Start Date"
                    value={
                      vehicleDetail?.start_date ? dayjs(vehicleDetail?.start_date).format(APP_CONFIG.format.date) : '-'
                    }
                  />
                  <InfoRow label="Vehicle Chassis No. " value={vehicleDetail?.chassis} />
                </div>
                <div className="flex-1">
                  {isExpired ? (
                    <>
                      <InfoRow
                        label="Policy End"
                        value={
                          vehicleDetail?.end_date ? dayjs(vehicleDetail?.end_date).format(APP_CONFIG.format.date) : '-'
                        }
                      />
                      <InfoRow label="Status">
                        <div className="flex items-center">
                          <StatusText status="Expired" color="#EB5757" />
                          <button className="rounded-md bg-red-500 px-3 py-1 text-white" onClick={_onRenew}>
                            RENEW
                          </button>
                        </div>
                      </InfoRow>
                    </>
                  ) : (
                    <>
                      <InfoRow
                        label="Upcoming Policy"
                        value={
                          vehicleDetail?.upcoming_date
                            ? dayjs(vehicleDetail?.upcoming_date).format(APP_CONFIG.format.date)
                            : '-'
                        }
                      />
                      <InfoRow label="Upcoming Status">
                        <OverviewStatusCell status={vehicleDetail?.upcoming_status} />
                      </InfoRow>
                    </>
                  )}
                  <InfoRow label="Auto-Renewal">
                    {!isDailyUnit && !isExpired ? (
                      <div data-tooltip-id="button" className="w-max">
                        <SimpleSwitch
                          checked={vehicleDetail?.is_auto_renewal}
                          disabled={!allowAddVehicles || vehicleDetail?.unit_obj?.name === 'days'}
                          onChange={_onChangeAutoRenewal}
                        />
                      </div>
                    ) : isDailyUnit ? (
                      'OFF'
                    ) : (
                      <SimpleSwitch checked={false} onChange={_onRenew} />
                    )}
                  </InfoRow>
                </div>
                {!isDailyUnit && !isExpired ? (
                  <div data-tooltip-id="button">
                    <button
                      disabled={!allowAddVehicles}
                      className="flex items-center self-start rounded-lg bg-main-green p-2 text-base text-white disabled:cursor-not-allowed disabled:bg-gray-300"
                      onClick={() => setShowModalUpdateExcess(true)}
                    >
                      <PencilAltIcon className="w-5 text-white" /> Edit
                    </button>
                  </div>
                ) : null}
              </div>
            ),
          },
          {
            label: 'History',
            value: 'history',
            content: (
              <ReactTable
                manual
                data={policies || []}
                columns={[
                  {
                    Header: 'Policy Number',
                    accessor: VEHICLE_ORDER_DETAILS_FIELDS.CODE,
                    sortable: false,
                    filterable: false,
                  },
                  {
                    Header: 'Policy Start',
                    accessor: VEHICLE_ORDER_DETAILS_FIELDS.START_DATE,
                    sortable: false,
                    filterable: false,
                  },
                  {
                    Header: 'Policy End',
                    accessor: VEHICLE_ORDER_DETAILS_FIELDS.END_DATE,
                    sortable: false,
                    filterable: false,
                  },
                  {
                    Header: 'Status',
                    accessor: VEHICLE_ORDER_DETAILS_FIELDS.STATUS,
                    Cell: cellInfo => {
                      return (
                        <OrderStatusCell
                          status={cellInfo.value}
                          data={cellInfo.original}
                          showingStatus={vehicleDetail?.showing_status}
                        />
                      );
                    },
                    sortable: false,
                    filterable: false,
                  },
                ]}
                defaultPageSize={5}
                previousText="< Previous"
                nextText="Next >"
                onFetchData={({ page, pageSize }) => {
                  updateSearchParams({ page_number: page, page_size: pageSize });
                }}
                loading={fetching}
                pages={totalPages}
                // eslint-disable-next-line tailwindcss/no-custom-classname
                className="app-table-no-border"
              />
            ),
          },
        ]}
      />
      {fetching || fetchingUpdate || fetchingProductListing ? (
        <div className="fixed top-0 left-0 z-50 flex h-screen w-screen items-center justify-center bg-white/90">
          <BeatLoader color="#ddd" speedMultiplier={0.5} size={15} />
        </div>
      ) : null}

      {showAddCardTooltip ? (
        <ReactTooltip id="button" variant="dark">
          <span>Please add payment card to perform this action</span>
        </ReactTooltip>
      ) : null}
      <ModalUpdateExcess
        pricingList={pricingList}
        vehicleDetail={vehicleDetail}
        isVisible={showModalUpdateExcess}
        hideModalCB={() => setShowModalUpdateExcess(false)}
        refreshDataCB={getVehicleDetail}
      />
      <ModalChangeVehicleRenewal
        isVisible={showModalUpdateRenewal}
        hideModalCB={() => setShowModalUpdateRenewal(false)}
        currentValue={vehicleDetail?.is_auto_renewal}
        confirmCB={_onUpdateRenewal}
      />
    </div>
  );
};

export default VehiclePage;

const DataTag = ({ text, color, iconName }: { text: string; color?: string; iconName?: string }) => {
  return (
    <div className="flex items-center rounded-full bg-indigo-50 px-3 py-1">
      {iconName ? (
        <img src={`/img/dataTag/${iconName}.svg`} className="mr-2 w-5" />
      ) : (
        <div className="mr-2 h-2 w-2 rounded-full bg-main-green" style={{ backgroundColor: color }} />
      )}
      {capitalizeFirstLetter(text)}
    </div>
  );
};

const CRUMBS = [
  {
    to: '/dashboard',
    label: 'Overview',
  },
  {
    label: 'Vehicle Details',
  },
];
const OrderStatusCell = ({ status, data, showingStatus }) => {
  const { payment_ref } = data || {};
  const { color, text } = useMemo(() => {
    switch (status) {
      case ORDER_DETAIL_STATUS.PREPARED:
        return { text: 'Prepared', color: '#FFB700' };
      case ORDER_DETAIL_STATUS.WAITING:
        return { text: 'Paid', color: '#27AE60' };
      case ORDER_DETAIL_STATUS.DRAFT:
        return { text: 'Draft', color: '#aaa' };
      case ORDER_DETAIL_STATUS.IN_FORCE:
        return { text: showingStatus, color: '#27AE60' };
      case ORDER_DETAIL_STATUS.PAID:
        return { text: 'Paid', color: '#27AE60' };
      case ORDER_DETAIL_STATUS.EXPIRED:
        return { text: 'Expired', color: '#EB5757' };
      case ORDER_DETAIL_STATUS.PENDING:
        if (payment_ref) return { text: 'Pending', color: '#DE1B77' };
        return { text: 'Prepared', color: '#DE1B77' };
      case ORDER_DETAIL_STATUS.CANCELLED:
        return { text: 'Canceled', color: '#aaa' };
      default:
        return { color: '' };
    }
  }, [status]);

  if (status === ORDER_DETAIL_STATUS.IN_FORCE) return <OverviewStatusCell status={showingStatus} />;
  return <StatusText status={text} color={color} />;
};
