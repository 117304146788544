import { Dialog, Transition } from '@headlessui/react';
import clsx from 'clsx';
import type { FC, ReactNode } from 'react';
import React, { Fragment } from 'react';
import { SyncLoader } from 'react-spinners';

interface BaseModalProps {
  isVisible: boolean;
  panelWrapperClassName?: string;
  panelClassName?: string;
  backdropClassName?: string;
  hideModalCB?: () => void;
  children: React.ReactNode;
}
export const BaseModal: FC<BaseModalProps> = ({
  isVisible,
  panelWrapperClassName,
  panelClassName,
  backdropClassName,
  hideModalCB = () => {},
  children,
}) => {
  return (
    <Transition.Root show={!!isVisible} as={Fragment}>
      <Dialog as="div" className="relative z-30" onClose={hideModalCB}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-75"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className={clsx('fixed inset-0 transition-opacity', backdropClassName || 'bg-gray-500/75')} />
        </Transition.Child>

        <div className="fixed inset-0 z-30 overflow-y-auto">
          <div className={panelWrapperClassName}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className={panelClassName}>{children}</Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export function SimpleFullScreenModal({ children, showCloseIcon, hideModalCB, ...rest }) {
  return (
    // @ts-ignore
    <BaseModal
      backdropClassName="bg-transparent"
      panelWrapperClassName="flex flex-col min-h-full bg-white/90 items-center w-full justify-center"
      panelClassName="relative w-full flex-1 transition-all items-center flex flex-col"
      {...rest}
    >
      {showCloseIcon ? (
        <button className="absolute top-0 right-0 p-3 outline-none" onClick={hideModalCB}>
          <img src="/img/smallIcon/close.svg" alt="close" />
        </button>
      ) : null}
      <div className="flex w-full flex-1 flex-col justify-center">{children}</div>
    </BaseModal>
  );
}
interface SimpleModalProps extends BaseModalProps {
  children: React.ReactNode;
  hideModalCB: () => void;
  showCloseIcon?: boolean;
  title?: string;
  subtitle?: string;
  leftBtText?: string;
  leftCB?: () => void;
  rightBtText?: string;
  rightCB?: () => void;
  fetchingRight?: boolean;
  disableRightBt?: boolean;
}
export const SimpleModal: FC<SimpleModalProps> = ({
  children,
  hideModalCB,
  showCloseIcon,
  title,
  subtitle,
  leftBtText,
  leftCB,
  rightBtText,
  rightCB,
  fetchingRight,
  disableRightBt,
  ...rest
}) => {
  return (
    <BaseModal
      panelWrapperClassName="flex items-center w-full h-full justify-center p-4"
      panelClassName="relative flex flex-col bg-white p-4 overflow-hidden shadow-xl w-full transform transition-all sm:my-8 sm:max-w-xl rounded-2xl sm:max-h-[90vh] sm:p-6"
      hideModalCB={hideModalCB}
      {...rest}
    >
      <SimpleModalContent
        showCloseIcon={showCloseIcon}
        hideModalCB={hideModalCB}
        title={title}
        subtitle={subtitle}
        leftBtText={leftBtText}
        leftCB={leftCB}
        disableRightBt={disableRightBt}
        fetchingRight={fetchingRight}
        rightCB={rightCB}
        rightBtText={rightBtText}
        children={children}
      />
    </BaseModal>
  );
};

export const SimpleModalContent = ({
  showCloseIcon,
  hideModalCB,
  title,
  subtitle,
  leftBtText,
  leftCB,
  disableRightBt,
  fetchingRight,
  rightCB,
  rightBtText,
  children,
}: {
  showCloseIcon?: boolean;
  hideModalCB?: () => void;
  title?: string;
  subtitle?: string;
  leftBtText?: string;
  leftCB?: () => void;
  disableRightBt?: boolean;
  fetchingRight?: boolean;
  rightCB?: () => void;
  rightBtText?: string;
  children?: ReactNode;
}) => {
  return (
    <>
      {showCloseIcon ? (
        <button className="self-end outline-none" onClick={hideModalCB}>
          <img src="/img/smallIcon/close.svg" alt="close" />
        </button>
      ) : null}
      <div className="mb-3 text-lg">{title}</div>
      <div className="text-center text-base">{subtitle}</div>
      {children}
      <ModalButtonRow
        leftBtText={leftBtText}
        leftCB={leftCB}
        rightBtText={rightBtText}
        rightCB={rightCB}
        fetchingRight={fetchingRight}
        disableRightBt={disableRightBt}
      />
    </>
  );
};

export const ModalButtonRow = ({
  leftBtText,
  leftCB,
  disableRightBt,
  fetchingRight,
  rightCB,
  rightBtText,
}: {
  leftBtText?: string;
  leftCB?: () => void;
  disableRightBt?: boolean;
  fetchingRight?: boolean;
  rightCB?: () => void;
  rightBtText?: string;
}) => {
  return (
    <div className="mt-5 flex w-full items-center justify-end gap-3">
      {leftCB ? (
        <button className="px-3 py-1 text-gray-400" onClick={leftCB}>
          {leftBtText || 'Cancel'}
        </button>
      ) : null}
      {rightCB ? (
        <button
          className="rounded-lg bg-main-green px-3 py-1 text-white disabled:cursor-not-allowed disabled:bg-gray-400"
          disabled={disableRightBt || fetchingRight}
          onClick={rightCB}
        >
          {fetchingRight ? <SyncLoader color="#eaeaea" speedMultiplier={0.5} size={5} /> : rightBtText || 'Confirm'}
        </button>
      ) : null}
    </div>
  );
};
