import { addOpacityToHex } from 'app/general';
import React from 'react';

export const StatusText = ({ color, status }) => (
  <div className="flex items-center">
    <div
      className="flex h-4 w-4 items-center justify-center rounded-full"
      style={{ backgroundColor: addOpacityToHex(color, 0.25) }}
    >
      <div className="h-2 w-2 rounded-full" style={{ backgroundColor: color }} />
    </div>
    <div className="ml-2 w-24 whitespace-normal text-left text-base">{status}</div>
  </div>
);
