import { checkIsEWallet, PAYMENT_METHODS } from 'app/constants/claimConfig';
import { ApiInstance } from 'app/services/api';
import { handleError } from 'app/services/apiHelper';
import { getNricfinErrorMessage, getRequireValueErrorMessage } from 'app/utilities/validationErrorMessageForm';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';

export function useClaimFormInput(formData, updateFormDataClaim) {
  const [errors, setErrors] = useState<any>({});

  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const [checkState, setCheckState] = useState<any>({});

  const [lastSectionInfo, setLastSectionInfo] = useState<string>('');
  const [showModalConfirmSectionChange, setShowModalConfirmSectionChange] = useState(false);
  const [shouldShowError, setShouldShowError] = useState(false);
  const [oldClaimsToCheck, setOldClaimsToCheck] = useState<any[]>([]);
  const [currentPoliciesToCheck, setCurrentPoliciesToCheck] = useState<any[]>([]);

  const formDataRef = useRef(formData);

  useEffect(() => {
    formDataRef.current = formData;
  }, [formData]);

  const _getOldClaim = async (extraData: any) => {
    const params = {
      page_size: 35,
      page_number: 1,
      ...extraData,
    };
    const res = await ApiInstance.getVehicleClaimList(params);
    const { result, error } = handleError(res);
    if (error) return [];
    setOldClaimsToCheck(result?.data || []);
    return result?.data || [];
  };

  const _getCurrentPolicy = async (extraData: any) => {
    const params = {
      page_size: 20,
      page_number: 1,
      ...extraData,
    };
    const res = await ApiInstance.getOrderDetail(params);
    const { result, error } = handleError(res);
    if (error) return [];
    setCurrentPoliciesToCheck(result?.data || []);
    return result?.data;
  };

  const getCheckData = async (oldClaimExtraParams, policyExtraParams) => {
    const [oldClaims, policies] = await Promise.all([
      _getOldClaim(oldClaimExtraParams),
      _getCurrentPolicy(policyExtraParams),
    ]);

    if (formData?.incident_date && _checkIncidentDateWithOldClaim(formData?.incident_date, oldClaims)) {
      toast.error('You have already submitted a claim for this incident.');
      updateFormDataClaim({ ...formData, incident_date: '' });
      return;
    }

    if (formData?.incident_date && _checkIncidentDateWithPolicy(formData?.incident_date, policies)) {
      toast.error('We are unable to find policy coverage during the incident date.');
      updateFormDataClaim({ ...formData, incident_date: '' });
    }
  };

  function onChange(e) {
    const { name, value } = e.target;
    if (name === 'incident_date' && _checkIncidentDateWithOldClaim(value, oldClaimsToCheck)) {
      toast.error('You have already submitted a claim for this incident.');
      return;
    }
    if (name === 'incident_date' && _checkIncidentDateWithPolicy(value, currentPoliciesToCheck)) {
      toast.error('We are unable to find policy coverage during the incident date.');
      return;
    }
    if (name === 'payment_method') {
      updateFormDataClaim(currentState => ({
        ...currentState,
        payment_method: value,
        paynow_nricfin: undefined,
        paynow_nickname: undefined,
        paynow_profile_screenshot: undefined,
        bank_account_nricfin: undefined,
        bank_name: undefined,
        bank_account_holder_name: undefined,
        bank_account_number: undefined,
        bank_statement_screenshot: undefined,
      }));
      setErrors(error => ({
        ...error,
        payment_method: '',
        paynow_nricfin: '',
        paynow_nickname: '',
        paynow_profile_screenshot: '',
        bank_account_nricfin: '',
        bank_name: '',
        bank_account_holder_name: '',
        bank_account_number: '',
        bank_statement_screenshot: '',
      }));
      return;
    }
    const text =
      name === 'vehicle_reg'
        ? value.replace(/[^a-zA-Z0-9]/g, '')?.toUpperCase()
        : name === 'section1excess' || name === 'section2excess' || name === 'paynow_mobile'
        ? value.replace(/\D/g, '')
        : value;

    const editFormData = {
      ...formData,
      [name]: text,
    };
    if (name === 'vehicle_reg') {
      editFormData.vehicleObject = null;
    }
    if (name === 'section1excess' && text === '') {
      setLastSectionInfo('section1excess');
      setShowModalConfirmSectionChange(true);
      return;
    }
    if (name === 'section2excess' && text === '') {
      setShowModalConfirmSectionChange(true);
      setLastSectionInfo('section2excess');
      return;
    }

    updateFormDataClaim(editFormData);
    setErrors({
      ...errors,
      [name]: '',
    });
  }

  const onSelectVehicle = value => {
    updateFormDataClaim({
      ...formData,
      vehicle_reg: value.vehicle_reg,
      vehicleObject: value,
    });
    setErrors({
      ...errors,
      vehicle_reg: '',
    });
  };

  const onSelectEbUser = value => {
    updateFormDataClaim({
      ...formData,
      user_id: value.user?.id,
      full_name: `${value.user?.first_name || ''} ${value.user?.last_name || ''}`.trim(),
      userObject: value,
    });
    setErrors({
      ...errors,
      user_id: '',
      full_name: '',
    });
  };

  const onConfirmChangeSection = () => {
    setShowModalConfirmSectionChange(false);
    if (!lastSectionInfo) return;
    const formDataEdit = {
      ...formData,
      [lastSectionInfo]: '',
    };
    if (lastSectionInfo === 'section1excess') {
      delete formDataEdit.original_repair_invoice;
      delete formDataEdit.photos_of_vehicle_before_repair;
      delete formDataEdit.photos_of_vehicle_after_repair;
    }
    if (lastSectionInfo === 'section2excess') {
      delete formDataEdit.discharge_voucher;
      delete formDataEdit.proof_of_excess_paid;
    }

    updateFormDataClaim(formDataEdit);
    setLastSectionInfo('');
  };

  const onCancelChangeSection = () => {
    setShowModalConfirmSectionChange(false);
    if (!lastSectionInfo) return;
    setLastSectionInfo('');
  };

  function onUploadImage(fieldName, newListImage) {
    onChange({
      target: {
        name: fieldName,
        value: newListImage,
      },
    });
  }

  function onDeleteImage(fieldName, orderImage) {
    const imageUpdates = formData[fieldName];
    const newListImages = imageUpdates.filter((img, idx) => idx !== orderImage);
    onChange({
      target: {
        name: fieldName,
        value: newListImages,
      },
    });
  }

  function onChangeSelect(value, fieldName) {
    onChange({
      target: {
        name: fieldName,
        value,
      },
    });
  }

  const validateDisburmentInput = overrideFormData => {
    const checkFormData = overrideFormData || formData;
    const errorsObject: any = {};
    // if (checkFormData.payment_method === PAYMENT_METHODS.PAYNOW_MOBILENUMBER) {
    //   errorsObject.paynow_mobile = getMobileErrorMessage(checkFormData.paynow_mobile, PRODUCT_REGIONS.SG, '+65');
    //   errorsObject.paynow_nickname = getRequireValueErrorMessage(checkFormData.paynow_nickname);
    // } else
    if (checkFormData.payment_method === PAYMENT_METHODS.PAYNOW_NRIC) {
      errorsObject.paynow_nickname = getRequireValueErrorMessage(checkFormData.paynow_nickname);
      errorsObject.paynow_nricfin = getNricfinErrorMessage(checkFormData.paynow_nricfin);
      errorsObject.paynow_profile_screenshot = getRequireValueErrorMessage(
        checkFormData.paynow_profile_screenshot?.length,
      );
    } else if (checkFormData.payment_method === PAYMENT_METHODS.PAYNOW_UEN) {
      errorsObject.uen_number = getRequireValueErrorMessage(checkFormData.uen_number);
      errorsObject.company_name = getRequireValueErrorMessage(checkFormData.company_name);
      errorsObject.paynow_profile_screenshot = getRequireValueErrorMessage(
        checkFormData.paynow_profile_screenshot?.length,
      );
    } else {
      errorsObject.bank_account_nricfin = getNricfinErrorMessage(checkFormData.bank_account_nricfin);
      errorsObject.bank_account_holder_name = getRequireValueErrorMessage(checkFormData.bank_account_holder_name);
      errorsObject.bank_account_number = getRequireValueErrorMessage(checkFormData.bank_account_number);
      errorsObject.bank_name = getRequireValueErrorMessage(checkFormData.bank_name);
      errorsObject.bank_statement_screenshot = getRequireValueErrorMessage(
        checkFormData.bank_statement_screenshot?.length,
      );
    }
    return errorsObject;
  };
  const validateDisburmentInputPH = overrideFormData => {
    const checkFormData = overrideFormData || formData;
    const errorsObject: any = {};
    errorsObject.preferred_payment = getRequireValueErrorMessage(checkFormData.preferred_payment);
    if (checkIsEWallet(checkFormData.preferred_payment)) {
      errorsObject.mobile = getRequireValueErrorMessage(checkFormData.mobile);
    } else {
      errorsObject.bank_account_number = getRequireValueErrorMessage(checkFormData.bank_account_number);
    }
    // errorsObject.mobile = getMobileErrorMessage(checkFormData.mobile, PRODUCT_REGIONS.PH);
    return errorsObject;
  };

  return {
    errors,
    setErrors,
    onChange,
    onSelectVehicle,
    onSelectEbUser,
    onUploadImage,
    onDeleteImage,
    onChangeSelect,
    showSuccessModal,
    setShowSuccessModal,
    validateDisburmentInput,
    validateDisburmentInputPH,

    checkState,
    setCheckState,

    onConfirmChangeSection,
    onCancelChangeSection,
    showModalConfirmSectionChange,
    shouldShowError,
    setShouldShowError,
    getCheckData,
  };
}

const _checkIncidentDateWithOldClaim = (incidentDate: string, oldClaims: any[]) => {
  return oldClaims.some(claim => claim.incident_date === incidentDate);
};

const _checkIncidentDateWithPolicy = (incidentDate: string, policies: any[]) => {
  const incidentDateObj = dayjs(incidentDate);
  const policy = policies.find(policy => {
    const startDate = dayjs(policy.start_date);
    const endDate = dayjs(policy.end_date);
    return incidentDateObj.isBetween(startDate, endDate, 'day', '[]');
  });
  return !policy;
};
