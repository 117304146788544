import { SuccessStep } from 'app/components/pages/checkout';
import React from 'react';

const CheckoutSuccess = () => {
  return (
    <div className="flex flex-col items-center">
      <SuccessStep />
    </div>
  );
};

export default CheckoutSuccess;
