import { FilterDatePickerInput } from 'app/components/filter-date-picker-input';
import { FilterCheckBox, SearchFilterBar } from 'app/components/search-filter-bar';
import { SimpleGreenCheckbox } from 'app/components/simple-checkout';
import { DATE_FORMAT, SHOWING_OVERVIEW_STATUS } from 'app/constants';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';

export * from './overview-table';

export const OverviewSearchBar = ({ vehicleReg, onChangeVehicleReg, searchParams, updateSearchParams }) => {
  const [dateFilterMode, setDateFilterMode] = useState<string>('');

  useEffect(() => {
    if (dateFilterMode === 'end') {
      updateSearchParams({
        start_date_from: '',
        start_date_to: '',
        upcoming_status: '',
        current_status: '',
        is_auto_renewal: '',
      });
    } else if (dateFilterMode === 'start') {
      updateSearchParams({
        end_date_from: '',
        end_date_to: '',
        upcoming_status: '',
        current_status: '',
        is_auto_renewal: '',
      });
    } else {
      updateSearchParams({ start_date_from: '', start_date_to: '', end_date_from: '', end_date_to: '' });
    }
  }, [dateFilterMode]);

  const _onSelectByStatus = item => {
    setDateFilterMode('');
    updateSearchParams({
      current_status: searchParams?.current_status === item.value ? '' : item.value,
      upcoming_status: '',
      is_auto_renewal: '',
      start_date_from: '',
      start_date_to: '',
      end_date_from: '',
      end_date_to: '',
    });
  };

  const _onSelectByUpCommingStatus = item => {
    setDateFilterMode('');
    updateSearchParams({
      upcoming_status: searchParams?.upcoming_status === item.value ? '' : item.value,
      current_status: '',
      is_auto_renewal: '',
      start_date_from: '',
      start_date_to: '',
      end_date_from: '',
      end_date_to: '',
    });
  };

  const _onSelectByAutoRenewal = item => {
    setDateFilterMode('');
    updateSearchParams({
      is_auto_renewal: searchParams?.is_auto_renewal === item.value ? '' : item.value,
      current_status: '',
      upcoming_status: '',
      start_date_from: '',
      start_date_to: '',
      end_date_from: '',
      end_date_to: '',
    });
  };

  const _onChangeFilterDate = (date, key) => {
    updateSearchParams({ [key]: dayjs(date).format(DATE_FORMAT.serverDate) });
  };

  return (
    <SearchFilterBar searchString={vehicleReg} onChangeSearchString={onChangeVehicleReg}>
      <div className="mb-3">
        <SimpleGreenCheckbox
          isChecked={dateFilterMode === 'start'}
          onClick={() => setDateFilterMode(dateFilterMode === 'start' ? '' : 'start')}
          text="By Policy Start"
        />
        <div className="mt-2 flex items-center gap-2">
          <DatePicker
            maxDate={dayjs(searchParams.start_date_to).toDate()}
            disabled={dateFilterMode !== 'start'}
            selected={searchParams?.start_date_from ? dayjs(searchParams.start_date_from).toDate() : undefined}
            onChange={(date: Date) => _onChangeFilterDate(date, 'start_date_from')}
            customInput={<FilterDatePickerInput />}
          />
          <div className="h-1 w-5 rounded-full bg-gray-400" />
          <DatePicker
            minDate={dayjs(searchParams.start_date_from).toDate()}
            disabled={dateFilterMode !== 'start'}
            selected={searchParams?.start_date_to ? dayjs(searchParams.start_date_to).toDate() : undefined}
            onChange={(date: Date) => _onChangeFilterDate(date, 'start_date_to')}
            customInput={<FilterDatePickerInput />}
          />
        </div>
      </div>
      <div className="mb-3">
        <SimpleGreenCheckbox
          isChecked={dateFilterMode === 'end'}
          onClick={() => setDateFilterMode(dateFilterMode === 'end' ? '' : 'end')}
          text="By Policy End"
        />
        <div className="mt-2 flex items-center gap-2">
          <DatePicker
            maxDate={dayjs(searchParams.end_date_to).toDate()}
            disabled={dateFilterMode !== 'end'}
            selected={searchParams?.end_date_from ? dayjs(searchParams.end_date_from).toDate() : undefined}
            onChange={(date: Date) => _onChangeFilterDate(date, 'end_date_from')}
            customInput={<FilterDatePickerInput />}
          />
          <div className="h-1 w-5 rounded-full bg-gray-400" />
          <DatePicker
            minDate={dayjs(searchParams.end_date_from).toDate()}
            disabled={dateFilterMode !== 'end'}
            selected={searchParams?.end_date_to ? dayjs(searchParams.end_date_to).toDate() : undefined}
            onChange={(date: Date) => _onChangeFilterDate(date, 'end_date_to')}
            customInput={<FilterDatePickerInput />}
          />
        </div>
      </div>
      <div className="mb-3 flex">
        <div className="flex-1">
          <div className="mb-2 text-sm">By Status</div>
          <div className="flex flex-col gap-2">
            {STATE_LIST_1.map(item => (
              <FilterCheckBox
                item={item}
                currentValue={searchParams?.current_status}
                key={item.value}
                onClick={_onSelectByStatus}
              />
            ))}
          </div>
        </div>
        <div className="flex-1">
          <div className="mb-2 text-sm">By Upcoming Status</div>
          <div className="flex flex-col gap-2">
            {STATE_LIST_2.map(item => (
              <FilterCheckBox
                item={item}
                currentValue={searchParams?.upcoming_status}
                key={item.value}
                onClick={_onSelectByUpCommingStatus}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="mb-2 text-sm">By Auto-Renewal</div>
      <div className="flex flex-col gap-2">
        {STATE_LIST_3.map(item => (
          <FilterCheckBox
            item={item}
            currentValue={searchParams?.is_auto_renewal}
            key={item.label}
            onClick={_onSelectByAutoRenewal}
          />
        ))}
      </div>
    </SearchFilterBar>
  );
};

const STATE_LIST_1 = [
  { value: SHOWING_OVERVIEW_STATUS.IN_FORCE },
  { value: SHOWING_OVERVIEW_STATUS.RENEWING },
  { value: SHOWING_OVERVIEW_STATUS.EXPIRING },
];
const STATE_LIST_2 = [
  { value: SHOWING_OVERVIEW_STATUS.PAID },
  { value: SHOWING_OVERVIEW_STATUS.PREPARED },
  { value: SHOWING_OVERVIEW_STATUS.PENDING },
];
const STATE_LIST_3 = [
  { value: true, label: 'On' },
  { value: false, label: 'Off' },
];
