import { ApiInstance } from 'app/services/api';
import { handleError } from 'app/services/apiHelper';
import debounce from 'lodash/debounce';
import { useCallback, useRef, useState } from 'react';
import toast from 'react-hot-toast';

export const useClaimTrackerList = (extraData?: any) => {
  const [searchParams, setSearchParams] = useState({ page_number: 0, page_size: 5, ...extraData });
  const [searchText, setSearchText] = useState('');

  const [claimList, setClaimList] = useState([]);
  const [totalPages, setTotalPages] = useState(0);

  const searchParamsRef = useRef<any>({ page_number: 0, page_size: 5, ...extraData });
  const fetching = useRef(false);

  const onChangeSearchText = e => {
    const text = e.target.value;
    setSearchText(text);
    _debounceSearch(text);
  };

  const _debounceSearch = useCallback(
    debounce(text => {
      updateSearchParams({ vehicle_reg: text });
    }, 500),
    [],
  );

  const debounceFetchData = useCallback(
    debounce(params => {
      updateSearchParams(params);
    }, 300),
    [],
  );

  const updateSearchParams = data => {
    const overrideParams: any = {};

    const searchParamsFromRef = searchParamsRef.current || {};

    if (
      (data.vehicleReg && searchParamsFromRef.vehicleReg !== data.vehicleReg) ||
      (data.status && searchParamsFromRef.status !== data.status)
    ) {
      overrideParams.page_number = 0;
    }

    onFetchData({ ...searchParamsFromRef, ...data, ...overrideParams });
  };

  const onFetchData = async params => {
    if (fetching.current) return;
    setSearchParams(params);
    searchParamsRef.current = params;
    fetching.current = true;
    const res = await ApiInstance.getVehicleClaimList({ ...params, page_number: params.page_number + 1 });
    fetching.current = false;
    const { error, result } = handleError(res);
    if (error) {
      toast.error(error.message);
    } else {
      setClaimList(result?.data || []);
      setTotalPages(Math.ceil((result?.total_data || 0) / (params.page_size || 1)));
    }
  };

  return {
    searchText,
    onChangeSearchText,

    searchParams,
    updateSearchParams,
    debounceFetchData,

    claimList,
    totalPages,
  };
};
