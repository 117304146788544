import type { AnyAction } from 'redux';
import type { DefaultActionCreators, DefaultActionTypes } from 'reduxsauce';
import { createActions, createReducer } from 'reduxsauce';
import * as Immutable from 'seamless-immutable';

/* ------------- Model interface Create Action ------------- */
export interface OverviewAction extends AnyAction {}

interface IActionTypes extends DefaultActionTypes {
  SET_LIST_UPLOAD_VEHICLES: 'setListUploadVehicles';
  SET_ORIGINAL_FILE: 'setOriginalFile';
  SET_LIVE_QUOTE_DATA: 'setLiveQuoteData';
  SET_BATCH_OBJ: 'setBatchObj';
}

interface IActionCreators extends DefaultActionCreators {
  setListUploadVehicles: (data: any) => AnyAction;
  setOriginalFile: (data: any) => AnyAction;
  setLiveQuoteData: (data: any) => AnyAction;
  setBatchObj: (data: any) => AnyAction;
}

type IActions = OverviewAction | AnyAction;

export interface OverviewState {
  listUploadVehicles: any;
  originalFile: any;
  liveQuotesData: any;
  batchObj: any;
}

type ImmutableMyType = Immutable.ImmutableObject<OverviewState>;

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions<IActionTypes, IActionCreators>({
  setListUploadVehicles: ['data'],
  setOriginalFile: ['data'],
  setLiveQuoteData: ['data'],
  setBatchObj: ['data'],
});

export const OverviewTypes = Types;

export const INITIAL_STATE: ImmutableMyType = Immutable.from({
  listUploadVehicles: [],
  originalFile: null,
  liveQuotesData: [],
  batchObj: null,
});

export const setListUploadVehicles = (state: ImmutableMyType, { data }: AnyAction) =>
  state.merge({ listUploadVehicles: data });
export const setOriginalFile = (state: ImmutableMyType, { data }: AnyAction) => state.merge({ originalFile: data });
export const setLiveQuoteData = (state: ImmutableMyType, { data }: AnyAction) => state.merge({ liveQuotesData: data });
export const setBatchObj = (state: ImmutableMyType, { data }: AnyAction) => state.merge({ batchObj: data });

export const reducer = createReducer<ImmutableMyType, IActions>(INITIAL_STATE, {
  [Types.SET_LIST_UPLOAD_VEHICLES]: setListUploadVehicles,
  [Types.SET_ORIGINAL_FILE]: setOriginalFile,
  [Types.SET_LIVE_QUOTE_DATA]: setLiveQuoteData,
  [Types.SET_BATCH_OBJ]: setBatchObj,
});

const stateKey = 'overview';
const getReducerState = state => state[stateKey];
const reducerMap = { [stateKey]: reducer };
export default {
  // default export
  INITIAL_STATE,
  Types,
  Creators,

  stateKey,
  getReducerState,
  reducerMap,
};
