/* eslint-disable no-constant-condition */
/* eslint-disable no-await-in-loop */
import { OverviewActions, selectors } from 'app/redux';
import { ApiInstance } from 'app/services/api';
import { handleError } from 'app/services/apiHelper';
import { isObjectExistKeys } from 'app/utils';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';

export const useLiveQuote = () => {
  const { user } = useSelector(selectors.user);
  const { listUnits } = useSelector(selectors.data);
  const { liveQuotesData } = useSelector(selectors.overview);

  const [fetching, setFetching] = useState(false);
  const [progress, setProgress] = useState(0);

  const dispatch = useDispatch();

  const stopRef = useRef(false);

  useEffect(() => {
    return () => {
      stopRef.current = true;
    };
  }, []);

  const getLiveQuote = async listUploadVehicles => {
    setProgress(0);
    const product_ids: any = [];
    if (listUploadVehicles?.length)
      listUploadVehicles.forEach(item => {
        const { pricingObject, contract_start, contract_end } = item || {};
        const { price_details } = pricingObject || {};
        product_ids.push({
          price_details_id: price_details?.id,
          price_id: price_details?.price_id,
          start_date: contract_start,
          // eslint-disable-next-line eqeqeq
          quantity: item.unit_id == listUnits?.days?.id ? dayjs(contract_end).diff(dayjs(contract_start), 'd') + 1 : 1,
          unit_id: item.unit_id || listUnits?.months?.id,
          vehicle_reg: item.vehicle_reg,
          auto_renewal: !!item.auto_renewal,
          details: {
            company_uen: item.company_uen || user?.group_obj?.uen,
            company_name: item.company_name || user?.group_obj?.display_name,
            contact_email: item.contact_email || user?.email,
            contact_num: item.contact_num || user?.mobile,
            make: item.make,
            model: item.model,
            chassis: item.chassis,
            engine: item.engine,
            vehicle_type: item.vehicle_type,
          },
        });
      });
    const body = {
      user_id: user?.id,
      product_ids,
      sale_type: 'mer',
      is_excel: true,
    };
    setFetching(true);
    const res = await ApiInstance.getSale(body);
    const { result, error } = handleError(res);
    if (error) {
      setFetching(false);
      if (error?.code === 422) {
        dispatch(OverviewActions.setLiveQuoteData(error?.data));
      } else {
        toast.error(error?.message);
      }
      return { result, error };
    }

    const { import_id, total_record } = result?.data || {};
    if (!import_id) {
      setFetching(false);
      setProgress(100);
      dispatch(OverviewActions.setLiveQuoteData(result?.data));
      dispatch(OverviewActions.setBatchObj(result?.batch_obj));
      return {
        result,
        error,
        warningsSumUp: result?.batch_obj?.id ? undefined : result?.data?.[0]?.warnings,
      };
    }

    await wait(5000);
    while (!stopRef.current) {
      const resImport = await ApiInstance.getSaleImportStatus(import_id);
      const { result: resultImport, error: errorImport } = handleError(resImport);
      if (errorImport) {
        setFetching(false);
        return { result: null, error: errorImport };
      }
      const { total_record: resultImportTotalRecord, batch_obj, orders, error_orders } = resultImport?.data || {};

      setProgress(Math.round(((resultImportTotalRecord || 0) / total_record) * 100));
      const orderArray = Array.isArray(orders) ? orders : orders ? [orders] : [];

      const data = [...(error_orders || []), ...orderArray]?.map(item => ({ ...item, ...item?.order_resp }));

      const warningsSumUp = batch_obj?.id ? undefined : sumUpWarning(data?.map(item => item.warnings));
      const warningToCheck = batch_obj?.id ? batch_obj?.warnings : warningsSumUp;
      if (warningToCheck && isObjectExistKeys(warningToCheck)) {
        setFetching(false);
        return {
          result: {
            batch_obj,
            data,
          },
          error: null,
          warningsSumUp,
        };
      }
      if (total_record === resultImportTotalRecord) {
        setFetching(false);
        dispatch(OverviewActions.setLiveQuoteData(data));
        dispatch(OverviewActions.setBatchObj(batch_obj));
        return {
          result: {
            batch_obj,
            data,
          },
          warningsSumUp: batch_obj?.id ? undefined : sumUpWarning(data?.map(item => item.warnings)),
          error: null,
        };
      }
      await wait(5000);
    }
  };

  const clearLiveQuoteRedux = () => {
    dispatch(OverviewActions.setLiveQuoteData(null));
  };

  return { fetching, progress, getLiveQuote, clearLiveQuoteRedux, liveQuotesData };
};

function wait(n: number) {
  return new Promise(resolve => {
    setTimeout(resolve, n);
  });
}

const sumUpWarning = (warnings: any[]) => {
  const warningObject = {};
  if (warnings?.length) {
    warnings.forEach(warning => {
      Object.keys(warning).forEach(key => {
        warningObject[key] = warning[key];
      });
    });
  }
  return warningObject;
};
