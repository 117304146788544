import React, { useEffect, useRef } from 'react';

export const DropDownSelectVehicle = ({
  setShowDropdown,
  showDropdown,
  searchResult,
  onSelectItem,
}: {
  setShowDropdown: (value: boolean) => void;
  showDropdown: boolean;
  searchResult: any[];
  onSelectItem: (item: any) => void;
}) => {
  const wrapperRef = useRef();
  useEffect(() => {
    function handleClickOutside(event) {
      // @ts-ignore
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  if (!showDropdown) return null;
  return (
    // @ts-ignore
    <div ref={wrapperRef} className="absolute top-16 w-full rounded-lg bg-white">
      <div className="flex max-h-[50vh] flex-col overflow-y-auto p-1">
        {searchResult?.map((item: any) => (
          <button
            key={item.id}
            className="border-b border-b-gray-100 p-2 outline-none"
            onClick={() => onSelectItem(item)}
          >
            <div className="text-left text-base">{item.vehicle_reg?.toUpperCase()}</div>
          </button>
        ))}
      </div>
    </div>
  );
};
