import clsx from 'clsx';
import React, { useMemo, useState } from 'react';

export const TabContainer = ({ tabOptions }) => {
  const [selectedTab, setSelectedTab] = useState(tabOptions[0].value);

  const currentTabContent = useMemo(
    () => tabOptions.find(item => item.value === selectedTab)?.content,
    [selectedTab, tabOptions],
  );

  return (
    <div className="flex flex-col border border-gray-200">
      <div className="flex border-b border-b-gray-200 pt-2">
        {tabOptions.map(item => (
          <TabButton currentTab={selectedTab} data={item} key={item.value} onClick={setSelectedTab} />
        ))}
      </div>
      {currentTabContent}
    </div>
  );
};

const TabButton = ({ currentTab, data, onClick }) => {
  const { label, value } = data;
  const isActive = currentTab === value;

  return (
    <button
      className={clsx('mx-4 py-2 px-4 text-base', isActive ? 'border-b-2 border-b-main-green' : '')}
      onClick={() => onClick(value)}
    >
      {label}
    </button>
  );
};
