import { ButtonRow } from 'app/components/button-row';
import { InlineDateInput, InlineSelectInput, InlineTextInput } from 'app/components/inline-input';
import { PRODUCT_REGIONS } from 'app/constants';
import { isObjectExistKeys, validateEmail, validatePhone } from 'app/utils';
import dayjs from 'dayjs';
import React, { useState } from 'react';

export const EmployeeForm = ({ groupRankOptions, onBack, onNext, addOneErrors }) => {
  const [form, setForm] = useState<any>({});
  const [errors, setErrors] = useState<any>({});

  const _onChangeField = e => {
    const { value, name } = e?.target || {};
    setForm({ ...form, [name]: value });
    setErrors({ ...errors, [name]: '' });
  };

  const _validateForm = () => {
    const errorObject: any = {};

    if (!form.firstName) {
      errorObject.firstName = 'Please input first name';
    }
    if (!form.email) {
      errorObject.email = 'Please input your email';
    } else if (!validateEmail(form.email)) {
      errorObject.email = 'Please input a valid email';
    }
    if (form.mobile && !validatePhone(form.mobile, PRODUCT_REGIONS.PH)) {
      errorObject.mobile = 'Please input a valid mobile number';
    }
    if (!form.employeeRank) {
      errorObject.employeeRank = 'Please select employee rank';
    }
    if (!form.effectiveDate) {
      errorObject.effectiveDate = 'Please select effective date';
    }
    if (!form.dob) {
      errorObject.dob = 'Please select date of birth';
    }

    setErrors(errorObject);

    return !isObjectExistKeys(errorObject);
  };

  const _onSubmit = () => {
    if (_validateForm()) {
      onNext(form);
    }
  };

  return (
    <div className="flex w-full max-w-4xl flex-col">
      <div className="mt-10 mb-5 text-xl font-medium">Basic Information</div>
      <div className="flex gap-5">
        <InlineTextInput
          className="flex-1"
          label="First Name"
          name="firstName"
          value={form?.firstName}
          error={errors?.firstName}
          onChange={_onChangeField}
          required
        />
        <InlineTextInput
          className="flex-1"
          label="Last Name"
          name="lastName"
          value={form?.lastName}
          error={errors?.lastName}
          onChange={_onChangeField}
        />
      </div>
      <div className="flex gap-5">
        <InlineDateInput
          className="flex-1"
          label="Date of Birth"
          maxDate={dayjs().toDate()}
          name="dob"
          value={form?.dob}
          error={errors?.dob}
          onChange={_onChangeField}
          required
        />
        <InlineTextInput
          className="flex-1"
          label="Email Address"
          name="email"
          value={form?.email}
          error={errors?.email}
          onChange={_onChangeField}
          required
        />
      </div>
      <div className="flex gap-5">
        <InlineTextInput
          className="flex-1"
          label="Mobile Number"
          name="mobile"
          prefix="+63"
          maxLength={10}
          value={form?.mobile}
          error={errors?.mobile}
          onChange={_onChangeField}
        />
        <div className="flex-1" />
      </div>
      <div className="mt-10 mb-5 text-xl font-medium">Employee Details</div>
      <div className="flex gap-5">
        <InlineDateInput
          className="flex-1"
          label="Effective Date"
          name="effectiveDate"
          minDate={dayjs().toDate()}
          value={form?.effectiveDate}
          error={errors?.effectiveDate}
          onChange={_onChangeField}
          required
        />
        <InlineSelectInput
          className="flex-1"
          options={groupRankOptions}
          label="Employee Rank"
          name="employeeRank"
          value={form?.employeeRank}
          error={errors?.employeeRank}
          onChange={_onChangeField}
          required
        />
      </div>
      <div className="mt-10 self-center text-center text-sm text-red-600">{addOneErrors?.message}</div>
      <ButtonRow onLeft={onBack} onRight={_onSubmit} />
    </div>
  );
};
