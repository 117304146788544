import { selectors } from 'app/redux';
import React from 'react';
import { useSelector } from 'react-redux';

export const CompanyInfo = ({ numOfVehicles }: { numOfVehicles?: number | undefined }) => {
  const { user } = useSelector(selectors.user);

  return (
    <div className="flex justify-between gap-4 py-2">
      <div className="flex flex-col">
        <div className="flex items-center gap-1 text-base font-medium">
          Welcome, {[user?.first_name, user?.last_name].filter(item => item).join(' ')}!
          <img src="/img/smallIcon/company-info/hand-shake.svg" alt="claim" className="h-6 w-6" />
        </div>
        <p className="text-xs">Here’s what’s going on today.</p>
      </div>
      <div className="flex gap-3">
        <InfoItem iconKey="building" label="Company Name" value={user?.group_obj?.display_name} />
        <div className="border-r border-r-gray-200" />
        <InfoItem iconKey="mail" label="Contact Email" value={user?.email} />
        <div className="border-r border-r-gray-200" />
        <InfoItem iconKey="phone" label="Contact Number" value={user?.mobile} />
        {numOfVehicles !== undefined ? (
          <>
            <div className="border-r border-r-gray-200" />
            <InfoItem iconKey="car" label="Total Active Vehicles" value={`${numOfVehicles}`} />
          </>
        ) : null}
      </div>
    </div>
  );
};

const InfoItem = ({ label, value, iconKey }: { label: string; value: string; iconKey: string }) => {
  return (
    <div className="flex min-w-[200px] gap-2">
      <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-main-greenLight/50">
        <img className="h-5 w-5" src={`/img/smallIcon/company-info/${iconKey}.svg`} alt="claim" />
      </div>
      <div className="flex flex-1 flex-col font-medium">
        <div className="text-sm">{label}</div>
        <p className="text-ellipsis text-base">{value}</p>
      </div>
    </div>
  );
};
