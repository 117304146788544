import { GoogleOAuthProvider } from '@react-oauth/google';
import { ModalForgotPassword } from 'app/modules/login';
import { LoginPanel } from 'app/modules/login/components/login-panel';
import { UserActions } from 'app/redux';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

const LoginPage = () => {
  const dispatch = useDispatch();

  const [showModalForgot, setShowModalForgot] = useState(false);
  const [apiError, setApiError] = useState('');
  const [fetching, setFetching] = useState(false);

  const onSubmitForm = body => {
    setFetching(true);
    dispatch(
      UserActions.loginRequest(body, (_, error) => {
        setFetching(false);
        if (error) setApiError(error?.message || 'Something went wrong');
      }),
    );
  };

  return (
    <>
      <div className="relative flex h-screen w-screen items-center gap-32 p-10 xl:p-16">
        <img className="absolute inset-0 -z-10 h-full w-full object-cover" src="/img/login-bg.jpg" alt="login-bg" />
        <div className="flex flex-1 justify-center">
          <img src="/img/logo-white.svg" alt="logo" className="w-72 drop-shadow-lg" />
        </div>

        <GoogleOAuthProvider clientId={process.env.REACT_APP_GG_CLIENT_ID!}>
          <LoginPanel
            fetching={fetching}
            apiError={apiError}
            onSubmit={onSubmitForm}
            onForgotPassword={() => setShowModalForgot(true)}
          />
        </GoogleOAuthProvider>
      </div>
      <ModalForgotPassword isVisible={showModalForgot} hideModalCB={() => setShowModalForgot(false)} />
    </>
  );
};

export default LoginPage;
