import { Loading } from 'app/components';
import { useLiveQuote, useSheetFileReader } from 'app/hooks';
import type { CsvConvertedData, WarningObject } from 'app/modules/upload-csv';
import { convertCsvData, extractWarning, ModalUploadFile } from 'app/modules/upload-csv';
import { ErrorPreview } from 'app/modules/upload-csv/components/error-preview';
import { selectors } from 'app/redux';
import { OverviewRedux } from 'app/redux/reducers';
import clsx from 'clsx';
import React, { useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';

const MAX_ROW = 100;
const UploadCSV = ({ history }) => {
  const { pricingList, listUnits } = useSelector(selectors.data);

  const [uploadWarnings, setUploadWarnings] = useState<WarningObject[]>([]);
  const [showModalUpload, setShowModalUpload] = useState(true);
  const [currentForm, setCurrentForm] = useState<any>(null);
  const [dataTable, setDataTable] = useState<CsvConvertedData[]>([]);
  const [errorMessage, setErrorMessage] = useState('');

  const { onReadFile, isLoading, progress } = useSheetFileReader();

  const { getLiveQuote, fetching: fetchingLiveQuote, progress: progressLiveQuote } = useLiveQuote();

  const dispatch = useDispatch();

  const displayTable = useMemo(
    () =>
      dataTable
        ?.map((item, index) => ({ ...item, warnings: uploadWarnings[index] }))
        .filter(data => data.hasError || data?.warnings?.hasWarning) || [],
    [dataTable, uploadWarnings],
  );

  const _convertData = (table: any[]) => {
    if (!table.length) return null;
    const { listUploadVehicles, warnings, hasWarning } = convertCsvData(table, pricingList, listUnits);
    if (hasWarning) {
      setUploadWarnings(warnings);
      dispatch(OverviewRedux.Creators.setOriginalFile(null));

      const findFirstErrorIndex = table.findIndex((_, index) => warnings?.[index]?.hasWarning);
      setCurrentForm({ ...table[findFirstErrorIndex], warnings: warnings?.[findFirstErrorIndex] });
      return null;
    }
    return listUploadVehicles;
  };

  const onSubmitFile = async (table: any[], listUploadVehicles: any[]) => {
    const { result, error, warningsSumUp } = await getLiveQuote(listUploadVehicles);
    if (error) {
      toast.error(error?.message || 'Error while getting live quote');
      return;
    }
    const { warnings, hasWarning } = extractWarning(
      result?.batch_obj?.id ? result?.batch_obj?.warnings : warningsSumUp,
    );
    if (hasWarning) {
      dispatch(OverviewRedux.Creators.setOriginalFile(null));
      setUploadWarnings(warnings);

      const findFirstErrorIndex = table.findIndex((_, index) => warnings?.[index]?.hasWarning);
      setCurrentForm({ ...table[findFirstErrorIndex], warnings: warnings?.[findFirstErrorIndex] });
    } else {
      dispatch(OverviewRedux.Creators.setListUploadVehicles(listUploadVehicles));
      if (listUploadVehicles?.length) history.push('/dashboard/mer/checkout');
      else toast.error('No vehicle to checkout');
    }
  };

  const _onReadFile = files => {
    setErrorMessage('');
    setDataTable([]);
    setCurrentForm(null);
    setUploadWarnings([]);
    onReadFile(files, ({ data, error }) => {
      if (!data?.length) {
        toast.error(error);
        return;
      }
      if (data.length > MAX_ROW) {
        setErrorMessage(
          `Maximum of ${MAX_ROW} vehicles allowed for Excel file upload. Please upload a file with fewer vehicles.`,
        );
        return;
      }
      setShowModalUpload(false);

      setDataTable(data);

      const listUploadVehicles = _convertData(data);
      if (!listUploadVehicles?.length) {
        // toast.error('No vehicle to upload');
        return;
      }
      if (error) {
        const findFirstError = data.find(item => item.hasError);
        setCurrentForm(findFirstError);
        // toast.error(error);
        return;
      }

      onSubmitFile(data, listUploadVehicles);
    });
  };

  return (
    <div className="flex h-[100dvh] flex-col">
      <div className="flex flex-col p-4">
        <p className="text-2xl font-bold text-main-green">Bulk Onboarding </p>
      </div>
      {isLoading || fetchingLiveQuote ? <Loading progress={fetchingLiveQuote ? progressLiveQuote : progress} /> : null}
      <div className="flex flex-1 gap-4 p-4">
        <div className="flex w-64 flex-col gap-4 rounded-lg bg-slate-100 py-6 px-4 text-center">
          <div className="text-xl">
            Vehicle Errors <img className="inline-block" src="/img/smallIcon/red-warning.svg" />
          </div>
          <div className="text-xs">Please resolve errors and reupload the correct excel file.</div>
          {displayTable?.map(data => (
            <button
              className={clsx(
                'rounded-md border p-3',
                currentForm?.vehicle_reg?.value === data.vehicle_reg?.value
                  ? 'border-red-600 bg-white text-red-600'
                  : '',
              )}
              key={data.vehicle_reg?.value}
              onClick={() => setCurrentForm(data)}
            >
              {data.vehicle_reg?.value}
            </button>
          ))}
        </div>
        <ErrorPreview className="flex-1" form={currentForm} />
      </div>
      <div className="flex justify-end border-t border-t-gray-200 p-5">
        <button
          className="flex h-12 items-center rounded-lg bg-main-green px-8 text-base text-white disabled:cursor-not-allowed disabled:bg-gray-400"
          onClick={() => setShowModalUpload(true)}
        >
          Upload Again
        </button>
      </div>
      <ModalUploadFile
        isVisible={showModalUpload}
        whiteBackground
        canClose={!!dataTable?.length}
        hideModalCB={() => setShowModalUpload(false)}
        disabled={isLoading}
        onReadFile={_onReadFile}
        errorMessage={errorMessage}
        onClearErrorMessage={() => setErrorMessage('')}
      />
    </div>
  );
};

export default UploadCSV;
