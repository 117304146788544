import { Icon } from 'app/components/icon/icon';
import { PRODUCT_REGIONS } from 'app/constants';
import { selectors } from 'app/redux';
import { getCurrencyString } from 'app/utilities';
import { capitalizeFirstLetter } from 'app/utils';
import dayjs from 'dayjs';
import React from 'react';
import { useSelector } from 'react-redux';

export const OverviewInfoRow = ({ overviewInfo }) => {
  const { user } = useSelector(selectors.user);
  const { name, contract_start, contract_end } = user?.group_obj || {};

  return (
    <div className="mb-12 flex gap-2">
      <div className="containter-shadow flex flex-[2]">
        <Icon name="logoQuareBorder" size={44} />
        <div className="ml-2 flex-1">
          <div className="text-base font-medium">Hello, {capitalizeFirstLetter(name)}!</div>
          <div className="flex items-center text-base">
            Product: <span className="font-medium">HMO</span>
            <div className="m-2 h-2 w-2 rounded-full bg-main-green" />
            Coverage Period:
            <span className="font-medium">
              {dayjs(contract_start).format('DD MMM YYYY')} - {dayjs(contract_end).format('DD MMM YYYY')}
            </span>
          </div>
        </div>
      </div>
      <div className="containter-shadow flex flex-1">
        <div className="mr-2 flex-1">
          <div className="text-base font-medium">
            {getCurrencyString(overviewInfo?.total_amount, PRODUCT_REGIONS.SG, 2)}
          </div>
          <div className="text-base">Total Premiums</div>
        </div>
        <Icon name="totalPremiumLogo" size={44} />
      </div>
      <div className="containter-shadow flex flex-1">
        <div className="mr-2 flex-1">
          <div className="text-base font-medium">{overviewInfo?.total_user}</div>
          <div className="text-base">Total Enrolled Employees</div>
        </div>
        <Icon name="totalEnrolledEmployeesLogo" size={44} />
      </div>
    </div>
  );
};
