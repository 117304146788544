import { ApiInstance } from 'app/services/api';
import { handleError } from 'app/services/apiHelper';
import { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';

export const useVehicleDetail = vehicleReg => {
  const [queryParams, setQueryParams] = useState({ page_number: 0, page_size: 5, vehicle_reg: `-${vehicleReg}` });

  const [vehicleDetail, setVehicleDetail] = useState<any>({});
  const [policies, setPolicies] = useState<any[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [fetchingOrders, setFetchingOrders] = useState(false);
  const [fetchingVehicleDetail, setFetchingVehicleDetail] = useState(false);

  const fetching = useMemo(() => fetchingOrders || fetchingVehicleDetail, [fetchingOrders, fetchingVehicleDetail]);

  useEffect(() => {
    if (vehicleReg) {
      getVehicleDetail();
    }
  }, [vehicleReg]);

  const updateSearchParams = data => {
    const overrideParams: any = {};
    if (queryParams.vehicle_reg !== data.vehicle_reg) overrideParams.page_number = 0;
    onFetchData({ ...queryParams, ...data });
  };

  const refreshData = () => {
    updateSearchParams({});
  };

  const getVehicleDetail = async () => {
    setFetchingVehicleDetail(true);
    const res = await ApiInstance.getVehiclesList({ search_text: vehicleReg });
    setFetchingVehicleDetail(false);
    const { result, error } = handleError(res, true);
    if (!error) {
      const data = result?.data?.[0];
      setVehicleDetail({ ...data, ...data?.details });
    }
  };

  const onFetchData = async params => {
    if (!params?.vehicle_reg) return;
    setQueryParams(params);
    setFetchingOrders(true);
    const res = await ApiInstance.getOrderDetail({ ...params, page_number: params.page_number + 1 });
    setFetchingOrders(false);
    const { error, result } = handleError(res);
    if (error) {
      toast.error(error.message);
    } else {
      setPolicies(result?.data || []);
      setTotalPages(Math.ceil((result?.total_data || 0) / (params?.page_size || 1)));
    }
  };

  return {
    fetching,
    queryParams,
    updateSearchParams,
    getVehicleDetail,
    refreshData,
    totalPages,
    vehicleDetail,
    policies,
  };
};
