import { APP_CONFIG } from 'app/constants';
import _ from 'lodash';
import moment from 'moment';
import XLSX from 'xlsx';

export const getDuplicateIndexs = (data, getCompareValue, setDuplicateCallback) => {
  const seen = {};
  const dup = {};

  data.forEach((item, idx) => {
    const fieldCheck = getCompareValue ? getCompareValue(item) : item;
    const seenIdx = seen[fieldCheck];
    if (seenIdx != null) {
      if (dup[fieldCheck] != null) {
        dup[fieldCheck].push(idx);
        if (setDuplicateCallback) setDuplicateCallback(idx);
      } else {
        dup[fieldCheck] = [seenIdx, idx];
        if (setDuplicateCallback) {
          setDuplicateCallback(seenIdx);
          setDuplicateCallback(idx);
        }
      }
    } else {
      seen[fieldCheck] = idx;
    }
  });

  return Object.values(dup).flat();
};

export const formatDate = date => {
  const isValid = moment(date).isValid();
  if (isValid) {
    return moment(date).format(APP_CONFIG.format.date);
  }
  return null;
};

export const getMomentFromNumberDateValue = value => {
  // using default date parser from xlsx library will somehow minus 40 secs from input date time so we need to parse it our self from date value number code
  const PDObj = XLSX.SSF.parse_date_code(value);

  // https://momentjs.com/docs/#/parsing/array/
  // [year, month, day, hour, minute, second, millisecond]
  // (month index start from 0)
  return moment([PDObj.y, PDObj.m - 1, PDObj.d, PDObj.H, PDObj.M, PDObj.S]);
};

export const checkPoliciesSatus = (policies, status) => {
  if (_.isEmpty(policies)) return undefined;

  // Check policies include policy status
  return (policies && _.find(policies, ['status', status])) || false;
};

export const getNextPolicy = policies => {
  if (_.isEmpty(policies)) return undefined;
  // get value in object policies
  const nextStatuses = [
    APP_CONFIG.policies.PREPARED,
    APP_CONFIG.policies.PENDING,
    APP_CONFIG.policies.PAID,
    APP_CONFIG.policies.DRAFT,
  ];
  return nextStatuses && _.find(policies, p => nextStatuses.includes(p.status));
};
export const getNextPolicyValue = (policies, key) => {
  if (_.isEmpty(policies)) return undefined;

  const nextPolicy = getNextPolicy(policies);
  return nextPolicy ? nextPolicy[key] : '';
};

export const getCurrentPolicy = policies => {
  if (_.isEmpty(policies)) return undefined;
  // get value in object policies
  const currentStatus = [
    APP_CONFIG.policies.IN_FORCE,
    APP_CONFIG.policies.RENEWING_SOON,
    APP_CONFIG.policies.EXPIRING_SOON,
  ];
  return policies && _.find(policies, p => currentStatus.includes(p.status));
};

export const getPolicyCell = (policies, key) => {
  if (_.isEmpty(policies)) return undefined;

  const currentPolicy = getCurrentPolicy(policies);

  return (currentPolicy && currentPolicy[key]) || '';
};

export const getPolicyUpdate = (policies, key) => {
  if (_.isEmpty(policies)) return undefined;

  const getPolicyDraft = _.find(policies, ['status', APP_CONFIG.policies.DRAFT]);
  const getPolicyApprove = _.find(policies, ['status', APP_CONFIG.policies.APPROVED]);

  const resValue = getPolicyDraft ? getPolicyDraft[key] || '' : (getPolicyApprove && getPolicyApprove[key]) || '';

  return resValue;
};

export const reduceListvehicleUpdate = listUploadVehicles => {
  if (_.isEmpty(listUploadVehicles)) return undefined;

  const listUploadVehiclesReduce = _.reduce(
    listUploadVehicles,
    (listUploadVehiclesReduce, vehicle) => {
      const auto_renewal = vehicle.auto_renewal_update !== undefined && { auto_renewal: vehicle.auto_renewal_update };
      const policy_start = vehicle.policy_start_update && { policy_start: vehicle.policy_start_update };
      return [
        ...listUploadVehiclesReduce,
        {
          ...auto_renewal,
          ...policy_start,
          vehicle_reg: vehicle.vehicle_reg,
        },
      ];
    },
    [],
  );

  return listUploadVehiclesReduce;
};
