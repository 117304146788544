import dayjs from 'dayjs';
import React from 'react';

import { SimpleFullScreenModal, SimpleModal } from '../../simple-modal';

export const ClaimSuccessModal = ({ isVisible, onBack, onMakeAnotherClaim }) => {
  return (
    // @ts-ignore
    <SimpleFullScreenModal isVisible={isVisible}>
      <div className="flex h-full flex-col items-center justify-center">
        <img src="/img/claim/thankyouImage.png" width={300} alt="thankyouImage" />
        <div className="my-3 text-2xl font-bold text-black">Congratulations! Claim is Successfully Submitted.</div>
        <div className="mb-5 max-w-xs text-center text-xl font-medium">
          Kindly wait for an email to check your claim’s status.
        </div>
        <div className="flex gap-5">
          <button className="bt-green w-52 font-bold" onClick={onBack}>
            BACK TO DASHBOARD
          </button>
          <button className="bt-green w-52 font-bold" onClick={onMakeAnotherClaim}>
            MAKE ANOTHER CLAIM
          </button>
        </div>
      </div>
    </SimpleFullScreenModal>
  );
};

export const ClaimCdwSuccessModal = ({ isVisible, onBack, onMakeAnotherClaim, isNotifyClaim, formData }) => {
  if (isNotifyClaim)
    return (
      // @ts-ignore
      <SimpleFullScreenModal isVisible={isVisible}>
        <div className="flex h-full max-w-xl flex-col items-center justify-center self-center">
          <img src="/img/claim/thankyouImage.png" width={300} alt="thankyouImage" />
          <div className="my-3 text-center text-2xl font-bold text-black">
            Accident that took place on{' '}
            <span className="text-main-green">{dayjs(formData?.incident_date).format('DD/MM/YYYY')}</span> with vehicle{' '}
            <span className="text-main-green">{formData?.vehicle_reg}</span> is now on pending.
          </div>
          <div className="text-base">
            <span className="font-medium text-main-green">Reminder:</span> Claims will only be processed after the
            documents below are received by the team. To submit the claim requirements, send it via email{' '}
            <a href="mailto:hey@gigacover.com" className="text-main-green">
              hey@gigacover.com
            </a>
            .
          </div>
          <div className="flex w-full flex-col text-base">
            <div className="mt-2 text-sm font-medium text-main-green">For all Claims: </div>
            <ul className="list-disc pl-5 text-sm">
              <li>Policy holder’s driving license and NRIC</li>
              <li>Certificate of Motor Insurance</li>
              <li>Rental Contract</li>
              <li>GIA Accident Report</li>
              <li>Police Report</li>
            </ul>
            <div className="mt-2 text-sm font-medium text-main-green">For Section 1 Claims:</div>
            <ul className="list-disc pl-5 text-sm">
              <li>Proof of excess payment (if excess was paid)</li>
              <li>Original Repair Invoice</li>
              <li>Colour Photographs showing damage of the car </li>
              <li>Colour Photographs showing the car after repaired </li>
            </ul>
            <div className="mt-2 text-sm font-medium text-main-green">For Section 2 Claims:</div>
            <ul className="list-disc pl-5 text-sm">
              <li>Discharge Voucher</li>
              <li>Proof of excess paid to primary insurer</li>
            </ul>
          </div>
          <div className="mt-5 flex gap-5">
            <button className="bt-green w-52 font-bold" onClick={onBack}>
              BACK TO DASHBOARD
            </button>
            <button className="bt-green w-52 font-bold" onClick={onMakeAnotherClaim}>
              MAKE ANOTHER CLAIM
            </button>
          </div>
        </div>
      </SimpleFullScreenModal>
    );
  return <ClaimSuccessModal isVisible={isVisible} onBack={onBack} onMakeAnotherClaim={onMakeAnotherClaim} />;
};

export const ClaimErrorModal = ({ errorMessage, onClose }) => {
  return (
    <SimpleModal isVisible={!!errorMessage} hideModalCB={onClose}>
      <div className="flex flex-col items-center">
        <img src="/img/smallIcon/error-red.svg" width={150} />
        <div className="mt-2 mb-3 max-w-xs text-center text-lg text-gray-800">{errorMessage}</div>
        <button
          onClick={onClose}
          className="rounded-md bg-main-green py-1 px-4 text-lg font-medium text-white disabled:cursor-not-allowed disabled:bg-gray-400"
        >
          Close
        </button>
      </div>
    </SimpleModal>
  );
};

export const ChangeSectionErrorModal = ({ isVisible, onCancel, onConfirm }) => {
  return (
    // @ts-ignore
    <SimpleModal isVisible={isVisible}>
      <div className="flex flex-col gap-7">
        <div className="text-lg text-gray-900">Confirmation of Action</div>
        <div className="text-base text-gray-900">
          Are you sure you want to deselect this section? Note that the uploaded files within this section will reset
          after confirming.
        </div>
        <div className="flex gap-5 self-end">
          <button
            onClick={onCancel}
            className=" text-lg font-medium text-gray-600 disabled:cursor-not-allowed disabled:bg-gray-400"
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className="rounded-md bg-main-green py-1 px-4 text-lg font-medium text-white disabled:cursor-not-allowed disabled:bg-gray-400"
          >
            Confirm
          </button>
        </div>
      </div>
    </SimpleModal>
  );
};
