import { selectors } from 'app/redux';
import { OverviewRedux } from 'app/redux/reducers';
import clsx from 'clsx';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

export const SuccessStep = ({ className }: { className?: string }) => {
  const { user } = useSelector(selectors.user);
  const history = useHistory();
  const dispatch = useDispatch();

  const _onBack = () => {
    dispatch(OverviewRedux.Creators.setListUploadVehicles([]));
    history.push('/dashboard/mer/overview');
  };

  return (
    <div className={clsx('flex flex-col items-center justify-center gap-12', className)}>
      <div className="text-3xl font-bold text-main-pink">Purchase Successful!</div>
      <img src="/img/banner/checkout-success.png" className="h-96" />
      <div className="flex flex-col items-center">
        <div className="text-2xl">
          Thank you for purchasing <span className="font-bold text-main-green">Motor Excess Reduction Insurance</span>
        </div>
        <div className="text-base">
          Your policy documents have been sent to <span className="text-main-pink">{user?.email}</span>
        </div>
      </div>
      <button
        className="rounded-lg bg-main-green py-3 px-10 text-white disabled:cursor-not-allowed disabled:bg-gray-600"
        onClick={_onBack}
      >
        Back to Home
      </button>
    </div>
  );
};
