import { ModalAlertContent } from 'app/components/pages/overview/modal';
import { BaseModal, SimpleModalContent } from 'app/components/simple-modal';
import { ApiInstance } from 'app/services/api';
import { handleError } from 'app/services/apiHelper';
import { validateEmail, validatePasswordFormat } from 'app/utils';
import React, { useEffect, useState } from 'react';
import OtpInput from 'react-otp-input';

export const ModalForgotPassword = ({ isVisible, hideModalCB }) => {
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');

  useEffect(() => {
    if (isVisible) {
      setStep(1);
    }
  }, [isVisible]);

  return (
    <BaseModal
      panelWrapperClassName="flex items-center w-full h-full justify-center p-4"
      panelClassName="relative flex flex-col bg-white p-4 overflow-hidden shadow-xl w-full transform transition-all sm:my-8 sm:max-w-lg rounded-2xl sm:max-h-[90vh] sm:p-6"
      isVisible={isVisible}
      hideModalCB={hideModalCB}
    >
      {step === 1 ? (
        <InputEmail email={email} setEmail={setEmail} onBack={hideModalCB} onNext={() => setStep(2)} />
      ) : step === 2 ? (
        <VerifyOtp otp={otp} setOtp={setOtp} onBack={() => setStep(1)} email={email} onNext={() => setStep(3)} />
      ) : step === 3 ? (
        <InputNewPassword email={email} otp={otp} onBack={() => setStep(2)} onNext={() => setStep(4)} />
      ) : step === 4 ? (
        <ModalAlertContent btText="OK" onConfirm={hideModalCB}>
          <div className="my-5 text-center text-lg">Change is successfully saved.</div>
          <div className="mb-5 text-center text-base">Please login with your new password.</div>
        </ModalAlertContent>
      ) : null}
    </BaseModal>
  );
};

const InputEmail = ({ onBack, onNext, email, setEmail }) => {
  const [error, setError] = useState('');
  const [fetching, setFetching] = useState(false);

  const _onNext = async () => {
    if (!email) {
      setError('Email is required');
      return;
    }
    if (!validateEmail(email)) {
      setError('Please input a valid email');
      return;
    }

    const body = {
      email,
      generate_for: 'forgot',
    };
    setFetching(true);
    const res = await ApiInstance.generateOtpCreatePassword(body);
    setFetching(false);

    const { error: apiError } = handleError(res);
    if (apiError) {
      setError(apiError.message);
      return;
    }
    onNext();
  };

  const _onChangeEmail = e => {
    setEmail(e.target.value);
    setError('');
  };

  return (
    <SimpleModalContent title="Forgot your password?" leftCB={onBack} fetchingRight={fetching} rightCB={_onNext}>
      Input your email address and we will help you to create your password
      <input
        className="mt-5 rounded-md border bg-transparent p-2 outline-none"
        value={email}
        placeholder="Email"
        onChange={_onChangeEmail}
      />
      {!!error && <div className="mt-2 text-red-600">{error}</div>}
    </SimpleModalContent>
  );
};

const VerifyOtp = ({ email, otp, setOtp, onBack, onNext }) => {
  const [error, setError] = useState('');
  const [fetching, setFetching] = useState(false);

  const _onNext = async () => {
    if (!otp) {
      setError('OTP is required');
      return;
    }

    const body = {
      email,
      otp,
    };
    setFetching(true);
    const res = await ApiInstance.verifyOtpCreatePassword(body);
    setFetching(false);
    const { error: apiError } = handleError(res);
    if (apiError) {
      setError(apiError.message);
      return;
    }
    onNext();
  };

  const _onChangeOtp = value => {
    setOtp(value);
    setError('');
  };

  return (
    <SimpleModalContent title="Verify OTP" leftCB={onBack} fetchingRight={fetching} rightCB={_onNext}>
      Input the OTP that we have sent to your email
      <OtpInput
        containerStyle="my-5 justify-center"
        inputStyle="!w-10 h-14 m-1 text-2xl text-center border-0 rounded-md bg-gray-100"
        value={otp}
        onChange={_onChangeOtp}
        numInputs={6}
        isInputNum
      />
      {!!error && <div className="mt-2 text-red-600">{error}</div>}
    </SimpleModalContent>
  );
};

const InputNewPassword = ({ onBack, email, otp, onNext }) => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [fetching, setFetching] = useState(false);

  const _onNext = async () => {
    if (!password) {
      setError('Password is required');
      return;
    }
    if (!confirmPassword) {
      setError('Confirm Password is required');
      return;
    }
    if (password !== confirmPassword) {
      setError('Password and Confirm Password must be the same');
      return;
    }
    if (!validatePasswordFormat(password)) {
      setError(
        'Password must contain minimum 8 characters, at least 1 uppercase letter, 1 lowercase letter and 1 number.',
      );
      return;
    }

    const body = {
      email,
      otp,
      password,
    };
    setFetching(true);
    const res = await ApiInstance.createPassword(body);
    setFetching(false);
    const { error: apiError } = handleError(res);
    if (apiError) {
      setError(apiError.message);
      return;
    }
    onNext();
  };

  const _onChangePassword = e => {
    setPassword(e.target.value);
    setError('');
  };

  const _onChangeConfirmPassword = e => {
    setConfirmPassword(e.target.value);
    setError('');
  };

  return (
    <SimpleModalContent title="Enter your new password" leftCB={onBack} fetchingRight={fetching} rightCB={_onNext}>
      Your Password must contain minimum 8 characters, at least 1 uppercase letter, 1 lowercase letter and 1 number.
      <input
        className="mt-5 rounded-md border bg-transparent p-2 outline-none"
        value={password}
        placeholder="Password"
        type="password"
        onChange={_onChangePassword}
      />
      <input
        className="mt-5 rounded-md border bg-transparent p-2 outline-none"
        value={confirmPassword}
        placeholder="Confirm Password"
        onChange={_onChangeConfirmPassword}
        type="password"
      />
      {!!error && <div className="mt-2 text-red-600">{error}</div>}
    </SimpleModalContent>
  );
};
