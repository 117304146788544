import React from 'react';
import { PuffLoader } from 'react-spinners';

export const Loading = ({ progress }: { progress?: number }) => {
  let textLoading = '';

  if (!progress) {
    textLoading = '';
  } else if (progress > 0 && progress < 100) {
    textLoading = 'LOADING';
  } else {
    textLoading = 'COMPLETED!';
  }

  return (
    <div className="fixed top-0 left-0 z-50 flex h-screen w-full flex-col items-center justify-center bg-white px-10">
      <div className="text-center text-3xl text-main-green">{textLoading}</div>
      {progress ? (
        <div className="mt-3 h-3 w-full rounded-full bg-gray-200">
          <div
            className="h-full rounded-full bg-main-green"
            style={{
              width: `${Math.min(progress, 100)}%`,
            }}
          />
        </div>
      ) : (
        <PuffLoader size={80} color="#aaa" />
      )}
    </div>
  );
};
