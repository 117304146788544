import { SimpleModal } from 'app/components/simple-modal';
import React from 'react';

export const ModalChangeVehicleRenewal = ({ isVisible, currentValue, confirmCB, hideModalCB }) => {
  return (
    <SimpleModal
      isVisible={isVisible}
      title="Confirmation of Action"
      leftCB={hideModalCB}
      rightCB={confirmCB}
      hideModalCB={hideModalCB}
    >
      <div>
        Are you sure you want to{' '}
        {!currentValue ? (
          <span className="font-bold text-main-green">turn on</span>
        ) : (
          <span className="font-bold text-red-500">turn off</span>
        )}{' '}
        the Auto-Renewal policy?
      </div>
    </SimpleModal>
  );
};
