import { ImageItemWithPreview } from 'app/context';
import React from 'react';

export function ClaimImageFieldPreview({
  listImages = [],
  subtitle,
  required,
  label,
}: {
  listImages: any;
  subtitle: string;
  required?: boolean;
  label: string;
}) {
  return (
    <div className="mb-3 last:mb-0">
      <div>
        <div className="flex items-center">
          <div className="flex-1">
            <div className="text-sm text-zinc-400">
              {label}
              {required ? <span className="text-red-600">*</span> : null}
            </div>
            <div className="text-xs text-zinc-400">{subtitle}&nbsp;</div>
          </div>
          {listImages?.length > 0 && (
            <div className="flex max-w-[45%] flex-col items-end overflow-hidden">
              <ImageItemWithPreview fileIcon="/img/smallIcon/eye.svg" showFileName={false} fileList={listImages} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
