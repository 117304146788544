import DayjsUtils from '@date-io/dayjs';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
//
import { DATE_FORMAT } from 'app/constants';
import clsx from 'clsx';
import dayjs from 'dayjs';
import type { FC } from 'react';
import React from 'react';

interface ClaimInputProps {
  title?: string;
  boldTitle?: boolean;
  required?: boolean;
  error?: string;
  children?: React.ReactNode;
  className?: string;
}
const ClaimInput: FC<ClaimInputProps> = ({ title, boldTitle, required, error, children, className }) => {
  return (
    <div className={className}>
      {title && (
        <label className={clsx('claim-input-title', boldTitle && 'font-bold')}>
          {title}
          {required ? <span className="text-red-600">*</span> : null}
        </label>
      )}
      {children}
      <div className="error-text">{error}&nbsp;</div>
    </div>
  );
};

interface ClaimBorderBottomLabelProps {
  title?: string;
  boldTitle?: boolean;
  disabled?: boolean;
  required?: boolean;
  error?: string;
  text?: string;
  value: string | undefined;
  onClick?: () => void;
}
export const ClaimBorderBottomLabel: FC<ClaimBorderBottomLabelProps> = ({
  title,
  boldTitle,
  disabled,
  required,
  error,
  text,
  value,
  onClick,
}) => {
  return (
    <div onClick={onClick}>
      {title && (
        <label className={clsx('claim-input-title', boldTitle && 'font-bold')}>
          {title}
          {required ? <span className="text-red-600">*</span> : null}
        </label>
      )}
      <div
        className={clsx('w-full p-1 text-sm text-zinc-900', disabled ? 'bg-gray-50' : 'border-b-2 border-b-zinc-200')}
      >
        {value || text}&nbsp;
      </div>
      <div className="error-text">{error}&nbsp;</div>
    </div>
  );
};

interface ClaimBorderBottomInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  title?: string;
  boldTitle?: boolean;
  required?: boolean;
  prefix?: string;
  error?: string;
  name: string;
  value?: string;
  className?: string;
  leftComp?: React.ReactNode;
}

export const ClaimBorderBottomInput: FC<ClaimBorderBottomInputProps> = ({
  title,
  boldTitle,
  required,
  prefix,
  error,
  name,
  value,
  className,
  leftComp,
  ...rest
}) => {
  return (
    <ClaimInput title={title} boldTitle={boldTitle} required={required} error={error} className={className}>
      <div className="flex items-center text-sm text-zinc-900">
        {prefix ? <div className="mr-1">{prefix}</div> : null}
        <input
          className="w-full flex-1 border-b-2 border-b-zinc-200 bg-transparent p-1 text-sm text-zinc-900 outline-none disabled:bg-gray-200"
          id={name}
          name={name}
          min="0"
          value={value || ''}
          {...rest}
        />
        {leftComp}
      </div>
    </ClaimInput>
  );
};

interface ClaimBorderBottomInputAreaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  title?: string;
  boldTitle?: boolean;
  required?: boolean;
  error?: string;
  name: string;
  rows?: number;
}
export const ClaimBorderBottomInputArea: FC<ClaimBorderBottomInputAreaProps> = ({
  title,
  boldTitle,
  required,
  error,
  name,
  rows = 2,
  ...rest
}) => {
  return (
    <ClaimInput title={title} boldTitle={boldTitle} required={required} error={error}>
      <textarea
        className="w-full border-b-2 border-b-zinc-200 bg-transparent p-1 text-sm text-zinc-900 outline-none"
        id={name}
        rows={rows}
        name={name}
        {...rest}
      />
    </ClaimInput>
  );
};

interface ClaimBorderBottomDateInputProps {
  title?: string;
  boldTitle?: boolean;
  required?: boolean;
  error?: string;
  onChange: (e: {
    target: {
      name: string;
      value: string;
    };
  }) => void;
  name: string;
  value: Date;
  maxDate?: string;
  minDate?: string;
  disabled?: boolean;
  placeholder?: string;
}
export const ClaimBorderBottomDateInput: FC<ClaimBorderBottomDateInputProps> = ({
  title,
  boldTitle,
  required,
  error,
  onChange,
  name,
  value,
  maxDate,
  minDate,
  disabled,
  placeholder,
  ...rest
}) => {
  const _onChange = changeValue => {
    onChange({
      target: {
        name,
        value: dayjs(changeValue).format(DATE_FORMAT.serverDate),
      },
    });
  };
  return (
    <ClaimInput title={title} boldTitle={boldTitle} required={required} error={error}>
      <div className="relative flex items-center">
        <img className="absolute right-0" src="/img/smallIcon/calendar.svg" width={20} alt="calendar" />
        <MuiPickersUtilsProvider utils={DayjsUtils}>
          <DatePicker
            className="w-full"
            value={value || null}
            minDate={minDate}
            maxDate={maxDate}
            disabled={disabled}
            emptyLabel={placeholder}
            format={DATE_FORMAT.date}
            InputProps={{
              disableUnderline: true,
              disabled,
              classes: {
                root: 'border-b-2 border-b-zinc-200 !text-sm',
                disabled: 'bg-stone-50',
              },
            }}
            onChange={_onChange}
            {...rest}
          />
        </MuiPickersUtilsProvider>
      </div>
    </ClaimInput>
  );
};

interface ClaimBorderBottomSelectInputProps {
  title?: string;
  boldTitle?: boolean;
  required?: boolean;
  error?: string;
  name: string;
  value?: string;
  disabled?: boolean;
  onChangeValue?: (value: string, name: string) => void;
  onChange?: ((e: React.ChangeEvent<HTMLSelectElement>) => void) | ((e: { name: string; value: any }) => void);
  options: { value: string; label: string }[];
  placeholder?: string;
}
export const ClaimBorderBottomSelectInput: FC<ClaimBorderBottomSelectInputProps> = ({
  title,
  name,
  boldTitle,
  required,
  value,
  disabled,
  onChangeValue,
  onChange,
  options,
  placeholder,
  error,
}) => {
  const _onChange = e => {
    if (onChangeValue) onChangeValue(e.target.value, name);
    // @ts-ignore
    if (onChange) onChange({ target: { value: e.target.value, name } });
  };

  return (
    <ClaimInput title={title} boldTitle={boldTitle} required={required} error={error}>
      <Select
        value={value || ''}
        disabled={disabled}
        displayEmpty
        onChange={_onChange}
        className="w-full border-b-2 border-b-zinc-200 py-1"
        classes={{
          selectMenu: '!font-workSans !text-sm text-zinc-900',
        }}
        disableUnderline
      >
        {placeholder && (
          <MenuItem className="font-workSans" value="" disabled>
            {placeholder}
          </MenuItem>
        )}
        {options.map(item => (
          <MenuItem className="font-workSans" key={item.value} value={item.value}>
            {item?.label}
          </MenuItem>
        ))}
      </Select>
    </ClaimInput>
  );
};
