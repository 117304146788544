import { toast } from 'react-hot-toast';

export function handleError<T = any>(response, showToast?: boolean) {
  let error: any = null;
  let result: T | undefined;
  if (response.ok) {
    if (response.status === 200 || response.status === 201) {
      result = response?.data;
      // //console.log('result ',result)
    } else {
      error = {
        value: response.problem,
        code: response.status,
        message: response?.data?.message || response?.data?.error_msg,
        data: response.data,
      };
      if (showToast) toast.error(error.message);
    }
  } else {
    const { data } = response;
    const { errors } = data || {};
    const { fields, error_message, error_code } = errors || {};

    error = {
      value: response.problem,
      code: response.status,
      message: getErrorMessage(error_message, fields),
      error_code,
      data: response.data,
    };
    if (showToast) toast.error(error.message);
  }
  return { result, error };
}

export const getErrorMessage = (error_message, fields) => {
  let message = error_message || '';
  if (fields) {
    Object.keys(fields).forEach(key => {
      const replaceText = key === 'vehicle_reg' ? fields[key]?.toUpperCase() : fields[key];
      message = message.replace(`{{${key}}}`, replaceText);
    });
  }
  return message;
};
