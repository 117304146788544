import { FilterCheckBox, SearchFilterBar } from 'app/components/search-filter-bar';
import { CLAIM_STATUS } from 'app/constants';
import React, { useMemo } from 'react';

export const StatusCell = ({ status }) => {
  const { color, colorSec, text } = useMemo(() => {
    switch (status) {
      case 'reported':
        return {
          color: '#F2C94C',
          colorSec: '#FFE8A5',
          text: 'Claim Reported',
        };
      case CLAIM_STATUS.INCOMPLETED:
        return {
          color: '#F2C94C',
          colorSec: '#FFE8A5',
          text: 'Claim Pending',
        };
      case 'rejected':
        return {
          color: '#EB5757',
          colorSec: '#F0C4C5',
          text: 'Claim Rejected',
        };
      case 'duplicate':
        return {
          color: '#EB5757',
          colorSec: 'rgba(235, 87, 87, 0.25)',
          text: 'Claim Is A Duplicate',
        };
      case 'info':
        return {
          color: '#DE1B77',
          colorSec: 'rgba(222, 27, 119, 0.25)',
          text: 'Requiring Further Information',
        };
      case 'processing':
        return {
          color: '#F2994A',
          colorSec: 'rgba(242, 153, 74, 0.25)',
          text: 'Claim Is Being Reviewed',
        };
      case 'processed':
        return {
          color: '#2F80ED',
          colorSec: 'rgba(47, 128, 237, 0.25)',
          text: 'Submitted To Insurer',
        };
      case 'approved':
        return {
          color: '#BB6BD9',
          colorSec: 'rgba(187, 107, 217, 0.25)',
          text: 'Claim Approved',
        };
      case 'insurer payout ready':
        return {
          color: '#BB6BD9',
          colorSec: 'rgba(187, 107, 217, 0.25)',
          text: 'Claim Approved',
        };
      case 'insurer payout done':
        return {
          color: '#BB6BD9',
          colorSec: 'rgba(187, 107, 217, 0.25)',
          text: 'Claim Approved',
        };
      case 'payout ready':
        return {
          color: '#BB6BD9',
          colorSec: 'rgba(187, 107, 217, 0.25)',
          text: 'Claim Approved',
        };
      case 'paid':
        return {
          color: '#51BD8A',
          colorSec: 'rgba(81, 189, 138, 0.25)',
          text: 'Claim Paid',
        };
      default:
        return {};
    }
  }, [status]);
  return (
    <div className="flex items-center">
      <div className="flex h-5 w-5 items-center justify-center rounded-full" style={{ backgroundColor: colorSec }}>
        <div className="h-2 w-2 rounded-full" style={{ backgroundColor: color }} />
      </div>
      <div className="ml-2 w-32 whitespace-normal text-left text-sm">{text}</div>
    </div>
  );
};

export const ClaimTrackerSearchBar = ({ searchText, onChangeSearchText, searchParams, updateSearchParams }) => {
  const _onItemClick = item => {
    updateSearchParams({ status: searchParams?.status === item.value ? '' : item.value });
  };

  return (
    <SearchFilterBar searchString={searchText} onChangeSearchString={onChangeSearchText}>
      <div className="text-sm">By Status</div>
      <div className="flex flex-col gap-2">
        {STATE_LIST.map(item => (
          <FilterCheckBox item={item} currentValue={searchParams?.status} key={item.value} onClick={_onItemClick} />
        ))}
      </div>
    </SearchFilterBar>
  );
};

const STATE_LIST = [
  { value: 'reported', label: 'Claim Reported' },
  { value: 'rejected', label: 'Claim Rejected' },
  { value: 'duplicate', label: 'Claim Is A Duplicate' },
  { value: 'info', label: 'Requiring Further Information' },
  { value: 'processing', label: 'Claim Is Being Reviewed' },
  { value: 'processed', label: 'Submitted To Insurer' },
  { value: 'approved', label: 'Claim Approved' },
  { value: 'paid', label: 'Claim Paid' },
];
