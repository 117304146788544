import { ApiInstance } from 'app/services/api';
import { handleError } from 'app/services/apiHelper';
import { useEffect, useState } from 'react';

export const useClaimDetail = claimId => {
  const [claimDetail, setClaimDetail] = useState<any>({});
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    _getClaimDetail();
  }, []);

  const _getClaimDetail = async () => {
    setFetching(true);
    const res = await ApiInstance.getVehicleClaimDetail(claimId);
    setFetching(false);
    const { error, result } = handleError(res, true);
    if (!error) {
      const { data } = result || {};
      const { claim_details, claim_files } = data || {};
      setClaimDetail({ ...data, fields: { ...claim_details, ...claim_files } });
    }
  };

  return { claimDetail, fetching };
};
