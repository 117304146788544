import { EXCEL_FIELDS_TO_KEY } from 'app/constants';
import { ERROR_WHEN_OVER_MID_DAY, getMinDate } from 'app/general';
import { getErrorMessage } from 'app/services/apiHelper';
import { getMomentFromNumberDateValue } from 'app/utilities/transformData';
import { validateVehicleReg, validateVehicleType } from 'app/utilities/validates';
import dayjs from 'dayjs';

export const convertCsvData = (dataTable: CsvConvertedData[], pricingList, listUnits) => {
  const listUploadVehicles: any = [];
  const warnings: WarningObject[] = [];
  let hasWarning = false;

  const pricingListFlag: any = {};
  pricingList.forEach(item => {
    const { config_objs } = item || {};
    if (!config_objs) return;
    const vehicleType = config_objs.vehicle_type?.toLowerCase();
    if (vehicleType) pricingListFlag[vehicleType] = true;
  });

  dataTable.forEach(item => {
    const haveVehicleType = !!pricingListFlag[item.vehicle_type?.value?.toLowerCase()];
    const findPricing = haveVehicleType
      ? pricingList.find(
          findItem =>
            findItem.config_objs.section1excess === stringToNumber(item.section1excess?.value) &&
            findItem.config_objs.section2excess === stringToNumber(item.section2excess?.value) &&
            findItem.config_objs.combined_excess === stringToNumber(item.combined_excess?.value) &&
            findItem.config_objs.reduced_combined_excess === stringToNumber(item.reduced_excess?.value) &&
            findItem.config_objs.vehicle_type === item.vehicle_type?.value?.toLowerCase(),
        )
      : undefined;
    const findUnit = findUnitId(item.unit?.value, listUnits);
    listUploadVehicles.push({
      vehicle_reg: item.vehicle_reg?.value?.toUpperCase(),
      company_uen: item.company_uen?.value?.toUpperCase(),
      // company_name: item['Company Name']?.value,
      // contact_num: item['Contact No.']?.value,
      contact_email: item.contact_email?.value?.toLowerCase(),
      make: item.make?.value,
      model: item.model?.value,
      chassis: item.chassis?.value,
      engine: item.engine?.value,
      section1excess: item.section1excess?.value,
      section2excess: item.section2excess?.value,
      combined_excess: item.combined_excess?.value,
      reduced_excess: item.reduced_excess?.value,
      vehicle_type: item.vehicle_type?.value?.toLowerCase(),
      auto_renewal:
        findUnit === listUnits?.days?.id
          ? false
          : item.auto_renewal?.value?.toLowerCase() !== 'no' && item.auto_renewal?.value?.toLowerCase() !== 'off',
      contract_start: dayjs(item.start_date?.value).format('YYYY-MM-DD'),
      contract_end: item.end_date?.value ? dayjs(item.end_date?.value).format('YYYY-MM-DD') : undefined,
      unit_id: findUnit,
      premium_no_gst: findPricing?.price_details?.unit_price,
      premium: findPricing?.price_details?.price_after_tax,
      pricingObject: findPricing,
    });

    if (!findPricing || !findUnit || !haveVehicleType) hasWarning = true;

    warnings.push({
      vehicle_type: !haveVehicleType ? 'No pricing found for this vehicle type' : undefined,
      pricing: haveVehicleType && !findPricing ? 'No pricing found for this vehicle' : undefined,
      unit: !findUnit ? 'No unit found for this vehicle' : undefined,
      hasWarning: !findPricing || !findUnit || !haveVehicleType,
    });
  });
  return { listUploadVehicles, warnings, hasWarning };
};

const findUnitId = (value, listUnits) => {
  if (!value) return listUnits?.months?.id;
  switch (value?.toLowerCase()) {
    case 'weekly':
      return listUnits?.weeks?.id;
    case 'monthly':
      return listUnits?.months?.id;
    case 'quarterly':
      return listUnits?.quarters?.id;
    case 'halfyearly':
      return listUnits?.halfyears?.id;
    case 'yearly':
      return listUnits?.years?.id;
    case 'daily':
      return listUnits?.days?.id;
    default:
      return null;
  }
};

const stringToNumber = (value: string) => {
  const number = Number(value);
  return Number.isNaN(number) ? 0 : number;
};

type DataObject = {
  value: any;
  error?: string;
};
export interface CsvConvertedData {
  vehicle_reg: DataObject;
  company_uen: DataObject;
  contact_email: DataObject;
  make: DataObject;
  model: DataObject;
  chassis: DataObject;
  engine: DataObject;
  section1excess: DataObject;
  section2excess: DataObject;
  combined_excess: DataObject;
  reduced_excess: DataObject;
  vehicle_type: DataObject;
  auto_renewal: DataObject;
  contract_start: DataObject;
  contract_end?: DataObject;
  start_date: DataObject;
  end_date?: DataObject;
  unit?: DataObject;

  hasError: boolean;
  generalError: string;
  warnings?: WarningObject;
}
export const onTransformFile = (
  files,
  {
    triberCarOrTreeDotsFleet,
    hasPayLater,
  }: {
    triberCarOrTreeDotsFleet: boolean;
    hasPayLater: boolean;
  },
) => {
  // data reading from csv will have this format ( first row is headers )
  // [[headers], [data], [data], [data] ...]
  // transform into readable format
  // [ { [headerKey]: dataField, ... }, ... ]

  const data: CsvConvertedData[] = [];

  for (let i = 1; i < files.length; i += 1) {
    const file = files[i];
    for (let j = 0; j < file.length; j += 1) {
      const header = files[0][j];
      const key = EXCEL_FIELDS_TO_KEY[header];
      if (key) {
        if (!data[i - 1]) data[i - 1] = {} as CsvConvertedData;
        const baseValue = file[j];
        const value =
          key === 'start_date' || key === 'end_date' || key === 'contract_start' || key === 'contract_end'
            ? getMomentFromNumberDateValue(baseValue)
            : baseValue;
        data[i - 1][key] = { value };
      }
    }
  }

  for (let i = 0; i < data.length; i += 1) {
    const item = data[i];
    if (!item.combined_excess?.value) {
      if (!item.section1excess?.value && !item.section2excess?.value) {
        item.section1excess = { value: undefined, error: 'Section 1 Excess is required' };
        item.section2excess = { value: undefined, error: 'Section 2 Excess is required' };
      }
      if (Number.isNaN(item.section1excess?.value)) item.section1excess.error = 'Invalid value';
      else if (Number.isNaN(item.section2excess?.value)) item.section2excess.error = 'Invalid value';
    } else if (Number.isNaN(item.combined_excess?.value)) item.combined_excess.error = 'Invalid value';

    if (!item.reduced_excess?.value) item.reduced_excess = { value: undefined, error: 'Reduce Excess is required' };
    else if (Number.isNaN(item.reduced_excess?.value)) item.reduced_excess.error = 'Invalid value';

    if (!item.start_date?.value) {
      if (item.contract_start?.value) item.start_date = { value: item.contract_start?.value };
      else item.start_date = { value: undefined, error: 'Start date is required' };
    }
    if (
      // check type of value is DayJS
      !dayjs(item.start_date?.value).isValid() ||
      dayjs(item.start_date?.value).isBefore(getMinDate(hasPayLater), 'd')
    ) {
      // if start date is today
      if (dayjs(item.start_date?.value).isSame(dayjs(), 'd')) item.start_date.error = ERROR_WHEN_OVER_MID_DAY;
      else item.start_date.error = 'Invalid start date';
    }

    if (!item.end_date?.value) {
      if (item.contract_end?.value) item.end_date = { value: item.contract_end?.value };
      else item.end_date = { value: undefined, error: 'Start date is required' };
    }
    if (item.unit?.value?.toLowerCase() === 'daily') {
      if (
        !dayjs(item.end_date?.value).isValid() ||
        (dayjs(item.start_date?.value).isValid() && dayjs(item.end_date?.value).isBefore(item.start_date?.value, 'd'))
      )
        item.end_date.error = 'Invalid end date';
    } else {
      item.end_date = { value: undefined };
    }

    if (!validateVehicleReg(item.vehicle_reg?.value)) item.vehicle_reg.error = 'Invalid vehicle registration number';
    if (!validateVehicleType(item.vehicle_type?.value)) item.vehicle_reg.error = 'Invalid vehicle type';

    const autoRenewalDefaultValue = triberCarOrTreeDotsFleet ? 'OFF' : 'ON';
    const autoRenewalValue = item.auto_renewal?.value || autoRenewalDefaultValue;
    item.auto_renewal = {
      value: item.unit?.value?.toLowerCase() === 'daily' ? 'OFF' : autoRenewalValue,
    };

    item.hasError = !!Object.values(item).find(value => value.error);
  }

  return {
    data,
    hasError: !!data.some(item => item.hasError),
  };
};

export interface WarningObject {
  vehicle_reg?: string;
  company_uen?: string;
  contact_email?: string;
  make?: string;
  model?: string;
  chassis?: string;
  engine?: string;
  section1excess?: string;
  section2excess?: string;
  combined_excess?: string;
  reduced_excess?: string;
  vehicle_type?: string;
  auto_renewal?: string;
  contract_start?: string;
  contract_end?: string;
  start_date?: string;
  end_date?: string;
  unit?: string;
  pricing?: string;
  generalError?: string;

  hasWarning: boolean;
}

export const extractWarning = warningsInput => {
  if (!warningsInput)
    return {
      warnings: [],
      hasWarning: false,
    };
  const result: WarningObject[] = [];
  let hasWarning = false;
  Object.keys(warningsInput).forEach(warningKey => {
    const warningRow = warningsInput[warningKey];
    if (!warningRow) return;
    const index = +warningKey;
    result[index] = {} as WarningObject;
    hasWarning = true;
    Object.keys(warningRow).forEach(key => {
      const { error_message, fields } = warningRow[key];
      result[index][key] = `${getErrorMessage(error_message, fields)}`;
      result[index].hasWarning = true;
    });
  });
  return { warnings: result, hasWarning };
};
