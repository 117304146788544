import React from 'react';
import { Link } from 'react-router-dom';

export const BreadCrumbs = ({ crumbs }) => {
  return (
    <div className="text-base text-gray-400">
      {crumbs.map((crumb, index) => (
        <span key={index}>
          {crumb.to ? <Link to={crumb.to}>{crumb.label}</Link> : crumb.label}
          {index < crumbs.length - 1 && <span> / </span>}
        </span>
      ))}
    </div>
  );
};
