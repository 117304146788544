import { validateNricFin } from 'app/utils';

export const MESSAGES = {
  THIS_FIELD_IS_REQUIRED: 'This field is required',
  FIELD_NAME_IS_INVALID: 'This field is invalid',
  COMPANY_ID_NOT_FOUND: 'company_id_not_found',
  NRICFIN_EXISTED: 'nricfin_existed',
  NRICFIN_EXISTED_KYC: 'nricfin_existed_kyc',
  EMAIL_EXISTED: 'email_existed',
  PHONE_EXISTED: 'phone_existed',
  UNKNOWN_ERROR: 'unknown_message_error',
  LOAN_PENDING: 'loan_pending',
  LOAN_EXISTED: 'loan_existed',
  HCP_PA_SUBMITTED: 'hcp_pa_submitted',
  USER_NOT_IN_GROUP: 'user_not_in_group',
  POLICY_BEFORE_START_ERROR: 'policy_start_cannot_be_before_today',
  NOT_CONTAINS_SPECIAL_CHARACTER: 'Field contains special character',
  PROMOCODE_NOT_FOUND: 'invalid_ref_code',
  PROMOCODE_REDEEMED: 'procode_redeemed',
  POLICY_EXISTING:
    'You have already purchased a policy which starts on {{policy_start}}. Please select a policy after {{policy_end}}',
  PRODUCT_IS_NOT_SPONSORE: 'company_id_not_found',
  MOBILE_EXISTED: 'This mobile number {{mobile}} already exists in system, please use a different mobile number',
  SIGN_UP_EMAIL_EXISTING: 'The {{email}} already existed.',
  SIGN_UP_EMAIL_EXISTING_FAST_GIG: 'The user associated with this email has already completed this form.',
  SIGN_UP_EMAIL_NOT_SPONSORE: 'User not found',
  SIGN_UP_EMAIL_NOT_SPONSORE_FAST_GIG: 'Please enter your FastGig-registered email!',
  ACCOUNT_NOT_AVAILABLE_IN_REGION: 'Your account is not available in this region',
  USER_IS_NOT_REGISTERED:
    'You’re not a registered Gig Worker with us yet. Please contact Gigacover at {{email}} for more information',
  PASSWORD_IS_NOT_CORRECT: 'You have keyed in an invalid password. Please try again or reset your password.',
  USER_IS_NOT_IN_GROUP: 'You are not belong to this group.',
  YOU_HAVE_ALREADY_BEEN_ONBOARDED: 'you_have_already_been_onboarded',
  YOUR_CURRENT_ACCOUNT_IS_REGISTERED: 'your_current_account_is_registered',
  UPDATE_CARD_ERROR: 'Your card was declined',
  NRICFIN_NOT_MATCH:
    'Your input does not match existing NRIC data associated with this email address. Please try asgain and contact customer service for help if the error persists.',
};

/**
 * check if the value is required.
 * @param {any} value
 * @returns {string} error key for i18n translated.
 */
export function getRequireValueErrorMessage(value) {
  if ((typeof value === 'string' && !value?.trim?.()) || !value) {
    // text only contains space.
    return MESSAGES.THIS_FIELD_IS_REQUIRED;
  }
  return '';
}

/**
 * Function that get message key error for nricfin.
 * @param nricfin
 * @param required
 * @returns {string}
 */
export function getNricfinErrorMessage(nricfin, required = true) {
  if (nricfin) {
    return validateNricFin(nricfin) ? '' : MESSAGES.FIELD_NAME_IS_INVALID;
  }
  return required ? MESSAGES.THIS_FIELD_IS_REQUIRED : '';
}
