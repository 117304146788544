import { ClaimBorderBottomInput, ClaimBorderBottomLabel, SimpleModal } from 'app/components';
import dayjs from 'dayjs';
import type { ReactNode } from 'react';
import React, { useEffect, useState } from 'react';
import { PuffLoader } from 'react-spinners';

export const ModalChangeAutoRenewal = ({ data, confirmCB, hideModalCB }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [autoRenewal, setAutoRenewal] = useState(false);

  useEffect(() => {
    if (data) {
      setIsVisible(true);
      setAutoRenewal(data?.value);
    } else {
      setIsVisible(false);
    }
  }, [data]);

  return (
    <SimpleModal
      isVisible={isVisible}
      title="Confirmation of Action"
      leftCB={hideModalCB}
      rightCB={confirmCB}
      hideModalCB={hideModalCB}
    >
      <div>
        Are you sure you want to{' '}
        {autoRenewal ? (
          <span className="font-bold text-main-green">turn on</span>
        ) : (
          <span className="font-bold text-red-500">turn off</span>
        )}{' '}
        the Auto-Renewal policy?
      </div>
    </SimpleModal>
  );
};

export const ModalChangePolicyStart = ({ data, confirmCB, hideModalCB }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [policyStart, setPolicyStart] = useState();

  useEffect(() => {
    if (data) {
      setIsVisible(true);
      setPolicyStart(data?.value);
    } else {
      setIsVisible(false);
    }
  }, [data]);

  return (
    <SimpleModal
      isVisible={isVisible}
      title="Confirmation of Action"
      leftCB={hideModalCB}
      rightCB={confirmCB}
      hideModalCB={hideModalCB}
    >
      <div>
        Are you sure you want to change date to{' '}
        {policyStart ? (
          <span className="font-bold text-main-green">{dayjs(policyStart).format('DD/MM/YYYY')}</span>
        ) : null}{' '}
        ?
      </div>
    </SimpleModal>
  );
};

export const ModalChangePolicyStatus = ({ data, confirmCB, hideModalCB }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [refNum, setRefNum] = useState('');

  useEffect(() => {
    if (data) {
      setIsVisible(true);
      setRefNum(data?.payment_ref || '');
    } else {
      setIsVisible(false);
    }
  }, [data]);

  function _onConfirm() {
    confirmCB(refNum);
  }

  function _onChangePolicy(e) {
    setRefNum(e.target.value);
  }

  return (
    <SimpleModal
      title="Confirm Payment"
      isVisible={isVisible}
      rightBtText="Confirm"
      rightCB={_onConfirm}
      leftCB={hideModalCB}
      hideModalCB={hideModalCB}
    >
      <ClaimBorderBottomLabel disabled title="Status" value="paid" />
      <ClaimBorderBottomLabel disabled title="Payment Method" value="PayNow(QR)" />
      <ClaimBorderBottomInput
        name="reference_number"
        title="Reference Number"
        disabled={data?.payment_ref}
        value={refNum}
        onChange={_onChangePolicy}
      />
    </SimpleModal>
  );
};

export const ModalLoadingContent = ({ text }) => {
  return (
    <div className="flex h-56 flex-col items-center justify-around">
      <PuffLoader size={70} color="#51BD8A" />
      <div className="text-base">{text}</div>
    </div>
  );
};

export const ModalAlertContent = ({
  mode = 'success',
  children,
  btText,
  onConfirm,
}: {
  mode?: 'success' | 'fail';
  children?: ReactNode;
  btText?: string;
  onConfirm?: () => void;
}) => {
  return (
    <div className="flex flex-col items-center">
      {mode === 'success' ? (
        <img className="w-36" src="/img/smallIcon/check-green.svg" />
      ) : mode === 'fail' ? (
        <img className="w-36" src="/img/smallIcon/cross-red.svg" />
      ) : null}
      {children}
      <button className="rounded-lg bg-main-green px-3 py-1 text-white" onClick={onConfirm}>
        {btText}
      </button>
    </div>
  );
};
