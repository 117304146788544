export const SERVICE_NAMES = {
  auth: 'auth',
  api: 'api',
};

const _SERVICES_CONFIG = {
  [SERVICE_NAMES.auth]: {
    host: process.env.AUTH_API_URL,
    basePath: '/',
    apiPath: '',
  },
  [SERVICE_NAMES.api]: {
    host: process.env.ATLAS_API_URL,
    basePath: '/v2',
    apiPath: '',
  },
};

const OVERVIEW_FIELDS = {
  VEHICLE_REG: 'vehicle_reg',
  AUTO_RENEWAL: 'auto_renewal',
  AUTO_RENEWAL_UPDATE: 'auto_renewal_update',
  CONTRACT_START: 'contract_start',
  CONTRACT_END: 'contract_end',
  POLICY_NUMBER: 'policy_number',
  POLICY_START: 'policy_start',
  POLICY_START_UPDATE: 'policy_start_update',
  POLICY_END: 'policy_end',
  CURRENT_STATUS: 'status',
  NEXT_START_DATE: 'start_date',
  NEXT_STATUS: 'next_status',
};

const POLICIES_FIELDS = {
  POLICY_NUMBER: 'policy_number',
  POLICY_START: 'policy_start',
  POLICY_END: 'policy_end',
  STATUS: 'status',
};

export const API_CONFIG = {
  isLoggingEnable: false,
  timeout: 10000,
  useDummyData: false,
  unauthorizedErrorCode: 401,
  services: _SERVICES_CONFIG,
};

const EXCEL_FIELDS = {
  COMPANY_UEN: 'Company UEN',
  COMPANY_NAME: 'Company Name',
  CONTACT_NO: 'Contact No.',
  CONTACT_EMAIL: 'Contact Email',
  VEHICLE_REGISTRATION_NO: 'Vehicle Registration No.',
  VEHICLE_MAKE: 'Vehicle Make',
  VEHICLE_MODEL: 'Vehicle Model',
  VEHICLE_CHASSIS_NO: 'Vehicle Chassis No.',
  VEHICLE_ENGINE_NO: 'Vehicle Engine No.',
  VEHICLE_TYPE: 'Vehicle Type',
  SECTION_1_EXCESS: 'Section1 Excess',
  SECTION_2_EXCESS: 'Section2 Excess',
  COMBINED_EXCESS: 'Combined Excess',
  REDUCED_EXCESS: 'Reduced Excess To',
  CONTRACT_START_DATE: 'Contract Start Date',
  CONTRACT_END_DATE: 'Contract End Date',
  START_DATE: 'Start Date',
  END_DATE: 'End Date',
  AUTO_RENEWAL: 'Auto Renewal',
  UNIT: 'Unit',
};
export const EXCEL_FIELDS_TO_KEY = {
  // [EXCEL_FIELDS.COMPANY_UEN]: 'company_uen',
  // [EXCEL_FIELDS.COMPANY_NAME]: 'company_name',
  // [EXCEL_FIELDS.CONTACT_NO]: 'contact_num',
  // [EXCEL_FIELDS.CONTACT_EMAIL]: 'contact_email',
  [EXCEL_FIELDS.VEHICLE_REGISTRATION_NO]: 'vehicle_reg',
  [EXCEL_FIELDS.VEHICLE_MAKE]: 'make',
  [EXCEL_FIELDS.VEHICLE_MODEL]: 'model',
  [EXCEL_FIELDS.VEHICLE_CHASSIS_NO]: 'chassis',
  [EXCEL_FIELDS.VEHICLE_ENGINE_NO]: 'engine',
  [EXCEL_FIELDS.VEHICLE_TYPE]: 'vehicle_type',
  [EXCEL_FIELDS.SECTION_1_EXCESS]: 'section1excess',
  [EXCEL_FIELDS.SECTION_2_EXCESS]: 'section2excess',
  [EXCEL_FIELDS.COMBINED_EXCESS]: 'combined_excess',
  [EXCEL_FIELDS.REDUCED_EXCESS]: 'reduced_excess',
  [EXCEL_FIELDS.CONTRACT_START_DATE]: 'contract_start',
  [EXCEL_FIELDS.CONTRACT_END_DATE]: 'contract_end',
  [EXCEL_FIELDS.START_DATE]: 'start_date',
  [EXCEL_FIELDS.END_DATE]: 'end_date',
  [EXCEL_FIELDS.UNIT]: 'unit',
  [EXCEL_FIELDS.AUTO_RENEWAL]: 'auto_renewal',
};

export const KEY_TO_EXCEL_FIELDS = {
  company_uen: EXCEL_FIELDS.COMPANY_UEN,
  company_name: EXCEL_FIELDS.COMPANY_NAME,
  contact_num: EXCEL_FIELDS.CONTACT_NO,
  contact_email: EXCEL_FIELDS.CONTACT_EMAIL,
  vehicle_reg: EXCEL_FIELDS.VEHICLE_REGISTRATION_NO,
  make: EXCEL_FIELDS.VEHICLE_MAKE,
  model: EXCEL_FIELDS.VEHICLE_MODEL,
  chassis: EXCEL_FIELDS.VEHICLE_CHASSIS_NO,
  engine: EXCEL_FIELDS.VEHICLE_ENGINE_NO,
  vehicle_type: EXCEL_FIELDS.VEHICLE_TYPE,
  section1excess: EXCEL_FIELDS.SECTION_1_EXCESS,
  section2excess: EXCEL_FIELDS.SECTION_2_EXCESS,
  combined_excess: EXCEL_FIELDS.COMBINED_EXCESS,
  reduced_excess: EXCEL_FIELDS.REDUCED_EXCESS,
  contract_start: EXCEL_FIELDS.CONTRACT_START_DATE,
  contract_end: EXCEL_FIELDS.CONTRACT_END_DATE,
  auto_renewal: EXCEL_FIELDS.AUTO_RENEWAL,
};

export const APP_CONFIG = {
  logoBranch: 'gigacover',
  reducerPrefix: 'admin-sip',
  stylePrefix: 'admin-sip',
  format: {
    date: 'DD/MM/YYYY',
    // server accpet date format 'YYYY-MM-DD'
    serverDate: 'YYYY-MM-DD',
  },
  policies: {
    CONFIRMED: 'confirmed_with_policy_num',
    PAID: 'paid',
    DRAFT: 'draft',
    CONTRACT: 'CONTRACT',
    PREPARED: 'prepared',
    APPROVED: 'approved',
    IN_FORCE: 'in_force',
    RENEWING_SOON: 'renewing_soon',
    EXPIRING_SOON: 'expiring_soon',
    PENDING: 'pending',
    FIELDS: POLICIES_FIELDS,
  },
  customClass: {
    CONFIRMED: 'item-timeline-confirm flex-row-center',
    PAID: 'item-timeline-next flex-row-center',
    DRAFT: 'item-timeline-mer flex-row-center',
    CONTRACT: 'item-timeline flex-row-center',
  },
  uploadCSV: {
    FIELDS: EXCEL_FIELDS,
    VALIDATE_FIELDS: [
      // EXCEL_FIELDS.COMPANY_UEN,
      // EXCEL_FIELDS.COMPANY_NAME,
      // EXCEL_FIELDS.CONTACT_NO,
      // EXCEL_FIELDS.CONTACT_EMAIL,
      EXCEL_FIELDS.VEHICLE_REGISTRATION_NO,
      EXCEL_FIELDS.VEHICLE_MAKE,
      EXCEL_FIELDS.VEHICLE_MODEL,
      EXCEL_FIELDS.VEHICLE_CHASSIS_NO,
      EXCEL_FIELDS.VEHICLE_ENGINE_NO,
      EXCEL_FIELDS.VEHICLE_TYPE,
      EXCEL_FIELDS.SECTION_1_EXCESS,
      EXCEL_FIELDS.SECTION_2_EXCESS,
      // EXCEL_FIELDS.COMBINED_EXCESS,
      EXCEL_FIELDS.REDUCED_EXCESS,
    ],
  },
  approve: {
    term: 'By clicking Approve, I allow Gigacover to automatically confirm the renewals of policies for vehicles with Auto Renewal ON, for the Contract Period I have declared. I understand that I can stop Auto Renewal for a vehicle 3 days or more before the start of a policy.',
  },
  overview: {
    allVehicleExits:
      'All vehicles already exist. No vehicles were added. Should you have any problems, please contact hey@gigacover.com',
    apiCallFailed:
      'Unable to perform action. If this issue persists, please contact hey@gigacover.com. We apologize for the inconvenience caused.',
    DEFAULT_PAGE_SIZE: 5,
    FIELDS: OVERVIEW_FIELDS,
    DATE_FIELDS: [
      OVERVIEW_FIELDS.CONTRACT_START,
      OVERVIEW_FIELDS.CONTRACT_END,
      OVERVIEW_FIELDS.POLICY_START,
      OVERVIEW_FIELDS.POLICY_END,
    ],
    statusButton: {
      prepared: {
        btnName: 'PREPARED',
        btnClass: 'btn-prepared',
      },
      draft: {
        btnName: 'DRAFT',
        btnClass: 'btn-draft',
      },
      approved: {
        btnName: 'APPROVED',
        btnClass: 'btn-approved',
      },
      in_force: {
        btnName: 'IN-FORCE',
        btnClass: 'btn-confirmed',
      },
      paid: {
        btnName: 'PAID',
        btnClass: 'btn-paid',
      },
      expired: {
        btnName: 'EXPIRED',
        btnClass: 'btn-expired',
      },
      pending: {
        btnName: 'PENDING',
        btnClass: 'btn-expired',
      },
      renewing_soon: {
        btnName: 'RENEWING SOON',
        btnClass: 'btn-renewing-soon',
      },
      expiring_soon: {
        btnName: 'EXPIRING SOON',
        btnClass: 'btn-expiring-soon',
      },
      cancelled: {
        btnName: 'CANCELED',
        btnClass: 'btn-expired',
      },
    },
  },
};
export const TAX_FEE = 130.43;

export const PAYMENT_METHOD = {
  CARD: 'card',
  PAYNOW: 'paynow',
  SPONSOR: 'sponsor',
};

export const ROLE = {
  SUPER_ADMIN: 'super_admin',
  ADMIN: 'admin',
};

export const GROUP = {
  GIGACOVER: 'gigacover',
};

export const COMPANY_UEN = '201619282D';
export const AUTO_RENEWAL_VALUE = {
  ON: 'ON',
  OFF: 'OFF',
};

export const FLEET_TYPE = {
  TRIBECAR: 'tribecar',
  TREEDOTS: 'treedots',
};
