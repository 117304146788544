import { DocumentAddIcon } from '@heroicons/react/outline';
import { ClaimTrackerSearchBar } from 'app/components/pages/claim-tracker';
import { HmoClaimTable } from 'app/components/table';
import { useClaimTrackerList } from 'app/hooks';
import React from 'react';

const HmoClaimTracker = ({ history }) => {
  const { totalPages, updateSearchParams, searchParams, searchText, onChangeSearchText, claimList } =
    useClaimTrackerList({ template_name: 'eb' });

  return (
    <div className="flex flex-col p-3">
      <div className="mb-5 text-3xl font-medium text-main-green">Claim Tracker</div>
      <div className="mb-4 mt-2 flex justify-between">
        <ClaimTrackerSearchBar
          searchText={searchText}
          onChangeSearchText={onChangeSearchText}
          searchParams={searchParams}
          updateSearchParams={updateSearchParams}
        />
        <button
          className="flex items-center gap-2 rounded-lg bg-main-darkBlue p-4 text-base text-white disabled:cursor-not-allowed"
          onClick={() => history.push('/dashboard/hmo/submit-claim')}
        >
          New Claim
          <DocumentAddIcon className="w-5" />
        </button>
      </div>

      <HmoClaimTable
        claimDetailPath="/dashboard/hmo/claim-detail"
        data={claimList}
        totalPages={totalPages}
        onFetchData={({ page, pageSize }) => {
          updateSearchParams({ page_number: page, page_size: pageSize });
        }}
      />
    </div>
  );
};

export default HmoClaimTracker;
