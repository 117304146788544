import { ApiInstance } from 'app/services/api';
import { handleError } from 'app/services/apiHelper';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useRef, useState } from 'react';

export const useSearchCoverage = vehicleReg => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchResult, setSearchResult] = useState<any[]>([]);
  const [fetching, setFetching] = useState(false);

  const temporaryBlockSearch = useRef(false);

  useEffect(() => {
    _debounceSearch(vehicleReg);
  }, [vehicleReg]);

  const _searchVehicle = async text => {
    if (temporaryBlockSearch.current) {
      temporaryBlockSearch.current = false;
      return;
    }
    if (!text) {
      setShowDropdown(false);
      setSearchResult([]);
      return;
    }
    setFetching(true);
    const res = await ApiInstance.searchVehicle({
      template_name: 'mep',
      sale_type: 'mer',
      page_number: 1,
      page_size: 20,
      vehicle_reg: text,
    });
    setFetching(false);
    const { result, error } = handleError(res, true);
    if (!error) {
      const vehicles =
        Object.keys(result?.grouped).map(key => ({
          vehicle_reg: key,
          start_date: result?.grouped[key]?.[0],
          end_date: result?.grouped[key]?.[1],
        })) || [];
      setSearchResult(vehicles);
      if (vehicles.length > 0) {
        setShowDropdown(true);
      }
    }
  };
  const _debounceSearch = useCallback(
    debounce(text => {
      _searchVehicle(text);
    }, 500),
    [],
  );

  return { showDropdown, setShowDropdown, fetching, searchResult, temporaryBlockSearch };
};
