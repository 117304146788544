import { Icon } from 'app/components/icon/icon';
import { selectors } from 'app/redux';
import clsx from 'clsx';
import type { FC } from 'react';
import React, { useMemo } from 'react';
import Dropzone from 'react-dropzone';
import { useSelector } from 'react-redux';

interface UploadZoneProps {
  onReadFile: (files: File[]) => void;
  fileError: any;
  uploadError: any;
  isReadingFile: boolean;
  isPreviewingFile: boolean;
  progress: number;
  dataTable: any[];
  originalFile: File | undefined;
  onPressRemove: () => void;
}
export const UploadZone: FC<UploadZoneProps> = ({
  onReadFile,
  fileError,
  uploadError,
  isReadingFile,
  isPreviewingFile,
  originalFile,
  progress,
  dataTable,
  onPressRemove,
}) => {
  const { user } = useSelector(selectors.user);
  const _handleFile = files => {
    onReadFile(files);
  };

  return (
    <div className="flex max-w-2xl flex-col">
      <div className="mb-5 text-base font-medium">
        Please use this{' '}
        <a href={user?.accessPages?.hmo_dashboard?.template_url} className="text-main-green underline">
          Bulk Enrollment Template
        </a>{' '}
        to enroll your employees.
      </div>
      {originalFile && !isReadingFile && !isPreviewingFile ? (
        <UploadCompleted dataTable={dataTable} fileError={fileError} uploadError={uploadError} />
      ) : (
        <Dropzone disabled={isReadingFile || isPreviewingFile} multiple={false} onDrop={_handleFile} maxSize={10000000}>
          {({ getRootProps, getInputProps }) => (
            <div
              {...getRootProps()}
              className={clsx(
                'relative h-64 rounded-xl border border-dashed border-black',
                (isReadingFile || isPreviewingFile) && 'bg-green-100',
              )}
            >
              <input {...getInputProps()} />
              <div className="z-10 flex h-full w-full flex-col items-center justify-center text-center text-main-green">
                <Icon name="cloudUpload" size={53} />
                <div className="mt-5 font-medium">Drag and drop or browse to choose an excel file.</div>
                <div className="mt-1 text-sm">Max. File Size: 10mb</div>
              </div>
            </div>
          )}
        </Dropzone>
      )}
      {originalFile ? (
        <UploadProcess originalFile={originalFile} process={progress} onPressRemove={onPressRemove} />
      ) : null}
    </div>
  );
};

const UploadCompleted = ({ dataTable, fileError, uploadError }) => {
  const { valid, invalid } = useMemo(() => {
    const errors = fileError || uploadError;
    if (!errors) {
      return {
        valid: dataTable?.length || 0,
        invalid: 0,
      };
    }

    const numOfInvalid = Object.keys(errors || {}).length;

    return {
      valid: (dataTable?.length || 0) - numOfInvalid,
      invalid: numOfInvalid,
    };
  }, [dataTable, fileError, uploadError]);

  return (
    <div className="flex h-64 flex-col items-center justify-center rounded-xl border border-black">
      <Icon name={invalid ? 'yellowWarning' : 'checkGreen'} />
      <div className="mt-5 text-base font-medium">Please see the preview below to check your file.</div>
      <div className="mb-4 flex italic">
        To upload again, click on the <Icon name="minus" className="mx-1 mt-1" size={15} /> button.
      </div>
      {uploadError ? (
        <>
          <div className="text-main-green">{valid} valid employees</div>
          {invalid ? <div className="text-red-600">{invalid} invalid employees</div> : null}
        </>
      ) : null}
    </div>
  );
};

const UploadProcess = ({ process, originalFile, onPressRemove }) => {
  return (
    <div className="mt-3 rounded-xl border border-black p-3">
      <div className="flex">
        <div className="flex-1">
          <div className="text-main-green">{originalFile?.name}</div>
          <div className="flex items-center text-sm">
            {process}%
            {process === 100 ? (
              <>
                <div className="mx-1 h-1.5 w-1.5 rounded-full bg-main-green" />
                Upload Complete
              </>
            ) : null}
          </div>
        </div>
        <button onClick={onPressRemove}>
          <Icon name="minus" size={24} />
        </button>
      </div>
      <div className="mt-2 h-1 w-full rounded-full bg-gray-200">
        <div className="mt-2 h-1 rounded-full bg-main-green" style={{ width: `${process}%` }} />
      </div>
    </div>
  );
};
