import { SimpleFullScreenModal } from 'app/components/simple-modal';
import React from 'react';

export const ModalCdwInfo = ({ isVisible, onNo, onYes }) => {
  return (
    // @ts-ignore
    <SimpleFullScreenModal isVisible={isVisible}>
      <div className="flex h-full w-full justify-center p-1">
        <div className="flex max-w-lg flex-col justify-center">
          <img className="w-36 self-center object-cover" src="/img/claim/warning.svg" alt="warning logo" />
          <div className="my-2 text-center text-2xl font-medium">You are about to submit a claim for MER!</div>
          <div className="text-base">
            <span className="font-medium text-main-green">Reminder:</span> Claims will only be processed after the
            documents below are received by the team. To submit the claim requirements, send it via email{' '}
            <a href="mailto:hey@gigacover.com" className="text-main-green">
              hey@gigacover.com
            </a>
            .
          </div>
          <div className="my-4 text-base font-bold text-main-green">
            Do you have all the supporting documents? Please refer to the list below.
          </div>
          <div className="flex w-full flex-col text-base">
            <div className="text-sm font-medium text-main-green">For all Claims: </div>
            <ul className="list-disc pl-5 text-sm">
              <li>Policy holder’s driving license and NRIC</li>
              <li>Certificate of Motor Insurance</li>
              <li>Rental Contract</li>
              <li>GIA Accident Report</li>
              <li>Police Report</li>
            </ul>
            <div className="mt-2 text-sm font-medium text-main-green">For Section 1 Claims:</div>
            <ul className="list-disc pl-5 text-sm">
              <li>Proof of excess payment (if excess was paid)</li>
              <li>Original Repair Invoice</li>
              <li>Colour Photographs showing damage of the car </li>
              <li>Colour Photographs showing the car after repaired </li>
            </ul>
            <div className="mt-2 text-sm font-medium text-main-green">For Section 2 Claims:</div>
            <ul className="list-disc pl-5 text-sm">
              <li>Discharge Voucher</li>
              <li>Proof of excess paid to primary insurer</li>
            </ul>
          </div>
          <div className="mt-5 flex gap-2 self-center">
            <button className="bt-green" onClick={onNo}>
              NO
            </button>
            <button className="bt-green" onClick={onYes}>
              YES
            </button>
          </div>
        </div>
      </div>
    </SimpleFullScreenModal>
  );
};
