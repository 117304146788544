import clsx from 'clsx';
import type { FC } from 'react';
import React from 'react';

interface ClaimOptionsInputProps {
  title: string;
  options: any[];
  value: any;
  required?: boolean;
  name: string;
  error?: string;
  boldTitle?: boolean;
  onChangeValue?: (value: any, name: string, required?: boolean) => void;
  onChange?: (e: any, required?: boolean) => void;
  disabled?: boolean;
}
export const ClaimOptionsInput: FC<ClaimOptionsInputProps> = ({
  title,
  options,
  value,
  required,
  name,
  error,
  boldTitle,
  onChangeValue,
  onChange,
  disabled,
}) => {
  const _onChangeValue = changeValue => {
    if (onChangeValue) onChangeValue(changeValue, name, required);
    if (onChange)
      onChange(
        {
          target: {
            value: changeValue,
            name,
          },
        },
        required,
      );
  };
  return (
    <div>
      <label
        className={clsx('mt-2 text-sm text-zinc-800', boldTitle && 'font-bold', disabled && 'pointer-events-none')}
      >
        {title}
        {required ? <span className="text-red-600">*</span> : null}
      </label>
      <div className="flex flex-wrap border-b-2 border-b-zinc-200 py-1">
        {options?.map(item => (
          <OptionButton key={item?.value || item} currentValue={value} data={item} onClick={_onChangeValue} />
        ))}
      </div>
      <div className="error-text">{error}&nbsp;</div>
    </div>
  );
};

const OptionButton = ({ data, currentValue, onClick }) => {
  const { value, label } = data || {};

  const isSelected = currentValue === value || currentValue === data;

  const _onClick = () => {
    if (value !== undefined) onClick(value);
    else onClick(value || label || data);
  };

  return (
    <button className="mr-2 flex min-w-[50px] border-0 bg-transparent last:mr-0" onClick={_onClick}>
      <div className={clsx('mr-1 mt-1 h-4 w-4', isSelected ? 'bg-main-green' : 'bg-stone-300')} />
      <div className="text-left text-sm text-zinc-400">{label || value || data}</div>
    </button>
  );
};
