import { APP_CONFIG } from 'app/constants';
import { UserRedux } from 'app/redux/reducers';
import type { ReactNode } from 'react';
import React from 'react';
import { connect } from 'react-redux';

class ErrorBoundary extends React.PureComponent<
  { children: ReactNode; onLogoutRequest: () => void },
  { hasError: boolean }
> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
    this.onLogout = this.onLogout.bind(this);
  }

  // eslint-disable-next-line unused-imports/no-unused-vars
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  // eslint-disable-next-line class-methods-use-this, unused-imports/no-unused-vars
  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }

  onLogout() {
    this.props.onLogoutRequest();
    window.location.href = '/';
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="">
          <div className="flex items-center bg-white py-3 drop-shadow-lg">
            <img
              className="h-16"
              src="/img/green-logo.svg"
              alt="gigacover-green-logo"
              onClick={() => {
                window.location.href = '/';
              }}
            />
            <h4 className="ml-2 text-3xl text-main-green">{APP_CONFIG.logoBranch}</h4>
          </div>
          <div className="m-10 flex flex-col items-center rounded-[50px] bg-gray-200 p-10 text-center">
            <h1 className="text-lg font-medium text-gray-600">OOPS! Something went wrong!</h1>
            <img alt="error-message-icon" className="h-56" src="/img/error.svg" />
            <div className="text-lg font-medium text-gray-600">
              Please try again.
              <br />
              If the issue persists please contact hey@gigacover.com
            </div>
            <button
              className="mt-5 rounded-2xl bg-main-green py-2 px-5 text-xl font-medium text-white"
              onClick={this.onLogout}
            >
              Logout
            </button>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default connect(null, dispatch => ({
  onLogoutRequest: () => dispatch(UserRedux.Creators.logoutRequest()),
}))(ErrorBoundary);
