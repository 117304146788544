import { CheckCircleIcon } from '@heroicons/react/outline';
import {
  ChangeSectionErrorModal,
  ClaimAgreeCheckboxHmo,
  ClaimBorderBottomInput,
  ClaimErrorModal,
  ClaimFormFieldList,
  ClaimInputDisbursement,
  ClaimPageLayout,
  ClaimSuccessModal,
} from 'app/components';
import { CheckRadio } from 'app/components/check-box';
import { DropDownSelectEbUser } from 'app/components/pages/submit-claim';
import { PRODUCT_REGIONS } from 'app/constants';
//
import { useClaimFormInput } from 'app/hooks';
import { useSubmitClaim } from 'app/hooks/useSubmitClaim';
import { ApiInstance } from 'app/services/api';
import { handleError } from 'app/services/apiHelper';
import { isObjectExistKeys } from 'app/utilities';
import { getRequireValueErrorMessage } from 'app/utilities/validationErrorMessageForm';
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import { globalLoading } from 'react-global-loading';
import toast from 'react-hot-toast';

const SubmitHmoClaim = ({ history }) => {
  const { data } = history.location.state || {};

  const [formData, setFormData] = useState<any>({
    type: 'inpatient',
  });

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const isInpatient = formData.type === 'inpatient';

  const {
    errors,
    setErrors,
    onChange,
    onSelectEbUser,
    onUploadImage,
    onDeleteImage,
    validateDisburmentInputPH,

    onConfirmChangeSection,
    onCancelChangeSection,
    showModalConfirmSectionChange,
    setShouldShowError,
  } = useClaimFormInput(formData, setFormData);

  const { onSubmitClaim, clearClaimId, clearErrorMessage, errorMessage, fetching } = useSubmitClaim(
    formData,
    setFormData,
    () => {
      setShowSuccessModal(true);
    },
  );

  useEffect(() => {
    _findUserObject();
  }, []);

  const _findUserObject = async () => {
    if (!data) return;
    const id = data?.user_obj?.id;
    globalLoading.show();
    const res = await ApiInstance.searchEbUser({
      template_name: 'eb',
      user_id: id,
    });
    globalLoading.hide();
    const { result, error } = handleError(res);
    if (error) {
      toast.error(error.message);
      history.replace('/dashboard/hmo/claim-tracker');
    } else {
      const { data: findUserData } = result || {};
      if (!findUserData?.[0]) {
        toast.error('Cannot find user');
        history.replace('/dashboard/hmo/claim-tracker');
        return;
      }
      onSelectEbUser(findUserData?.[0]);
    }
  };

  const onSuccessGoBack = () => {
    setShowSuccessModal(false);
    history.replace('/dashboard/hmo/claim-tracker');
  };

  const _validateForm = overrideFormData => {
    const checkFormData = overrideFormData || formData;
    const errorForm = validateDisburmentInputPH(checkFormData);

    if (!checkFormData.userObject) {
      errorForm.user_id = 'Please select user id';
    }
    if (!checkFormData.user_id) {
      errorForm.user_id = 'Please input user id';
    }
    if (!checkFormData.full_name) {
      errorForm.full_name = 'Please input user name';
    }
    if (isInpatient) {
      errorForm.inpatient_form = getRequireValueErrorMessage(checkFormData.inpatient_form?.length);
    } else {
      errorForm.outpatient_form = getRequireValueErrorMessage(checkFormData.outpatient_form?.length);
    }
    errorForm.incident_date = getRequireValueErrorMessage(checkFormData.incident_date);
    errorForm.medical_certificate = getRequireValueErrorMessage(checkFormData.medical_certificate?.length);
    errorForm.lab_requests = getRequireValueErrorMessage(checkFormData.lab_requests?.length);
    errorForm.prescription = getRequireValueErrorMessage(checkFormData.prescription?.length);
    errorForm.government_id = getRequireValueErrorMessage(checkFormData.government_id?.length);

    setShouldShowError(true);
    if (isObjectExistKeys(errorForm)) {
      setErrors(errorForm);
      return false;
    }

    if (!isChecked) {
      toast.error('Please check the acknowledgement');
      return false;
    }

    return true;
  };

  const _onSubmit = () => {
    const formDataSubmit = _clearUnuseField();
    if (!_validateForm(formDataSubmit)) return;
    onSubmitClaim({
      user_id: formDataSubmit.user_id,
      claim_key: formDataSubmit.userObject?.claim_key,
      template_name: 'eb',
    });
  };

  const overrideConfig = useMemo(() => {
    const config: any = {
      incident_date: {
        minDate: dayjs(formData?.userObject?.start_date).toDate(),
        maxDate: dayjs(formData?.userObject?.end_date).isAfter(dayjs())
          ? dayjs().toDate()
          : dayjs(formData?.userObject?.end_date).toDate(),
      },
    };
    // config.section1excess = { isRequired: false };
    return config;
  }, [formData?.userObject]);

  const _clearUnuseField = () => {
    const formDataEdit: any = { ...formData };

    if (isInpatient) {
      delete formDataEdit.outpatient_form;
    } else {
      delete formDataEdit.inpatient_form;
    }

    setFormData(formDataEdit);
    return formDataEdit;
  };

  const _renderFieldList = data => (
    <ClaimFormFieldList
      overrideConfig={overrideConfig}
      forms={data}
      country="SGP"
      product="eb"
      formData={formData}
      error={errors}
      onChange={onChange}
      onUploadImage={onUploadImage}
      onDeleteImage={onDeleteImage}
    />
  );

  const _onMakeAnotherClaim = () => {
    clearClaimId();
    setFormData({
      userObject: formData?.userObject,
      user_id: formData?.user_id,
      full_name: formData?.full_name,
    });
    setIsChecked(false);
    setShowSuccessModal(false);
  };

  return (
    <ClaimPageLayout fetching={fetching} onSubmit={_onSubmit}>
      <div className="flex gap-2">
        <div className="claim-form z-10 flex-1">
          <div className="claim-form-title">Choose an Employee</div>
          <div className="claim-form-subtitle">
            Type in the user id and name you want to make a claim with. If employee is not present, add the employee in
            the dashboard page.
          </div>
          <div className="relative z-10 w-full">
            <ClaimBorderBottomInput
              className="flex-1"
              title="User ID"
              name="user_id"
              required
              disabled={!!data}
              value={formData?.user_id}
              error={errors?.user_id}
              onChange={onChange}
              leftComp={formData?.userObject ? <CheckCircleIcon className="w-5 text-main-green" /> : null}
            />
            <ClaimBorderBottomInput
              className="flex-1"
              title="Full Name"
              name="full_name"
              required
              disabled={!!data}
              value={formData?.full_name}
              error={errors?.full_name}
              onChange={onChange}
              leftComp={formData?.userObject ? <CheckCircleIcon className="w-5 text-main-green" /> : null}
            />
            <DropDownSelectEbUser
              userId={formData?.user_id}
              fullName={formData?.full_name}
              stateData={data}
              onSelectUser={onSelectEbUser}
            />
          </div>
        </div>
        <div className="claim-form flex-1">
          <div className="claim-form-title">Claim Details</div>
          {_renderFieldList(['incident_date'])}
          <div className="claim-form-subtitle">
            Choose whether the employee is an In Patient (stays in a hospital while under treatment or Out Patient
            (under treatment without being admitted to a hospital).
          </div>
          <CheckRadio
            centerText
            type="donus"
            checked={isInpatient}
            onClick={() => setFormData(state => ({ ...state, type: 'inpatient' }))}
          >
            In Patient
          </CheckRadio>
          <CheckRadio
            centerText
            type="donus"
            checked={!isInpatient}
            onClick={() => setFormData(state => ({ ...state, type: 'outpatient' }))}
          >
            Out Patient
          </CheckRadio>
        </div>
      </div>
      <ClaimInputDisbursement
        country={PRODUCT_REGIONS.PH}
        formData={formData}
        setFormData={setFormData}
        error={errors}
        onChange={onChange}
      />
      <div className="claim-form">
        <div className="claim-form-title">Document Submission</div>
        <div className="flex gap-10">
          <div className="flex-1">
            {_renderFieldList([
              isInpatient ? 'inpatient_form' : 'outpatient_form',
              'hospital_medical_bill',
              'prescription',
            ])}
          </div>
          <div className="flex-1">
            {_renderFieldList(['medical_certificate', 'hospital_statement', 'lab_requests', 'government_id'])}
          </div>
        </div>
      </div>
      <div className="max-w-xl">
        <ClaimAgreeCheckboxHmo isChecked={isChecked} setIsChecked={value => setIsChecked(value)} />
      </div>
      <ClaimErrorModal errorMessage={errorMessage} onClose={clearErrorMessage} />
      <ClaimSuccessModal
        isVisible={showSuccessModal}
        onMakeAnotherClaim={_onMakeAnotherClaim}
        onBack={onSuccessGoBack}
      />
      <ChangeSectionErrorModal
        isVisible={showModalConfirmSectionChange}
        onCancel={onCancelChangeSection}
        onConfirm={onConfirmChangeSection}
      />
    </ClaimPageLayout>
  );
};

export default SubmitHmoClaim;
