import clsx from 'clsx';
import type { FC } from 'react';
import React from 'react';

interface ClaimFormLayoutProps {
  title?: string;
  subtitle?: string;
  children?: React.ReactNode;
  className?: string;
}
export const ClaimFormLayout: FC<ClaimFormLayoutProps> = ({ title, subtitle, children, className }) => {
  return (
    <div className={clsx('claim-form', className)}>
      <div className="claim-form-title">{title}</div>
      <div className="claim-form-subtitle">{subtitle}</div>
      {children}
    </div>
  );
};
