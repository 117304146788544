import { DocumentAddIcon, EyeIcon } from '@heroicons/react/outline';
import { ClaimTrackerSearchBar, StatusCell } from 'app/components/pages/claim-tracker';
import { APP_CONFIG, DATE_FORMAT } from 'app/constants';
import { useClaimTrackerList } from 'app/hooks';
import dayjs from 'dayjs';
import React from 'react';
import ReactTable from 'react-table';

const MerClaimTracker = ({ history }) => {
  const { totalPages, updateSearchParams, searchParams, searchText, onChangeSearchText, claimList } =
    useClaimTrackerList({ template_name: 'mep', sale_type: 'mer' });

  const onGetTdProps = (state, rowInfo) => {
    return {
      onClick: () => {
        history.push(`/dashboard/mer/claim-detail/${rowInfo.original.id}`);
      },
    };
  };

  return (
    <div className="flex flex-col p-3">
      <div className="mb-5 text-3xl font-medium text-main-green">Claim Tracker</div>
      <div className="mb-4 mt-2 flex justify-between">
        <ClaimTrackerSearchBar
          searchText={searchText}
          onChangeSearchText={onChangeSearchText}
          searchParams={searchParams}
          updateSearchParams={updateSearchParams}
        />
        <button
          className="flex items-center gap-2 rounded-lg bg-main-darkBlue p-4 text-base text-white disabled:cursor-not-allowed"
          onClick={() => history.push('/dashboard/mer/submit-claim')}
        >
          New Claim
          <DocumentAddIcon className="w-5" />
        </button>
      </div>

      <ReactTable
        manual
        getTdProps={onGetTdProps}
        columns={CLAIM_TABLE_COLUMNS}
        data={claimList}
        defaultPageSize={APP_CONFIG.overview.DEFAULT_PAGE_SIZE}
        loading={false}
        nextText={`Next >`}
        previousText={`< Previous`}
        pages={totalPages}
        resizable={false}
        onFetchData={({ page, pageSize }) => {
          updateSearchParams({ page_number: page, page_size: pageSize });
        }}
        noDataText={`Not Found`}
        style={{ border: 'none' }}
        // eslint-disable-next-line tailwindcss/no-custom-classname
        className="app-table"
      />
    </div>
  );
};

export default MerClaimTracker;

const CLAIM_TABLE_COLUMNS = [
  {
    Header: 'Vehicle Number',
    sortable: false,
    filterable: false,
    accessor: 'claim_details',
    Cell: data => <span>{data.value.vehicle_reg?.toUpperCase()}</span>,
  },
  {
    Header: 'Date Submitted',
    sortable: false,
    filterable: false,
    accessor: 'created_at',
    Cell: data => <span>{data.value ? dayjs(data.value).format(DATE_FORMAT.dateSlash) : '-'}</span>,
  },
  {
    Header: 'Last Updated',
    sortable: false,
    filterable: false,
    accessor: 'updated_at',
    Cell: data => <span>{data.value ? dayjs(data.value).format(DATE_FORMAT.dateSlash) : '-'}</span>,
  },
  {
    Header: 'Status',
    sortable: false,
    filterable: false,
    accessor: 'status',
    Cell: data => <StatusCell status={data.value} />,
  },
  {
    Header: '',
    sortable: false,
    filterable: false,
    Cell: () => (
      <div className=" rounded-md bg-[#DE1B77] p-2">
        <EyeIcon className="h-5 w-5 text-white" />
      </div>
    ),
  },
];
