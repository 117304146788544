import { StepBar } from 'app/components/pages/checkout';
import { AddEmployeeSuccess, EmployeeForm, SelectAddMode, UploadEbFile } from 'app/components/pages/eb/add-employee';
import { DATE_FORMAT } from 'app/constants';
import { useGroupRank } from 'app/hooks';
import { ApiInstance } from 'app/services/api';
import { handleError } from 'app/services/apiHelper';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { globalLoading } from 'react-global-loading';

const EbAddEmployee = ({ history }) => {
  const [step, setStep] = useState(1);
  const [currentMode, setCurrentMode] = useState('');
  const [addOneErrors, setAddOneErrors] = useState<any>();

  const { groupRankOptions } = useGroupRank();

  const _onSelectMode = value => {
    setCurrentMode(value);
    setStep(2);
  };

  const _onAddOneEmployee = async form => {
    setAddOneErrors(undefined);
    const body = {
      'Employee Rank': form.employeeRank || undefined,
      'First Name': form.firstName || undefined,
      'Last Name': form.lastName || undefined,
      Email: form.email || undefined,
      'Date of Birth': dayjs(form.dob).format(DATE_FORMAT.serverDate),
      Mobile: form.mobile || undefined,
      'Effective Date': dayjs(form.effectiveDate).format(DATE_FORMAT.serverDate),
      'Is Dependent': 'No',
    };
    globalLoading.show();
    const res = await ApiInstance.addEmployee(body);
    globalLoading.hide();
    const { error } = handleError(res);
    if (error) {
      setAddOneErrors(error);
    } else {
      setStep(3);
    }
  };

  const _backToStep1 = () => {
    setStep(1);
    setCurrentMode('');
    setAddOneErrors(null);
  };

  return (
    <div className="flex flex-col items-center">
      <StepBar steps={STEPS} step={step} />
      {step === 1 ? (
        <SelectAddMode currentMode={currentMode} onSelectMode={_onSelectMode} onBack={() => history.goBack()} />
      ) : step === 2 ? (
        currentMode === 'bulk' ? (
          <UploadEbFile onBack={_backToStep1} onNext={() => setStep(3)} />
        ) : (
          <EmployeeForm
            addOneErrors={addOneErrors}
            groupRankOptions={groupRankOptions}
            onBack={_backToStep1}
            onNext={_onAddOneEmployee}
          />
        )
      ) : step === 3 ? (
        <AddEmployeeSuccess />
      ) : null}
    </div>
  );
};

export default EbAddEmployee;

const STEPS = ['Type of Enrollment', `Input Employee's Details`, 'Onboarding Successful!'];
