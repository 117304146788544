import type { AnyAction } from 'redux';
import type { DefaultActionCreators, DefaultActionTypes } from 'reduxsauce';
import { createActions, createReducer } from 'reduxsauce';
import * as Immutable from 'seamless-immutable';

/* ------------- Model interface Create Action ------------- */
export interface AppAction extends AnyAction {}

interface IActionTypes extends DefaultActionTypes {
  SET_GLOBAL_LOADING: 'setGlobalLoading';
}

interface IActionCreators extends DefaultActionCreators {
  setGlobalLoading: (data: any) => AnyAction;
}

type IActions = AppAction | AnyAction;

export interface AppState {
  loading: any;
}

type ImmutableMyType = Immutable.ImmutableObject<AppState>;

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions<IActionTypes, IActionCreators>({
  setGlobalLoading: ['value'],
});

export const AppTypes = Types;

export const INITIAL_STATE: ImmutableMyType = Immutable.from({
  loading: false,
});

export const setGlobalLoading = (state: ImmutableMyType, { value }: AnyAction) => state.merge({ loading: value });

export const reducer = createReducer<ImmutableMyType, IActions>(INITIAL_STATE, {
  [Types.SET_GLOBAL_LOADING]: setGlobalLoading,
});

const stateKey = 'app';
const getReducerState = state => state[stateKey];
const reducerMap = { [stateKey]: reducer };
export default {
  // default export
  INITIAL_STATE,
  Types,
  Creators,

  stateKey,
  getReducerState,
  reducerMap,
};
