import { isObjectExistKeys } from 'app/utils';
import dayjs from 'dayjs';
import type { ChangeEvent } from 'react';
import { useState } from 'react';
import toast from 'react-hot-toast';

const REGREX_CARD =
  /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/;

export const useOmise = () => {
  const [form, setForm] = useState<OmiseForm>({
    cardName: '',
    cardNumber: '',
    cardExpiry: '',
    cardCvc: '',
  });
  const [errors, setErrors] = useState<OmiseForm>({} as OmiseForm);
  const [fetching, setFetching] = useState(false);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const newValue =
      name === 'cardName'
        ? value.toUpperCase()
        : name === 'cardNumber'
        ? value.replace(/\D/g, '').replace(/(\d{1,4})?(\d{1,4})?(\d{1,4})?(\d{1,4})?/, (_, p1, p2, p3, p4) => {
            let output = '';
            if (p1) output = `${p1}`;
            if (p2) output += ` ${p2}`;
            if (p3) output += ` ${p3}`;
            if (p4) output += ` ${p4}`;
            return output;
          })
        : name === 'cardExpiry'
        ? value.replace(/\D/g, '').replace(/(\d{1,2})?(\d{1,2})?/, (_, p1, p2) => {
            let output = '';
            if (p1) output = `${p1}`;
            if (p2) output += `/${p2}`;
            return output;
          })
        : value;
    setForm({
      ...form,
      [name]: newValue,
    });
    setErrors({
      ...errors,
      [name]: '',
    });
  };

  const validateForm = () => {
    const errorObject = {} as OmiseForm;
    if (!form.cardName) {
      errorObject.cardName = 'Name is required';
    }
    if (!form.cardNumber) {
      errorObject.cardNumber = 'Card number is required';
    } else if (!REGREX_CARD.test(form.cardNumber?.replace(/\D/g, ''))) {
      errorObject.cardNumber = 'Card number is invalid';
    }
    if (!form.cardExpiry) {
      errorObject.cardExpiry = 'Expiry date is required';
    } else if (!/^(0[1-9]|1[0-2])\/\d{2}$/.test(form.cardExpiry)) {
      errorObject.cardExpiry = 'Expiry date is invalid';
    } else if (dayjs().isAfter(dayjs(`20${form.cardExpiry.split('/')[1]}-${form.cardExpiry.split('/')[0]}-01`))) {
      errorObject.cardExpiry = 'Card is expired';
    }

    if (!form.cardCvc) {
      errorObject.cardCvc = 'CVC is required';
    } else if (!/^\d{3}$/.test(form.cardCvc)) {
      errorObject.cardCvc = 'CVC is invalid';
    }
    setErrors(errorObject);
    return !isObjectExistKeys(errorObject);
  };

  const onCreateOmiseToken = (callback: (token: string) => void) => {
    if (!validateForm()) return;
    const expireDate = form.cardExpiry.split('/');
    const tokenParameters = {
      // city: 'New York',
      country: 'SG',
      expiration_month: expireDate[0],
      expiration_year: `20${expireDate[1]}`,
      name: form.cardName,
      number: form.cardNumber.replace(/\D/g, ''),
      // phone_number: '0123456789',
      // postal_code: 10320,
      security_code: form.cardCvc,
      // state: 'NY',
      // street1: '476 Fifth Avenue',
    };

    // @ts-ignore
    if (window.Omise) {
      setFetching(true);
      // @ts-ignore
      window.Omise.createToken('card', tokenParameters, (statusCode, response) => {
        setFetching(false);
        if (statusCode === 200) {
          callback(response?.id);
        } else {
          toast.error(response?.message || 'Create token failed');
        }
      });
    } else {
      toast.error('Omise is not available');
    }
  };

  return {
    form,
    onChange,
    errors,
    fetching,
    validateForm,
    onCreateOmiseToken,
  };
};

interface OmiseForm {
  cardName: string;
  cardNumber: string;
  cardExpiry: string;
  cardCvc: string;
}
