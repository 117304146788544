//
import { PRODUCT_REGIONS } from 'app/constants';
import {
  ACCEPT_FILES,
  PAYMENT_METHODS,
  PAYMENT_METHODS_OPTIONS,
  PAYMENT_OPTIONS,
  PAYMENT_OPTIONS_BANK_EWALLET,
} from 'app/constants/claimConfig';
import type { ChangeEventHandler, FC } from 'react';
import React, { useEffect, useMemo } from 'react';

//
import {
  ClaimBorderBottomInput,
  ClaimBorderBottomSelectInput,
} from './claim-border-bottom-input/claim-border-bottom-input';
import { ClaimFormLayout } from './claim-form-layout/claim-form-layout';
import { ClaimSubmitImage } from './claim-submit-image';

interface ClaimInputPhDisbursementProps {
  formData: any;
  eWalletOnly?: boolean;
  error: any;
  onChange: any;
  disabled?: boolean;
  className?: string;
}
export const ClaimInputPhDisbursement: FC<ClaimInputPhDisbursementProps> = ({
  formData,
  eWalletOnly,
  error,
  onChange,
  disabled,
  className,
}) => {
  const bankOptions = useMemo(() => (eWalletOnly ? PAYMENT_OPTIONS : PAYMENT_OPTIONS_BANK_EWALLET), [eWalletOnly]);

  const isEWallet = useMemo(
    () => eWalletOnly || bankOptions?.find(item => item.value === formData?.preferred_payment)?.isEWallet,
    [eWalletOnly, bankOptions, formData?.preferred_payment],
  );

  return (
    <ClaimFormLayout
      title={'Disbursement Details'}
      className={className}
      subtitle={'For us to send claims payout, please provide us with your bank account or e-wallet details.'}
    >
      <ClaimBorderBottomSelectInput
        title={'Bank/ E-Wallet Name'}
        name={'preferred_payment'}
        options={bankOptions}
        value={formData.preferred_payment}
        error={error.preferred_payment}
        onChange={onChange}
        disabled={disabled}
      />
      {isEWallet ? (
        <ClaimBorderBottomInput
          name="mobile"
          maxLength={10}
          title="Mobile Number"
          prefix="+63"
          value={formData.mobile}
          onChange={onChange}
          error={error.mobile}
          disabled={disabled}
        />
      ) : (
        <ClaimBorderBottomInput
          name="bank_account_number"
          maxLength={10}
          title="Account Number"
          value={formData.bank_account_number}
          onChange={onChange}
          error={error.bank_account_number}
          disabled={disabled}
        />
      )}
    </ClaimFormLayout>
  );
};

interface ClaimInputSgDisbursementProps {
  formData: any;
  setFormData?: (data: any) => void;
  error: any;
  onChange: ChangeEventHandler<HTMLInputElement>;
  onUploadImage: (e: any) => void;
  onDeleteImage: (e: any) => void;
  disabled?: boolean;
  className?: string;
}
const ClaimInputSgDisbursement: FC<ClaimInputSgDisbursementProps> = ({
  formData,
  setFormData,
  error,
  onChange,
  onUploadImage,
  onDeleteImage,
  disabled,
  className,
}) => {
  useEffect(() => {
    const editFormData = { ...formData };
    if (formData.payment_method === PAYMENT_METHODS.PAYNOW_MOBILENUMBER) {
      setFormData?.(removeUnuseField(['paynow_mobile', 'paynow_nickname'], editFormData));
    } else if (formData.payment_method === PAYMENT_METHODS.PAYNOW_NRIC) {
      setFormData?.(removeUnuseField(['paynow_nricfin', 'paynow_nickname'], editFormData));
    } else if (formData.payment_method === PAYMENT_METHODS.PAYNOW_UEN) {
      setFormData?.(removeUnuseField(['uen_number', 'company_name'], editFormData));
    } else if (formData.payment_method === PAYMENT_METHODS.BANK_ACCOUNT) {
      setFormData?.(
        removeUnuseField(
          ['bank_account_nricfin', 'bank_name', 'bank_account_holder_name', 'bank_account_number'],
          editFormData,
        ),
      );
    }
  }, [formData.payment_method]);

  return (
    <ClaimFormLayout className={className} title="Disbursement Details">
      <ClaimBorderBottomSelectInput
        required
        options={PAYMENT_METHODS_OPTIONS}
        title="Payment Method"
        name="payment_method"
        value={formData.payment_method}
        error={error.payment_method}
        // @ts-ignore
        onChange={onChange}
        disabled={disabled}
      />
      {/* {formData.payment_method === PAYMENT_METHODS.PAYNOW_MOBILENUMBER && (
        <>
          <ClaimBorderBottomInput
            required
            maxLength={8}
            title="Mobile Number"
            prefix="+65"
            name="paynow_mobile"
            value={formData.paynow_mobile}
            error={error.paynow_mobile}
            onChange={onChange}
          />
          <ClaimBorderBottomInput
            required
            title="PayNow Nickname"
            name="paynow_nickname"
            value={formData.paynow_nickname}
            error={error.paynow_nickname}
            onChange={onChange}
          />
        </>
      )} */}
      {formData.payment_method === PAYMENT_METHODS.PAYNOW_NRIC && (
        <>
          <ClaimBorderBottomInput
            required
            title="NRIC"
            name="paynow_nricfin"
            value={formData.paynow_nricfin}
            error={error.paynow_nricfin}
            onChange={onChange}
            disabled={disabled}
          />
          <ClaimBorderBottomInput
            required
            title="PayNow Nickname"
            name="paynow_nickname"
            value={formData.paynow_nickname}
            error={error.paynow_nickname}
            onChange={onChange}
            disabled={disabled}
          />
          <ClaimSubmitImage
            required
            label="PayNow Profile Screenshot"
            name="paynow_profile_screenshot"
            subtitle="(Please take a screenshot of your PayNow Profile for us to verify)"
            listImages={formData.paynow_profile_screenshot}
            errorText={error.paynow_profile_screenshot}
            acceptTypes={ACCEPT_FILES.IMAGES}
            limit={1}
            onChange={onUploadImage}
            onDeleteImg={onDeleteImage}
            limitDocs={0}
            limitImages={1}
            disabled={disabled}
          />
        </>
      )}
      {formData.payment_method === PAYMENT_METHODS.PAYNOW_UEN && (
        <>
          <ClaimBorderBottomInput
            required
            title="UEN Number"
            name="uen_number"
            value={formData.uen_number}
            error={error.uen_number}
            onChange={onChange}
            disabled={disabled}
          />
          <ClaimBorderBottomInput
            required
            title="Company Name"
            name="company_name"
            value={formData.company_name}
            error={error.company_name}
            onChange={onChange}
            disabled={disabled}
          />
          <ClaimSubmitImage
            required
            label="PayNow Profile Screenshot"
            name="paynow_profile_screenshot"
            subtitle="(Please take a screenshot of your PayNow Profile for us to verify)"
            listImages={formData.paynow_profile_screenshot}
            errorText={error.paynow_profile_screenshot}
            acceptTypes={ACCEPT_FILES.IMAGES}
            limit={1}
            onChange={onUploadImage}
            onDeleteImg={onDeleteImage}
            limitDocs={0}
            limitImages={1}
            disabled={disabled}
          />
        </>
      )}
      {formData.payment_method === PAYMENT_METHODS.BANK_ACCOUNT && (
        <div>
          <ClaimBorderBottomInput
            required
            title="NRIC/FIN/UEN"
            name="bank_account_nricfin"
            value={formData.bank_account_nricfin}
            error={error.bank_account_nricfin}
            onChange={onChange}
            disabled={disabled}
          />
          <ClaimBorderBottomInput
            required
            title="Name of the Bank"
            name="bank_name"
            value={formData.bank_name}
            error={error.bank_name}
            onChange={onChange}
            disabled={disabled}
          />
          <ClaimBorderBottomInput
            required
            title="Name of the Bank Account Holder"
            name="bank_account_holder_name"
            value={formData.bank_account_holder_name}
            error={error.bank_account_holder_name}
            onChange={onChange}
            disabled={disabled}
          />
          <ClaimBorderBottomInput
            required
            title="Bank Account Number"
            name="bank_account_number"
            value={formData.bank_account_number}
            error={error.bank_account_number}
            onChange={onChange}
            disabled={disabled}
          />
          <ClaimSubmitImage
            required
            label="Bank Statement Screenshot"
            name="bank_statement_screenshot"
            subtitle="(Please take a screenshot of your Bank Statement for us to verify your name and bank account number)"
            listImages={formData.bank_statement_screenshot}
            errorText={error.bank_statement_screenshot}
            acceptTypes={ACCEPT_FILES.IMAGES}
            limit={1}
            onChange={onUploadImage}
            onDeleteImg={onDeleteImage}
            limitDocs={0}
            limitImages={1}
            disabled={disabled}
          />
        </div>
      )}
    </ClaimFormLayout>
  );
};

interface ClaimInputDisbursementProps {
  country?: string;
  formData: any;
  setFormData?: (data: any) => void;
  error: any;
  onChange: (e: { name: string; value: any }) => void;
  onUploadImage?: (fieldName: any, newListImage: any) => void;
  onDeleteImage?: (fieldName: any, orderImage: any) => void;
  disabled?: boolean;
  className?: string;
}
export const ClaimInputDisbursement: FC<ClaimInputDisbursementProps> = ({ country = PRODUCT_REGIONS.SG, ...rest }) => {
  if (country === PRODUCT_REGIONS.PH) return <ClaimInputPhDisbursement {...rest} />;
  // @ts-ignore
  if (country === PRODUCT_REGIONS.SG) return <ClaimInputSgDisbursement {...rest} />;
  return null;
};

const ALL_FIELDS = [
  'paynow_mobile',
  'paynow_nricfin',
  'paynow_nickname',
  'uen_number',
  'company_name',
  'bank_account_nricfin',
  'bank_name',
  'bank_account_holder_name',
  'bank_account_number',
];
const removeUnuseField = (useFields, formData) => {
  const editFormData = { ...formData };
  ALL_FIELDS.forEach(field => {
    if (useFields.includes(field)) return;
    delete editFormData[field];
  });
  return editFormData;
};
