import { InlineDateInput, InlineSelectInput, InlineTextInput } from 'app/components/inline-input';
import { getMinDate } from 'app/general';
import { selectors } from 'app/redux';
import { checkHasPayLater } from 'app/utilities/data-helper';
import clsx from 'clsx';
import dayjs from 'dayjs';
import type { FC } from 'react';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

interface AddOneFormProps {
  className?: string;
  form: any;
  errors: any;
  verifyErrors: any;
  onChangeField: (e: any) => void;
  vehicleTypeOptions: any;
  section1Options: any;
  section2Options: any;
  reduceOptions: any;
  disableEdit?: boolean;
  fetchingDetail?: boolean;
}
export const AddOneForm: FC<AddOneFormProps> = ({
  className,
  form,
  errors,
  verifyErrors,
  onChangeField,
  vehicleTypeOptions,
  section1Options,
  section2Options,
  reduceOptions,
  disableEdit,
  fetchingDetail,
}) => {
  const { listUnits } = useSelector(selectors.data);
  const { user } = useSelector(selectors.user);
  const hasPayLater = checkHasPayLater(user);

  const unitOptions = useMemo(
    () => [
      {
        label: 'Daily',
        value: listUnits?.days?.id,
      },
      {
        label: 'Monthly',
        value: listUnits?.months?.id,
      },
      {
        label: 'Yearly',
        value: listUnits?.years?.id,
      },
    ],
    [listUnits],
  );

  return (
    <div className={clsx('flex gap-10 p-4', className)}>
      <div className="flex flex-1 flex-col">
        <InlineTextInput
          disabled={disableEdit}
          label="Vehicle Registration Number"
          name="vehicle_reg"
          value={form?.vehicle_reg}
          fetching={fetchingDetail}
          error={errors?.vehicle_reg || verifyErrors?.vehicle_reg}
          onChange={onChangeField}
        />
        <InlineTextInput
          disabled={disableEdit}
          label="Vehicle Model"
          name="model"
          value={form?.model}
          error={errors?.model || verifyErrors?.model}
          onChange={onChangeField}
        />
        <InlineTextInput
          disabled={disableEdit}
          label="Vehicle Make"
          name="make"
          value={form?.make}
          error={errors?.make || verifyErrors?.make}
          onChange={onChangeField}
        />
        <InlineTextInput
          disabled={disableEdit}
          label="Vehicle Chassis No."
          name="chassis"
          value={form?.chassis}
          error={errors?.chassis || verifyErrors?.chassis}
          onChange={onChangeField}
        />
        <InlineTextInput
          disabled={disableEdit}
          label="Vehicle Engine No."
          name="engine"
          value={form?.engine}
          error={errors?.engine || verifyErrors?.engine}
          onChange={onChangeField}
        />
        <InlineSelectInput
          label="Vehicle Type"
          disabled={disableEdit}
          options={vehicleTypeOptions}
          name="vehicle_type"
          value={form?.vehicle_type}
          error={errors?.vehicle_type || verifyErrors?.vehicle_type}
          onChange={onChangeField}
        />
        <InlineTextInput
          disabled={disableEdit}
          label="Company UEN"
          name="company_uen"
          value={form?.company_uen}
          error={errors?.company_uen || verifyErrors?.company_uen}
          onChange={onChangeField}
        />
        <InlineTextInput
          disabled={disableEdit}
          label="Company Name"
          name="company_name"
          value={form?.company_name}
          error={errors?.company_name || verifyErrors?.company_name}
          onChange={onChangeField}
        />
        {/* <InlineTextInput
          disabled={disableEdit}
          label="Contact Number"
          name="contact_num"
          value={form?.contact_num}
          error={errors?.contact_num|| verifyErrors?.contact_num}
          onChange={onChangeField}
        />
        <InlineTextInput
          disabled={disableEdit}
          label="Contact Email"
          name="contact_email"
          value={form?.contact_email}
          error={errors?.contact_email|| verifyErrors?.contact_email}
          onChange={onChangeField}
        /> */}
      </div>
      <div className="flex flex-1 flex-col gap-6">
        <div className="flex flex-col rounded-xl border p-4">
          <div className="mb-2 text-xl">Choose your excess levels</div>
          <InlineSelectInput
            label="Section 1 Excess"
            options={section1Options}
            name="section1excess"
            value={form?.section1excess}
            error={errors?.section1excess || verifyErrors?.section1excess}
            onChange={onChangeField}
          />
          <InlineSelectInput
            label="Section 2 Excess"
            options={section2Options}
            name="section2excess"
            value={form?.section2excess}
            error={errors?.section2excess || verifyErrors?.section2excess}
            onChange={onChangeField}
          />
          <InlineSelectInput
            label="Reduced Excess To"
            options={reduceOptions}
            name="reduced_excess"
            value={form?.reduced_excess}
            error={errors?.reduced_excess || verifyErrors?.reduced_excess}
            onChange={onChangeField}
          />
        </div>
        <div className="flex flex-col rounded-xl border p-4">
          <div className="mb-2 text-xl">Vehicle Plan</div>
          <InlineSelectInput
            label="Unit"
            options={unitOptions}
            name="unit_id"
            value={form?.unit_id}
            error={errors?.unit_id || verifyErrors?.unit_id}
            onChange={onChangeField}
          />
          <InlineDateInput
            label="Start Date"
            name="contract_start"
            minDate={getMinDate(hasPayLater)}
            value={form?.contract_start}
            error={errors?.contract_start || verifyErrors?.contract_start}
            onChange={onChangeField}
          />
          {/* eslint-disable-next-line eqeqeq */}
          {form?.unit_id == listUnits?.days?.id ? (
            <InlineDateInput
              label="End Date"
              name="contract_end"
              minDate={form?.contract_start ? dayjs(form?.contract_start).add(1, 'd').toDate() : undefined}
              value={form?.contract_end}
              error={errors?.contract_end || verifyErrors?.contract_end}
              onChange={onChangeField}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};
