import { DATE_FORMAT } from 'app/constants';
import { ACCEPT_FILES, getClaimConfigObject } from 'app/constants/claimConfig';
import dayjs from 'dayjs';
import type { FC } from 'react';
import React from 'react';

import {
  ClaimBorderBottomDateInput,
  ClaimBorderBottomInput,
  ClaimBorderBottomLabel,
} from './claim-border-bottom-input/claim-border-bottom-input';
import { ClaimFormLayout } from './claim-form-layout/claim-form-layout';
import { ClaimImageFieldPreview } from './claim-image-field-preview';
import { ClaimOptionsInput } from './claim-options-input/claim-options-input';
import { ClaimSubmitImage } from './claim-submit-image';

export const ClaimForm = ({
  overrideConfig,
  claimForms,
  country,
  product,
  formData,
  error,
  onChange,
  onUploadImage,
  onDeleteImage,
}) => {
  return claimForms.map(item => (
    <ClaimFormLayout key={item.title} title={item.title}>
      {item?.formSections?.map(section => (
        <React.Fragment key={section.title}>
          {!overrideConfig[section.configKey]?.hidden ? (
            <>
              {typeof section.title === 'string' ? (
                <div className="mb-1 text-xs font-bold text-main-green">{section.title}</div>
              ) : (
                section.title
              )}
              {section.forms.map(keyName => (
                <ClaimFormItem
                  overrideConfig={overrideConfig}
                  key={keyName}
                  keyName={keyName}
                  country={country}
                  product={product}
                  formData={formData}
                  error={error}
                  onChange={onChange}
                  onUploadImage={onUploadImage}
                  onDeleteImage={onDeleteImage}
                />
              ))}
            </>
          ) : null}
        </React.Fragment>
      ))}

      {item?.forms?.map(keyName => (
        <ClaimFormItem
          overrideConfig={overrideConfig}
          key={keyName}
          keyName={keyName}
          country={country}
          product={product}
          formData={formData}
          error={error}
          onChange={onChange}
          onUploadImage={onUploadImage}
          onDeleteImage={onDeleteImage}
        />
      ))}
    </ClaimFormLayout>
  ));
};

export const ClaimFormFieldList = ({
  overrideConfig,
  forms,
  country,
  product,
  formData,
  error,
  onChange,
  onUploadImage,
  onDeleteImage,
  disabled,
}: {
  overrideConfig?: any;
  forms: string[];
  country: string;
  product: string;
  formData: any;
  error: any;
  onChange: any;
  onUploadImage: any;
  onDeleteImage: any;
  disabled?: boolean;
}) => {
  return (
    <>
      {forms?.map(keyName => (
        <ClaimFormItem
          overrideConfig={overrideConfig}
          key={keyName}
          keyName={keyName}
          country={country}
          product={product}
          formData={formData}
          error={error}
          onChange={onChange}
          onUploadImage={onUploadImage}
          onDeleteImage={onDeleteImage}
          disabled={disabled}
        />
      ))}
    </>
  );
};

const ClaimFormItem = ({
  keyName,
  country,
  product,
  formData,
  error,
  onChange,
  onUploadImage,
  onDeleteImage,
  overrideConfig,
  disabled,
}: {
  keyName: string;
  country: string;
  product: string;
  formData: any;
  error: any;
  onChange: any;
  onUploadImage: any;
  onDeleteImage: any;
  overrideConfig?: any;
  disabled?: boolean;
}) => {
  const claimConfigObject = getClaimConfigObject(keyName, country, product) || {};
  const config = { ...claimConfigObject, ...overrideConfig?.[keyName] };
  const {
    type,
    inputType,
    isRequired,
    name,
    options,
    docTypes,
    maxFileNumber,
    hidden,
    limitDocs,
    limitImages,
    description,
    minDate,
    maxDate,
    maxLength,
  } = config;
  if (hidden) return null;
  switch (type) {
    case 'input':
      return (
        <ClaimBorderBottomInput
          required={isRequired}
          title={name}
          type={inputType}
          maxLength={maxLength}
          name={keyName}
          value={formData[keyName]}
          error={error[keyName]}
          onChange={onChange}
          disabled={disabled}
        />
      );
    case 'date':
      return (
        <ClaimBorderBottomDateInput
          required={isRequired}
          title={name}
          name={keyName}
          minDate={minDate}
          maxDate={maxDate}
          value={formData[keyName]}
          error={error[keyName]}
          onChange={onChange}
          disabled={disabled}
        />
      );
    case 'select':
      return (
        <ClaimOptionsInput
          options={options}
          required={isRequired}
          title={name}
          name={keyName}
          value={formData[keyName]}
          error={error[keyName]}
          onChange={onChange}
          disabled={disabled}
        />
      );
    case 'file':
      return (
        <ClaimSubmitImage
          required={isRequired}
          label={name}
          name={keyName}
          subtitle={description}
          listImages={formData[keyName]}
          errorText={error[keyName]}
          acceptTypes={docTypes || ACCEPT_FILES.DOCUMENTS_AND_IMAGE}
          limit={maxFileNumber}
          onChange={onUploadImage}
          onDeleteImg={onDeleteImage}
          limitDocs={limitDocs}
          limitImages={limitImages}
          disabled={disabled}
        />
      );
    default:
      return null;
  }
};

interface ClaimDetailFieldListProps {
  overrideConfig?: any;
  forms: string[];
  country: string;
  product: string;
  data?: any;
}

export const ClaimDetailFieldList: FC<ClaimDetailFieldListProps> = ({
  overrideConfig,
  forms,
  country,
  product,
  data,
}) => {
  return (
    <>
      {forms?.map(keyName => (
        <ClaimDetailItem
          overrideConfig={overrideConfig}
          key={keyName}
          keyName={keyName}
          country={country}
          product={product}
          data={data || {}}
        />
      ))}
    </>
  );
};

const ClaimDetailItem = ({ keyName, country, product, data, overrideConfig }) => {
  const claimConfigObject = getClaimConfigObject(keyName, country, product) || {};
  const config = { ...claimConfigObject, ...overrideConfig?.[keyName] };
  const { type, name, hidden, description } = config;
  if (hidden) return null;
  switch (type) {
    case 'date':
      return (
        <ClaimBorderBottomLabel
          // required={isRequired}
          title={name}
          value={data[keyName] ? dayjs(data[keyName]).format(DATE_FORMAT.date) : '-'}
        />
      );
    case 'input':
    case 'select':
      return (
        <ClaimBorderBottomLabel
          // required={isRequired}
          title={name}
          value={data[keyName]}
        />
      );
    case 'file':
      return <ClaimImageFieldPreview label={name} subtitle={description} listImages={data[keyName]} />;
    default:
      return null;
  }
};
