//
import {
  ClaimBorderBottomLabel,
  ClaimBottomButton,
  ClaimDetailFieldList,
  ClaimDisbursementDetail,
} from 'app/components';
import { CLAIM_STATUS } from 'app/constants';
import { ImagePreviewProvider } from 'app/context';
//
import { useClaimDetail } from 'app/hooks';
import React, { useEffect } from 'react';
import { BeatLoader } from 'react-spinners';

const ClaimDetail = ({ history, match }) => {
  const { params } = match || {};
  const { id } = params || {};

  useEffect(() => {
    if (!id) {
      history.replace('/dashboard/mer/claim-tracker/');
    }
  }, [id]);

  const { claimDetail, fetching } = useClaimDetail(id);
  const { fields, claim_details, status } = claimDetail || {};

  const _renderFieldList = keyList => (
    <ClaimDetailFieldList forms={keyList} country="SGP" product="cdw" data={fields} />
  );

  if (!id) return null;
  if (fetching)
    return (
      <div className="flex w-full justify-center p-20">
        <BeatLoader color="#ddd" speedMultiplier={0.5} size={15} />
      </div>
    );
  return (
    <ImagePreviewProvider>
      <div className="flex flex-col items-center">
        <div className="relative flex w-full items-center justify-center overflow-hidden">
          <img className="w-full md:-mt-16 lg:-mt-24" src="/img/banner/banner-bg.png" />
          <div className="absolute flex flex-col items-center">
            <img className="w-24" src="/img/banner/claim-icon.svg" />
            <div className="mt-1 text-2xl font-bold text-white">Claim</div>
          </div>
        </div>
        <div className="flex w-full max-w-7xl flex-col p-7">
          <div className="flex gap-2">
            <div className="flex-1">
              <div className="claim-form z-10">
                <div className="claim-form-title">Choose a Vehicle</div>
                <div className="claim-form-subtitle">Type in the vehicle number you want to make a claim with.</div>
                <div className="relative z-10 w-full">
                  <ClaimBorderBottomLabel
                    title="Vehicle No."
                    // @ts-ignore
                    name="vehicle_reg"
                    required
                    value={claim_details?.vehicle_reg?.toUpperCase()}
                  />
                </div>
              </div>
              <ClaimDisbursementDetail country="SGP" data={claim_details} />
            </div>
            <div className="claim-form flex-1">
              <div className="claim-form-title">Claim Details</div>
              {_renderFieldList(['rental_company', 'policy_holder', 'incident_date'])}
              <div className="claim-form-title">Amount Payable or Excess Payable</div>
              {_renderFieldList(['section1excess', 'section2excess'])}
            </div>
          </div>
          <div className="claim-form">
            <div className="claim-form-title">Document Submission</div>
            {status === CLAIM_STATUS.INCOMPLETED ? (
              <div className="mb-3 text-sm text-zinc-400">
                If you do not have all the requirements on hand during the submission of this claim, you can always
                submit the missing documents through email{' '}
                <a href="mailto:hey@gigacover.com" className="text-main-green">
                  hey@gigacover.com
                </a>{' '}
                , but we encourage you to send those documents as soon as possible for us to continue processing your
                claim.
              </div>
            ) : null}
            <div className="flex gap-5">
              <div className="flex-1">
                <div className="text-sm text-main-green">For All Claims</div>
                {_renderFieldList([
                  'id_front',
                  'id_back',
                  'drivers_license_front',
                  'drivers_license_back',
                  'certificate_of_motor_insurance',
                  'rental_contract',
                  'gia_report',
                  'police_report',
                  'proof_of_excess_payable_or_paid',
                ])}
              </div>
              <div className="flex-[2]">
                <div className="flex gap-5">
                  <div className="flex-1">
                    <div className="text-sm text-main-green">Section 1</div>
                    {_renderFieldList([
                      'original_repair_invoice',
                      'photos_of_vehicle_before_repair',
                      'photos_of_vehicle_after_repair',
                    ])}
                  </div>
                  <div className="flex-1">
                    <div className="text-sm text-main-green">Section 2</div>
                    {_renderFieldList(['discharge_voucher', 'proof_of_excess_paid'])}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ClaimBottomButton />
        </div>
      </div>
    </ImagePreviewProvider>
  );
};

export default ClaimDetail;
