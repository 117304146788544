import { ROLE } from 'app/constants';
import { getDefaultDate, getMinDate } from 'app/general';
import { useLiveQuote, useMerProductList } from 'app/hooks';
import { AddOneForm, useAddOneFormStore } from 'app/modules/add-one-vehicle';
import { selectors } from 'app/redux';
import { extractPricingList, PRODUCT_MER } from 'app/redux/Data/DataSagas';
import { OverviewRedux } from 'app/redux/reducers';
import { ApiInstance } from 'app/services/api';
import { getErrorMessage, handleError } from 'app/services/apiHelper';
import { checkHasPayLater } from 'app/utilities/data-helper';
import { getRequiredFieldError } from 'app/utilities/validates';
import { isObjectExistKeys } from 'app/utils';
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PuffLoader, SyncLoader } from 'react-spinners';

const AddOneVehicle = () => {
  const history = useHistory();

  const { pricingList, listCountries, listTemplates, listUnits } = useSelector(selectors.data);
  const { user } = useSelector(selectors.user);
  const hasPayLater = checkHasPayLater(user);

  const dispatch = useDispatch();

  const { getLiveQuote, fetching, clearLiveQuoteRedux } = useLiveQuote();

  const form = useAddOneFormStore(state => state.form);
  const setForm = useAddOneFormStore(state => state.setForm);

  const [errors, setErrors] = useState<any>({});
  const [fetchingProductListing, setFetchingProductListing] = useState(false);
  const [overridePricingList, setOverridePricingList] = useState<any>();
  const [verifyErrors, setVerifyErrors] = useState<any>();

  const locationStateRef = useRef(history.location.state);
  const { currentPreFillObject, disableEdit, fromVehiclePage } = locationStateRef.current || {};

  const [fetchingDetail, setFetchingDetail] = useState(false);
  const [blockNext, setBlockNext] = useState(false);

  const { section1Options, section2Options, reduceOptions, vehicleTypeOptions } = useMerProductList(
    form?.vehicle_type,
    form?.section1excess,
    form?.section2excess,
    overridePricingList,
  );

  useEffect(() => {
    clearLiveQuoteRedux();
    setErrors({});
    _prefillData();
    window.history.replaceState({}, '');
  }, []);

  const _debounceGetInfo = useCallback(
    debounce(text => {
      _getAndPrefillInfo(text);
    }, 600),
    [],
  );

  const _cleanForm = () => {
    setForm({
      model: '',
      make: '',
      chassis: '',
      engine: '',
      vehicle_type: '',
      section1excess: '',
      section2excess: '',
      reduced_excess: '',
    });
  };

  const _getAndPrefillInfo = async (vehicleReg: string) => {
    if (!/^[A-Za-z]{1,3}[\d]{1,5}[A-Za-z]{0,3}$/g.test(vehicleReg?.toLowerCase())) {
      _cleanForm();
      setBlockNext(false);
      return;
    }
    setFetchingDetail(true);
    const res = await ApiInstance.getVehiclesList({
      page_number: 1,
      page_size: 1,
      vehicle_reg: vehicleReg,
    });
    setFetchingDetail(false);
    const { result, error } = handleError(res);
    setBlockNext(false);
    if (error) {
      _cleanForm();
      return;
    }
    const { details, configs } = result?.data?.[0] || {};
    const { model, make, chassis, engine, vehicle_type } = details || {};
    const { section1excess, section2excess, reduced_combined_excess } = configs || {};
    setForm({
      model,
      make,
      chassis,
      engine,
      vehicle_type,
      section1excess: section1excess?.toString?.(),
      section2excess: section2excess?.toString?.(),
      reduced_excess: reduced_combined_excess?.toString?.(),
    });
  };

  const _prefillData = () => {
    if (currentPreFillObject) {
      const { vehicle_reg, details, configs, unit_obj, group_id } = currentPreFillObject;
      const { company_uen, company_name, model, make, chassis, engine, contact_num, contact_email, vehicle_type } =
        details || {};
      const { section1excess, section2excess, reduced_combined_excess } = configs || {};
      setForm({
        vehicle_reg,
        company_uen,
        company_name,
        contact_num,
        contact_email,
        model,
        make,
        chassis,
        engine,
        vehicle_type,
        section1excess: section1excess?.toString?.(),
        section2excess: section2excess?.toString?.(),
        unit_id: unit_obj?.id,
        reduced_excess: reduced_combined_excess?.toString?.(),
        contract_start: getDefaultDate(),
      });
      if (group_id && user?.role === ROLE.ADMIN) getProductListing(group_id);
    } else {
      setForm({
        company_uen: form?.company_uen || user?.group_obj?.uen,
        company_name: form?.company_name || user?.group_obj?.display_name,
        unit_id: form?.unit_id || listUnits?.months?.id,
        contract_start:
          form?.contract_start &&
          dayjs(form?.contract_start).isValid() &&
          !dayjs(form?.contract_start)?.isBefore(getMinDate(hasPayLater), 'd')
            ? form?.contract_start
            : getDefaultDate(),
      });
      setOverridePricingList(undefined);
    }
  };

  const getProductListing = async group_id => {
    const body = {
      country_id: listCountries?.[PRODUCT_MER.COUNTRY]?.id,
      template_id: listTemplates?.[PRODUCT_MER.TEMPLATE]?.id,
      unit_id: listUnits?.[PRODUCT_MER.UNIT]?.id,
      group_id,
    };

    setFetchingProductListing(true);
    const res = await ApiInstance.getProductListing(body);
    setFetchingProductListing(false);

    const { result, error } = handleError(res, true);
    if (!error) {
      setOverridePricingList(extractPricingList(result?.data));
    }
  };

  const _validateForm = async () => {
    const errorObject: any = {};
    errorObject.vehicle_reg = getRequiredFieldError(form?.vehicle_reg);
    errorObject.company_uen = getRequiredFieldError(form?.company_uen);
    errorObject.company_name = getRequiredFieldError(form?.company_name);
    // errorObject.contact_num = getRequiredFieldError(form?.contact_num);
    // errorObject.contact_email = getEmailError(form.contact_email);
    errorObject.model = getRequiredFieldError(form?.model);
    errorObject.make = getRequiredFieldError(form?.make);
    errorObject.chassis = getRequiredFieldError(form?.chassis);
    errorObject.engine = getRequiredFieldError(form?.engine);
    errorObject.vehicle_type = getRequiredFieldError(form?.vehicle_type);
    errorObject.contract_start = getRequiredFieldError(form?.contract_start);
    // eslint-disable-next-line eqeqeq
    if (form?.unit_id == listUnits?.days?.id) errorObject.contract_end = getRequiredFieldError(form?.contract_end);
    //
    if (!form?.combined_excess) {
      if (!form?.section1excess) {
        errorObject.section1excess = 'This field is required';
      }
      if (!form?.section2excess) {
        errorObject.section2excess = 'This field is required';
      }
      if (!form?.section1excess && !form?.section2excess) {
        errorObject.combined_excess = 'This field is required';
      }
    }
    errorObject.reduced_excess = getRequiredFieldError(form?.reduced_excess);

    setErrors(errorObject);
    if (isObjectExistKeys(errorObject)) {
      return false;
    }
    return true;
  };

  const _onNext = async () => {
    setVerifyErrors(undefined);
    if (!(await _validateForm())) return;

    const listToFind = overridePricingList || pricingList;
    const findPricing = listToFind.find(
      findItem =>
        findItem.config_objs.section1excess === +(form?.section1excess || 0) &&
        findItem.config_objs.section2excess === +(form?.section2excess || 0) &&
        findItem.config_objs.reduced_combined_excess === +(form?.reduced_excess || 0) &&
        findItem.config_objs.combined_excess === +(form?.combined_excess || 0) &&
        findItem.config_objs.vehicle_type === form?.vehicle_type,
    );
    if (!findPricing) {
      toast.error('Could not find pricing');
      return;
    }
    const verifyInfo = {
      vehicle_reg: form?.vehicle_reg?.toUpperCase(),
      company_uen: form?.company_uen?.toUpperCase(),
      company_name: form?.company_name,
      contact_num: form?.contact_num || !currentPreFillObject ? user?.mobile : '',
      contact_email: form?.contact_email || !currentPreFillObject ? user?.email : '',
      make: form?.make,
      model: form?.model,
      chassis: form?.chassis,
      engine: form?.engine,
      section1excess: form?.section1excess || 0,
      section2excess: form?.section2excess || 0,
      combined_excess: form?.combined_excess || 0,
      reduced_excess: form?.reduced_excess || 0,
      vehicle_type: form?.vehicle_type?.toLowerCase(),
      // eslint-disable-next-line eqeqeq
      auto_renewal: form?.unit_id != listUnits?.days?.id,
      contract_start: dayjs(form?.contract_start).format('YYYY-MM-DD'),
      contract_end:
        // eslint-disable-next-line eqeqeq
        form?.unit_id == listUnits?.days?.id ? dayjs(form?.contract_end).format('YYYY-MM-DD') : undefined,
      unit_id: +(form?.unit_id || listUnits?.months?.id || 11),
      // premiums: findPricing.price_details?.unit_price,
      premium_no_gst: findPricing?.price_details?.unit_price,
      premium: findPricing?.price_details?.price_after_tax,
      pricingObject: findPricing,
    };

    const { result, error } = await getLiveQuote([verifyInfo]);
    if (error) {
      toast.error('Could not get live quote');
      return;
    }

    const { warnings } = result?.data?.[0] || {};
    const errorsObj = warnings?.[0] || {};
    const errorsObjByMessage = {};
    Object.keys(errorsObj).forEach(key => {
      errorsObjByMessage[key] = getErrorMessage(errorsObj[key].error_message, errorsObj[key].fields);
    });
    if (isObjectExistKeys(errorsObjByMessage)) {
      setVerifyErrors(errorsObjByMessage);
    } else {
      dispatch(OverviewRedux.Creators.setListUploadVehicles([verifyInfo]));
      history.push('/dashboard/mer/checkout', { fromModalAddOne: true });
    }
  };

  const _onChangeField = e => {
    const { value, name } = e?.target || {};
    if (name === 'vehicle_reg') {
      setBlockNext(true);
      _debounceGetInfo(value);
    }
    switch (name) {
      case 'section1excess':
        if (value)
          setForm({ ...form, section1excess: value, section2excess: '', combined_excess: '', reduced_excess: '' });
        else setForm({ ...form, section1excess: value });
        setErrors({ ...errors, section1excess: '', combined_excess: '' });
        break;
      case 'section2excess':
        if (value) setForm({ ...form, section2excess: value, combined_excess: '', reduced_excess: '' });
        else setForm({ ...form, section2excess: value });
        setErrors({ ...errors, section2excess: '', combined_excess: '' });
        break;
      case 'combined_excess':
        if (value)
          setForm({ ...form, combined_excess: value, section1excess: '', section2excess: '', reduced_excess: '' });
        else setForm({ ...form, combined_excess: value });
        setErrors({ ...errors, combined_excess: '', section1excess: '', section2excess: '' });
        break;
      case 'vehicle_reg':
        setForm({ ...form, vehicle_reg: value.toUpperCase() });
        setErrors({ ...errors, vehicle_reg: '' });
        break;

      default:
        setForm({ ...form, [name]: value });
        setErrors({ ...errors, [name]: '' });
        break;
    }
  };

  return (
    <div className="flex h-[100dvh] flex-col">
      <div className="flex flex-col p-4">
        <p className="text-2xl font-bold text-main-green">MER Vehicle Onboarding</p>
        <p className="text-sm text-gray-800">REDUCED MOTOR EXCESS DECLARATION FORM</p>
      </div>
      {fetchingProductListing ? (
        <div className="flex w-full justify-center p-5">
          <PuffLoader size={60} color="#aaa" />
        </div>
      ) : (
        <AddOneForm
          className="w-full max-w-5xl flex-1 self-start"
          form={form}
          errors={errors}
          verifyErrors={verifyErrors}
          disableEdit={disableEdit}
          fetchingDetail={fetchingDetail}
          onChangeField={_onChangeField}
          vehicleTypeOptions={vehicleTypeOptions}
          section1Options={section1Options}
          section2Options={section2Options}
          reduceOptions={reduceOptions}
        />
      )}
      <div className="flex justify-between border-t border-t-gray-200 p-5">
        <button className="underline" onClick={() => history.goBack()}>
          {fromVehiclePage ? 'Back' : 'Back to Home'}
        </button>
        <button
          className="flex h-12 items-center rounded-lg bg-main-green px-32 text-base text-white disabled:cursor-not-allowed disabled:bg-gray-400"
          disabled={fetching || blockNext}
          onClick={_onNext}
        >
          {fetching ? <SyncLoader color="#eaeaea" speedMultiplier={0.5} size={5} /> : 'Next'}
        </button>
      </div>
    </div>
  );
};

export default AddOneVehicle;
