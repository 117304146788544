import clsx from 'clsx';
import type { FC } from 'react';
import React from 'react';

interface TextButtonProps {
  text: string;
  className?: string;
  mt?: number;
  mb?: number;
  ml?: number;
  mr?: number;
  onClick?: () => void;
}
export const TextButton: FC<TextButtonProps> = ({ text, className, mt, mb, ml, mr, onClick }) => {
  const styles = { marginTop: mt, marginBottom: mb, marginLeft: ml, marginRight: mr };
  return (
    <button className={clsx('p-2 text-lg font-bold text-main-green', className)} style={styles} onClick={onClick}>
      {text}
    </button>
  );
};
