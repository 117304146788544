import { EyeIcon } from '@heroicons/react/outline';
import { APP_CONFIG, DATE_FORMAT } from 'app/constants';
import { getPHCurrencyString } from 'app/utilities';
import dayjs from 'dayjs';
import React from 'react';
import { useHistory } from 'react-router-dom';
import ReactTable from 'react-table';

import { Icon } from '../icon/icon';
import { StatusCell } from '../pages/claim-tracker';

export const HmoClaimTable = ({ data, totalPages, claimDetailPath, onFetchData }) => {
  const history = useHistory();

  const onGetTdProps = (state, rowInfo) => {
    return {
      onSubmit: () => {
        history.push(`${claimDetailPath}/${rowInfo.original.id}`);
      },
    };
  };

  return (
    <ReactTable
      manual
      getTdProps={onGetTdProps}
      columns={CLAIM_TABLE_COLUMNS}
      data={data}
      defaultPageSize={APP_CONFIG.overview.DEFAULT_PAGE_SIZE}
      loading={false}
      nextText={`Next >`}
      previousText={`< Previous`}
      pages={totalPages}
      resizable={false}
      onFetchData={onFetchData}
      noDataText={`Not Found`}
      style={{ border: 'none' }}
      // eslint-disable-next-line tailwindcss/no-custom-classname
      className="app-table"
    />
  );
};

const CLAIM_TABLE_COLUMNS = [
  {
    Header: 'User ID',
    sortable: false,
    filterable: false,
    accessor: 'user_id',
    Cell: data => <span>{data.value}</span>,
  },
  {
    Header: 'Insurer Name',
    sortable: false,
    filterable: false,
    accessor: 'claim_details.full_name',
    Cell: data => <span>{data.value}</span>,
  },
  {
    Header: 'Claim Type',
    sortable: false,
    filterable: false,
    accessor: 'claim_details',
    Cell: props => <ClaimTypeCell {...props} />,
  },
  {
    Header: 'Date Submitted',
    sortable: false,
    filterable: false,
    accessor: 'created_at',
    Cell: data => <span>{data.value ? dayjs(data.value).format(DATE_FORMAT.dateSlash) : '-'}</span>,
  },
  {
    Header: 'Last Updated',
    sortable: false,
    filterable: false,
    accessor: 'updated_at',
    Cell: data => <span>{data.value ? dayjs(data.value).format(DATE_FORMAT.dateSlash) : '-'}</span>,
  },
  {
    Header: 'Claim Amount',
    sortable: false,
    filterable: false,
    accessor: 'amount_paid',
    Cell: data => <span>{getPHCurrencyString(data.value)}</span>,
  },
  {
    Header: 'Status',
    sortable: false,
    filterable: false,
    accessor: 'status',
    Cell: data => <StatusCell status={data.value} />,
  },
  {
    Header: '',
    sortable: false,
    filterable: false,
    Cell: cellInfo => (
      <button className=" rounded-md bg-[#DE1B77] p-2" onClick={cellInfo.tdProps.rest.onSubmit}>
        <EyeIcon className="h-5 w-5 text-white" />
      </button>
    ),
  },
];

const ClaimTypeCell = props => {
  const { value } = props;
  const { type } = value || {};
  switch (type) {
    case 'inpatient':
      return (
        <div className="flex items-center gap-2">
          <Icon name="inpatient" />
          In-Patient
        </div>
      );
    case 'outpatient':
      return (
        <div className="flex items-center gap-2">
          <Icon name="outpatient" />
          Out-Patient
        </div>
      );

    default:
      return null;
  }
};
