import { PRODUCT_REGIONS } from 'app/constants';
import { numberWithCommas } from 'app/utils';
import { isNaN } from 'lodash';

export function generateReferencePaynow() {
  return `paynow-mer-${+new Date()}`;
}

export const isObjectExistKeys = (errors = {}) => Object.keys(errors).some(key => errors[key]);

export const getCurrencyString = (value, country, fixed, negative?: boolean) => {
  const symbol = negative || value < 0 ? '- ' : '';
  if (!value || isNaN(value)) return '-';
  let valueString = value.toString();
  if (fixed) valueString = parseFloat(Math.abs(value).toString()).toFixed(fixed);
  else valueString = parseFloat(Math.abs(value).toString());
  switch (country) {
    case PRODUCT_REGIONS.SG:
      return `${symbol}$${numberWithCommas(valueString, ',', '.')}`;
    case PRODUCT_REGIONS.PH:
      return `${symbol}P${numberWithCommas(valueString, '.', ',')}`;
    default:
      return `${symbol}$${numberWithCommas(valueString, ',', '.')}`;
  }
};

export const getPHCurrencyString = value => getCurrencyString(value, PRODUCT_REGIONS.PH, 2);
