import { SimpleModal } from 'app/components/simple-modal';
import React, { createContext, useContext, useMemo, useState } from 'react';

interface ImagePreviewContextProps {
  showPreview: boolean;
  setShowPreview: React.Dispatch<React.SetStateAction<boolean>>;
  fileList: any[];
  setFileList: React.Dispatch<React.SetStateAction<any[]>>;
  onOpenPreview: (list: any[]) => void;
}

const ImagePreviewContext = createContext({} as ImagePreviewContextProps);

const ImagePreviewProvider = ({ children }) => {
  const [showPreview, setShowPreview] = useState(false);
  const [fileList, setFileList] = useState<any[]>([]);

  const onOpenPreview = (list: any[]) => {
    setFileList(list);
    setShowPreview(true);
  };

  const value = {
    showPreview,
    setShowPreview,
    fileList,
    setFileList,
    onOpenPreview,
  };
  return (
    <ImagePreviewContext.Provider value={value}>
      {children}
      <ModalPreviewImage />
    </ImagePreviewContext.Provider>
  );
};

export { ImagePreviewContext, ImagePreviewProvider };

export function ImageItemWithPreview({
  fileList,
  fileName,
  showFileName = true,
  fileIcon,
  error,
}: {
  fileList: any[];
  fileName?: string;
  showFileName?: boolean;
  fileIcon?: string;
  error?: string;
}) {
  const { onOpenPreview } = useContext(ImagePreviewContext);

  return (
    <div>
      {fileList.length > 0 && (
        <button onClick={() => onOpenPreview(fileList)} className="flex items-center p-1">
          <img src={fileIcon || '/img/smallIcon/attachment-file.svg'} alt="document" width={25} />
          {showFileName ? (
            <span className="w-40 overflow-hidden text-ellipsis whitespace-nowrap text-xs text-gray-400">
              {fileName || fileList[0]?.name}
            </span>
          ) : null}
          {error ? <div className="error-text text-right font-medium"> - {error}</div> : null}
        </button>
      )}
    </div>
  );
}

const ModalPreviewImage = () => {
  const { fileList, showPreview, setShowPreview } = useContext(ImagePreviewContext);

  const [currentImageIdx, setCurrentImageIdx] = useState(0);
  const currentFile = useMemo(() => fileList?.[currentImageIdx] || null, [fileList, currentImageIdx]);

  const imageUrl = useMemo(() => {
    if (currentFile?.type && currentFile.type.includes('image')) return URL.createObjectURL(currentFile); // file
    if (currentFile?.content_type && currentFile.content_type?.includes('image')) return currentFile.url; // file url
    if (currentFile?.url) return currentFile.url; // file url
    if (typeof currentFile === 'string') return currentFile; // url string
    return '/img/smallIcon/attachment-file.svg'; // default icon
  }, [currentFile]);

  const _onPrevious = () => {
    if (currentImageIdx <= 0) return;
    setCurrentImageIdx(currentImageIdx - 1);
  };
  const _onNext = () => {
    if (currentImageIdx + 1 > fileList.length - 1) return;
    setCurrentImageIdx(currentImageIdx + 1);
  };

  return (
    <SimpleModal isVisible={showPreview} hideModalCB={() => setShowPreview(false)}>
      <div className="flex w-full flex-col items-center">
        <div className="flex w-full items-center">
          <button disabled={currentImageIdx === 0} className="rounded-sm bg-gray-50 p-1" onClick={_onPrevious}>
            <img
              className="h-5 w-5"
              src="https://storage.googleapis.com/gigacover-production-public/assets/arrow-left.svg"
              alt="left-arrow"
            />
          </button>
          <div className="mx-2 flex flex-1 flex-col items-center">
            <img className="h-64 w-full object-contain" src={imageUrl} alt="img-uploaded" />
            <div className="h-20 w-64 break-words text-center text-xs">
              {currentFile?.name ||
                currentFile?.filename ||
                cutNameFromUrl(imageUrl) ||
                `File number ${currentImageIdx + 1}`}
            </div>
          </div>
          <button
            disabled={currentImageIdx === fileList.length - 1}
            className="rounded-sm bg-gray-50 p-1"
            onClick={_onNext}
          >
            <img
              className="h-5 w-5"
              src="https://storage.googleapis.com/gigacover-production-public/assets/arrow-right.svg"
              alt="right-arrow"
            />
          </button>
        </div>
        <div className="text-center text-base text-gray-800">
          {currentImageIdx + 1} / {fileList.length}
        </div>
        <button className="text-lg text-neutral-400" onClick={() => setShowPreview(false)}>
          CLOSE
        </button>
      </div>
    </SimpleModal>
  );
};

const cutNameFromUrl = (url: string) => {
  return url.substring(url.indexOf('/files/') + 1, url.lastIndexOf('?Expires'));
};
