import type { FC } from 'react';
import React from 'react';
import { useHistory } from 'react-router-dom';

//
import { Button } from '../button/button';
import { TextButton } from '../text-button';

interface ClaimBottomButtonProps {
  fetching?: boolean;
  onSubmit?: () => void;
}
export const ClaimBottomButton: FC<ClaimBottomButtonProps> = ({ fetching, onSubmit }) => {
  const history = useHistory();
  const _onBackBtPress = () => {
    history.goBack();
  };
  return (
    <div className="mt-7 flex items-center justify-between">
      <TextButton text={'BACK'} onClick={_onBackBtPress} />
      {onSubmit ? <Button fetching={fetching} small text={'SUBMIT'} onClick={onSubmit} /> : null}
    </div>
  );
};
