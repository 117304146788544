import dayjs from 'dayjs';

export const addOpacityToHex = (hex: string, opacity: number) => {
  const rgb = hexToRgb(hex);
  if (!rgb) return 'rgba(0, 0, 0, 0)';
  return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${opacity})`;
};

export const hexToRgb = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

export const dateToExcelNumber = (date: Date) => {
  const epoch = Date.UTC(1899, 11, 30);
  return (date.getTime() - epoch) / (1000 * 60 * 60 * 24);
};

export const excelNumberToDate = (excelNumber: number) => {
  const epoch = Date.UTC(1899, 11, 30);
  return new Date(epoch + excelNumber * (1000 * 60 * 60 * 24));
};

export const checkBeforeMidDay = () => {
  const currentTimeInSingapore = dayjs().tz('Asia/Singapore');
  // return (
  //   currentTimeInSingapore.hour() < 10 || (currentTimeInSingapore.hour() === 10 && currentTimeInSingapore.minute() < 30)
  // );
  return currentTimeInSingapore.hour() < 12;
};
export const ERROR_WHEN_OVER_MID_DAY =
  'Sorry, it is now after 12.00pm SGT - you are not able to purchase a policy with coverage starting today. Please select another start date';

export const getMinDate = (hasPayLater: boolean) =>
  hasPayLater
    ? dayjs().subtract(7, 'd').startOf('d').toDate()
    : checkBeforeMidDay()
    ? dayjs().startOf('d').toDate()
    : dayjs().startOf('d').add(1, 'd').toDate();

export const getDefaultDate = () =>
  checkBeforeMidDay() ? dayjs().startOf('d').toDate() : dayjs().startOf('d').add(1, 'd').toDate();
