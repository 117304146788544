import { CheckCircleIcon } from '@heroicons/react/solid';
import { CardView } from 'app/components/card-view';
import { COMPANY_UEN, PAYMENT_METHOD } from 'app/constants';
import { selectors } from 'app/redux';
import { checkHasCard, checkHasPayNow } from 'app/utilities/data-helper';
import clsx from 'clsx';
import React from 'react';
import { useSelector } from 'react-redux';

import { PaynowInfo } from './paynow-info';

export const SelectPaymentMethod = ({
  paymentMethod,
  totalPrice,
  referenceNumber,
  setPaymentMethod,
  className,
}: {
  paymentMethod: string;
  totalPrice: number;
  referenceNumber: string;
  setPaymentMethod: (value: string) => void;
  className?: string;
}) => {
  const { user } = useSelector(selectors.user);

  const hasCard = checkHasCard(user);
  const hasPayNow = checkHasPayNow(user);

  return (
    <div className={clsx('flex flex-col gap-10 rounded-md border border-gray-200 p-6', className)}>
      <div className="text-lg">Pay with</div>
      <div className="flex gap-4">
        <PaymentItem
          imgSrc="/img/pay/credit-card.png"
          currentValue={paymentMethod}
          value={PAYMENT_METHOD.CARD}
          onSelect={setPaymentMethod}
        />
        {hasPayNow ? (
          <PaymentItem
            imgSrc="/img/pay/paynow.png"
            currentValue={paymentMethod}
            value={PAYMENT_METHOD.PAYNOW}
            onSelect={setPaymentMethod}
          />
        ) : null}
      </div>
      <div className="w-full border-t border-t-gray-300" />
      {paymentMethod === PAYMENT_METHOD.CARD ? (
        <div className="flex flex-col items-center gap-6">
          <div className="text-lg">Card infomation</div>
          {!hasCard ? (
            <div className="mb-5 flex items-center text-base text-red-600">
              <img src="/img/smallIcon/red-warning.svg" className="mr-2 h-4" />
              Card is not yet registered. Please add your card to continue with the transaction
            </div>
          ) : null}
          <CardView />
        </div>
      ) : paymentMethod === PAYMENT_METHOD.PAYNOW ? (
        <div className="flex flex-col items-center gap-6">
          <div className="text-lg">PayNow infomation</div>
          <PaynowInfo companyUen={COMPANY_UEN} referenceNumber={referenceNumber} amount={totalPrice?.toFixed(2)} />
        </div>
      ) : null}
    </div>
  );
};

const PaymentItem = ({ imgSrc, value, currentValue, onSelect }) => {
  const isSelected = currentValue === value;

  return (
    <button className="relative h-40 flex-1 rounded-lg border border-gray-500 p-5" onClick={() => onSelect(value)}>
      {isSelected ? (
        <CheckCircleIcon className="absolute top-5 right-5 h-5 rounded-full text-main-green" />
      ) : (
        <div className="absolute top-5 right-5 h-5 w-5 rounded-full border border-main-green bg-white" />
      )}
      <img src={imgSrc} className="h-full w-full object-contain" alt="method" />
    </button>
  );
};
