import { ERROR_WHEN_OVER_MID_DAY, getMinDate } from 'app/general';
import { selectors } from 'app/redux';
import { OverviewRedux } from 'app/redux/reducers';
import { ApiInstance } from 'app/services/api';
import { handleError } from 'app/services/apiHelper';
import dayjs from 'dayjs';
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';

export const usePayment = successCB => {
  const { user } = useSelector(selectors.user);
  const { liveQuotesData, batchObj } = useSelector(selectors.overview);

  const [fetching, setFetching] = useState(false);

  const dispatch = useDispatch();

  const onPaymentWithCard = async () => {
    const liveQuotesObject = liveQuotesData?.[0] || {};
    if (!checkLiveQuote(liveQuotesObject)) return;
    const body: any = {
      user_id: user?.id,
      user_pm_id: user?.cardDetail?.id,
    };
    if (batchObj?.id) body.batch_order_id = batchObj?.id;
    else body.order_id = liveQuotesObject?.orders?.id;

    setFetching(true);
    const res = await ApiInstance.userPayment(body);
    const { error, result } = handleError(res, true);
    if (error) {
      setFetching(false);
      return;
    }

    if (result?.data?.payment_status !== 'succeeded') {
      setFetching(false);
      toast.error(result?.data?.failure_message || `Payment failed , status : ${result?.data?.payment_status}`);
      return;
    }
    const bodyReconcile: any = {
      user_id: user?.id,
      payment_status: 'succeeded',
    };
    if (batchObj?.id) bodyReconcile.batch_order_id = batchObj?.id;
    else bodyReconcile.order_id = liveQuotesObject?.orders?.id;
    const resReconcile = await ApiInstance.reconcilePayment(bodyReconcile);

    setFetching(false);
    const { error: errorReconcile } = handleError(resReconcile, true);
    if (errorReconcile) return;

    // handle success
    dispatch(OverviewRedux.Creators.setOriginalFile(null));
    successCB();
  };

  const onPayNow = async refNum => {
    const liveQuotesObject = liveQuotesData?.[0] || {};
    if (!checkLiveQuote(liveQuotesObject)) return;
    let body = {};
    if (batchObj?.id) {
      body = {
        payment_ref: refNum,
        batch_order_id: batchObj.id,
      };
    } else {
      body = {
        payment_ref: refNum,
        order_id: liveQuotesObject?.orders?.id,
      };
    }
    setFetching(true);
    const res = await ApiInstance.putBatchOrder(body);
    setFetching(false);
    const { error } = handleError(res, true);
    if (!error) {
      // handle success
      dispatch(OverviewRedux.Creators.setOriginalFile(null));
      successCB();
    }
  };

  const onPayLater = async cb => {
    const liveQuotesObject = liveQuotesData?.[0] || {};
    const body: any = {
      user_id: user?.id,
      payment_status: 'succeeded',
      user_pm_id: user?.payLaterObj?.id,
    };
    if (batchObj?.id) body.batch_order_id = batchObj.id;
    else body.order_id = liveQuotesObject?.orders?.id;
    setFetching(true);
    const res = await ApiInstance.reconcilePayment(body);
    setFetching(false);
    const { error } = handleError(res, true);
    if (!error) {
      dispatch(OverviewRedux.Creators.setOriginalFile(null));
      cb();
    }
  };

  return { fetching, onPaymentWithCard, onPayNow, onPayLater };
};

const checkLiveQuote = (liveQuotesObject: any) => {
  const { orders } = liveQuotesObject || {};
  const { order_details_collection } = orders || {};
  const minDate = getMinDate(false);
  for (let i = 0; i < order_details_collection.length; i += 1) {
    if (dayjs(order_details_collection[i].start_date).isBefore(minDate)) {
      toast.error(ERROR_WHEN_OVER_MID_DAY);
      return false;
    }
  }
  return true;
};
