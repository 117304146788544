import { CellDatePicker, SimpleSwitch, StatusText } from 'app/components';
import { APP_CONFIG, DATE_FORMAT, OVERVIEW_LIST_FIELDS, ROLE, SHOWING_OVERVIEW_STATUS } from 'app/constants';
import { checkAllowAddVehicles } from 'app/utilities/data-helper';
import dayjs from 'dayjs';
import moment from 'moment';
import React, { useMemo } from 'react';
import ReactTable from 'react-table';
import { Tooltip as ReactTooltip } from 'react-tooltip';

export const OverviewTable = ({
  onGetTdProps,
  listVehicles,
  fetching,
  totalPages,
  currentPage = 0,
  role,
  onFetchData,
  user,
  onClickEdit,
}) => {
  const allowAddVehicles = checkAllowAddVehicles(user);

  const _renderUpcomingPolicyCell = cellInfo => {
    const { showing_status, upcoming_status } = cellInfo.original || {};
    const isSpecialStatus = checkSpecialStatus(showing_status);

    const { end_date, start_date } = cellInfo.original;

    const upcoming_date = cellInfo.value;

    if (isSpecialStatus) return <span>{dayjs(start_date).format(DATE_FORMAT.dateSlash)}</span>;
    if (!!upcoming_date && upcoming_status !== SHOWING_OVERVIEW_STATUS.PAID && allowAddVehicles) {
      const { onChange } = cellInfo.tdProps.rest;
      const minDate = dayjs(end_date || null)
        .add(1, 'd')
        .toDate();
      return <CellDatePicker value={dayjs(upcoming_date).toDate()} minDate={minDate} onChange={onChange} />;
    }
    return (
      <span>
        {upcoming_date && moment(upcoming_date).isValid() ? moment(upcoming_date).format(APP_CONFIG.format.date) : '-'}
      </span>
    );
  };

  return (
    <div>
      <ReactTable
        manual
        getTdProps={onGetTdProps}
        columns={[
          {
            Header: 'Vehicle Registration',
            sortable: false,
            filterable: false,
            accessor: OVERVIEW_LIST_FIELDS.VEHICLE_REG,
            Cell: cellInfo => <span>{cellInfo.value?.toUpperCase()}</span>,
          },
          {
            Header: 'Policy Number',
            sortable: false,
            filterable: false,
            accessor: OVERVIEW_LIST_FIELDS.CODE,
            Cell: cellInfo => {
              const { showing_status } = cellInfo.original || {};
              const isExpired = showing_status === SHOWING_OVERVIEW_STATUS.EXPIRED;
              if (isExpired) return null;
              return <span>{cellInfo.value}</span>;
            },
          },
          {
            Header: 'Policy Start',
            sortable: false,
            filterable: false,
            accessor: OVERVIEW_LIST_FIELDS.START_DATE,
            width: 120,
            Cell: cellInfo => {
              const { showing_status } = cellInfo.original || {};
              const isSpecialStatus = checkSpecialStatus(showing_status);
              const isExpired = showing_status === SHOWING_OVERVIEW_STATUS.EXPIRED;
              if (isSpecialStatus || isExpired) return null;
              return <span>{dayjs(cellInfo.value).format(DATE_FORMAT.dateSlash)}</span>;
            },
          },
          {
            Header: 'Policy End',
            sortable: false,
            filterable: false,
            accessor: OVERVIEW_LIST_FIELDS.END_DATE,
            width: 120,
            Cell: cellInfo => {
              const { showing_status } = cellInfo.original || {};
              const isSpecialStatus = checkSpecialStatus(showing_status);
              const isExpired = showing_status === SHOWING_OVERVIEW_STATUS.EXPIRED;
              if (isSpecialStatus || isExpired) return null;
              return <span>{dayjs(cellInfo.value).format(DATE_FORMAT.dateSlash)}</span>;
            },
          },
          {
            Header: 'Status',
            Cell: cellInfo => {
              const { showing_status } = cellInfo.original || {};
              const isSpecialStatus = checkSpecialStatus(showing_status);
              const isExpired = showing_status === SHOWING_OVERVIEW_STATUS.EXPIRED;
              if (isSpecialStatus || isExpired) return null;
              return <OverviewStatusCell status={cellInfo.value} />;
            },
            sortable: false,
            filterable: false,
            accessor: OVERVIEW_LIST_FIELDS.SHOWING_STATUS,
            className: 'border-right-tb',
            headerClassName: 'border-right-tb',
          },

          {
            Header: 'Upcoming Policy',
            sortable: false,
            filterable: false,
            accessor: OVERVIEW_LIST_FIELDS.UPCOMING_DATE,
            width: 160,
            Cell: _renderUpcomingPolicyCell,
          },
          {
            Header: 'Upcoming Status',
            sortable: false,
            filterable: false,
            accessor: OVERVIEW_LIST_FIELDS.UPCOMING_STATUS,
            Cell: cellInfo => {
              const { showing_status, need_confirm } = cellInfo.original || {};
              const isSpecialStatus = checkSpecialStatus(showing_status);
              return (
                <div className="flex w-40 items-center justify-start">
                  <OverviewStatusCell status={isSpecialStatus ? showing_status : cellInfo.value} />
                  {(need_confirm || cellInfo.value === SHOWING_OVERVIEW_STATUS.PREPARED) && role === ROLE.ADMIN && (
                    <button className="ml-2" onClick={() => onClickEdit(cellInfo.original)}>
                      <img className="w-5" src="/img/smallIcon/editIcon.png" alt="edit" />
                    </button>
                  )}
                </div>
              );
            },
            width: 250,
          },
          {
            Header: 'Unit',
            sortable: false,
            filterable: false,
            accessor: 'unit',
            Cell: cellInfo => {
              const { unit_obj } = cellInfo.original || {};
              return (
                <div className="rounded border border-gray-500 p-1.5 text-sm">
                  {unit_obj?.name === 'days'
                    ? 'Daily'
                    : unit_obj?.name === 'years'
                    ? 'Yearly'
                    : unit_obj?.name === 'months'
                    ? 'Monthly'
                    : ''}
                </div>
              );
            },
          },
          {
            Header: 'Auto-renewal',
            sortable: false,
            filterable: false,
            accessor: 'is_auto_renewal',
            Cell: cellInfo => {
              const { onChange } = cellInfo.tdProps.rest;
              const { unit_obj } = cellInfo.original || {};
              return (
                <div data-tooltip-id="switcher">
                  <SimpleSwitch
                    checked={cellInfo.value}
                    disabled={!allowAddVehicles || unit_obj?.name === 'days'}
                    onChange={onChange}
                  />
                </div>
              );
            },
          },
        ]}
        data={listVehicles}
        defaultPageSize={APP_CONFIG.overview.DEFAULT_PAGE_SIZE}
        loading={fetching}
        nextText={`Next >`}
        previousText={`< Previous`}
        page={currentPage}
        pages={totalPages}
        resizable={false}
        onFetchData={onFetchData}
        noDataText="Not Found"
        style={{ border: 'none' }}
        // eslint-disable-next-line tailwindcss/no-custom-classname
        className="app-table"
      />
      {!allowAddVehicles ? (
        <ReactTooltip id="switcher">
          <span>Please add payment card to perform this action</span>
        </ReactTooltip>
      ) : null}
    </div>
  );
};

const checkSpecialStatus = status =>
  status === SHOWING_OVERVIEW_STATUS.PAID || status === SHOWING_OVERVIEW_STATUS.PENDING;

export const OverviewStatusCell = ({ status }) => {
  const color = useMemo(() => {
    switch (status) {
      case SHOWING_OVERVIEW_STATUS.IN_FORCE:
      case SHOWING_OVERVIEW_STATUS.PAID:
        return '#27AE60';
      case SHOWING_OVERVIEW_STATUS.PENDING:
        return '#DE1B77';
      case SHOWING_OVERVIEW_STATUS.PREPARED:
      case SHOWING_OVERVIEW_STATUS.RENEWING:
        return '#FFB700';
      case SHOWING_OVERVIEW_STATUS.EXPIRING:
        return '#EB5757';
      default:
        return '';
    }
  }, [status]);
  return <StatusText status={status} color={color} />;
};
