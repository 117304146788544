import { applyMiddleware, compose, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
// import { createLogger } from 'redux-logger';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';

import immutablePersistenceTransform from './immutable-persistence-transfrom';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['fetching', 'error', 'blacklistPersist', 'app', 'heading'],
  transforms: [immutablePersistenceTransform],
};

// creates the store
export default (rootReducer, rootSaga) => {
  /* ------------- Redux Configuration ------------- */

  const middleware = [];
  const enhancers = [];

  /* ------------- Saga Middleware ------------- */

  const sagaMiddleware = createSagaMiddleware();
  // @ts-ignore
  middleware.push(sagaMiddleware);
  if (process.env.NODE_ENV === 'development') {
    // const logger = createLogger();
    // middleware.push(logger);
  }
  // middleware.push(client.middleware())

  /* ------------- Assemble Middleware ------------- */

  // @ts-ignore
  enhancers.push(applyMiddleware(...middleware));

  /* ------------- React Redux Firebase ------------- */
  // enhancers.push(reactReduxFirebaseEnhancer)

  const persistedReducer = persistReducer(persistConfig, rootReducer);

  /* ------------- createStore ------------- */

  // @ts-ignore
  const store = createStore(persistedReducer, compose(...enhancers));

  const persistor = persistStore(store);

  // kick off root saga
  sagaMiddleware.run(rootSaga);

  return { store, persistor };
};
