//
import { ClaimBorderBottomLabel, ClaimDetailFieldList, ClaimDisbursementDetail, ClaimPageLayout } from 'app/components';
import { CheckRadio } from 'app/components/check-box';
import { DATE_FORMAT, PRODUCT_REGIONS } from 'app/constants';
//
import { useClaimDetail } from 'app/hooks';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { BeatLoader } from 'react-spinners';

const HmoClaimDetail = ({ history, match }) => {
  const { params } = match || {};
  const { id } = params || {};

  useEffect(() => {
    if (!id) {
      history.replace('/dashboard/hmo/claim-tracker');
    }
  }, [id]);

  const { claimDetail, fetching } = useClaimDetail(id);
  const { fields, claim_details } = claimDetail || {};

  const isInpatient = claim_details?.type === 'inpatient';

  const _renderFieldList = keyList => (
    <ClaimDetailFieldList forms={keyList} country={PRODUCT_REGIONS.PH} product="cdw" data={fields} />
  );

  if (!id) return null;
  if (fetching)
    return (
      <div className="flex w-full justify-center p-20">
        <BeatLoader color="#ddd" speedMultiplier={0.5} size={15} />
      </div>
    );
  return (
    <ClaimPageLayout>
      <div className="flex gap-2">
        <div className="claim-form z-10 flex-1">
          <div className="claim-form-title">Choose an Employee</div>
          <div className="claim-form-subtitle">
            Type in the user id and name you want to make a claim with. If employee is not present, add the employee in
            the dashboard page.
          </div>
          <div className="relative z-10 w-full">
            <ClaimBorderBottomLabel title="User ID" required value={claim_details?.user_id} />
            <ClaimBorderBottomLabel title="Full Name" required value={claim_details?.full_name} />
          </div>
        </div>
        <div className="claim-form flex-1">
          <div className="claim-form-title">Claim Details</div>
          <ClaimBorderBottomLabel
            title="Incident Date"
            required
            value={dayjs(claim_details?.incident_date).format(DATE_FORMAT.date)}
          />
          <div className="claim-form-subtitle">
            Choose whether the employee is an In Patient (stays in a hospital while under treatment or Out Patient
            (under treatment without being admitted to a hospital).
          </div>
          <CheckRadio centerText type="donus" checked={isInpatient}>
            In Patient
          </CheckRadio>
          <CheckRadio centerText type="donus" checked={!isInpatient}>
            Out Patient
          </CheckRadio>
        </div>
      </div>
      <ClaimDisbursementDetail country={PRODUCT_REGIONS.PH} data={claim_details} />
      <div className="claim-form">
        <div className="claim-form-title">Document Submission</div>
        <div className="flex gap-5">
          <div className="flex-1">
            {_renderFieldList([
              isInpatient ? 'inpatient_form' : 'outpatient_form',
              'hospital_medical_bill',
              'prescription',
            ])}
          </div>
          <div className="flex-1">
            {_renderFieldList(['medical_certificate', 'hospital_statement', 'lab_requests', 'government_id'])}
          </div>
        </div>
      </div>
    </ClaimPageLayout>
  );
};

export default HmoClaimDetail;
