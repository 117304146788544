import { ApiInstance } from 'app/services/api';
import { handleError } from 'app/services/apiHelper';
import dayjs from 'dayjs';
import { useState } from 'react';

export const useSubmitClaim = (formData, setFormData, sucessCB) => {
  const [fetching, setFetching] = useState(false);
  const [claimId, setClaimId] = useState<string>();
  const [errorMessage, setErrorMessage] = useState('');

  const clearErrorMessage = () => {
    setErrorMessage('');
  };

  const _getClaimId = async infoForm => {
    if (claimId) return claimId;
    const createFormResponse = await ApiInstance.createClaim(infoForm);
    const { result, error } = handleError(createFormResponse);
    if (error) {
      setErrorMessage(error.message || 'Error happen');
      setFetching(false);
      return undefined;
    }
    setClaimId(result?.data?.id);
    return result?.data?.id;
  };

  const _uploadImage = async id => {
    const imagesList: any = []; // contain info of formData field

    const imageForms: any = []; // array of request body

    Object.keys(formData).forEach(key => {
      const item = formData[key];
      if (Array.isArray(item)) {
        item
          .filter(fieldData => !fieldData.url)
          .forEach((fieldData, index) => {
            imagesList.push({ key, index, ...fieldData });
            const fileExt = fieldData.file.name.split('.').pop();
            const form = new FormData();
            form.append(key, fieldData.file, `${id}_${key}_${index}.${fileExt}`);
            imageForms.push(form);
          });
      }
    });

    const uploadFileRes = await Promise.all(imageForms.map(body => ApiInstance.uploadFile(body)));

    const editFormData = { ...formData };
    let uploadImageFailed = false;
    uploadFileRes.forEach((res, index) => {
      const { result, error } = handleError(res);
      if (error) {
        uploadImageFailed = true;
      }
      const { key: fieldKey, index: fieldIndex } = imagesList[index];
      if (editFormData[fieldKey]?.[fieldIndex]) {
        editFormData[fieldKey][fieldIndex].uploadData = result?.data?.[0];
        editFormData[fieldKey][fieldIndex].error = error ? error?.message || 'Upload image failed' : '';
      }
    });

    setFormData(editFormData);

    if (uploadImageFailed) {
      setErrorMessage('Upload image failed');
      setFetching(false);
      return false;
    }
    return true;
  };

  const _confirmClaim = async (id, infoForm) => {
    setErrorMessage('');
    const body = { ...infoForm };
    Object.keys(formData).forEach(key => {
      const item = formData[key];
      if (!Array.isArray(item)) {
        // override some field
        switch (key) {
          case 'paynow_mobile':
            body.claim_details[key] = `+65${item}`;
            break;

          default:
            body.claim_details[key] = item;
            break;
        }
      } else {
        body.claim_files[key] = item.map(data => data.uploadData).filter(Boolean);
      }
    });
    const confirmClaimResponse = await ApiInstance.confirmClaim(id, body);
    const { error: errorConfirmClaim } = handleError(confirmClaimResponse);
    if (errorConfirmClaim) {
      setErrorMessage(errorConfirmClaim.message || 'Error happen');
      setFetching(false);
      return;
    }
    setFetching(false);
    sucessCB();
  };

  const onSubmitClaim = async extraData => {
    setFetching(true);
    const infoForm: any = {
      // user_id: user.id,
      // product_id: formData?.vehicleObject?.order_details_obj?.product_id,
      ...extraData,
      // refnum: `CLAIM#${dayjs().format('x')}`,
      incident_date: dayjs(formData?.incident_date).format('YYYY-MM-DD'),
      // description: 'description',
      claim_details: {},
      claim_files: {},
    };

    const id = await _getClaimId(infoForm);
    if (!id) {
      // toast.error('Claim ID not found');
      setFetching(false);
      return;
    }

    const updateImageSuccess = await _uploadImage(id);
    if (!updateImageSuccess) {
      setFetching(false);
      return;
    }

    _confirmClaim(id, infoForm);
  };

  const clearClaimId = () => {
    setClaimId('');
  };

  return {
    clearClaimId,
    onSubmitClaim,

    clearErrorMessage,
    errorMessage,
    fetching,
  };
};
