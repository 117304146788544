import * as R from 'ramda';
import { createActions, createReducer } from 'reduxsauce';
import * as Immutable from 'seamless-immutable';

import { reducerPrefixFormat } from '../common';

const stateKey = 'user';

/* ------------- Initial State ------------- */
const INITIAL_STATE = Immutable.from({
  user: null,
  token: null,
  refreshToken: null,
  swiftToken: null,
  authenticated: false,
  cardDetail: null,
});

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions(
  {
    loginRequest: ['body', 'callback'],
    loginSucceed: ['user', 'token', 'refreshToken'],
    setToken: ['token'],
    setRefreshToken: ['refreshToken'],
    setSwiftToken: ['swiftToken'],

    logoutRequest: null,

    logoutSucceed: null,

    requestFailure: ['error'],
    setCardDetail: ['data'],
    getUserInfo: null,
    setUserInfo: ['data'],
  },
  {
    prefix: reducerPrefixFormat(stateKey),
  },
);

/* ------------- Reducers ------------- */
const loginSucceed = (state, { user, token, refreshToken }) =>
  state.merge({ authenticated: true, user, token, refreshToken });

const setToken = (state, { token, swiftToken }) => state.merge({ token, swiftToken });
const setRefreshToken = (state, { refreshToken }) => state.merge({ refreshToken });
const setSwiftToken = (state, { swiftToken }) => state.merge({ swiftToken });

const logoutRequest = R.identity;

const logoutSucceed = state => state.merge({ authenticated: false, user: null, token: null });
const setCardDetail = (state, { data }) => state.merge({ cardDetail: data });
const setUserInfo = (state, { data }) => state.merge({ user: data });

const requestFailure = R.identity;

/* ------------- Hookup Reducers To Types ------------- */
const reducer = createReducer(INITIAL_STATE, {
  [Types.LOGIN_SUCCEED]: loginSucceed,
  [Types.SET_TOKEN]: setToken,
  [Types.SET_REFRESH_TOKEN]: setRefreshToken,
  [Types.SET_SWIFT_TOKEN]: setSwiftToken,

  [Types.LOGOUT_REQUEST]: logoutRequest,

  [Types.LOGOUT_SUCCEED]: logoutSucceed,

  [Types.REQUEST_FAILURE]: requestFailure,
  [Types.SET_CARD_DETAIL]: setCardDetail,
  [Types.SET_USER_INFO]: setUserInfo,
});

const reducerMap = { [stateKey]: reducer };

/* ------------- Selectors ------------- */
const getReducerState = state => state[stateKey];

/* ------------- Export ------------- */
export default {
  // default export
  INITIAL_STATE,
  Types,
  Creators,

  stateKey,
  getReducerState,
  reducerMap,
};
