import { API_CONFIG } from 'app/constants';
import { ApiInstance } from 'app/services/api';
import { handleError } from 'app/services/apiHelper';
import { globalLoading } from 'react-global-loading';
import { call, put, takeLatest } from 'redux-saga/effects';

import DataActions from '../Data/DataRedux';
import OverviewRedux from '../Overview/OverviewRedux';
import UserRedux from './UserRedux';

function* loginRequest({ body, callback }) {
  try {
    const res = yield call(ApiInstance.login, body);
    const { result, error } = handleError(res);

    if (error) {
      if (error.code === API_CONFIG.unauthorizedErrorCode) {
        throw new Error('Incorrect email or password!');
      } else {
        throw new Error(error.message);
      }
    }

    const { user_obj } = result?.data || {};
    const { access_token, refresh_token } = user_obj || {};

    ApiInstance.setAuthToken(access_token);
    const resSwiftLogin = yield call(ApiInstance.swiftLogin, access_token);
    const { result: resultSwiftLogin, error: errorSwiftLogin } = handleError(resSwiftLogin);
    if (!errorSwiftLogin) {
      const { access_token: swiftToken } = resultSwiftLogin?.data || {};
      ApiInstance.setSwiftAuthToken(swiftToken);
      yield put(UserRedux.Creators.setToken(access_token));
      yield put(UserRedux.Creators.setRefreshToken(refresh_token));
      yield put(UserRedux.Creators.setSwiftToken(swiftToken));
    }

    const userInfo = convertUserObj(user_obj);
    yield put(UserRedux.Creators.loginSucceed(userInfo, access_token, refresh_token));
    yield put(UserRedux.Creators.getUserInfo());
    callback(user_obj, null);
  } catch (e) {
    callback(null, e);
  }
}

function* getUserInfo() {
  globalLoading.show();
  const res = yield call(ApiInstance.getUserInfo);
  globalLoading.hide();
  const { result, error } = handleError(res, true);
  if (!error) {
    const userInfo = convertUserObj(result?.data?.[0]);
    yield put(DataActions.getProductsListing(userInfo.group_id));
    yield put(UserRedux.Creators.setUserInfo(userInfo));
  }
}

const convertUserObj = user => {
  const { user_pm_obj, access_pages_obj } = user || {};
  const paymentMethods = {};
  if (user_pm_obj?.length) {
    user_pm_obj.forEach(item => {
      const key = item.code ? `${item.type}_${item.code}` : item.type;
      paymentMethods[key] = item;
    });
  }

  const accessPages = {};
  if (access_pages_obj?.length) {
    access_pages_obj.forEach(item => {
      const key = item.access_page;
      accessPages[key] = item;
    });
  }

  const cardDetail = user_pm_obj?.find?.((item: any) => item.type === 'token');
  const payLaterObj = user_pm_obj?.find?.((item: any) => item.payment_method_obj?.background_service === 'pay_later');
  return { ...user, cardDetail, paymentMethods, payLaterObj, accessPages };
};

export function* logoutRequest() {
  try {
    ApiInstance.setAuthToken(null);
    ApiInstance.setSwiftAuthToken(null);
    yield put(OverviewRedux.Creators.setListUploadVehicles([]));
    yield put(UserRedux.Creators.logoutSucceed());
  } catch (e: any) {
    yield put(UserRedux.Creators.requestFailure(e.message));
  }
}

export default [
  // @ts-ignore
  takeLatest(UserRedux.Types.LOGIN_REQUEST, loginRequest),
  takeLatest(UserRedux.Types.LOGOUT_REQUEST, logoutRequest),
  takeLatest(UserRedux.Types.GET_USER_INFO, getUserInfo),
];
